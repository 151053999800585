import { useMemo } from "react";
import {
  Radar,
  RadarChart,
  PolarGrid,
  PolarAngleAxis,
  PolarRadiusAxis,
} from "recharts";

const GraphinaChart = ({ data }) => {
  const chartData = useMemo(
    () => [
      { title: "Memory", you: 59, everyone: 85 },
      { title: "Language", you: 47, everyone: 65 },
      { title: "Attention", you: 65, everyone: 78 },
      { title: "Executive Function", you: 74, everyone: 95 },
      { title: "Visuospatial Skills", you: 56, everyone: 75 },
      { title: "Perception", you: 66, everyone: 83 },
    ],
    []
  );
  return (
    <RadarChart
      cx={105}
      cy={60}
      outerRadius={32}
      width={170}
      height={110}
      data={chartData}
    >
      <PolarGrid />
      <PolarAngleAxis
        dataKey="title"
        fontSize={8}
        stroke="#fff"
        strokeWidth={1}
        tickLine={false}
      />
      <PolarRadiusAxis angle={90} tickCount={4} tick={false} />
      <Radar
        dataKey="everyone"
        stroke="#3266FF"
        fill="#3266FF"
        fillOpacity={0.75}
      />
      <Radar dataKey="you" stroke="#F28A28" fill="#FFC061" fillOpacity={0.75} />
    </RadarChart>
  );
};

export default GraphinaChart;
