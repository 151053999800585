import { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import LoadingScreen from "../components/LoadingScreen";
// import {
//   CircularProgressbarWithChildren,
//   buildStyles,
// } from "react-circular-progressbar";
// import "react-circular-progressbar/dist/styles.css";
// import WalkStep from "../assets/icons/walk-svgrepo-com.svg";
// import Cal from "../assets/icons/calories.svg";
import { ReactComponent as Check } from "../assets/icons/Check.svg";
import Scan from "../assets/icons/object-scan-svgrepo-com.svg";
import { sdkWrapperURL } from "../utils/api-url-list";
import { useAuth } from "../utils/auth";

const HealthChallenge = () => {
  const {
    getToken,
    userData: { profile_id },
  } = useAuth();
  const [loading, setLoading] = useState(true);
  const [isScanDoneToday, setScanDoneToday] = useState(false);
  const [streak, setStreak] = useState(0);
  const [moodStreak, setMoodStreak] = useState(0);
  const [isMoodDataFilledToday, setMoodDataFilledToday] = useState(false);

  useEffect(() => {
    const controller = new AbortController();
    setLoading(true);
    const currentDate = new Date();
    const timeZoneOffsetInMinutes = currentDate.getTimezoneOffset();
    const timeZoneOffsetHours = Math.floor(
      Math.abs(timeZoneOffsetInMinutes) / 60
    )
      .toString()
      .padStart(2, "0");
    const timeZoneOffsetMinutes = (Math.abs(timeZoneOffsetInMinutes) % 60)
      .toString()
      .padStart(2, "0");
    const timeZoneOffsetSign = timeZoneOffsetInMinutes >= 0 ? "-" : "+";
    const start_date = `${currentDate
      .toISOString()
      .slice(
        0,
        -1
      )}${timeZoneOffsetSign}${timeZoneOffsetHours}:${timeZoneOffsetMinutes}`;
    Promise.allSettled([
      new Promise(async (resolve, reject) => {
        try {
          const token = await getToken();
          const streakResp = await fetch(sdkWrapperURL("/users/user-streaks"), {
            method: "POST",
            headers: {
              "Content-Type": "application/json",
              Authorization: token,
            },
            body: JSON.stringify({
              profile_id,
              start_date,
              streak_code: "CS-07",
            }),
          });
          const streakRespJSON = await streakResp.json();
          if (streakRespJSON?.statusCode?.toString().startsWith("2")) {
            const streak_count = streakRespJSON.streak_count;
            const scanDone = streakRespJSON.completed_today;
            if (scanDone) {
              if (streak_count === 0) setStreak(1);
              else setStreak(streak_count);
            } else {
              if (streak_count >= 7) setStreak(0);
              else setStreak(streak_count);
            }
            setScanDoneToday(scanDone);
            resolve();
          } else
            throw new Error(
              streakRespJSON?.message ?? "Error in Fetching Streak Data"
            );
        } catch (err) {
          reject(err);
        }
      }),
      new Promise(async (resolve, reject) => {
        try {
          const token = await getToken();
          const streakResp = await fetch(sdkWrapperURL("/users/user-streaks"), {
            method: "POST",
            headers: {
              "Content-Type": "application/json",
              Authorization: token,
            },
            body: JSON.stringify({
              profile_id,
              start_date,
              streak_code: "MT-07",
            }),
          });
          const streakRespJSON = await streakResp.json();
          if (streakRespJSON?.statusCode?.toString().startsWith("2")) {
            const streak_count = streakRespJSON.streak_count;
            const MoodDataFilled = streakRespJSON.completed_today;
            if (MoodDataFilled) {
              if (streak_count === 0) setMoodStreak(1);
              else setMoodStreak(streak_count);
            } else {
              if (streak_count >= 7) setMoodStreak(0);
              else setMoodStreak(streak_count);
            }
            setMoodDataFilledToday(MoodDataFilled);
            resolve();
          } else
            throw new Error(
              streakRespJSON?.message ?? "Error in Fetching Streak Data"
            );
        } catch (err) {
          reject(err);
        }
      }),
    ])
      .then((results) =>
        results.forEach((result) => {
          if (result.status === "rejected") console.error(result.reason);
        })
      )
      .catch(console.error)
      .finally(() => setLoading(false));
    return () => {
      controller.abort();
    };
  }, [getToken, profile_id]);

  return loading ? (
    <LoadingScreen style={{ minHeight: "60vh" }} />
  ) : (
    <div className="px-6 pt-0 pb-24">
      <div className="flex items-center mb-5">
        <Link to={-1} className="mr-3">
          <svg
            width="6"
            height="10"
            viewBox="0 0 10 17"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M9.42402 16.2142C9.99277 15.6454 9.99277 14.7267 9.42402 14.1579L3.76569 8.49959L9.42402 2.84126C9.99277 2.27251 9.99277 1.35376 9.42402 0.785009C8.85527 0.216259 7.93652 0.216259 7.36777 0.785009L0.674024 7.47876C0.105274 8.04751 0.105274 8.96626 0.674024 9.53501L7.36777 16.2288C7.92194 16.7829 8.85527 16.7829 9.42402 16.2142Z"
              fill="#fff"
            />
          </svg>
        </Link>
        <h3 className="text-white text-[14px] font-semibold">
          Weekly Challenges
        </h3>
      </div>
      <div className="mt-8 flex items-center flex-col justify-center">
        <div className="bg-gradient-to-t from-[#02102B] to-[#5D14B0]/80 px-4 py-4 rounded-[15px] w-full relative overflow-hidden mb-4 min-h-[150px]">
          <div className="flex w-full items-center justify-between">
            <div className="flex items-center">
              <img src={Scan} width="30" alt="" />
              <div className="ml-2">
                <h2 className="text-[14px] text-white font-semibold text-left">
                  Scan for 7 days
                </h2>
                <p className="text-[10px] text-white text-left">
                  {streak} day streak
                </p>
              </div>
            </div>
            <div className="flex flex-col items-end">
              <p className="text-[11px] font-medium text-white/[0.7]">
                Care Coins
              </p>
              <h2 className="text-[14px] text-[#C924A4] font-bold">700</h2>
            </div>
          </div>

          <div className="rounded-[12px] mt-4">
            <ul className="flex justify-between">
              {[...Array(streak).keys()].reverse().map((offset) => {
                const today = new Date();
                today.setDate(today.getDate() - offset - !isScanDoneToday);
                const day = today.toLocaleDateString(undefined, {
                  weekday: "short",
                });
                return (
                  <li
                    key={`completed-${offset}`}
                    className="flex flex-col items-center text-white text text-[11px] bg-[#A562FF] rounded-[20px] px-2 py-2"
                  >
                    {day}
                    <span className="w-[25px] h-[25px] rounded-full bg-transparent border border-[#8F4DE7] mt-2 flex items-center justify-center">
                      <Check width="10" />
                    </span>
                  </li>
                );
              })}
              {[...Array(7 - streak).keys()].map((offset) => {
                const today = new Date();
                today.setDate(today.getDate() + offset + isScanDoneToday);
                const day = today.toLocaleDateString(undefined, {
                  weekday: "short",
                });
                return (
                  <li
                    key={`not-completed-${offset}`}
                    className="flex flex-col items-center text-white/[0.8] text text-[11px] px-2 py-2"
                  >
                    {day}
                    <span className="w-[25px] h-[25px] rounded-full bg-transparent border border-[#8F4DE7] mt-2"></span>
                  </li>
                );
              })}
            </ul>
          </div>
        </div>
        {/* <div className="bg-gradient-to-t from-[#02102B] to-[#2055F3]/60 px-4 py-4 rounded-[15px] w-full relative overflow-hidden mb-4">
          <div className="flex">
            <div className="w-[120px] mt-1">
              <CircularProgressbarWithChildren
                value={60}
                strokeWidth={5}
                styles={buildStyles({
                  textColor: "red",
                  pathColor: "#3298F1",
                  trailColor: "#fff",
                  strokeWidth: "2",
                })}
              >
                <img style={{ width: 25 }} src={WalkStep} alt="step" />
                <div className="flex flex-col items-center">
                  <p className="text-[18px] text-[#C924A4] font-semibold">
                    4500
                  </p>
                  <p className="text-[8px] text-white/[0.7] mt-1">GOAL</p>
                  <p className="text-[8px] text-white/[0.7] tracking-[1px]">
                    70,000
                  </p>
                </div>
              </CircularProgressbarWithChildren>
            </div>
            <div className="flex flex-col ml-5 w-[60%]">
              <div className="mt-0 mb-1">
                <h2 className="text-[14px] text-white font-semibold text-left leading-[18px]">
                  You have walked&nbsp;
                  <span className=" font-semibold text-[#C924A4]">60%</span>
                  &nbsp;of your goal
                </h2>
              </div>

              <div className="flex items-center justify-between mt-2">
                <div className="flex items-center">
                  <span className="w-[32px] h-[32px] calori-bg rounded-full flex items-center justify-center mr-3">
                    <img src={Cal} width="32" alt="" />
                  </span>
                  <div>
                    <h5 className="text-[12px] text-white font-semibold tracking-[1px]">
                      Burned Calories
                    </h5>
                    <p className="text-[11px] text-white/[0.7] mt-1 tracking-[1px]">
                      2358 Kcal
                    </p>
                  </div>
                </div>
              </div>
              <div className="flex items-center mt-2 border-t-[1px] border-dotted border-[#2055F3]/[0.7] pt-2">
                <p className="text-[11px] text-white/[0.7]">Care Coins :</p>
                <h5 className="text-[14px] text-[#C924A4] font-bold ml-2">
                  450
                </h5>
              </div>
            </div>
          </div>
        </div> */}
        <div className="bg-gradient-to-t from-[#02102B] to-[#543C9A]/70 px-4 py-4 rounded-[15px] w-full relative overflow-hidden mb-4 min-h-[150px]">
          <div className="flex w-full items-center justify-between">
            <div className="flex flex-col ">
              <h2 className="text-[14px] text-white font-semibold text-left">
                7 days daily check-ins
              </h2>
              <p className="text-[10px] text-white text-left">
                {moodStreak} day streak
              </p>
            </div>
            <div className="flex flex-col items-end">
              <p className="text-[11px] font-medium text-white/[0.9]">
                Care Coins
              </p>
              <h2 className="text-[14px] text-[#C924A4] font-bold">700</h2>
            </div>
          </div>
          <div className="rounded-[12px] mt-4">
            <ul className="flex justify-between">
              {[...Array(moodStreak).keys()].reverse().map((offset) => {
                const today = new Date();
                today.setDate(
                  today.getDate() - offset - !isMoodDataFilledToday
                );
                const day = today.toLocaleDateString(undefined, {
                  weekday: "short",
                });
                return (
                  <li
                    key={`completed-${offset}`}
                    className="flex flex-col items-center text-white text text-[11px] bg-[#AE72FF] rounded-[20px] px-2 py-2"
                  >
                    {day}
                    <span className="w-[25px] h-[25px] rounded-full bg-transparent border border-[#8F4DE7] mt-2 flex items-center justify-center">
                      <Check width="10" />
                    </span>
                  </li>
                );
              })}
              {[...Array(7 - moodStreak).keys()].map((offset) => {
                const today = new Date();
                today.setDate(today.getDate() + offset + isMoodDataFilledToday);
                const day = today.toLocaleDateString(undefined, {
                  weekday: "short",
                });
                return (
                  <li
                    key={`not-completed-${offset}`}
                    className="flex flex-col items-center text-white/[0.6] text text-[11px] px-2 py-2"
                  >
                    {day}
                    <span className="w-[25px] h-[25px] rounded-full bg-transparent border border-[#8F4DE7] mt-2"></span>
                  </li>
                );
              })}
            </ul>
          </div>
        </div>
      </div>
    </div>
  );
};

export default HealthChallenge;
