import { useEffect, useState } from "react";
import { Navigate, useLocation } from "react-router-dom";

const DigitalClinic = () => {
  const location = useLocation();
  const url = location.state?.mskUrl ?? "";
  const [iframeHeight, setIframeHeight] = useState("100vh");

  useEffect(() => {
    const setInnerHeight = () => setIframeHeight(`${window.innerHeight}px`);
    setInnerHeight();
    window.addEventListener("resize", setInnerHeight);
    window.addEventListener("load", setInnerHeight);
    window.addEventListener("orientationchange", setInnerHeight);
    return () => {
      window.removeEventListener("resize", setInnerHeight);
      window.removeEventListener("load", setInnerHeight);
      window.removeEventListener("orientationchange", setInnerHeight);
    };
  }, []);

  return url?.length > 0 ? (
    <div className="text-white">
      <iframe
        title="Careplix<>Rootally - Engagement"
        src={url}
        className="w-full overflow-y-auto"
        style={{ height: iframeHeight }}
        scrolling="auto"
        sandbox="allow-scripts allow-top-navigation allow-same-origin"
        allow="camera *"
      />
    </div>
  ) : (
    <Navigate to={-1} />
  );
};

export default DigitalClinic;
