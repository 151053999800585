import { Link } from "react-router-dom";

import { ReactComponent as BackArrow } from "../assets/icons/BackArrow.svg";
import { ReactComponent as SupportBanner } from "../assets/icons/SupportBanner.svg";
import { ReactComponent as Mail } from "../assets/icons/Mail.svg";
import { ReactComponent as Phone } from "../assets/icons/Phone.svg";

const Support = () => (
  <section className="px-6 pt-2 pb-28">
    <div className="flex items-center">
      <Link className="shrink-0 p-1 pr-2.5" to={-1}>
        <BackArrow className="h-2.5 w-2.5" />
      </Link>
      <h3 className="text-sm font-medium">Support</h3>
    </div>

    <div className="my-6 space-y-6">
      <div className="mb-12 flex flex-col items-center">
        <SupportBanner height={200} width={200} />
        <p className="mt-4 text-sm font-medium">How can we help you?</p>
      </div>

      <Link
        to="mailto:connect@careplix.com"
        className="px-6 py-4 rounded-xl flex items-center justify-between space-x-3 bg-ternary"
      >
        <div className="text-xs space-y-1.5">
          <h4 className="opacity-75 font-medium">Email</h4>
          <h6>connect@careplix.com</h6>
        </div>
        <Mail className="shrink-0 h-5 w-5" />
      </Link>

      <Link
        to="tel:+913341816533"
        className="px-6 py-4 rounded-xl flex items-center justify-between space-x-3 bg-secondary"
      >
        <div className="text-xs space-y-1.5">
          <h4 className="opacity-75 font-medium">Phone</h4>
          <h6>033-41816533</h6>
        </div>
        <Phone className="shrink-0 h-5 w-5" />
      </Link>
    </div>
  </section>
);

export default Support;
