import { useState } from "react";
import { Link } from "react-router-dom";
import {
  Bar,
  BarChart,
  Cell,
  ResponsiveContainer,
  CartesianGrid,
  Tooltip,
  XAxis,
  YAxis,
} from "recharts";
import { Tab, Tabs, TabList, TabPanel } from "react-tabs";
import InfoIcon from "../assets/icons/info-icon.svg";
import StepImg from "../assets/images/step-img.png";

const data = [
  {
    name: "12am",
    uv: 1000,
    pv: 1000,
    amt: 900,
  },
  {
    name: "4am",
    uv: 600,
    pv: 650,
    amt: 700,
  },
  {
    name: "8am",
    uv: 500,
    pv: 600,
    amt: 550,
  },
  {
    name: "12pm",
    uv: 650,
    pv: 700,
    amt: 750,
  },
  {
    name: "4pm",
    uv: 700,
    pv: 650,
    amt: 600,
  },
  {
    name: "8pm",
    uv: 900,
    pv: 850,
    amt: 800,
  },
  {
    name: "12am",
    uv: 1000,
    pv: 1050,
    amt: 950,
  },
];

const Walking = () => {
  const [tabIndex, setTabIndex] = useState(0);

  return (
    <div className="w-full">
      <div className="flex items-center mb-8 px-6">
        <Link to="/" className="mr-3">
          <svg
            width="6"
            height="10"
            viewBox="0 0 10 17"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M9.42402 16.2142C9.99277 15.6454 9.99277 14.7267 9.42402 14.1579L3.76569 8.49959L9.42402 2.84126C9.99277 2.27251 9.99277 1.35376 9.42402 0.785009C8.85527 0.216259 7.93652 0.216259 7.36777 0.785009L0.674024 7.47876C0.105274 8.04751 0.105274 8.96626 0.674024 9.53501L7.36777 16.2288C7.92194 16.7829 8.85527 16.7829 9.42402 16.2142Z"
              fill="#fff"
            />
          </svg>
        </Link>
        <h3 className="text-white text-[14px] font-semibold">Walking</h3>
      </div>
      <Tabs
        selectedIndex={tabIndex}
        onSelect={(tabIndex) => setTabIndex(tabIndex)}
      >
        <TabList className="walking-tab flex justify-center items-center">
          <Tab className="order-4 text-xs font-medium text-[#fff]/[.7]">
            Today
          </Tab>
          <Tab className="order-3 text-xs font-medium mr-6 text-[#fff]/[.7]">
            Past 1 Week
          </Tab>
          <Tab className="order-2 text-xs font-medium mr-6 text-[#fff]/[.7]">
            Past 1 Month
          </Tab>
          <Tab className="order-1 text-xs font-medium mr-6 text-[#fff]/[.7]">
            Past 3 Months
          </Tab>
        </TabList>
        <TabPanel>
          <div className="px-6 mt-3">
            <div className="border border-[#812EFD]/50 rounded-[10px] pt-3 px-3">
              <h3 className="flex items-center text-white text-[14px] font-medium mb-4 mt-1 ml-3">
                Steps{" "}
                <Link>
                  <img src={InfoIcon} alt="info icon" className="ml-2" />
                </Link>
              </h3>
              <ResponsiveContainer width="100%" height={200}>
                <BarChart
                  className="ml-[-10px]"
                  data={data}
                  margin={{
                    top: 5,
                    right: -10,
                    left: -10,
                    bottom: 5,
                  }}
                >
                  <CartesianGrid horizontal vertical={false} />
                  <XAxis
                    tickLine={false}
                    axisLine={{ stroke: "#A977F9" }}
                    dataKey="name"
                    style={{ fontSize: 10, color: "#A977F9" }}
                  />
                  <YAxis
                    tickLine={false}
                    stroke="#A977F9"
                    axisLine={false}
                    style={{ fontSize: 10, color: "#A977F9" }}
                  />
                  <Tooltip />

                  <Bar dataKey="pv" barSize={8} radius={[10, 10, 10, 10]}>
                    <Cell fill="#2055F3" />
                    <Cell fill="#5D14B0" />
                    <Cell fill="#2055F3" />
                    <Cell fill="#5D14B0" />
                    <Cell fill="#2055F3" />
                    <Cell fill="#5D14B0" />
                    <Cell fill="#2055F3" />
                    <Cell fill="#5D14B0" />
                  </Bar>
                </BarChart>
              </ResponsiveContainer>
            </div>
          </div>
          <div className="px-6">
            <div className="flex items-center justify-between mt-6">
              <h3 className="text-[16px] text-[#812EFD] font-normal">
                Total Steps
              </h3>
              <p className="text-[10px] text-[#fff] text-right leading-[12px]">
                Last Recorded : 04-08-2022
                <br />
                at 11:46pm
              </p>
            </div>
            <div className=" w-full my-4">
              <img src={StepImg} alt="info icon" />
            </div>
            <h4 className="text-[14px] text-[#812EFD] font-normal mt-8">
              Your walking activity is{" "}
              <span className="text-[#FFC400] font-semibold">
                Somewhat Active
              </span>
            </h4>
            <p className="text-[12px] text-[#fff]/80 leading-[18px] text-justify mt-4">
              This indicates a moderate level of physical activity. This may be
              appropriate for individuals who are just starting to incorporate
              physical activity into their daily routine, or for those who have
              mobility limitations. However, it may not be sufficient for
              individuals who are looking to improve their cardiovascular
              fitness or achieve weight loss goals.
            </p>
            <div className="w-full mb-28 mt-8 flex items-center justify-center">
              <Link
                to="/Walking-details"
                className=" w-[70%] h-[35px] bg-gradient-to-r from-[#063BC4] to-[#680FAD] text-center text-[12px] text-white flex items-center justify-center hover:bg-[#812EFD] active:bg-[#812EFD] rounded-full"
              >
                View History
              </Link>
            </div>
          </div>
        </TabPanel>
        <TabPanel>
          <div className="px-6 mt-3">
            <div className="border border-[#E9E9E9] rounded-[10px] pt-3 px-3">
              <h3 className="flex items-center text-[#812EFD] text-[14px] font-medium mb-4 mt-1 ml-3">
                Average Steps{" "}
                <Link>
                  <img src={InfoIcon} alt="info icon" className="ml-2" />
                </Link>
              </h3>
              <ResponsiveContainer width="100%" height={200}>
                <BarChart
                  className="ml-[-10px]"
                  data={data}
                  margin={{
                    top: 5,
                    right: -10,
                    left: -10,
                    bottom: 5,
                  }}
                >
                  <CartesianGrid horizontal vertical={false} />
                  <XAxis
                    tickLine={false}
                    axisLine={{ stroke: "#812EFD" }}
                    dataKey="name"
                    style={{ fontSize: 10, color: "#812EFD" }}
                  />
                  <YAxis
                    tickLine={false}
                    stroke="#A977F9"
                    axisLine={false}
                    style={{ fontSize: 10, color: "#812EFD" }}
                  />
                  <Tooltip />

                  <Bar dataKey="pv" barSize={8} radius={[10, 10, 10, 10]}>
                    <Cell fill="#2055F3" />
                    <Cell fill="#5D14B0" />
                    <Cell fill="#2055F3" />
                    <Cell fill="#5D14B0" />
                    <Cell fill="#2055F3" />
                    <Cell fill="#5D14B0" />
                    <Cell fill="#2055F3" />
                    <Cell fill="#5D14B0" />
                  </Bar>
                </BarChart>
              </ResponsiveContainer>
            </div>
            <div className="w-full mb-28 mt-8 flex items-center justify-center">
              <Link
                to="/Walking-details"
                className="w-[70%] h-[35px] bg-gradient-to-r from-[#063BC4] to-[#680FAD] text-center text-[12px] text-white flex items-center justify-center hover:bg-[#812EFD] active:bg-[#812EFD] rounded-full"
              >
                View History
              </Link>
            </div>
          </div>
        </TabPanel>
        <TabPanel>
          <div className="px-6 mt-3">
            <div className="border border-[#E9E9E9] rounded-[10px] pt-3 px-3">
              <h3 className="flex items-center text-[#812EFD] text-[14px] font-medium mb-4 mt-1 ml-3">
                Average Steps{" "}
                <Link>
                  <img src={InfoIcon} alt="info icon" className="ml-2" />
                </Link>
              </h3>
              <ResponsiveContainer width="100%" height={200}>
                <BarChart
                  className="ml-[-10px]"
                  data={data}
                  margin={{
                    top: 5,
                    right: -10,
                    left: -10,
                    bottom: 5,
                  }}
                >
                  <CartesianGrid horizontal vertical={false} />
                  <XAxis
                    tickLine={false}
                    axisLine={{ stroke: "#812EFD" }}
                    dataKey="name"
                    style={{ fontSize: 10, color: "#812EFD" }}
                  />
                  <YAxis
                    tickLine={false}
                    stroke="#A977F9"
                    axisLine={false}
                    style={{ fontSize: 10, color: "#812EFD" }}
                  />
                  <Tooltip />

                  <Bar dataKey="pv" barSize={8} radius={[10, 10, 10, 10]}>
                    <Cell fill="#2055F3" />
                    <Cell fill="#5D14B0" />
                    <Cell fill="#2055F3" />
                    <Cell fill="#5D14B0" />
                    <Cell fill="#2055F3" />
                    <Cell fill="#5D14B0" />
                    <Cell fill="#2055F3" />
                    <Cell fill="#5D14B0" />
                  </Bar>
                </BarChart>
              </ResponsiveContainer>
            </div>
            <div className="w-full mb-28 mt-8 flex items-center justify-center">
              <Link
                to="/Walking-details"
                className=" w-[70%] h-[35px] bg-gradient-to-r from-[#063BC4] to-[#680FAD] text-center text-[12px] text-white flex items-center justify-center hover:bg-[#812EFD] active:bg-[#812EFD] rounded-full"
              >
                View History
              </Link>
            </div>
          </div>
        </TabPanel>
        <TabPanel>
          <div className="px-6 mt-3">
            <div className="border border-[#E9E9E9] rounded-[10px] pt-3 px-3">
              <h3 className="flex items-center text-[#812EFD] text-[14px] font-medium mb-4 mt-1 ml-3">
                Average Steps{" "}
                <Link>
                  <img src={InfoIcon} alt="info icon" className="ml-2" />
                </Link>
              </h3>
              <ResponsiveContainer width="100%" height={200}>
                <BarChart
                  className="ml-[-10px]"
                  data={data}
                  margin={{
                    top: 5,
                    right: -10,
                    left: -10,
                    bottom: 5,
                  }}
                >
                  <CartesianGrid horizontal vertical={false} />
                  <XAxis
                    tickLine={false}
                    axisLine={{ stroke: "#812EFD" }}
                    dataKey="name"
                    style={{ fontSize: 10, color: "#812EFD" }}
                  />
                  <YAxis
                    tickLine={false}
                    stroke="#A977F9"
                    axisLine={false}
                    style={{ fontSize: 10, color: "#812EFD" }}
                  />
                  <Tooltip />

                  <Bar dataKey="pv" barSize={8} radius={[10, 10, 10, 10]}>
                    <Cell fill="#2055F3" />
                    <Cell fill="#5D14B0" />
                    <Cell fill="#2055F3" />
                    <Cell fill="#5D14B0" />
                    <Cell fill="#2055F3" />
                    <Cell fill="#5D14B0" />
                    <Cell fill="#2055F3" />
                    <Cell fill="#5D14B0" />
                  </Bar>
                </BarChart>
              </ResponsiveContainer>
            </div>
            <div className="w-full mb-28 mt-8 flex items-center justify-center">
              <Link
                to="/Walking-details"
                className=" w-[70%] h-[35px] bg-gradient-to-r from-[#063BC4] to-[#680FAD] text-center text-[12px] text-white flex items-center justify-center hover:bg-[#812EFD] active:bg-[#812EFD] rounded-full"
              >
                View History
              </Link>
            </div>
          </div>
        </TabPanel>
      </Tabs>
    </div>
  );
};

export default Walking;
