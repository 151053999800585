import { ReactComponent as CheckMark } from "../assets/icons/Check.svg";

const ListItem = ({ children }) => (
  <li className="flex items-start justify-start space-x-4">
    <CheckMark className="flex-shrink-0 mt-1 h-4 w-4 text-secondary" />
    <p className="text-darkgray text-sm">{children}</p>
  </li>
);

const TermsOfUse = () => (
  <div className="p-8 bg-white text-justify">
    <h1 className="mb-8 text-secondary text-2xl text-center font-semibold">
      Terms of Use
    </h1>

    <p className="mt-3 text-darkgray text-sm">
      Thank you for using our products and services (the “Service/s”). By
      becoming a member or using our Services, you accept and agree to these
      Terms and Conditions (“Terms”). Therefore, it is important that you
      carefully read through these Terms. If you do not agree to these Terms,
      you should not register with Careplix Vitals.
    </p>
    <p className="mt-3 text-darkgray text-sm">
      The Application Careplix Vitals mobile app is operated and managed by
      (CareNow Healthcare Pvt Ltd, a company incorporated under the provisions
      of the Companies Act, 2013 and having its registered office at 71D/1
      Swinhoe Lane, 2nd Floor -Kolkata-India on behalf of itself and of CareNow
      International LLC, with its principal place of business at 7115 West North
      Avenue, Oak Park-IL USA hereinafter referred to as the “WE, or OUR or
      US”).
    </p>
    <p className="mt-3 text-darkgray text-sm">
      These Terms of Use (the "Agreement") describe the terms and conditions
      under which users ("Users" or "you") can use the Application (as defined
      hereafter).
    </p>
    <p className="mt-3 text-darkgray text-sm">
      To be able to use our Products, you first need to accept and agree to our
      Privacy Policy. You cannot use the Products unless you first accept our
      Privacy Policy.
    </p>
    <p className="mt-3 text-darkgray text-sm">
      BY USING THE APPLICATION, YOU ALSO ACCEPT OUR TERMS OF USE. SO PLEASE MAKE
      SURE YOU CAREFULLY READ THE PRESENT TERMS OF USE BEFORE USING THE
      APPLICATION.
    </p>
    <p className="mt-3 text-darkgray text-sm">
      If you have any questions about the Products (as defined hereinafter) of
      this Agreement, please contact us via conect@careplix.com.
    </p>

    <h3 className="mt-6 text-primary font-medium">PLEASE NOTE</h3>
    <p className="mt-3 text-darkgray text-sm">
      THAT YOU ARE NOT OBLIGED TO INSTALL THE APPLICATION AND YOUR REFUSAL TO
      INSTALL THE APPLICATION DOES NOT HAVE ANY IMPACT ON A POTENTIAL TREATMENT
      BY YOUR PHYSICIAN OR THE QUALITY OF SUCH TREATMENT. IF YOU THINK YOU MAY
      HAVE A MEDICAL EMERGENCY, PLEASE CONTACT YOUR PHYSICIAN OR THE EMERGENCY
      SERVICES IMMEDIATELY.
    </p>

    <h3 className="mt-6 text-primary font-medium">Using our Services</h3>
    <p className="mt-3 text-darkgray text-sm">
      To use our Services, you must follow some simple rules. The Service is
      only available for your private use and is not intended for commercial
      use. Do not misuse the Services, so that either Careplix Vitals or anyone
      else is harmed in any way. You may only use the Services as permitted by
      law and these Terms. By using our Services, you agree that:
    </p>
    <p className="mt-3 text-darkgray text-sm">
      This app uses a phone camera and flashlight to determine heart rate and
      blood oxygen level. You may feel a little sensation on your finger as the
      scan proceeds.
    </p>
    <p className="mt-3 text-darkgray text-sm">
      All of the personal data provided by you is accurate and up to date. You
      are solely responsible for all activities on your account and all the
      content that is uploaded and/or created under your Careplix Vitals account
      (“User Material”).
    </p>
    <p className="mt-3 text-darkgray text-sm">
      Careplix Vitals does not monitor the contents of the Service, but may at
      any time choose, at its sole discretion, to remove User Material from the
      Service and/or your user account and to terminate your account and
      membership.
    </p>
    <p className="mt-3 text-darkgray text-sm">
      Your membership, including your email and password, with Careplix Vitals,
      is personal and may not be transferred or used by someone else. You are
      responsible for safely storing your login details. Careplix Vitals is not
      in any way responsible for any loss or damage caused by unauthorized
      access to your account or the use of your login details. If you learn of
      or suspect any unauthorized use of your account, you must immediately
      inform Careplix Vitals's Customer Service.
    </p>
    <p className="mt-3 text-darkgray text-sm">
      Contact details are available at the bottom of this document. Careplix
      Vitals is not intended for use by persons under the age of 13. To use
      Careplix Vitals, you must be at least 13 years old.
    </p>
    <p className="mt-3 text-darkgray text-sm">
      You may not engage in any commercial activities, advertise and/or provide
      hints (such as links) on where commercial activities are present through
      our Services.
    </p>
    <p className="mt-3 text-darkgray text-sm">
      You may not contribute with any propaganda, religious and/or political
      views, or contribute with information that in any way contains or involves
      incitement to racial hatred, child pornography, or pornography through the
      use of our Services. Further, you may not defame, harass or offend other
      people through the use of our Services.
    </p>
    <p className="mt-3 text-darkgray text-sm">
      If you have any criticism or feedback regarding Careplix Vitals or our
      Services, you will first contact Careplix Vitals to help us to improve our
      Services.
    </p>
    <p className="mt-3 text-darkgray text-sm">
      You may not transmit, and/or distribute files that may damage Careplix
      Vitals or others' computers or property (such as viruses and trojan
      horses).
    </p>
    <p className="mt-3 text-darkgray text-sm">
      You may not share others' personal information, without their approval.
    </p>

    <h3 className="mt-6 text-primary font-medium">Intellectual Property</h3>
    <p className="mt-3 text-darkgray text-sm">
      All rights in and to the Services, including any trademarks, service
      marks, trade names, and copyrighted content (collectively “Intellectual
      Property”) presented within the Service are the property of Careplix
      Vitals and/or third parties.
    </p>
    <p className="mt-3 text-darkgray text-sm">
      You agree not to use Intellectual Property for any other purposes except
      for your use of the Service unless required otherwise by applicable
      mandatory law.
    </p>
    <p className="mt-3 text-darkgray text-sm">
      By submitting User Material to Careplix Vitals, you warrant and represent
      that you hold the copyright, trademark, and/or other intellectual property
      rights to your content. You agree to grant Careplix Vitals a
      non-exclusive, transferable, sub-licensable, royalty-free, worldwide
      license to use User Material to the extent necessary for Careplix Vitals
      to operate and maintain the Service. This license shall remain valid until
      the respective User Material is deleted from the Service by you or by
      Careplix Vitals in accordance with these Terms.
    </p>

    <h3 className="mt-6 text-primary font-medium">
      Disclaimer and limitation of liability
    </h3>
    <p className="mt-3 text-darkgray text-sm">
      You use the Service at your own risk. The Service is provided “AS IS”,
      without any warranties, and Careplix Vitals does not warrant that the
      Service and availability thereof will be uninterrupted or error-free.
      Careplix Vitals does not assume any responsibility for errors or omissions
      in the information or software or other documents, including User
      Material, which is referenced by or linked to. References or links to
      third parties websites are provided "AS IS" without warranty of any kind,
      either express or implied. In no event shall Careplix Vitals be liable for
      any indirect or consequential damages, except in cases of intentional
      misconduct or gross negligence. To the maximum extent permitted under
      applicable law, Careplix Vitals's aggregated liability for any direct
      damages shall be limited to the lesser of: (i) membership fees paid by the
      user; (ii) membership fees paid by the user during the previous calendar
      year.
    </p>

    <h3 className="mt-6 text-primary font-medium">Miscellaneous</h3>
    <p className="mt-3 text-darkgray text-sm">
      You are not entitled to assign your rights and/or obligations under these
      Terms or use of the Service to any third party without Careplix Vitals's
      prior written consent. Careplix Vitals is entitled to assign its rights
      and/or obligations under these Terms.
    </p>
    <p className="mt-3 text-darkgray text-sm">
      Careplix Vitals reserves the right to refuse the Service to anyone for any
      reason at any time. Careplix Vitals may revise these Terms from time to
      time and the most current version will always be posted on Careplix
      Vitals's website (www. Careplix Vitals.com). Any and all material changes
      shall become into effect between you and Careplix Vitals upon your
      acceptance of such changes (e.g. by using the Services after such
      notification has been made to you or renewing your subscription).
    </p>

    <h3 className="mt-6 text-primary font-medium">
      Governing law and dispute resolution
    </h3>
    <p className="mt-3 text-darkgray text-sm">
      This Agreement and any claims arising out of or relating to this Agreement
      and its subject matter shall be governed by and construed under the laws
      of India, without reference to its conflicts of law principles.
    </p>

    <h3 className="mt-6 text-primary font-medium">Contact details</h3>
    <p className="mt-3 text-darkgray text-sm">
      For further information, inquiries, or assistance you may contact Careplix
      Vitals. Customer Service is available in ways specified on the website or
      in the apps. The channels for support might differ from time to time.
    </p>

    <h3 className="mt-6 text-primary font-medium">Contact Information</h3>
    <p className="mt-3 text-darkgray text-sm">
      <b>Address -</b>
    </p>
    <p className="mt-3 text-darkgray text-sm">
      CareNow Healthcare Pvt Ltd
      <br />
      71, D/1, Swinhoe Ln., New Ballygunge, Kasba, <br />
      Kolkata, West Bengal 700042
    </p>
    <p className="mt-3 text-darkgray text-sm">
      <b>Email -</b>
    </p>
    <p className="mt-3 text-darkgray text-sm">
      connect@careplix.com / help@carenow.healthcare
    </p>

    <h3 className="mt-6 text-primary font-medium">
      LEGAL AND MEDICAL DISCLAIMER
    </h3>
    <ul className="mt-3 space-y-4">
      <ListItem>
        <b>THIS APP DOES NOT PROVIDE MEDICAL/HEALTH ADVICE. </b>
        <br />
        The information including but not limited to text graphics images and
        vitals contained on this app are for information purposes only. No
        material on this app is intended to be a substitute for professional
        medical advice, diagnosis, or treatment. We recommend you to consult
        your physician or other qualified health care provider with any
        questions you may have regarding a medical condition or treatment and
        never disregard professional medical advice or delay in seeking it
        because you have read something on this website.
      </ListItem>
      <ListItem>
        The information provided on our APP is for educational and informational
        purposes only and solely as a SELF HELP TOOL FOR YOUR OWN USE.
      </ListItem>
      <ListItem>
        We are not doctors/physicians or any medical health providers,
        registered or licensed. Always seek the advice of your Health Care
        Provider and We request you to not stop any medication or start any
        based on any information provided under this APP.
      </ListItem>
      <ListItem>
        All content and information contained in this APP DO NOT CONSTITUTE A
        PATIENT-CLIENT RELATIONSHIP. Always consult a professional in the area
        for your particular needs and circumstances prior to making any
        professional, legal, and medical decisions.
      </ListItem>
      <ListItem>
        DO NOT USE THIS APP IF YOUR PHYSICIAN OR HEALTH CARE PROVIDER ADVISES
        AGAINST IT.
      </ListItem>
    </ul>
  </div>
);

export default TermsOfUse;
