import { Link, useLocation } from "react-router-dom";

const NutritionPackageList = () => {
  const {
    state: { name, data },
  } = useLocation();
  return (
    <div className="pb-28 w-full">
      <div className="px-6">
        <div className="flex items-center mb-5">
          <Link to={-1} className="mr-3">
            <svg
              width="6"
              height="10"
              viewBox="0 0 10 17"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M9.42402 16.2142C9.99277 15.6454 9.99277 14.7267 9.42402 14.1579L3.76569 8.49959L9.42402 2.84126C9.99277 2.27251 9.99277 1.35376 9.42402 0.785009C8.85527 0.216259 7.93652 0.216259 7.36777 0.785009L0.674024 7.47876C0.105274 8.04751 0.105274 8.96626 0.674024 9.53501L7.36777 16.2288C7.92194 16.7829 8.85527 16.7829 9.42402 16.2142Z"
                fill="#fff"
              />
            </svg>
          </Link>
          <h3 className="text-white text-[14px] font-semibold">{name}</h3>
        </div>
      </div>
      <div className="w-full px-6">
        {data?.contents?.map((el, index) => (
          <div className="mb-4" key={index}>
            <Link
              to="/content-details"
              state={{
                name: el.title,
                data: el.contents?.sort?.(
                  (a, b) => (a.content_rank ?? 0) - (b.content_rank ?? 0)
                ),
              }}
            >
              <img
                src={el?.thumbnail}
                className=" rounded-xl mb-3 opacity-90"
                alt={el?.title}
              />
              <p className="text-[12px] font-semibold text-[#812EFD]">
                {el?.title}
              </p>
            </Link>
          </div>
        ))}
      </div>
    </div>
  );
};

export default NutritionPackageList;
