import { Fragment, useState } from "react";
import { Link } from "react-router-dom";
import { Dialog, Transition } from "@headlessui/react";

const Medication = () => {
  let [isOpen, setIsOpen] = useState(false);
  let [isOpen2, setIsOpen2] = useState(false);
  function closeModal() {
    setIsOpen(false);
  }
  function openModal() {
    setIsOpen(true);
  }
  function closeModal2() {
    setIsOpen2(false);
  }
  function openModal2() {
    setIsOpen2(true);
  }

  return (
    <div
      className="px-6"
      data-aos="fade-up"
      data-aos-delay="150"
      data-aos-duration="1000"
    >
      <div className="flex items-center mb-5">
        <Link to="/" className="mr-3">
          <svg
            width="7"
            height="12"
            viewBox="0 0 10 17"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M9.42402 16.2142C9.99277 15.6454 9.99277 14.7267 9.42402 14.1579L3.76569 8.49959L9.42402 2.84126C9.99277 2.27251 9.99277 1.35376 9.42402 0.785009C8.85527 0.216259 7.93652 0.216259 7.36777 0.785009L0.674024 7.47876C0.105274 8.04751 0.105274 8.96626 0.674024 9.53501L7.36777 16.2288C7.92194 16.7829 8.85527 16.7829 9.42402 16.2142Z"
              fill="#192852"
            />
          </svg>
        </Link>
        <h3 className="text-primary text-[16px] font-semibold">
          Medications & Lab Orders
        </h3>
      </div>
      <h3 className="text-[#232323] text-[14px] font-medium mb-4">
        My Medications
      </h3>
      <div className="flex justify-between items-center mb-4">
        <h4 className="text-secondary text-[13px] font-medium">
          Current Medications
        </h4>
        <button
          onClick={openModal}
          className="flex items-center justify-center px-4 py-1 w-[103px] h-[30px] rounded-full bg-secondary disabled:bg-darkgray text-white text-[12px] font-medium hover:bg-primary active:bg-primary"
        >
          Add New
        </button>
      </div>
      <div className="w-full mt-4">
        <div className="bg-[#FAFAFA] w-full flex mb-1">
          <div className="w-[34%] text-[12px] text-[#182A4E]/[.6] font-medium text-left p-1">
            Drug Name
          </div>
          <div className="w-[22%] text-[12px] text-[#182A4E]/[.6] font-medium p-1 text-left">
            Frequency
          </div>
          <div className="w-[22%] text-[12px] text-[#182A4E]/[.6] font-medium p-1 text-left">
            Duration
          </div>
          <div className="w-[22%] text-[12px] text-[#182A4E]/[.6] font-medium p-1 text-right">
            Insdivuctions
          </div>
        </div>
        <div className="flex">
          <div className="w-[34%] text-[10px] text-[#182A4E] font-light leading-[12px] p-1 align-top text-left">
            Magaldrate (400mg) + Simedivicone (20mg) Mag S Mango Syrup
          </div>
          <div className="w-[22%] text-[10px] text-[#182A4E] font-light leading-[12px] p-1 align-top text-left">
            divrice a day
          </div>
          <div className="w-[22%] text-[10px] text-[#182A4E] font-light leading-[12px] p-1 align-top text-left">
            35 days
          </div>
          <div className="w-[22%] text-[10px] text-[#182A4E] font-light leading-[12px] p-1 align-top text-right">
            Urgent
          </div>
        </div>

        <h4 className="text-secondary text-[13px] font-medium mb-2 mt-4">
          Medications History
        </h4>
        <div className="w-full">
          <div className="bg-[#FAFAFA] w-full flex mb-1">
            <div className="w-[44%] text-[12px] text-[#182A4E]/[.6] font-medium text-left p-1">
              Drug Name
            </div>
            <div className="w-[28%] text-[12px] text-[#182A4E]/[.6] font-medium p-1 text-left">
              Frequency
            </div>
            <div className="w-[28%] text-[12px] text-[#182A4E]/[.6] font-medium p-1 text-right">
              Prescribed by
            </div>
          </div>
          <div className="flex mb-1">
            <div className="w-[44%] text-[10px] text-[#182A4E] font-light leading-[12px] p-1 align-top">
              Paracetamol (1000mg) Lekadol 1000mg divt
            </div>
            <div className="w-[28%] text-[10px] text-[#182A4E] font-light leading-[12px] p-1 align-top">
              divrice a day
            </div>
            <div className="w-[28%] text-[10px] text-[#182A4E] font-light leading-[12px] p-1 align-top text-right">
              Dr Ankit Saha
            </div>
          </div>
          <div className="bg-[#FAFAFA] w-full flex mb-1">
            <div className="w-[44%] text-[10px] text-[#182A4E] font-light leading-[12px] p-1 align-top">
              Cedivizine divt
            </div>
            <div className="w-[28%] text-[10px] text-[#182A4E] font-light leading-[12px] p-1 align-top">
              Once a day
            </div>
            <div className="w-[28%] text-[10px] text-[#182A4E] font-light leading-[12px] p-1 align-top text-right">
              Dr S Dutta
            </div>
          </div>
          <div className="flex">
            <div className="w-[44%] text-[10px] text-[#182A4E] font-light leading-[12px] p-1 align-top">
              Vitamin C (1000mg) Limcor Chewable divt
            </div>
            <div className="w-[28%] text-[10px] text-[#182A4E] font-light leading-[12px] p-1 align-top">
              divrice a day
            </div>
            <div className="w-[28%] text-[10px] text-[#182A4E] font-light leading-[12px] p-1 align-top text-right">
              Dr Prakash K.
            </div>
          </div>
        </div>
        <div className="flex justify-between items-center mb-4 mt-4">
          <h4 className="text-secondary text-[13px] font-medium">Labs</h4>
          <button
            onClick={openModal2}
            className="flex items-center justify-center px-4 w-[103px] h-[30px] py-1 rounded-full bg-secondary disabled:bg-darkgray text-white text-[12px] font-medium hover:bg-primary active:bg-primary"
          >
            Add New
          </button>
        </div>
        <div className="w-full">
          <div className="bg-[#FAFAFA] w-full flex mb-1">
            <div className="w-[44%] text-[12px] text-[#182A4E]/[.6] font-medium text-left p-1">
              Test Name
            </div>
            <div className="w-[28%] text-[12px] text-[#182A4E]/[.6] font-medium p-1 text-left">
              Instructions
            </div>
            <div className="w-[28%] text-[12px] text-[#182A4E]/[.6] font-medium p-1 text-right">
              Document
            </div>
          </div>
          <div className="flex mb-1">
            <div className="w-[44%] text-[10px] text-[#182A4E] font-light leading-[12px] p-1 align-top flex items-center">
              Glucose, fasting (mg/dl)
            </div>
            <div className="w-[28%] text-[10px] text-[#182A4E] font-light leading-[12px] p-1 align-top flex items-center">
              With 2 days
            </div>
            <div className="w-[28%] flex justify-end my-1">
              <Link
                to=""
                className="flex w-[63px] h-[25px] items-center justify-center px-4 py-1 rounded-full bg-secondary disabled:bg-darkgray text-white text-[11px] font-medium hover:bg-primary active:bg-primary"
              >
                View
              </Link>
            </div>
          </div>
          <div className="bg-[#FAFAFA] w-full flex mb-1">
            <div className="w-[44%] text-[10px] text-[#182A4E] font-light leading-[12px] p-1 align-top flex items-center">
              Cetrizine Tablet
            </div>
            <div className="w-[28%] text-[10px] text-[#182A4E] font-light leading-[12px] p-1 align-top flex items-center">
              Once a day
            </div>
            <div className="w-[28%] flex justify-end my-1">
              <Link
                to=""
                className="flex w-[63px] h-[25px] items-center justify-center px-4 py-1 rounded-full bg-secondary disabled:bg-darkgray text-white text-[11px] font-medium hover:bg-primary active:bg-primary"
              >
                View
              </Link>
            </div>
          </div>
          <div className="flex">
            <div className="w-[44%] text-[10px] text-[#182A4E] font-light leading-[12px] p-1 align-top flex items-center">
              Vitamin C (1000mg) Limcor Chewable Tablet
            </div>
            <div className="w-[28%] text-[10px] text-[#182A4E] font-light leading-[12px] p-1 align-top flex items-center">
              Thrice a day
            </div>
            <div className="w-[28%] flex justify-end my-1">
              <Link
                to=""
                className="flex w-[63px] h-[25px] items-center justify-center px-4 py-1 rounded-full bg-secondary disabled:bg-darkgray text-white text-[11px] font-medium hover:bg-primary active:bg-primary"
              >
                View
              </Link>
            </div>
          </div>
        </div>
      </div>
      <Transition appear show={isOpen} as={Fragment}>
        <Dialog as="div" className="relative z-10" onClose={closeModal}>
          <Transition.Child
            as={Fragment}
            enter="ease-out duration-300"
            enterFrom="opacity-0"
            enterTo="opacity-100"
            leave="ease-in duration-200"
            leaveFrom="opacity-100"
            leaveTo="opacity-0"
          >
            <div className="fixed inset-0 bg-black bg-opacity-25" />
          </Transition.Child>

          <div className="fixed inset-0 overflow-y-auto">
            <div className="flex min-h-full items-center justify-center p-4 text-center">
              <Transition.Child
                as={Fragment}
                enter="ease-out duration-300"
                enterFrom="opacity-0 scale-95"
                enterTo="opacity-100 scale-100"
                leave="ease-in duration-200"
                leaveFrom="opacity-100 scale-100"
                leaveTo="opacity-0 scale-95"
              >
                <Dialog.Panel className="w-full max-w-md transform overflow-hidden rounded-2xl bg-white p-6 text-left align-middle shadow-xl transition-all">
                  <h3 className="text-secondary text-[14px] font-medium">
                    Add New Medication
                  </h3>
                  <button
                    onClick={closeModal}
                    className=" absolute right-5 top-4"
                  >
                    <svg width="30" height="30" viewBox="0 0 30 30" fill="none">
                      <path
                        d="M15 2.5C21.875 2.5 27.5 8.125 27.5 15C27.5 21.875 21.875 27.5 15 27.5C8.125 27.5 2.5 21.875 2.5 15C2.5 8.125 8.125 2.5 15 2.5Z"
                        stroke="#1B4083"
                        stroke-opacity="0.7"
                        strokeLinecap="round"
                        strokeLinejoin="round"
                      />
                      <path
                        d="M11.4624 11.4622L18.5374 18.5371"
                        stroke="#1B4083"
                        stroke-opacity="0.7"
                        strokeLinecap="round"
                        strokeLinejoin="round"
                      />
                      <path
                        d="M18.5374 11.4622L11.4624 18.5371"
                        stroke="#1B4083"
                        stroke-opacity="0.7"
                        strokeLinecap="round"
                        strokeLinejoin="round"
                      />
                    </svg>
                  </button>

                  <form>
                    <div className=" flex mt-4 mb-4">
                      <div className="flex items-center mr-4">
                        <input
                          id="vue-checkbox-list"
                          type="checkbox"
                          value=""
                          className="w-4 h-4 text-secondary bg-secondary border-secondary rounded focus:ring-blue-500 "
                        />
                        <label
                          for="vue-checkbox-list"
                          className="w-full py-3 ml-2 text-sm font-medium text-secondary dark:text-secondary"
                        >
                          Generic Name
                        </label>
                      </div>
                      <div className="flex items-center pl-3">
                        <input
                          id="react-checkbox-list"
                          type="checkbox"
                          value=""
                          className="w-4 h-4 text-secondary bg-secondary border-secondary rounded focus:ring-blue-500 dark:focus:ring-secondary  dark:focus:ring-offset-secondary dark:bg-secondary dark:border-gray-500"
                        />
                        <label
                          for="react-checkbox-list"
                          className="w-full py-3 ml-2 text-sm font-medium text-secondary dark:text-secondary"
                        >
                          Brand Name
                        </label>
                      </div>
                    </div>
                    <div className="mb-4 flex">
                      <select
                        className="bg-white border border-gray-300 text-gray-900 text-[12px] rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full dark:bg-secondary dark:border-secondary dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500 select"
                        required
                      >
                        <option selected>Drug Name *</option>
                        <option value="">United States</option>
                        <option value="">Canada</option>
                        <option value="">France</option>
                        <option value="">Germany</option>
                      </select>
                    </div>
                    <div className="flex mb-4">
                      <select
                        className="bg-white border border-gray-300 text-gray-900 text-[12px] rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full dark:bg-secondary dark:border-secondary dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500 select"
                        required
                      >
                        <option selected>Frequency *</option>
                        <option value="">United States</option>
                        <option value="">Canada</option>
                        <option value="">France</option>
                        <option value="">Germany</option>
                      </select>
                    </div>
                    <div className="flex justify-between mb-4">
                      <input
                        id="duration"
                        name="duration"
                        className="block w-full px-4 py-2 rounded-lg outline-none focus:border-secondary border border-gray-300 text-[11px] text-primary placeholder:text-[#5C6984]/[.6]"
                        placeholder={"Duration *"}
                        type="text"
                        inputMode="numeric"
                        autoComplete="one-time-code"
                        autoFocus
                        required
                      />

                      <select
                        className="bg-white ml-4 border border-gray-300 text-gray-900 text-[12px] rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full dark:bg-secondary dark:border-secondary dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500 select"
                        required
                      >
                        <option selected>Days</option>
                        <option value="">United States</option>
                        <option value="">Canada</option>
                        <option value="">France</option>
                        <option value="">Germany</option>
                      </select>
                    </div>
                    <div className="mb-4">
                      <input
                        name="duration"
                        className="block w-full px-4 py-2 h-[40px] rounded-lg outline-none focus:border-secondary border border-gray-300 text-[11px] text-primary placeholder:text-[#5C6984]/[.6]"
                        placeholder={"Prescribed Date *"}
                        type="text"
                        inputMode="numeric"
                        autoComplete="one-time-code"
                        autoFocus
                        required
                      />
                    </div>
                    <div className="mb-4">
                      <input
                        name="duration"
                        className="block w-full px-4 py-2 h-[40px] rounded-lg outline-none focus:border-secondary border border-gray-300 text-[11px] text-primary placeholder:text-[#5C6984]/[.6]"
                        placeholder={"End Date *"}
                        type="text"
                        inputMode="numeric"
                        autoComplete="one-time-code"
                        autoFocus
                        required
                      />
                    </div>
                    <div className="">
                      <textarea
                        id="message"
                        rows="4"
                        className="block p-2.5 w-full text-[11px] text-primary bg-white rounded-lg border border-gray-300 focus:ring-blue-500 focus:border-blue-500 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500 placeholder:text-[#5C6984]/[.6]"
                        placeholder="Instructions *"
                        required
                      ></textarea>
                    </div>
                    <div className="flex w-full items-center justify-center mt-6">
                      <button className="w-[130px] h-[35px] bg-secondary rounded-full flex items-center justify-center text-[12px] outline-none text-white font-medium hover:bg-primary active:bg-primary focus:outline-none">
                        Add
                      </button>
                    </div>
                  </form>
                </Dialog.Panel>
              </Transition.Child>
            </div>
          </div>
        </Dialog>
      </Transition>

      {/*  */}
      <Transition appear show={isOpen2} as={Fragment}>
        <Dialog as="div" className="relative z-10" onClose={closeModal2}>
          <Transition.Child
            as={Fragment}
            enter="ease-out duration-300"
            enterFrom="opacity-0"
            enterTo="opacity-100"
            leave="ease-in duration-200"
            leaveFrom="opacity-100"
            leaveTo="opacity-0"
          >
            <div className="fixed inset-0 bg-[#000]/[0.7]" />
          </Transition.Child>

          <div className="fixed inset-0 overflow-y-auto">
            <div className="flex min-h-full items-center justify-center p-4 text-center">
              <Transition.Child
                as={Fragment}
                enter="ease-out duration-300"
                enterFrom="opacity-0 scale-95"
                enterTo="opacity-100 scale-100"
                leave="ease-in duration-200"
                leaveFrom="opacity-100 scale-100"
                leaveTo="opacity-0 scale-95"
              >
                <Dialog.Panel className="w-full max-w-md transform overflow-hidden rounded-2xl bg-white p-6 text-left align-middle shadow-xl transition-all">
                  <h3 className="text-secondary text-[14px] font-medium">
                    Add New Lab Reports
                  </h3>
                  <button
                    onClick={closeModal2}
                    className=" absolute right-5 top-4"
                  >
                    <svg width="30" height="30" viewBox="0 0 30 30" fill="none">
                      <path
                        d="M15 2.5C21.875 2.5 27.5 8.125 27.5 15C27.5 21.875 21.875 27.5 15 27.5C8.125 27.5 2.5 21.875 2.5 15C2.5 8.125 8.125 2.5 15 2.5Z"
                        stroke="#1B4083"
                        stroke-opacity="0.7"
                        strokeLinecap="round"
                        strokeLinejoin="round"
                      />
                      <path
                        d="M11.4624 11.4622L18.5374 18.5371"
                        stroke="#1B4083"
                        stroke-opacity="0.7"
                        strokeLinecap="round"
                        strokeLinejoin="round"
                      />
                      <path
                        d="M18.5374 11.4622L11.4624 18.5371"
                        stroke="#1B4083"
                        stroke-opacity="0.7"
                        strokeLinecap="round"
                        strokeLinejoin="round"
                      />
                    </svg>
                  </button>

                  <form>
                    <div className="mb-4 flex mt-8">
                      <select
                        className="bg-white border border-gray-300 text-gray-900 text-[12px] rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full dark:bg-secondary dark:border-secondary dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500 select"
                        required
                      >
                        <option selected>Lab record name *</option>
                        <option value="">United States</option>
                        <option value="">Canada</option>
                        <option value="">France</option>
                        <option value="">Germany</option>
                      </select>
                    </div>

                    <div className="mt-4 mb-4">
                      <textarea
                        id="message"
                        rows="4"
                        className="block p-2.5 w-full text-[11px] text-primary bg-white rounded-lg border border-gray-300 focus:ring-blue-500 focus:border-blue-500 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500 placeholder:text-[#5C6984]/[.6]"
                        placeholder="Instructions *"
                        required
                      ></textarea>
                    </div>
                    <div className="flex items-center justify-center w-full">
                      <label
                        for="dropzone-file"
                        className="flex flex-col items-center justify-center w-full h-[109px] border border-gray-300 border-dashed rounded-lg cursor-pointer bg-white dark:hover:bg-bray-800 dark:bg-gray-700 hover:bg-gray-100 dark:border-gray-600 dark:hover:border-gray-500 dark:hover:bg-gray-600"
                      >
                        <div className="flex flex-col items-center justify-center pt-5 pb-6">
                          <div className="w-[52px] h-[52px] rounded-full bg-[#F0F0F0] flex items-center justify-center">
                            <svg
                              width="26"
                              height="26"
                              viewBox="0 0 26 26"
                              fill="none"
                              xmlns="http://www.w3.org/2000/svg"
                            >
                              <path
                                d="M21.6668 7.07241V21.1254C21.6668 22.6212 20.4543 23.8338 18.9585 23.8338H7.04183C5.54606 23.8338 4.3335 22.6212 4.3335 21.1254V4.87543C4.3335 3.37966 5.54606 2.1671 7.04183 2.1671H16.7615C16.9053 2.15798 17.055 2.20593 17.1748 2.32575L21.5082 6.65909C21.628 6.77891 21.6759 6.9286 21.6668 7.07241ZM16.2502 3.25043H7.04183C6.14437 3.25043 5.41683 3.97797 5.41683 4.87543V21.1254C5.41683 22.0229 6.14437 22.7504 7.04183 22.7504H18.9585C19.856 22.7504 20.5835 22.0229 20.5835 21.1254V7.58377H16.7918C16.4927 7.58377 16.2502 7.34126 16.2502 7.0421V3.25043ZM17.3335 4.01647V6.50043H19.8175L17.3335 4.01647ZM13.0002 11.5987V18.9577C13.0002 19.2568 12.7576 19.4993 12.4585 19.4993C12.1593 19.4993 11.9168 19.2568 11.9168 18.9577V11.5987L9.59151 13.924C9.37998 14.1356 9.03701 14.1356 8.82548 13.924C8.61395 13.7125 8.61395 13.3695 8.82548 13.158L12.0755 9.908C12.287 9.69647 12.63 9.69647 12.8415 9.908L16.0915 13.158C16.303 13.3695 16.303 13.7125 16.0915 13.924C15.88 14.1356 15.537 14.1356 15.3255 13.924L13.0002 11.5987Z"
                                fill="#3298F1"
                              />
                            </svg>
                          </div>

                          <p className="mb-2 text-sm text-[#182A4E]/[.7] mt-3">
                            Click to upload or drag and drop
                          </p>
                        </div>
                        <input
                          id="dropzone-file"
                          type="file"
                          className="hidden"
                        />
                      </label>
                    </div>
                    <div className="flex w-full items-center justify-center mt-6">
                      <button className="w-[130px] h-[35px] bg-secondary rounded-full flex items-center justify-center text-[12px] outline-none text-white font-medium hover:bg-primary active:bg-primary focus:outline-none">
                        Add
                      </button>
                    </div>
                  </form>
                </Dialog.Panel>
              </Transition.Child>
            </div>
          </div>
        </Dialog>
      </Transition>
    </div>
  );
};

export default Medication;
