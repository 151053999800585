import { useState, useEffect, useMemo, useCallback } from "react";
import { Link } from "react-router-dom";
import { Tab, Tabs, TabList, TabPanel } from "react-tabs";
import "react-tabs/style/react-tabs.css";

import Wave from "react-wavify";
import {
  Dialog,
  Card,
  CardBody,
  CardFooter,
  DialogBody,
  DialogFooter,
} from "@material-tailwind/react";
import { RadioGroup } from "@headlessui/react";
import {
  buildStyles,
  CircularProgressbarWithChildren,
} from "react-circular-progressbar";
import { Switch } from "@headlessui/react";
import "react-circular-progressbar/dist/styles.css";
import WaterIcon from "../assets/icons/water-icon.svg";
import HistoryIcon from "../assets/icons/history-icon.svg";
import ReminderIcon from "../assets/icons/reminder-icon.svg";
import TargetIcon from "../assets/icons/target-flag.svg";
import CustomizeGlass from "../assets/icons/glass-button.png";
import { ReactComponent as Loader } from "../assets/icons/Loader.svg";
import { ReactComponent as Undo } from "../assets/icons/undo.svg";
import AddDrink from "../assets/icons/add.svg";
import Glass1 from "../assets/icons/glass1.svg";
import Glass2 from "../assets/icons/glass2.svg";
import Glass3 from "../assets/icons/glass3.svg";
import Glass4 from "../assets/icons/glass4.svg";
import Glass5 from "../assets/icons/glass5.svg";
import { Chart } from "../components/Graph";
import { sdkWrapperURL } from "../utils/api-url-list";
import { useAuth } from "../utils/auth";

const formatDate = (date = new Date()) => {
  const year = date.getFullYear();
  const month = (date.getMonth() + 1).toString().padStart(2, "0");
  const day = date.getDate().toString().padStart(2, "0");
  return `${year}-${month}-${day}`;
};

const waterCupsize = [
  { img: Glass1, value: 100 },
  { img: Glass2, value: 150 },
  { img: Glass3, value: 200 },
  { img: Glass4, value: 250 },
  { img: Glass5, value: 300 },
  { img: Glass5, value: 500 },
];

const WaterTracker = () => {
  useEffect(() => {
    document.body.classList.add("Water-tracker");
    return () => {
      document.body.classList.remove("Water-tracker");
    };
  }, []);

  const {
    userData: { profile_id },
    getToken,
  } = useAuth();
  const [goalModalOpen, setGoalModalOpen] = useState(false);
  const [cupSizeModelOpen, setCupSizeModelOpen] = useState(false);
  const [selectedCupSize, setSelectCupSize] = useState(
    () =>
      JSON.parse(localStorage.getItem("selectedCupSize") ?? "{}")?.[
        profile_id
      ] ?? 100
  );
  const [enabled, setEnabled] = useState(false);
  const [loading, setLoading] = useState(false);
  const [btnLoading, setBtnLoading] = useState(false);
  const [waterInatkeStatus, setWaterIntakeStatus] = useState(0);
  const [waterIntakeGoal, setWaterIntake] = useState(0);
  const [waterIntakeReport, setWaterIntakeReport] = useState();
  const maxWaterIntake = 5000;
  const [val, setVal] = useState(maxWaterIntake / 2);
  const [selectedTabIndex, setSelectedTabIndex] = useState(0);
  const [graphType, setGraphType] = useState("weekly");
  const [graphData, setGraphData] = useState([]);
  const timezone = useMemo(
    () => Intl.DateTimeFormat().resolvedOptions().timeZone,
    []
  );
  const percentage = useMemo(
    () => Math.round((waterInatkeStatus / waterIntakeGoal) * 100) || 0,
    [waterInatkeStatus, waterIntakeGoal]
  );

  const fetchWaterIntakeGoals = useCallback(async () => {
    setLoading(true);
    try {
      const token = await getToken();
      const waterIntakeResp = await fetch(
        sdkWrapperURL("/nutrition/fetch-goals"),
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
            Authorization: token,
          },
          body: JSON.stringify({
            profile_id: profile_id,
            goal_type: "water_intake_goal",
            goal_data_version: "latest",
          }),
        }
      );
      const waterIntakeRespJSON = await waterIntakeResp.json();
      if (waterIntakeRespJSON?.statusCode?.toString().startsWith("2")) {
        setWaterIntake(waterIntakeRespJSON.water_intake_goal[0].goal_details);
        setVal(waterIntakeRespJSON.water_intake_goal[0].goal_details);
      } else
        throw Error(waterIntakeRespJSON.message ?? "Error in fetching Alerts.");
    } catch (err) {
      console.error(err);
    } finally {
      setLoading(false);
    }
  }, [getToken, profile_id]);
  const fetchWaterIntakeLog = useCallback(async () => {
    setLoading(true);
    try {
      const token = await getToken();
      const waterIntakeResp = await fetch(
        sdkWrapperURL("/nutrition/water-intake-history"),
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
            Authorization: token,
          },
          body: JSON.stringify({
            profile_id: profile_id,
            nutrition_type: "water_intake",
            interval: "aggregate",
            timezone,
          }),
        }
      );
      const waterIntakeRespJSON = await waterIntakeResp.json();
      if (waterIntakeRespJSON?.statusCode?.toString().startsWith("2")) {
        setWaterIntakeStatus(
          waterIntakeRespJSON.data[0]
            ? waterIntakeRespJSON?.data[0]?.total_quantity
            : "0"
        );
      } else
        throw Error(waterIntakeRespJSON.message ?? "Error in fetching Alerts.");
    } catch (err) {
      console.error(err);
    } finally {
      setLoading(false);
    }
  }, [getToken, profile_id, timezone]);
  const fetchWaterIntakeReport = useCallback(async () => {
    setLoading(true);
    try {
      const token = await getToken();
      const waterIntakeReportResp = await fetch(
        sdkWrapperURL("/nutrition/get-water-report"),
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
            Authorization: token,
          },
          body: JSON.stringify({
            profile_id: profile_id,
            nutrition_type: "water_intake",
            timezone,
          }),
        }
      );
      const waterIntakeReportRespJSON = await waterIntakeReportResp.json();
      if (waterIntakeReportRespJSON?.statusCode?.toString().startsWith("2")) {
        setWaterIntakeReport(waterIntakeReportRespJSON);
      } else
        throw Error(
          waterIntakeReportRespJSON.message ?? "Error in fetching Alerts."
        );
    } catch (err) {
      console.error(err);
    } finally {
      setLoading(false);
    }
  }, [getToken, profile_id, timezone]);
  const fetchWaterIntakeHistory = useCallback(async () => {
    try {
      const token = await getToken();
      const waterIntakeResp = await fetch(
        sdkWrapperURL("/nutrition/water-intake-history"),
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
            Authorization: token,
          },
          body: JSON.stringify({
            profile_id: profile_id,
            nutrition_type: "water_intake",
            interval: graphType,
            timezone,
          }),
        }
      );
      const waterIntakeRespJSON = await waterIntakeResp.json();
      if (waterIntakeRespJSON?.statusCode?.toString().startsWith("2")) {
        setGraphData(
          waterIntakeRespJSON?.data?.map?.((item) => ({
            label: item.meal_date,
            value: item.total_quantity,
            color: "#48AFFF",
          })) ?? []
        );
      } else
        throw Error(waterIntakeRespJSON.message ?? "Error in fetching Alerts.");
    } catch (err) {
      console.error(err);
    }
  }, [getToken, graphType, profile_id, timezone]);

  const handleTabSelect = (index) => {
    if (index === 1) {
      fetchWaterIntakeReport();
      fetchWaterIntakeHistory();
    }
    setSelectedTabIndex(index);
  };
  const handleSelectCupSize = () => {
    const old = JSON.parse(localStorage.getItem("selectedCupSize") ?? "{}");
    localStorage.setItem(
      "selectedCupSize",
      JSON.stringify({ ...old, [profile_id]: selectedCupSize })
    );
    setCupSizeModelOpen(false);
  };
  const handleCloseGoalModel = () => {
    fetchWaterIntakeGoals();
    setGoalModalOpen(false);
  };
  const handleCloseCupSizemodel = () => {
    setSelectCupSize(
      JSON.parse(localStorage.getItem("selectedCupSize") ?? "{}")?.[
        profile_id
      ] ?? 100
    );
    setCupSizeModelOpen(false);
  };
  const addWaterIntakeGoals = async () => {
    try {
      setBtnLoading(true);
      const token = await getToken();
      const alertResp = await fetch(sdkWrapperURL("/nutrition/add-goals"), {
        method: "POST",
        headers: { "Content-Type": "application/json", Authorization: token },
        body: JSON.stringify({
          profile_id: profile_id,
          goal_type: "water_intake_goal",
          value: val,
        }),
      });
      const RespJSON = await alertResp.json();
      if (RespJSON?.statusCode?.toString().startsWith("2")) {
        setGoalModalOpen(false);
        fetchWaterIntakeGoals();
      } else throw new Error(RespJSON.message);
    } catch (e) {
      console.error(e);
    } finally {
      setBtnLoading(false);
    }
  };
  const addWaterIntake = async () => {
    try {
      setLoading(true);
      const today = new Date();
      const token = await getToken();
      const alertResp = await fetch(
        sdkWrapperURL("/nutrition/update-nutrition"),
        {
          method: "POST",
          headers: { "Content-Type": "application/json", Authorization: token },
          body: JSON.stringify({
            profile_id: profile_id,
            nutrition_type: "water_intake",
            meal_date: formatDate(today),
            meal_type: "water",
            meal_info: {
              quantity: selectedCupSize,
              timestamp: today.toISOString(),
            },
          }),
        }
      );
      const RespJSON = await alertResp.json();
      if (RespJSON?.statusCode?.toString().startsWith("2")) {
        fetchWaterIntakeGoals();
        fetchWaterIntakeLog();
      } else throw new Error(RespJSON.message);
    } catch (e) {
      console.error(e);
    } finally {
      setLoading(false);
    }
  };
  const deleteWaterIntakeEntry = async () => {
    try {
      setLoading(true);
      const today = new Date();
      const token = await getToken();
      const alertResp = await fetch(
        sdkWrapperURL("/nutrition/delete-nutrition"),
        {
          method: "POST",
          headers: { "Content-Type": "application/json", Authorization: token },
          body: JSON.stringify({
            profile_id: profile_id,
            meal_date: formatDate(today),
            meal_type: "water",
            quantity: 1,
          }),
        }
      );
      const RespJSON = await alertResp.json();
      if (RespJSON?.statusCode?.toString().startsWith("2")) {
        fetchWaterIntakeLog();
      } else throw new Error(RespJSON.message);
    } catch (e) {
      console.error(e);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    setLoading(true);
    Promise.allSettled([
      fetchWaterIntakeGoals(),
      fetchWaterIntakeLog(),
      fetchWaterIntakeReport(),
      fetchWaterIntakeHistory(),
    ]).finally(() => {
      setLoading(false);
    });
  }, [
    fetchWaterIntakeGoals,
    fetchWaterIntakeLog,
    fetchWaterIntakeReport,
    fetchWaterIntakeHistory,
  ]);

  return (
    <div className="pb-20 fixed left-0 right-0">
      <div className="flex items-center mb-5 px-6">
        <Link to={-1} className="mr-3">
          <svg
            width="6"
            height="10"
            viewBox="0 0 10 17"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M9.42402 16.2142C9.99277 15.6454 9.99277 14.7267 9.42402 14.1579L3.76569 8.49959L9.42402 2.84126C9.99277 2.27251 9.99277 1.35376 9.42402 0.785009C8.85527 0.216259 7.93652 0.216259 7.36777 0.785009L0.674024 7.47876C0.105274 8.04751 0.105274 8.96626 0.674024 9.53501L7.36777 16.2288C7.92194 16.7829 8.85527 16.7829 9.42402 16.2142Z"
              fill="#fff"
            />
          </svg>
        </Link>
        <h3 className="text-white text-base font-semibold">Water Tracker</h3>
      </div>
      <div className="">
        <Tabs selectedIndex={selectedTabIndex} onSelect={handleTabSelect}>
          <TabList className="tracker-tab bg-[#13223E] mx-6 h-9 rounded-[10px] justify-between flex items-center overflow-hidden">
            <Tab className="flex items-center justify-center outline-none text-xs h-9 w-1/2">
              <img src={WaterIcon} alt="" className="mr-2 h-[14px]" />
              Today
            </Tab>
            <Tab className="flex items-center justify-center outline-none text-xs h-9 w-1/2">
              <img src={HistoryIcon} alt="" className="mr-2 h-[15px]" />
              History
            </Tab>
            {/* <Tab className="flex items-center justify-center outline-none text-xs h-9 w-1/3">
              <img src={ReminderIcon} alt="" className="mr-1 h-[15px]" />
              Reminder
            </Tab> */}
          </TabList>

          <TabPanel className=" overflow-hidden">
            <div
              className="w-full fixed bottom-16 border-t border-dashed border-white/40 max-h-full water-main-col"
              style={{ height: "calc(100dvh - 18rem)" }}
            >
              <div className="flex items-center justify-end absolute -translate-y-full top-0 right-6">
                <p className="text-xs text-white mr-3">
                  {waterIntakeGoal < 100 ? "--" : waterIntakeGoal} ml
                </p>
                <img src={TargetIcon} className="h-6" alt="" />
              </div>

              <div className="absolute top-1/2 left-1/2 -translate-x-1/2 -translate-y-2/3 w-40 h-40 bg-primary rounded-full z-50 water-goal">
                <CircularProgressbarWithChildren
                  value={percentage}
                  // text={`${percentage}ml`}
                  strokeWidth="6"
                  styles={buildStyles({
                    // Whether to use rounded or flat corners on the ends - can use 'butt' or 'round'
                    strokeLinecap: "butt",

                    // Text size
                    textSize: "10px",

                    // How long animation takes to go from one percentage to another, in seconds
                    pathTransitionDuration: 0.5,

                    // Can specify path transition in more detail, or remove it entirely
                    // pathTransition: 'none',

                    // Colors
                    pathColor: "#063BC4",
                    trailColor: "#142953",
                    backgroundColor: "#063BC4",
                  })}
                >
                  <div className="text-center">
                    <h4 className="text-white text-4xl font-light">
                      {waterInatkeStatus}
                      <span className="text-white text-lg">ml</span>
                    </h4>
                    <p className="text-white text-sm font-light">
                      Goal: {waterIntakeGoal < 100 ? "--" : waterIntakeGoal}ml
                    </p>
                  </div>
                </CircularProgressbarWithChildren>
              </div>
              <div
                className="absolute left-0 translate-y-1/2 z-50"
                style={{
                  bottom: `${Math.min(Math.max(percentage, 4), 100)}%`,
                }}
              >
                <div className="total-water bg-white text-sm font-medium h-8 text-black px-2 leading-8">
                  {percentage}%
                </div>
              </div>
              <Wave
                fill="#40A2FC"
                paused={false}
                className="absolute bottom-0 left-0 right-0 max-h-full"
                style={{ height: `calc(${percentage}% + 40px)` }}
                options={{ height: 15, amplitude: 22, points: 4 }}
              />
              <div className="flex items-center justify-between absolute bottom-9 px-6 left-0 right-0">
                <button
                  onClick={() => setCupSizeModelOpen(true)}
                  disabled={loading}
                >
                  <img src={CustomizeGlass} alt="" />
                </button>
                <button
                  className="bg-white disabled:bg-[#DEDEDE] w-24 h-10 flex items-center justify-center text-sm font-semibold text-[#7257D7] disabled:text-[#8E8E8E] rounded-full"
                  onClick={addWaterIntake}
                  disabled={
                    loading ||
                    waterInatkeStatus + selectedCupSize > maxWaterIntake
                  }
                >
                  <img src={AddDrink} alt="" className="mr-2" /> Drink
                </button>
                <button
                  className="bg-white disabled:bg-[#DEDEDE] w-8 h-8 flex items-center justify-center text-[#7257D7] disabled:text-[#8E8E8E] rounded-full"
                  onClick={deleteWaterIntakeEntry}
                  disabled={loading || !waterInatkeStatus}
                >
                  <Undo className="h-5" />
                </button>
                <button
                  className="bg-gradient-to-r from-[#063BC4] to-[#680FAD] w-24 h-10 rounded-full text-sm font-normal"
                  onClick={() => setGoalModalOpen(true)}
                  disabled={loading}
                >
                  Set Goals
                </button>
              </div>
            </div>
            <Dialog
              size="xs"
              open={goalModalOpen}
              handler={handleCloseGoalModel}
              className="bg-transparent shadow-none rounded-2xl"
            >
              <Card className="mx-auto w-full max-w-[24rem]">
                <CardBody className="flex flex-col gap-4">
                  <p className="text-base font-medium text-black">Set Goals</p>
                  {/* <div class="range">
                      <input type="range" />
                    </div> */}

                  <div class="range">
                    <p className="text-sm font-medium text-black" for="range">
                      {val}ml
                    </p>
                    <input
                      className="bg-transparent w-32"
                      type="range"
                      min={100}
                      max={maxWaterIntake}
                      step={50}
                      value={val}
                      onChange={(e) => setVal(e.target.value)}
                    />
                    <p className="text-right text-xs text-black/50">ml</p>
                  </div>
                </CardBody>
                <CardFooter className="pt-0 flex justify-between space-x-8">
                  <button
                    variant="gradient"
                    onClick={handleCloseGoalModel}
                    className="flex-1 px-6 py-3 border border-[#FA6363] rounded-full text-sm font-medium text-[#FA6363]"
                  >
                    Close
                  </button>
                  <button
                    className="flex-1 space-x-3 px-6 py-3 flex items-center justify-center rounded-full bg-gradient-to-r from-[#063BC4] to-[#680FAD] text-sm font-medium text-white"
                    onClick={addWaterIntakeGoals}
                  >
                    <span>Submit</span>
                    {btnLoading && <Loader className="shrink-0 h-4 w-4" />}
                  </button>
                </CardFooter>
              </Card>
            </Dialog>
            <Dialog
              open={cupSizeModelOpen}
              handler={handleCloseCupSizemodel}
              className="rounded-2xl"
            >
              <DialogBody>
                <h4 className="text-base font-medium text-black mb-6 ml-2 mt-2">
                  Choose Capacity
                </h4>
                <RadioGroup
                  className="grid grid-cols-3 gap-4"
                  value={selectedCupSize}
                  onChange={setSelectCupSize}
                >
                  {waterCupsize.map((cup) => (
                    <RadioGroup.Option
                      className={({ checked }) =>
                        `h-28 flex flex-col items-center justify-center gap-4 px-2 py-4 rounded-xl border ${
                          checked
                            ? "border-[#071D91] text-white bg-[#071D91]"
                            : "border-[#071D91]/50 text-[#353535]"
                        }`
                      }
                      key={cup.value}
                      value={cup.value}
                    >
                      <img src={cup.img} alt={`${cup.value} ml`} />
                      <p className="text-xs">{cup.value} ml</p>
                    </RadioGroup.Option>
                  ))}
                </RadioGroup>
              </DialogBody>
              <DialogFooter className="pb-8">
                <button
                  type="button"
                  onClick={handleCloseCupSizemodel}
                  className="border border-[#FA6363] rounded-full h-9 w-24 text-sm font-medium text-[#FA6363] mr-4"
                >
                  Close
                </button>
                <button
                  type="button"
                  onClick={handleSelectCupSize}
                  className="h-9 rounded-full w-28 bg-gradient-to-r from-[#063BC4] to-[#680FAD] text-sm font-medium text-white"
                >
                  Submit
                </button>
              </DialogFooter>
            </Dialog>

            {/*  */}
          </TabPanel>
          <TabPanel>
            <div className="px-6 h-[60vh] overflow-y-auto pb-6 water-history">
              <RadioGroup
                value={graphType}
                onChange={setGraphType}
                disabled={loading}
                className="flex items-center justify-between space-x-2 mt-8"
              >
                {["yearly", "monthly", "weekly", "daily"].map((type) => (
                  <RadioGroup.Option
                    key={`graph-type-${type}`}
                    value={type}
                    className={({ checked }) =>
                      `text-xs ${
                        checked ? "text-[#812EFD]" : "text-white"
                      } capitalize cursor-pointer font-medium`
                    }
                  >
                    {type}
                  </RadioGroup.Option>
                ))}
              </RadioGroup>
              <div className="mt-6 bg-gradient-to-t from-[#331879]/30 to-[#5517A7]/30 rounded-2xl pt-5 px-3">
                {graphData.length > 0 &&
                graphData.some((d) => Boolean(d.value)) ? (
                  <Chart type={graphType} data={graphData} unit="" />
                ) : (
                  <p className="p-6 pt-0 text-center text-xs text-white/50">
                    No data available
                  </p>
                )}
              </div>
              <h4 className="text-sm font-medium my-5">Drink Water Report</h4>
              <div className="flex items-center justify-between flex-wrap mb-4">
                <p className="flex items-center text-xs">
                  <span className="w-2 h-2 bg-[#2CBD30] rounded-full mr-4"></span>{" "}
                  Weekly average
                </p>
                <p className="text-xs text-right">
                  {waterIntakeReport?.weekly_average || 0} ml/day
                </p>
              </div>
              <div className="flex items-center justify-between flex-wrap mb-4">
                <p className="flex items-center text-xs">
                  <span className="w-2 h-2 bg-[#48AFFF] rounded-full mr-4"></span>{" "}
                  Monthly average
                </p>
                <p className="text-xs text-right">
                  {waterIntakeReport?.monthly_average || 0} ml/day
                </p>
              </div>
              <div className="flex items-center justify-between flex-wrap mb-4">
                <p className="flex items-center text-xs">
                  <span className="w-2 h-2 bg-[#FFAD46] rounded-full mr-4"></span>{" "}
                  average_completion
                </p>
                <p className="text-xs text-right">
                  {waterIntakeReport?.average_completion || 0}%
                </p>
              </div>
              <div className="flex items-center justify-between flex-wrap mb-4">
                <p className="flex items-center text-xs">
                  <span className="w-2 h-2 bg-[#F54B54] rounded-full mr-4"></span>{" "}
                  Drink frequency
                </p>
                <p className="text-xs text-right">
                  {waterIntakeReport?.drink_frequency || 0} times/day
                </p>
              </div>
            </div>
          </TabPanel>
          <TabPanel>
            <div className="px-6">
              <div className="mt-6 bg-gradient-to-t from-[#331879]/30 to-[#5517A7]/30 rounded-2xl px-6 py-5">
                <div className="flex items-center justify-between mb-6">
                  <p className="text-base font-semibold text-white">
                    Set Reminder
                  </p>
                  <Switch
                    checked={enabled}
                    onChange={setEnabled}
                    className={`${enabled ? "bg-[#4867D6]" : "bg-[#5e7deb]"}
            relative inline-flex h-[16px] w-[40px] shrink-0 cursor-pointer rounded-[10px] border-2 border-transparent transition-colors duration-200 ease-in-out focus:outline-none focus-visible:ring-2  focus-visible:ring-white/75`}
                  >
                    <span className="sr-only">Use setting</span>
                    <span
                      aria-hidden="true"
                      className={`${
                        enabled ? "translate-x-[22px]" : "translate-x-[-5px]"
                      }
              pointer-events-none inline-block h-[18px] w-[18px] transform rounded-full bg-[#1D41C0] shadow-lg ring-0 transition duration-200 ease-in-out -mt-[3px]`}
                    />
                  </Switch>
                </div>
                <div class="range">
                  <p className="text-xs font-medium text-white" for="range">
                    {val} min
                  </p>
                  <input
                    className="bg-transparent w-full"
                    type="range"
                    min={1}
                    max={1200}
                    step={1}
                    value={val}
                    onChange={(e) => setVal(e.target.value)}
                  />
                  <div className="flex items-center justify-between mt-8">
                    <p className="text-left text-xs text-white">
                      Next Reminder in 30:00 min
                    </p>
                    <button className="bg-gradient-to-r from-[#063BC4] to-[#680FAD] w-20 h-10 rounded-full text-sm font-normal">
                      Update
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </TabPanel>
        </Tabs>
      </div>
    </div>
  );
};

export default WaterTracker;
