import { useState } from "react";
import { Link } from "react-router-dom";
import { useAuth } from "../utils/auth";

const SelectUserAlert = () => {
  const {
    userData: { profile_id },
    profiles,
  } = useAuth();
  const [selectedUser, setSelectedUser] = useState(profile_id);

  return (
    <div className="pt-2 px-6 pb-24">
      <div className="flex items-center mb-5">
        <Link to={-1} className="mr-3">
          <svg
            width="6"
            height="10"
            viewBox="0 0 10 17"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M9.42402 16.2142C9.99277 15.6454 9.99277 14.7267 9.42402 14.1579L3.76569 8.49959L9.42402 2.84126C9.99277 2.27251 9.99277 1.35376 9.42402 0.785009C8.85527 0.216259 7.93652 0.216259 7.36777 0.785009L0.674024 7.47876C0.105274 8.04751 0.105274 8.96626 0.674024 9.53501L7.36777 16.2288C7.92194 16.7829 8.85527 16.7829 9.42402 16.2142Z"
              fill="#fff"
            />
          </svg>
        </Link>
        <h3 className="text-white text-base font-semibold"> Set Alerts</h3>
      </div>
      <div className="space-y-4">
        {profiles.map((p) => (
          <button
            key={p.member_profile_id}
            className={`w-full flex items-center justify-between px-6 py-3 rounded-xl ${
              p.member_profile_id === selectedUser
                ? "border-2 border-secondary bg-gradient-transparent"
                : "border border-white/50"
            }`}
            type="button"
            onClick={() => setSelectedUser(p.member_profile_id)}
          >
            <span className="text-sm">
              {p.first_name} {p.last_name}
            </span>
            <p className="w-20 p-1 rounded-full bg-gradient-to-b from-[#4051A4] to-transparent text-xxs">
              {p.relation}
            </p>
          </button>
        ))}
      </div>
      <Link
        className="block mt-8 w-full rounded-full outline-none px-5 py-2.5 bg-gradient disabled:bg-secondary text-white text-center"
        to="/alert-setting"
        state={{
          selectedUser,
          name:
            profiles.find((p) => p.member_profile_id === selectedUser)
              ?.first_name ?? "",
        }}
        replace
      >
        Select User
      </Link>
    </div>
  );
};

export default SelectUserAlert;
