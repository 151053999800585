import { useEffect, useMemo, useState, useRef } from "react";
import { Link } from "react-router-dom";
import {
  Bar,
  BarChart,
  Cell,
  ResponsiveContainer,
  CartesianGrid,
  Tooltip,
  XAxis,
  YAxis,
} from "recharts";
import ProgressCircle from "../components/ProgressCircle";
import { constants } from "../utils/ranges";
import { useAuth } from "../utils/auth";
import { sdkWrapperURL } from "../utils/api-url-list";
import ChartIcon from "../assets/icons/insert_chart.svg";
import Downarrow from "../assets/icons/down_arrow.svg";
import Carousel from "react-elastic-carousel";
import WellnessCard from "../components/WellnessCard";
import HealthHeart from "../assets/images/heart-health.png";
import MentalWellbing from "../assets/images/mental-wellbing.png";
import VitalsIndex from "../assets/images/vitals-index.png";
// import HealthyHabits from "../assets/images/Healthy-Habits.png";
import DiabetesInsights from "../assets/images/Diabetes-Insights.png";
const data = [
  {
    name: "30/06",
    uv: 100,
    pv: 90,
    amt: 90,
  },
  {
    name: "07/07",
    uv: 20.5,
    pv: 20.2,
    amt: 21,
  },
  {
    name: "14/07",
    uv: 25,
    pv: 25,
    amt: 25,
  },
  {
    name: "21/07",
    uv: 75,
    pv: 75,
    amt: 75,
  },
  {
    name: "27/07",
    uv: 85,
    pv: 85,
    amt: 80,
  },
  {
    name: "04/08",
    uv: 50,
    pv: 50,
    amt: 50,
  },
];
const CustomTooltip = ({ active, payload, label }) => {
  if (active && payload && payload.length) {
    return (
      <div className="custom-tooltip bg-white rounded relative h-[40px] border border-secondary px-3 flex items-center justify-center">
        <p className="label text-[11px]">{`${label} : ${payload[0].value}`}</p>
      </div>
    );
  }

  return null;
};

const MyCare = () => {
  // ***  //
  const [active, setActive] = useState(false);
  const content = useRef(null);
  const [height, setHeight] = useState("0px");

  useEffect(() => {
    console.log("Height for ", ": ", height);
  }, [height]);

  function toggleAccordion() {
    setActive(!active);
    setHeight(active ? "0px" : `${content.current.scrollHeight}px`);
  }
  // **** //
  const {
    getToken,
    userData: { employee_id },
  } = useAuth();
  const [loading, setLoading] = useState(true);
  const [lastScanResult, setLastResult] = useState({});
  const selectedScoreRange = useMemo(
    () =>
      constants["Wellbeing Score"].ranges.reduce((p, c) =>
        lastScanResult.wellness_score > c.point ? c : p
      ),
    [lastScanResult.wellness_score]
  );

  useEffect(() => {
    const controller = new AbortController();
    setLoading(true);
    (async () => {
      try {
        const token = await getToken();
        const scoreResp = await fetch(
          sdkWrapperURL("/users/sdk/test_api_private"),
          {
            method: "POST",
            headers: {
              "Content-Type": "application/json",
              Authorization: token,
            },
            body: JSON.stringify({
              action: "list_scan_UL",
              employee_id,
              table_config_rows_per_page: 1,
              table_config_page_no: 1,
            }),
            signal: controller.signal,
          }
        );
        const scoreRespJSON = await scoreResp.json();
        if (
          scoreRespJSON?.statusCode?.toString().startsWith("2") &&
          scoreRespJSON?.result?.length > 0
        )
          setLastResult(scoreRespJSON.result[0]);
      } catch (err) {
        console.error(err);
      } finally {
        setLoading(false);
      }
    })();
    return () => {
      controller.abort();
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const breakPoints = [
    { width: 1, itemsToShow: 3 },
    { width: 550, itemsToShow: 3, itemsToScroll: 1, pagination: false },
    { width: 850, itemsToShow: 3 },
    { width: 1150, itemsToShow: 4, itemsToScroll: 1 },
    { width: 1450, itemsToShow: 5 },
    { width: 1750, itemsToShow: 6 },
  ];

  return (
    <div className="w-full pb-24">
      <div className="px-6">
        <div
          className="flex items-center mb-7"
          data-aos="fade-right"
          data-aos-duration="1000"
          data-aos-delay="50"
        >
          <Link to="/" className="mr-3">
            <svg
              width="6"
              height="10"
              viewBox="0 0 10 17"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M9.42402 16.2142C9.99277 15.6454 9.99277 14.7267 9.42402 14.1579L3.76569 8.49959L9.42402 2.84126C9.99277 2.27251 9.99277 1.35376 9.42402 0.785009C8.85527 0.216259 7.93652 0.216259 7.36777 0.785009L0.674024 7.47876C0.105274 8.04751 0.105274 8.96626 0.674024 9.53501L7.36777 16.2288C7.92194 16.7829 8.85527 16.7829 9.42402 16.2142Z"
                fill="#192852"
              />
            </svg>
          </Link>
          <h3 className="text-primary text-[14px] font-semibold">
            My Digital Health Assessment Score™
          </h3>
        </div>
        <div
          className="flex flex-col items-center"
          data-aos="fade-up"
          data-aos-duration="1000"
          data-aos-delay="100"
        >
          <ProgressCircle
            loading={loading}
            value={lastScanResult.wellness_score}
            color={selectedScoreRange.color}
            // subtitle={selectedScoreRange.label}
          />
          <h3 className="text-[14px] text-[#192852] font-medium mt-2">
            My Digital Health Assessment Score
          </h3>
          <h4 className="text-[16px] text-[#1A5701] font-medium">Excellent</h4>
          <p className="text-[11px] text-[#414141] mb-2">
            Last update August 04, 2022
          </p>
          <div className="accordion__section w-full">
            <div
              className={`accordion ${active ? "active" : ""}`}
              onClick={toggleAccordion}
            >
              <p className="accordion__title text-[10px] text-secondary font-medium flex items-center justify-center mb-4">
                {" "}
                <img src={ChartIcon} alt="chart icon" className="mr-1" /> View
                Analytics
              </p>
            </div>
            <div
              ref={content}
              style={{ maxHeight: `${height}` }}
              className="accordion__content"
            >
              <ResponsiveContainer width="100%" height={150}>
                <BarChart
                  className="ml-[-10px]"
                  data={data}
                  margin={{
                    top: 5,
                    right: -10,
                    left: -10,
                    bottom: 5,
                  }}
                >
                  <CartesianGrid horizontal vertical={false} />
                  <XAxis
                    tickLine={false}
                    axisLine={{ stroke: "rgba(25,40,82, 0.5)" }}
                    dataKey="name"
                    style={{ fontSize: 10, color: "rgba(25,40,82, 0.5)" }}
                  />
                  <YAxis
                    tickLine={false}
                    stroke="#333"
                    axisLine={false}
                    style={{ fontSize: 10, color: "rgba(25,40,82, 0.5)" }}
                  />
                  <Tooltip content={<CustomTooltip />} />

                  <Bar dataKey="pv" barSize={8} radius={[10, 10, 10, 10]}>
                    <Cell fill="#C7212A" />
                    <Cell fill="#64BD40" />
                    <Cell fill="#64BD40" />
                    <Cell fill="#FFC400" />
                    <Cell fill="#FFC400" />
                    <Cell fill="#64BD40" />
                    <Cell fill="#64BD40" />
                    <Cell fill="#FFC400" />
                  </Bar>
                </BarChart>
              </ResponsiveContainer>
              <div className="flex items-center justify-center mb-5">
                {" "}
                <span style={{}} onClick={toggleAccordion}>
                  <img src={Downarrow} alt="Down arrow" />
                </span>
              </div>
            </div>
          </div>

          <p className="text-[11px] text-[#000]/[.7] text-justify">
            My Digital Health Assessment Score is a numerical measurement of an
            individual's overall well-being, considering various aspects of
            physical, mental, and emotional health. A 'good' well-being score
            typically refers to a score that indicates a high level of
            well-being, with low levels of stress, anxiety, and negative
            emotions, and high levels of physical and mental energy, resilience,
            and satisfaction with life.
          </p>
          <div className="flex items-center justify-center mt-4 mb-2">
            <button className=" border border-secondary rounded-[40px] px-3 py-1 text-[11px] text-secondary hover:bg-secondary active:bg-secondary hover:text-white active:text-white focus:outline-none">
              View History
            </button>
          </div>
        </div>
      </div>

      <div
        className="wellness-col mb-6 mt-5"
        data-aos="fade-up"
        data-aos-duration="1000"
        data-aos-delay="200"
      >
        <h2 className="text-primary mb-4 text-sm font-medium flex justify-between items-center pl-6">
          My Scores & Insights
        </h2>
        <div className="scroll-col">
          <div className="fle-scroll">
            <Carousel
              data-aos="fade-up"
              data-aos-duration="1000"
              breakPoints={breakPoints}
              showArrows={false}
              pagination={false}
            >
              <Link to="/vitals-index">
                <WellnessCard
                  img={VitalsIndex}
                  tittle="Vitals Index"
                  subtittle="Complete the assessment to get your mental wellbeing score."
                  color="rgba(211, 79, 118, 0.8)"
                />
              </Link>

              <Link to="/heart-score">
                <WellnessCard
                  img={HealthHeart}
                  tittle="Heart Health"
                  subtittle="Find out if your nutrition in-take is sufficient."
                  color="rgba(221, 187, 13, 0.8)"
                />
              </Link>

              <Link to="/diabetes-insight">
                <WellnessCard
                  img={DiabetesInsights}
                  tittle="Diabetes Insights"
                  subtittle="Healthy habits tracks your daily activity."
                  color="rgba(40, 129, 171, 0.8)"
                />
              </Link>

              <Link to="/mental-wellbing">
                <WellnessCard
                  img={MentalWellbing}
                  tittle="Mental Well-being"
                  subtittle="Complete the assessment to get your mental wellbeing score."
                  color="rgba(208, 80, 31, 0.8)"
                />
              </Link>

              {/* ... */}
            </Carousel>
          </div>
        </div>
      </div>
    </div>
  );
};

export default MyCare;
