import { useState } from "react";
import { Link } from "react-router-dom";
import { Tab, Tabs, TabList, TabPanel } from "react-tabs";
import MedicationCard from "../components/MedicationCard";

const WellbingDetails = () => {
  const [tabIndex, setTabIndex] = useState(0);
  return (
    <div className="pb-28 w-full">
      <div className="px-6">
        <div className="flex items-center mb-5">
          <Link to="/" className="mr-3">
            <svg
              width="6"
              height="10"
              viewBox="0 0 10 17"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M9.42402 16.2142C9.99277 15.6454 9.99277 14.7267 9.42402 14.1579L3.76569 8.49959L9.42402 2.84126C9.99277 2.27251 9.99277 1.35376 9.42402 0.785009C8.85527 0.216259 7.93652 0.216259 7.36777 0.785009L0.674024 7.47876C0.105274 8.04751 0.105274 8.96626 0.674024 9.53501L7.36777 16.2288C7.92194 16.7829 8.85527 16.7829 9.42402 16.2142Z"
                fill="#fff"
              />
            </svg>
          </Link>
          <h3 className="text-white text-[14px] font-semibold">Meditation</h3>
        </div>
      </div>
      <Tabs
        selectedIndex={tabIndex}
        onSelect={(tabIndex) => setTabIndex(tabIndex)}
        className="wellbing-tab"
      >
        <TabList>
          <Tab>
            <h4>Mindfulness</h4>
            <p>
              Be fully present & aware of thoughts, feelings, & surroundings
            </p>
          </Tab>
          <Tab>
            <h4>Focus</h4>
            <p>
              Focus your attention on a specific object, thought, or sensation.
            </p>
          </Tab>
          <Tab>
            <h4>Relaxation</h4>
            <p>
              Reduces stress & anxiety, blood pressure, & improve mental health
            </p>
          </Tab>
          <Tab>
            <h4>Self-reflection</h4>
            <p>Helps you identify areas for personal growth and development</p>
          </Tab>
        </TabList>
        <TabPanel>
          <div className="px-6 mt-7">
            <MedicationCard />
          </div>
        </TabPanel>
        <TabPanel>
          <div className="px-6 mt-5">
            <h4>Focus</h4>
          </div>
        </TabPanel>
        <TabPanel>
          <div className="px-6 mt-5">
            <h4>Relaxation</h4>
          </div>
        </TabPanel>
        <TabPanel>
          <div className="px-6 mt-5">
            <h4>Self-reflection</h4>
          </div>
        </TabPanel>
      </Tabs>
    </div>
  );
};

export default WellbingDetails;
