import { useState } from "react";
import { Link } from "react-router-dom";
import { RadioGroup } from "@headlessui/react";
import { logAnalyticsEvent } from "../utils/analytics";
import { ReactComponent as Check } from "../assets/icons/Check.svg";
import FaceScan from "../assets/images/FaceScan.webp";
import FingerScan from "../assets/images/FingerScan.webp";

const SelectScanOption = () => {
  const [selectedScanType, setScanType] = useState("face");
  return (
    <section className="px-8 pt-2 pb-24">
      <h3 className="font-semibold  text-white">Select Scan Option</h3>

      <RadioGroup
        className="mt-4 space-y-6"
        value={selectedScanType}
        onChange={setScanType}
      >
        {[
          {
            type: "face",
            img: FaceScan,
            label: "Face Scan",
            desc: "Contactless vital scan using your smartphone's front camera. It is as simple as taking a selfie.",
          },
          {
            type: "finger",
            img: FingerScan,
            label: "Finger Scan",
            desc: "Place your finger on the rear camera of your smartphone to scan your vital signs.",
          },
        ].map((scan) => (
          <RadioGroup.Option
            key={`${scan.type}-scan`}
            value={scan.type}
            className="flex flex-col items-center text-center"
          >
            {({ checked }) => (
              <>
                <div
                  className={`relative rounded-full border-4 ${
                    checked
                      ? "w-32 border-dashed border-secondary"
                      : "w-[7.5rem] border-dotted border-white "
                  } `}
                >
                  <img src={scan.img} alt={scan.label} />
                  {checked && (
                    <Check className="absolute top-1.5 left-1.5 w-6 h-6 p-1 rounded-full bg-success" />
                  )}
                </div>
                <RadioGroup.Label
                  as="h5"
                  className="mt-2.5 font-bold  text-white"
                >
                  {scan.label}
                </RadioGroup.Label>
                <RadioGroup.Description className="mt-0.5 text-xs font-light leading-tight tracking-tighter  text-white">
                  {scan.desc}
                </RadioGroup.Description>
              </>
            )}
          </RadioGroup.Option>
        ))}
      </RadioGroup>

      <Link
        className="block mt-6 w-full rounded-full outline-none px-5 py-2.5 bg-gradient text-white text-center"
        to="/scan-instructions"
        state={{ scanType: selectedScanType }}
        onClick={() => {
          window?.ReactNativeWebView?.postMessage?.("prompt-camera-permission");
          logAnalyticsEvent(`${selectedScanType}_scan_initiated`);
        }}
      >
        Proceed to Scan
      </Link>
      <Link
        to={-1}
        className="block max-w-max mx-auto mt-4 text-sm font-medium  text-white"
      >
        Back
      </Link>

      <div className="mt-6 p-4 rounded-xl bg-gradient-transparent text-white space-y-1.5">
        <h3 className="text-xs font-medium">Disclaimer</h3>
        <p className="text-xxs text-left">
          For Investigational Use Only. CarePlix is not a substitute for the
          clinical judgment of a healthcare professional. CarePlix is intended
          to improve your awareness of general wellness. CarePlix does not
          diagnose, treat, mitigate or prevent any disease, symptom, disorder or
          abnormal physical state. Consult with a healthcare professional or
          emergency services if you believe you may have a medical issue.
        </p>
      </div>
    </section>
  );
};

export default SelectScanOption;
