import { Link } from "react-router-dom";
import HeartBlink from "../assets/icons/heart-blink.svg";
import HeartBlink2 from "../assets/icons/heart-blink2.svg";
import HeartBlink3 from "../assets/icons/heart-blink3.svg";

const OurPricing = () => (
  <div className="mb-28 w-full">
    <div className="flex items-center justify-between mb-5 px-6">
      <div className="flex items-center">
        <Link to="/" className="mr-3">
          <svg
            width="6"
            height="10"
            viewBox="0 0 10 17"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M9.42402 16.2142C9.99277 15.6454 9.99277 14.7267 9.42402 14.1579L3.76569 8.49959L9.42402 2.84126C9.99277 2.27251 9.99277 1.35376 9.42402 0.785009C8.85527 0.216259 7.93652 0.216259 7.36777 0.785009L0.674024 7.47876C0.105274 8.04751 0.105274 8.96626 0.674024 9.53501L7.36777 16.2288C7.92194 16.7829 8.85527 16.7829 9.42402 16.2142Z"
              fill="#fff"
            />
          </svg>
        </Link>
        <h3 className="text-white text-[14px] font-semibold">
          Our <span className="text-[#812EFD]">Pricing</span>
        </h3>
      </div>
      <Link to="" className="text-xs text-white tracking-wide">
        Skip
      </Link>
    </div>
    <div className="p-6 relative">
      <div className="bg-gradient-to-tl from-[#5916B1]/10 to-[#1A32BF] p-5 rounded-[20px] mb-8">
        <img
          src={HeartBlink}
          alt=""
          className=" absolute right-10 top-[-5px] w-20"
        />
        <h2 className="text-2xl font-semibold text-white mb-4">Lite</h2>
        <ul className=" list-disc pl-5 m-0">
          <li className="text-xs text-white mb-2">
            Key Body Vitals excluding Blood Pressure 
          </li>
          <li className="text-xs text-white mb-2">
            Upto 12 Screening Per Month Per Family.
          </li>
          <li className="text-xs text-white mb-2">
            Add Upto 6 Family Members.
          </li>
        </ul>
        <div className="flex justify-between items-center mt-6">
          <button className="bg-gradient-to-r from-[#063BC4] to-[#680FAD] text-white text-sm font-medium w-24 h-8 rounded-full">
            Buy
          </button>
          <p>
            <span className="text-white text-sm">₹</span>{" "}
            <span className=" text-2xl text-white font-semibold">599</span>{" "}
            <span className="text-white text-sm">/Yr</span>
          </p>
        </div>
      </div>
      <div className="relative">
        <div className="bg-gradient-to-t from-[#2A27DF]/10 to-[#4692D5] p-5 rounded-[20px] mb-8">
          <img
            src={HeartBlink2}
            alt=""
            className=" absolute right-5 top-[-30px] w-20"
          />
          <h2 className="text-2xl font-semibold text-white mb-4">Standard</h2>
          <ul className=" list-disc pl-5 m-0">
            <li className="text-xs text-white mb-2">
              Detailed Health Screening with 20+ Health Parameters 
            </li>
            <li className="text-xs text-white mb-2">
              Cardiac Risk Screening including Hypertension
            </li>
            <li className="text-xs text-white mb-2">Diabetes Screening</li>
          </ul>
          <p>
            <Link to="" className="text-xs text-[#982EFA] font-medium">
              View All Features &gt
            </Link>
          </p>
          <div className="flex justify-between items-center mt-3">
            <button className="bg-gradient-to-r from-[#063BC4] to-[#680FAD] text-white text-sm font-medium w-24 h-8 rounded-full">
              Buy
            </button>
            <p>
              <span className="text-white text-sm">₹</span>{" "}
              <span className=" text-2xl text-white font-semibold">1599</span>{" "}
              <span className="text-white text-sm">/Yr</span>
            </p>
          </div>
        </div>
      </div>
      <div className="relative">
        <div className="bg-gradient-to-t from-[#8327DF]/10 to-[#8152B0] p-5 rounded-[20px] mb-8">
          <img
            src={HeartBlink3}
            alt=""
            className=" absolute right-5 top-[-30px] w-20"
          />
          <h2 className="text-2xl font-semibold text-white mb-4">Pro</h2>
          <ul className=" list-disc pl-5 m-0">
            <li className="text-xs text-white mb-2">
              Unlimited Health Screening including Cardiac Risk, Diabetes
              Screening, Stress Levels 
            </li>
            <li className="text-xs text-white mb-2">
              Unlimited Access to Live Digital Physiotherapy with real time
              feedback during exercise
            </li>
          </ul>
          <p>
            <Link to="" className="text-xs text-[#982EFA] font-medium">
              View All Features &gt
            </Link>
          </p>
          <div className="flex justify-between items-center mt-3">
            <button className="bg-gradient-to-r from-[#063BC4] to-[#680FAD] text-white text-sm font-medium w-24 h-8 rounded-full">
              Buy
            </button>
            <p>
              <span className="text-white text-sm">₹</span>{" "}
              <span className=" text-2xl text-white font-semibold">2599</span>{" "}
              <span className="text-white text-sm">/Yr</span>
            </p>
          </div>
        </div>
      </div>
    </div>
  </div>
);

export default OurPricing;
