import { useState } from "react";
import { Switch } from "@headlessui/react";
import { Link } from "react-router-dom";
import { ReactComponent as Chevron } from "../assets/icons/Chevron.svg";
import { useAuth } from "../utils/auth";
import { sdkWrapperURL } from "../utils/api-url-list";

const ConfigureAlerts = () => {
  const {
    userData: { profile_id, alert_status },
    getToken,
  } = useAuth();
  const [isLoading, setLoading] = useState(false);
  const [alertEnabled, setAlertEnabled] = useState(
    alert_status.alert_enabled ?? false
  );
  const handleChange = async (value) => {
    setLoading(true);
    try {
      const token = await getToken();
      const alertConfigResp = await fetch(
        sdkWrapperURL("/alert/switch-alert"),
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
            Authorization: token,
          },
          body: JSON.stringify({
            member_profile_id: profile_id,
            alert_settings: {
              alert_enabled: value,
              digital_scan_alert_enabled: value,
            },
          }),
        }
      );
      const RespJSON = await alertConfigResp.json();
      if (RespJSON?.statusCode?.toString().startsWith("2")) {
        setAlertEnabled(value);
        window.location.reload(true);
      } else throw new Error(RespJSON.message);
    } catch (e) {
      console.error(e);
    } finally {
      setLoading(false);
    }
  };

  return (
    <div className="px-6 pt-2 pb-24">
      <div className="flex items-center">
        <Link to={-1} className="mr-3">
          <svg
            width="6"
            height="10"
            viewBox="0 0 10 17"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M9.42402 16.2142C9.99277 15.6454 9.99277 14.7267 9.42402 14.1579L3.76569 8.49959L9.42402 2.84126C9.99277 2.27251 9.99277 1.35376 9.42402 0.785009C8.85527 0.216259 7.93652 0.216259 7.36777 0.785009L0.674024 7.47876C0.105274 8.04751 0.105274 8.96626 0.674024 9.53501L7.36777 16.2288C7.92194 16.7829 8.85527 16.7829 9.42402 16.2142Z"
              fill="#fff"
            />
          </svg>
        </Link>
        <h3 className="text-white text-base font-semibold">Notifications</h3>
      </div>
      <Switch.Group
        as="div"
        className="mt-5 flex items-center justify-between space-x-4 p-6 rounded-xl bg-gradient-to-l from-[#331879]/30 to-[#5517A7]/30"
      >
        <Switch.Label className="text-white text-sm">
          Notifications
        </Switch.Label>
        <Switch
          checked={alertEnabled}
          disabled={isLoading}
          onChange={handleChange}
          className={({ checked }) =>
            `relative inline-flex h-4 w-11 items-center rounded-full transition-colors outline-none ${
              checked ? "bg-ternary/40" : "bg-ternary/20"
            }`
          }
        >
          {({ checked }) => (
            <span
              className={`inline-block h-4 w-4 rounded-full bg-ternary transition-transform ${
                checked ? "translate-x-7" : "translate-x-0"
              }`}
            />
          )}
        </Switch>
      </Switch.Group>
      {/* <Switch.Group
        as="div"
        className="mt-5 flex items-center justify-between space-x-4 p-6 rounded-xl bg-gradient-to-l from-[#331879]/30 to-[#5517A7]/30"
      >
        <Switch.Label className="text-white text-sm">
          Digital Assessment Notifications
        </Switch.Label>
        <Switch
          checked={assessmentEnabled}
          onChange={setAssessmentEnabled}
          className={({ checked }) =>
            `relative inline-flex h-4 w-11 items-center rounded-full transition-colors outline-none ${
              checked ? "bg-ternary/40" : "bg-ternary/20"
            }`
          }
        >
          {({ checked }) => (
            <span
              className={`inline-block h-4 w-4 rounded-full bg-ternary transition-transform ${
                checked ? "translate-x-7" : "translate-x-0"
              }`}
            />
          )}
        </Switch>
      </Switch.Group> */}
      {/* <Switch.Group
        as="div"
        className="mt-5 flex items-center justify-between space-x-4 p-6 rounded-xl bg-gradient-to-l from-[#331879]/30 to-[#5517A7]/30"
      >
        <Switch.Label className="text-white text-sm">
          Test Notifications
        </Switch.Label>
        <Switch
          checked={testEnabled}
          onChange={setTestEnabled}
          className={({ checked }) =>
            `relative inline-flex h-4 w-11 items-center rounded-full transition-colors outline-none ${
              checked ? "bg-ternary/40" : "bg-ternary/20"
            }`
          }
        >
          {({ checked }) => (
            <span
              className={`inline-block h-4 w-4 rounded-full bg-ternary transition-transform ${
                checked ? "translate-x-7" : "translate-x-0"
              }`}
            />
          )}
        </Switch>
      </Switch.Group> */}
      <Link
        to="/alert-select-user"
        className="mt-5 flex items-center justify-between space-x-4 p-6 rounded-xl bg-gradient-to-l from-[#331879]/30 to-[#5517A7]/30"
      >
        <span>Configure Alerts</span>
        <Chevron className="h-4 w-4 -rotate-90" />
      </Link>
    </div>
  );
};

export default ConfigureAlerts;
