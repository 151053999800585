import { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import { ReactComponent as BackArrow } from "../assets/icons/BackArrow.svg";
import { useAuth } from "../utils/auth";
import { sdkWrapperURL } from "../utils/api-url-list";
import LoadingScreen from "../components/LoadingScreen";

const NutritionReceipesList = () => {
  const {
    getToken,
    userData: { profile_id },
  } = useAuth();
  const [packagesList, setpackagesList] = useState();
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    (async () => {
      setLoading(true);
      try {
        const token = await getToken();
        const resp = await fetch(sdkWrapperURL("/users/list/products"), {
          method: "POST",
          headers: { "Content-Type": "application/json", Authorization: token },
          body: JSON.stringify({
            module_type: "content",
            profile_id,
            product_type_id: 34,
            table_config_rows_per_page: 100,
            table_config_page_no: 1,
          }),
        });
        const respJSON = await resp.json();
        if (respJSON?.statusCode?.toString().startsWith("2")) {
          setpackagesList(respJSON.response);
        } else throw new Error(respJSON?.message ?? "Error in Fetching List");
      } catch (err) {
        console.error(err);
      } finally {
        setLoading(false);
      }
    })();
  }, [getToken, profile_id]);

  return (
    <div className="w-full px-6 pt-2 pb-24">
      <div className="flex items-center">
        <Link className="shrink-0 p-1 pr-2.5" to={-1}>
          <BackArrow className="h-2.5 w-2.5" />
        </Link>
        <h3 className="text-white text-base font-semibold">Top 10 Recipes</h3>
      </div>
      {loading ? (
        <LoadingScreen style={{ minHeight: "60vh" }} />
      ) : (
        <div>
          {packagesList?.map?.((receipe) => (
            <Link
              key={receipe.product_id}
              to="/nutration-recipe-detail"
              state={{
                name: receipe.product_name,
                content: receipe.structure_val,
              }}
              className="mt-5 h-32 p-4 overflow-hidden rounded-xl bg-white flex items-center space-x-3"
            >
              <div className="relative shrink-0 h-20 w-20">
                <img
                  src={receipe.structure_val.content_thumbnail}
                  alt="Thumbnail"
                  className="h-full w-full rounded-xl object-cover"
                />
                <div className="absolute top-4 -left-1.5 h-4 w-4 rounded-full bg-secondary" />
              </div>
              <div className="grow flex flex-col items-stretch justify-between">
                <h4 className="line-clamp-2 text-secondary text-xs leading-tight font-medium">
                  {receipe.structure_val.heading}
                </h4>
                <p className="my-2 line-clamp-3 text-[8px] text-primary leading-tight">
                  {receipe.structure_val.meta_description}
                </p>
                <p className="text-secondary text-xs">View Details &gt;</p>
              </div>
            </Link>
          ))}
        </div>
      )}
    </div>
  );
};

export default NutritionReceipesList;
