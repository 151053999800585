import { createContext, useContext, useState, useEffect, useMemo } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { sdkWrapperURL } from "./api-url-list";
import { logAnalyticsEvent, setAnalyticsUserIdentity } from "./analytics";
import LoadingScreen from "../components/LoadingScreen";
// import { saveFCMToken } from "./firebase";

const decodeToken = (token = "") => {
  if (!(token?.length > 0)) throw new Error("Incorrect Token");
  const jwtPayload = token.split(".")[1];
  const base64string =
    jwtPayload.replace("-", "+").replace("_", "/") +
    "=".repeat((4 - (jwtPayload.length % 4)) % 4);
  const TABLE =
    "ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789+/";
  const REGEX_SPACE_CHARACTERS = /<%= spaceCharacters %>/g;
  let input = String(base64string).replace(REGEX_SPACE_CHARACTERS, "");
  let length = input.length;
  if (length % 4 === 0) {
    input = input.replace(/==?$/, "");
    length = input.length;
  }
  if (length % 4 === 1 || /[^+a-zA-Z0-9/]/.test(input)) {
    return "{}";
  }
  let bitCounter = 0;
  let bitStorage;
  let buffer;
  let output = "";
  let position = -1;
  while (++position < length) {
    buffer = TABLE.indexOf(input.charAt(position));
    bitStorage = bitCounter % 4 ? bitStorage * 64 + buffer : buffer;
    if (bitCounter++ % 4) {
      output += String.fromCharCode(
        0xff & (bitStorage >> ((-2 * bitCounter) & 6))
      );
    }
  }
  const data = JSON.parse(output);
  return data;
};

const AuthContext = createContext({
  getToken: async () => "",
  userData: {
    user_id: "",
    profile_id: "",
    relation: "",
    score_id: "",
    // flyy_id: "",
    fname: "",
    lname: "",
    email: "",
    phone: "",
    gender: "",
    dob: "",
    height: 0,
    weight: 0,
    waist: 0,
    alert_status: {},
  },
  profiles: [],
  subscriptionPlanDetails: {
    packageID: "",
    packageName: "",
    maxAllowedProfiles: 1,
    careCoins: 0,
  },
  selectProfile: (profileID = "", token = "") => {},
  login: async ({ id_token, refresh_token }) => {},
  logout: () => {},
});

const AuthProvider = (props) => {
  const location = useLocation();
  const navigate = useNavigate();
  const [isLoading, setLoading] = useState(true);
  const [tokenSet, setTokenSet] = useState({
    id_token: "",
    refresh_token: "",
    expiresAt: Date.now(),
    subscriptionPlanID: "",
  });
  const [profiles, setProfiles] = useState([]);
  const [subscriptionPlanDetails, setSubscriptionPlanDetails] = useState({
    packageID: "",
    packageName: "",
    maxAllowedProfiles: 1,
    careCoins: 0,
  });
  const [selectedProfileID, setSelectedProfileID] = useState("");

  const login = async ({ id_token, refresh_token }) => {
    setLoading(true);
    try {
      saveTokenSet(id_token, refresh_token);
      const profileData = await getProfiles(id_token);
      setProfiles(profileData.profiles);
      setSubscriptionPlanDetails(profileData.subscription_details);
      setSelectedProfileID("");
      logAnalyticsEvent("login", { method: "phone_otp" });
      navigate("/select-user", { replace: true });
    } catch (err) {
      console.error(err);
      setTokenSet({
        id_token: "",
        refresh_token: "",
        expiresAt: Date.now(),
        subscriptionPlanID: "",
      });
    } finally {
      setLoading(false);
    }
  };

  const logout = () => {
    setLoading(true);
    try {
      localStorage.removeItem("selected-profile");
      localStorage.removeItem("tokenset");
      localStorage.removeItem("nextPopupTime");
      setTokenSet({
        id_token: "",
        refresh_token: "",
        expiresAt: Date.now(),
        subscriptionPlanID: "",
      });
      navigate("/login", { replace: true });
    } catch (err) {
      console.error(err);
    } finally {
      setLoading(false);
    }
  };

  const saveTokenSet = (id_token = "", refresh_token = "") => {
    if (id_token.length <= 0 || refresh_token.length <= 0)
      throw new Error("Invalid Token Set");
    const decodeTokenData = decodeToken(id_token);
    const expiresAt = (decodeTokenData.exp ?? 0) * 1000;
    const subscriptionPlanID =
      decodeTokenData.subscription_details?.subscribed_package_id ?? "";
    localStorage.setItem(
      "tokenset",
      btoa(
        JSON.stringify({
          id_token,
          refresh_token,
          expiresAt,
          subscriptionPlanID,
        })
      )
    );
    setTokenSet({ id_token, refresh_token, expiresAt, subscriptionPlanID });
  };

  const selectProfile = (profileID = "", token = "") => {
    if (profileID.length <= 0) navigate("/select-user", { replace: true });
    else {
      localStorage.setItem("selected-profile", btoa(profileID));
      localStorage.removeItem("nextPopupTime");
      setSelectedProfileID(profileID);
      // saveFCMToken(token, profileID);
    }
  };

  const getProfiles = async (token = "") => {
    try {
      if (token.length <= 0) throw new Error("Invalid Token");
      const profileResp = await fetch(sdkWrapperURL("/users/profile/view"), {
        method: "POST",
        headers: { "Content-Type": "application/json", Authorization: token },
        body: JSON.stringify({}),
      });
      const profileRespJSON = await profileResp.json();
      if (profileRespJSON?.statusCode?.toString().startsWith("2"))
        return {
          profiles: profileRespJSON.member_profile ?? [],
          subscription_details: {
            packageID:
              profileRespJSON.subscription_details?.subscription_plan_id ?? "",
            packageName:
              profileRespJSON.subscription_details?.subscription_plan_inclusions
                ?.scan?.scan_package_name ?? "",
            maxAllowedProfiles:
              profileRespJSON.subscription_details?.subscription_plan_inclusions
                ?.multiprofile?.max_members_allowed ?? 1,
            careCoins: profileRespJSON.available_care_coins ?? 0,
          },
        };
      else
        throw new Error(
          profileRespJSON?.message ?? "Error in Fetching Profile Data"
        );
    } catch (err) {
      console.error(err);
      return {
        profiles: [],
        subscription_details: {
          packageID: "",
          packageName: "",
          maxAllowedProfiles: 1,
          careCoins: 0,
        },
      };
    }
  };

  const refreshTokenSet = (id_token = "", refresh_token = "") =>
    new Promise(async (resolve, reject) => {
      try {
        if (id_token.length <= 0 || refresh_token.length <= 0)
          throw new Error("Invalid Token Set");
        const resp = await fetch(sdkWrapperURL("/auth/regenerate_token"), {
          method: "POST",
          headers: { "Content-Type": "application/json" },
          body: JSON.stringify({ id_token, refresh_token }),
        });
        const respJSON = await resp.json();
        if (respJSON.statusCode?.toString().startsWith("2")) {
          if (respJSON.message === "Regeneration of ID token not required")
            resolve({ id_token, refresh_token });
          else
            resolve({
              id_token: respJSON.id_token,
              refresh_token: respJSON.refresh_token,
            });
        } else
          throw new Error(respJSON.message ?? "Error in Regenerating Token");
      } catch (err) {
        reject(err);
      }
    });

  useEffect(() => {
    (async () => {
      setLoading(true);
      try {
        const encoded_tokenset = localStorage.getItem("tokenset");
        if (encoded_tokenset?.length > 0) {
          const decoded_tokenset = JSON.parse(atob(encoded_tokenset));
          const decoded_profileID = atob(
            localStorage.getItem("selected-profile") ?? ""
          );
          const new_tokens = await refreshTokenSet(
            decoded_tokenset.id_token,
            decoded_tokenset.refresh_token
          );
          saveTokenSet(new_tokens.id_token, new_tokens.refresh_token);
          const profileData = await getProfiles(new_tokens.id_token);
          setProfiles(profileData.profiles);
          setSubscriptionPlanDetails(profileData.subscription_details);
          selectProfile(decoded_profileID, new_tokens.id_token);
          if (location.pathname === "/login") navigate("/", { replace: true });
        } else throw new Error("No Stored Token");
      } catch (err) {
        console.error(err);
        setTokenSet({
          id_token: "",
          refresh_token: "",
          expiresAt: Date.now(),
          subscriptionPlanID: "",
        });
        if (location.pathname !== "/login")
          navigate("/login", { replace: true });
      } finally {
        setLoading(false);
      }
    })();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (
      localStorage.getItem("tokenset") === null &&
      location.pathname !== "/login"
    )
      navigate("/login", { replace: true });
    else if (
      localStorage.getItem("selected-profile") === null &&
      !(
        location.pathname === "/select-user" ||
        location.pathname === "/basic-details" ||
        location.pathname === "/login" ||
        location.pathname === "/referal"
      )
    )
      navigate("/select-user", { replace: true });
  }, [navigate, location.pathname]);

  const getToken = () =>
    new Promise(async (resolve, reject) => {
      try {
        if (isLoading) reject(new Error("Loading..."));
        else if (
          tokenSet.expiresAt > Date.now() &&
          tokenSet.subscriptionPlanID === subscriptionPlanDetails.packageID
        )
          resolve(tokenSet.id_token);
        else {
          const new_tokens = await refreshTokenSet(
            tokenSet.id_token,
            tokenSet.refresh_token
          );
          saveTokenSet(new_tokens.id_token, new_tokens.refresh_token);
          const profileData = await getProfiles(new_tokens.id_token);
          setProfiles(profileData.profiles);
          setSubscriptionPlanDetails(profileData.subscription_details);
          resolve(new_tokens.id_token);
        }
      } catch (err) {
        try {
          const refreshTokenData = decodeToken(tokenSet.refresh_token);
          const expiresAt = (refreshTokenData?.exp ?? 0) * 1000;
          if (expiresAt <= Date.now()) throw new Error("Refresh Token Expired");
        } catch (err) {
          console.error(err);
          logout();
        }
        reject(err);
      }
    });

  const userData = useMemo(() => {
    const profileData = profiles.find(
      (p) => p.member_profile_id === selectedProfileID
    );
    setAnalyticsUserIdentity(
      `${profileData?.user_id ?? ""}+${profileData?.member_profile_id ?? ""}`
    );
    return {
      user_id: profileData?.user_id ?? "",
      profile_id: profileData?.member_profile_id ?? "",
      relation: profileData?.relation ?? "",
      score_id: profileData?.additional_info?.score_id ?? "",
      // flyy_id: profileData?.additional_info?.flyy_id ?? "",
      fname: profileData?.first_name ?? "",
      lname: profileData?.last_name ?? "",
      email: profileData?.email ?? "",
      phone: profileData?.phone_no ?? "",
      gender: profileData?.gender ?? "",
      dob: profileData?.dob ?? "",
      height: parseFloat(profileData?.height) || 0,
      weight: parseFloat(profileData?.weight) || 0,
      waist: parseFloat(profileData?.waist) || 0,
      alert_status: profileData?.user_alert_status ?? {},
    };
  }, [profiles, selectedProfileID]);

  return isLoading ? (
    <LoadingScreen />
  ) : (
    <AuthContext.Provider
      value={{
        getToken,
        userData,
        profiles,
        subscriptionPlanDetails,
        selectProfile,
        login,
        logout,
      }}
    >
      {props?.children}
    </AuthContext.Provider>
  );
};

export const useAuth = () => useContext(AuthContext);

export default AuthProvider;
