import { useState } from "react";
import {
  Bar,
  BarChart,
  Cell,
  ResponsiveContainer,
  CartesianGrid,
  Tooltip,
  XAxis,
  YAxis,
} from "recharts";
import { Tab, Tabs, TabList, TabPanel } from "react-tabs";
import { Link } from "react-router-dom";
import InfoIcon from "../assets/icons/info-icon.svg";
import StepImg from "../assets/images/step-img.png";

const data = [
  {
    name: "JAN",
    uv: 32.0,
    pv: 31.0,
    amt: 33,
  },
  {
    name: "FEB",
    uv: 20.5,
    pv: 20.2,
    amt: 21,
  },
  {
    name: "MAR",
    uv: 16.5,
    pv: 16.2,
    amt: 17,
  },
  {
    name: "APR",
    uv: 31.0,
    pv: 31.5,
    amt: 31,
  },
  {
    name: "MAY",
    uv: 30.0,
    pv: 30.2,
    amt: 32,
  },
  {
    name: "JUN",
    uv: 24.9,
    pv: 24.5,
    amt: 20,
  },
  {
    name: "JUL",
    uv: 18.4,
    pv: 18.5,
    amt: 16,
  },
  {
    name: "AUG",
    uv: 12.0,
    pv: 12.0,
    amt: 10,
  },
];

const BMI = () => {
  const [tabIndex, setTabIndex] = useState(0);

  return (
    <div className="w-full">
      <div
        className="flex items-center mb-8 px-6"
        data-aos="fade-right"
        data-aos-duration="1000"
        data-aos-delay="50"
      >
        <Link to="/" className="mr-3">
          <svg
            width="6"
            height="10"
            viewBox="0 0 10 17"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M9.42402 16.2142C9.99277 15.6454 9.99277 14.7267 9.42402 14.1579L3.76569 8.49959L9.42402 2.84126C9.99277 2.27251 9.99277 1.35376 9.42402 0.785009C8.85527 0.216259 7.93652 0.216259 7.36777 0.785009L0.674024 7.47876C0.105274 8.04751 0.105274 8.96626 0.674024 9.53501L7.36777 16.2288C7.92194 16.7829 8.85527 16.7829 9.42402 16.2142Z"
              fill="#fff"
            />
          </svg>
        </Link>
        <h3 className="text-white text-[14px] font-semibold">BMI</h3>
      </div>
      <Tabs
        selectedIndex={tabIndex}
        onSelect={(tabIndex) => setTabIndex(tabIndex)}
      >
        <TabList
          className="walking-tab flex justify-center items-center"
          data-aos="fade-right"
          data-aos-duration="1000"
          data-aos-delay="100"
        >
          <Tab className="order-4 text-xs font-medium text-[#fff]/[.7]">
            Weekly
          </Tab>
          <Tab className="order-3 text-xs font-medium mr-6 text-[#fff]/[.7]">
            Monthly
          </Tab>
          <Tab className="order-2 text-xs font-medium mr-6 text-[#fff]/[.7]">
            Quarterly
          </Tab>
          <Tab className="order-1 text-xs font-medium mr-6 text-[#fff]/[.7]">
            Yearly
          </Tab>
        </TabList>
        <TabPanel>
          <div
            className="px-6 mt-3"
            data-aos="fade-up"
            data-aos-duration="1000"
            data-aos-delay="200"
          >
            <div className="border border-[#812EFD]/50 rounded-[10px] pt-3 px-3">
              <h3 className="flex items-center text-[#812EFD] text-[14px] font-medium mb-4 mt-1 ml-2">
                BMI{" "}
                <Link>
                  <img src={InfoIcon} alt="info icon" className="ml-2" />
                </Link>
              </h3>
              <ResponsiveContainer width="100%" height={200}>
                <BarChart
                  className="ml-[-15px]"
                  data={data}
                  margin={{
                    top: 5,
                    right: -10,
                    left: -10,
                    bottom: 5,
                  }}
                >
                  <CartesianGrid horizontal vertical={false} />
                  <XAxis
                    tickLine={false}
                    axisLine={{ stroke: "#A977F9" }}
                    dataKey="name"
                    style={{ fontSize: 10, color: "#A977F9" }}
                  />
                  <YAxis
                    tickLine={false}
                    stroke="#A977F9"
                    axisLine={false}
                    style={{ fontSize: 10, color: "#A977F9" }}
                  />
                  <Tooltip />

                  <Bar dataKey="pv" barSize={8} radius={[10, 10, 10, 10]}>
                    <Cell fill="#C7212A" />
                    <Cell fill="#64BD40" />
                    <Cell fill="#64BD40" />
                    <Cell fill="#FFC400" />
                    <Cell fill="#FFC400" />
                    <Cell fill="#64BD40" />
                    <Cell fill="#64BD40" />
                    <Cell fill="#FFC400" />
                  </Bar>
                </BarChart>
              </ResponsiveContainer>
            </div>
          </div>
          <div
            className="px-6"
            data-aos="fade-up"
            data-aos-duration="1000"
            data-aos-delay="250"
          >
            <div className="flex items-center justify-between mt-6">
              <h3 className="text-[16px] text-[#812EFD] font-normal">
                Latest BMI
              </h3>
              <p className="text-[10px] text-[#fff] text-right leading-[12px]">
                Last Recorded : 04-08-2022
                <br />
                at 11:46pm
              </p>
            </div>
            <div className=" w-full my-4">
              <img src={StepImg} alt="info icon" />
            </div>
            <div className="w-full mb-5 mt-8 flex">
              <Link
                to="/my-account"
                className=" w-[135px] h-[35px] bg-gradient-to-r from-[#063BC4] to-[#680FAD] text-center text-[12px] text-white flex items-center justify-center hover:bg-[#680FAD] active:bg-[#680FAD] rounded-full"
              >
                Update BMI
              </Link>
            </div>
            <h4 className="text-[14px] text-[#812EFD] font-normal mt-8">
              Your BMI is{" "}
              <span className="text-[#FFC400] font-semibold">Overweight</span>
            </h4>
            <p className="text-[12px] text-[#fff]/80 leading-[18px] text-justify mt-4">
              Being overweight means that an individual has excess body weight
              relative to their height, which can increase their risk for
              various health issues such as heart disease, diabetes, and joint
              problems.
            </p>
            <p className="text-[12px] text-[#fff]/80 leading-[18px] text-justify mt-4">
              If an individual has an overweight BMI, it's important to consult
              with a healthcare provider to determine an appropriate course of
              action to manage weight and improve overall health. This may
              involve dietary changes, increased physical activity, or other
              lifestyle modifications.
            </p>
            <div className="w-full mb-28 mt-8 flex items-center justify-center">
              <Link
                to="/bmi-history"
                className=" w-[70%] h-[35px] bg-gradient-to-r from-[#063BC4] to-[#680FAD] text-center text-[12px] text-white flex items-center justify-center hover:bg-[#812EFD] active:bg-[#812EFD] rounded-full"
              >
                View History
              </Link>
            </div>
          </div>
        </TabPanel>
        <TabPanel>
          <div
            className="px-6 mt-3"
            data-aos="fade-up"
            data-aos-duration="1000"
            data-aos-delay="200"
          >
            <div className="border border-[#E9E9E9] rounded-[10px] pt-3 px-3">
              <h3 className="flex items-center text-primary text-[14px] font-medium mb-4 mt-1 ml-2">
                BMI{" "}
                <Link>
                  <img src={InfoIcon} alt="info icon" className="ml-2" />
                </Link>
              </h3>
              <ResponsiveContainer width="100%" height={200}>
                <BarChart
                  className="ml-[-15px]"
                  data={data}
                  margin={{
                    top: 5,
                    right: -10,
                    left: -10,
                    bottom: 5,
                  }}
                >
                  <CartesianGrid horizontal vertical={false} />
                  <XAxis
                    tickLine={false}
                    axisLine={{ stroke: "rgba(25,40,82, 0.5)" }}
                    dataKey="name"
                    style={{ fontSize: 10, color: "rgba(25,40,82, 0.5)" }}
                  />
                  <YAxis
                    tickLine={false}
                    stroke="#333"
                    axisLine={false}
                    style={{ fontSize: 10, color: "rgba(25,40,82, 0.5)" }}
                  />
                  <Tooltip />

                  <Bar dataKey="pv" barSize={8} radius={[10, 10, 10, 10]}>
                    <Cell fill="#C7212A" />
                    <Cell fill="#64BD40" />
                    <Cell fill="#64BD40" />
                    <Cell fill="#FFC400" />
                    <Cell fill="#FFC400" />
                    <Cell fill="#64BD40" />
                    <Cell fill="#64BD40" />
                    <Cell fill="#FFC400" />
                  </Bar>
                </BarChart>
              </ResponsiveContainer>
            </div>
            <div className="w-full mb-28 mt-8 flex items-center justify-center">
              <Link
                to="/bmi-history"
                className=" w-[70%] h-[35px] bg-white text-center text-[12px] text-secondary border border-secondary flex items-center justify-center hover:bg-primary active:bg-primary rounded-full"
              >
                View History
              </Link>
            </div>
          </div>
        </TabPanel>
        <TabPanel>
          <div
            className="px-6 mt-3"
            data-aos="fade-up"
            data-aos-duration="1000"
            data-aos-delay="200"
          >
            <div className="border border-[#E9E9E9] rounded-[10px] pt-3 px-3">
              <h3 className="flex items-center text-primary text-[14px] font-medium mb-4 mt-1 ml-2">
                BMI{" "}
                <Link>
                  <img src={InfoIcon} alt="info icon" className="ml-2" />
                </Link>
              </h3>
              <ResponsiveContainer width="100%" height={200}>
                <BarChart
                  className="ml-[-15px]"
                  data={data}
                  margin={{
                    top: 5,
                    right: -10,
                    left: -10,
                    bottom: 5,
                  }}
                >
                  <CartesianGrid horizontal vertical={false} />
                  <XAxis
                    tickLine={false}
                    axisLine={{ stroke: "rgba(25,40,82, 0.5)" }}
                    dataKey="name"
                    style={{ fontSize: 10, color: "rgba(25,40,82, 0.5)" }}
                  />
                  <YAxis
                    tickLine={false}
                    stroke="#333"
                    axisLine={false}
                    style={{ fontSize: 10, color: "rgba(25,40,82, 0.5)" }}
                  />
                  <Tooltip />

                  <Bar dataKey="pv" barSize={8} radius={[10, 10, 10, 10]}>
                    <Cell fill="#C7212A" />
                    <Cell fill="#64BD40" />
                    <Cell fill="#64BD40" />
                    <Cell fill="#FFC400" />
                    <Cell fill="#FFC400" />
                    <Cell fill="#64BD40" />
                    <Cell fill="#64BD40" />
                    <Cell fill="#FFC400" />
                  </Bar>
                </BarChart>
              </ResponsiveContainer>
            </div>
            <div className="w-full mb-28 mt-8 flex items-center justify-center">
              <Link
                to="/bmi-history"
                className=" w-[70%] h-[35px] bg-white text-center text-[12px] text-secondary border border-secondary flex items-center justify-center hover:bg-primary active:bg-primary rounded-full"
              >
                View History
              </Link>
            </div>
          </div>
        </TabPanel>
        <TabPanel>
          <div
            className="px-6 mt-3"
            data-aos="fade-up"
            data-aos-duration="1000"
            data-aos-delay="200"
          >
            <div className="border border-[#E9E9E9] rounded-[10px] pt-3 px-3">
              <h3 className="flex items-center text-primary text-[14px] font-medium mb-4 mt-1 ml-2">
                BMI{" "}
                <Link>
                  <img src={InfoIcon} alt="info icon" className="ml-2" />
                </Link>
              </h3>
              <ResponsiveContainer width="100%" height={200}>
                <BarChart
                  className="ml-[-15px]"
                  data={data}
                  margin={{
                    top: 5,
                    right: -10,
                    left: -10,
                    bottom: 5,
                  }}
                >
                  <CartesianGrid horizontal vertical={false} />
                  <XAxis
                    tickLine={false}
                    axisLine={{ stroke: "rgba(25,40,82, 0.5)" }}
                    dataKey="name"
                    style={{ fontSize: 10, color: "rgba(25,40,82, 0.5)" }}
                  />
                  <YAxis
                    tickLine={false}
                    stroke="#333"
                    axisLine={false}
                    style={{ fontSize: 10, color: "rgba(25,40,82, 0.5)" }}
                  />
                  <Tooltip />

                  <Bar dataKey="pv" barSize={8} radius={[10, 10, 10, 10]}>
                    <Cell fill="#C7212A" />
                    <Cell fill="#64BD40" />
                    <Cell fill="#64BD40" />
                    <Cell fill="#FFC400" />
                    <Cell fill="#FFC400" />
                    <Cell fill="#64BD40" />
                    <Cell fill="#64BD40" />
                    <Cell fill="#FFC400" />
                  </Bar>
                </BarChart>
              </ResponsiveContainer>
            </div>
            <div className="w-full mb-28 mt-8 flex items-center justify-center">
              <Link
                to="/bmi-history"
                className=" w-[70%] h-[35px] bg-white text-center text-[12px] text-secondary border border-secondary flex items-center justify-center hover:bg-primary active:bg-primary rounded-full"
              >
                View History
              </Link>
            </div>
          </div>
        </TabPanel>
      </Tabs>
    </div>
  );
};

export default BMI;
