import { Link } from "react-router-dom";
import Clock from "../assets/icons/clock.svg";
import Calender from "../assets/icons/calender.svg";

const Doctor = ({
  img,
  tittle = "",
  subtittle = "",
  designation = "",
  date = "",
  time = "",
}) => {
  return (
    <div
      className="rounded-[10px] bg-white p-[15px] mb-3 flex items-center justify-between"
      style={{ boxShadow: "0px 1px 10px rgba(0, 0, 0, 0.08)" }}
    >
      <div className="flex items-start justify-start">
        <div className="avatar mr-4">
          <img src={img} alt="avatar" width="45" />
        </div>
        <div className="">
          <h3 className="text-[#182A4E] text-[13px] font-medium mb-[2px]">
            {tittle}
          </h3>
          <p className="text-[#182A4E]/[.5] text-[11px]">{subtittle}</p>
          <p className="text-secondary font-medium text-[11px] mb-2">
            {designation}
          </p>
          <h5 className="flex items-center text-[#182A4E]/[.7] text-[11px]">
            <img src={Clock} alt="time" className="mr-1" /> {time}{" "}
            <span className="flex items-center justify-center">
              <img src={Calender} alt="date" className="mr-1 ml-3" /> {date}
            </span>
          </h5>
        </div>
      </div>
      <div>
        <Link
          to="/Doctor-appointment"
          className="bg-secondary w-[32px] h-[32px] rounded-full flex items-center justify-center hover:bg-primary active:bg-primary"
        >
          <svg
            width="13"
            height="13"
            viewBox="0 0 13 13"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <g clip-path="url(#clip0_4203_179)">
              <path
                d="M9.17257 2.42115L12.7692 5.98728L12.7992 6.01518C12.9196 6.13451 12.9896 6.29189 13 6.47784L12.9994 6.54035C12.9907 6.68764 12.9344 6.82835 12.8235 6.95445L12.7815 6.99777L9.17257 10.5768C8.90568 10.8415 8.47414 10.8415 8.20725 10.5768C7.93876 10.3106 7.93876 9.87769 8.20723 9.61147L10.6991 7.14005L0.684028 7.14029C0.30689 7.14029 0 6.83597 0 6.45913C0 6.08228 0.306894 5.77796 0.684023 5.77796L10.6187 5.77772L8.20725 3.38649C7.93876 3.12025 7.93876 2.68739 8.20725 2.42115C8.47414 2.15649 8.90568 2.15649 9.17257 2.42115Z"
                fill="white"
              />
            </g>
            <defs>
              <clipPath id="clip0_4203_179">
                <rect width="13" height="13" fill="white" />
              </clipPath>
            </defs>
          </svg>
        </Link>
      </div>
    </div>
  );
};

export default Doctor;
