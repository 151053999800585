import { Fragment, useState } from "react";
import { Link } from "react-router-dom";
import { Dialog, Transition } from "@headlessui/react";
import InsuranceLogo from "../assets/images/care-insurance.png";
import Aditya from "../assets/images/aditya-insurance.png";
import Discount from "../assets/images/discount-sign.svg";
import Next from "../assets/images/next.svg";

const InsuranceList = () => {
  let [isOpen, setIsOpen] = useState(false);
  function closeModal() {
    setIsOpen(false);
  }
  function openModal() {
    setIsOpen(true);
  }

  return (
    <div
      className="px-6 pb-28"
      data-aos="fade-up"
      data-aos-delay="150"
      data-aos-duration="1000"
    >
      <div className="flex items-center mb-5">
        <Link to="/" className="mr-3">
          <svg
            width="6"
            height="10"
            viewBox="0 0 10 17"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M9.42402 16.2142C9.99277 15.6454 9.99277 14.7267 9.42402 14.1579L3.76569 8.49959L9.42402 2.84126C9.99277 2.27251 9.99277 1.35376 9.42402 0.785009C8.85527 0.216259 7.93652 0.216259 7.36777 0.785009L0.674024 7.47876C0.105274 8.04751 0.105274 8.96626 0.674024 9.53501L7.36777 16.2288C7.92194 16.7829 8.85527 16.7829 9.42402 16.2142Z"
              fill="#192852"
            />
          </svg>
        </Link>
        <h3 className="text-primary text-[14px] font-semibold">
          Insurance Policy
        </h3>
      </div>
      {/* insurance card */}
      <div className="flex flex-col bg-white border border-[#e4e5e8] rounded-[10px] px-4 py-4 mb-3 health-box">
        <div className="flex mb-2">
          <img src={InsuranceLogo} alt="insurance logo" width="79" />
          <div className="ml-5">
            <h4 className="text-[16px] font-bold text-primary mb-1">
              Care Supreme Direct
            </h4>
            <p className="text-[12px] text-[#487e2a] font-medium flex items-center">
              <img src={Discount} alt="" width="25" /> 15% Instant Discount *
            </p>
          </div>
        </div>
        <div>
          <h4 className="text-[12px] text-secondary font-semibold mb-1">
            Key Benefit
          </h4>
          <ul className=" list-none m-0 p-0 flex mt-2 flex-wrap">
            <li className="border border-[#3298F1]/[0.3] px-2 py-1 text-[8px] mr-1 mb-1 rounded-full text-primary">
              Single Private Room
            </li>
            <li className="border border-[#3298F1]/[0.3] px-2 py-1 text-[8px] mb-1 mr-1 rounded-full text-primary">
              Ambulance Cover
            </li>
            <li className="border border-[#3298F1]/[0.3] px-2 py-1 text-[8px] mb-1 mr-1 rounded-full text-primary">
              10 Lacs Claim Bonus
            </li>
            <li className="border border-[#3298F1]/[0.3] px-2 py-1 text-[8px] mb-1 mr-1 rounded-full text-primary">
              Covid Care with OPD
            </li>
            <li className="border border-[#3298F1]/[0.3] px-2 py-1 text-[8px] mb-1 mr-1 rounded-full text-primary">
              Lifelong Renewability
            </li>
            <li className="text-[10px] ml-1 py-1">
              <Link to="" className="text-secondary font-medium flex">
                View all features{" "}
                <img src={Next} alt="" width="8" className="ml-1" />
              </Link>
            </li>
          </ul>
          <div className="flex justify-between items-center">
            <div className="flex items-center">
              <div className="flex flex-col items-start justify-start">
                <h2 className="text-[11px] font-semibold text-secondary mb-1">
                  Coverd
                </h2>
                <p className="text-[12px] text-primary font-bold text-left">
                  ₹ 5 Lacs
                </p>
              </div>
              <div className="ml-3 flex flex-col items-start justify-start">
                <h2 className="text-[11px] font-semibold text-secondary mb-1">
                  Cashless Hospital
                </h2>
                <p className="text-[12px] text-primary font-bold text-left">
                  3000
                </p>
              </div>
            </div>
            <div className="flex flex-col mt-3">
              <button
                onClick={openModal}
                className="px-2 text-white text-[11px] bg-secondary rounded-[10px] md-3 flex items-center justify-center py-1 text-center"
              >
                Buy Policy
              </button>
              <button
                onClick={openModal}
                className="px-2 w-[120px] text-primary text-[11px] bg-white border border-primary rounded-[10px] flex items-center focus:outline-none focus:text-secondary focus:border-secondary justify-center mt-2 py-1 text-center"
              >
                Request Callback
              </button>
            </div>
          </div>
        </div>
      </div>
      {/* card end */}
      {/* insurance card */}
      <div className="flex flex-col bg-white border border-[#e4e5e8] rounded-[10px] px-4 py-4 mb-3 health-box">
        <div className="flex mb-2">
          <img src={Aditya} alt="insurance logo" width="79" />
          <div className="ml-5">
            <h4 className="text-[16px] font-bold text-primary mb-1">
              Active Fit Plus
            </h4>
            <p className="text-[12px] text-[#487e2a] font-medium flex items-center">
              <img src={Discount} alt="" width="25" /> 10% Instant Discount *
            </p>
          </div>
        </div>
        <div>
          <h4 className="text-[12px] text-secondary font-semibold mb-1">
            Key Benefit
          </h4>
          <ul className=" list-none m-0 p-0 flex mt-2 flex-wrap">
            <li className="border border-[#3298F1]/[0.3] px-2 py-1 text-[8px] mr-1 mb-1 rounded-full text-primary">
              Discount On Renewal
            </li>
            <li className="border border-[#3298F1]/[0.3] px-2 py-1 text-[8px] mb-1 mr-1 rounded-full text-primary">
              Early Bird Discount
            </li>
            <li className="border border-[#3298F1]/[0.3] px-2 py-1 text-[8px] mb-1 mr-1  rounded-full text-primary">
              Maternity Benefit
            </li>
            <li className="border border-[#3298F1]/[0.3] px-2 py-1 text-[8px] mb-1 mr-1 rounded-full text-primary">
              Travel Protect
            </li>

            <li className="text-[10px] ml-1 py-1">
              <Link to="" className="text-secondary font-medium flex">
                View all features{" "}
                <img src={Next} alt="" width="8" className="ml-1" />
              </Link>
            </li>
          </ul>
          <div className="flex justify-between items-center">
            <div className="flex items-center">
              <div className="flex flex-col items-start justify-start">
                <h2 className="text-[11px] font-semibold text-secondary mb-1">
                  Coverd
                </h2>
                <p className="text-[12px] text-primary font-bold text-left">
                  ₹ 5 Lacs
                </p>
              </div>
              <div className="ml-3 flex flex-col items-start justify-start">
                <h2 className="text-[11px] font-semibold text-secondary mb-1">
                  Cashless Hospital
                </h2>
                <p className="text-[12px] text-primary font-bold text-left">
                  11091
                </p>
              </div>
            </div>
            <div className="flex flex-col mt-3">
              <button
                onClick={openModal}
                className="px-2 text-white text-[11px] bg-secondary rounded-[10px] md-3 flex items-center justify-center py-1 text-center"
              >
                Buy Policy
              </button>
              <button
                onClick={openModal}
                className="px-2 w-[120px] text-primary text-[11px] bg-white border border-primary rounded-[10px] active:border-secondary focus:outline-none focus:text-secondary focus:border-secondary flex items-center justify-center mt-2 py-1 text-center"
              >
                Request Callback
              </button>
            </div>
          </div>
        </div>
      </div>
      {/* card end */}
      {/* Modal */}
      <Transition appear show={isOpen} as={Fragment}>
        <Dialog as="div" className="relative z-10" onClose={closeModal}>
          <Transition.Child
            as={Fragment}
            enter="ease-out duration-300"
            enterFrom="opacity-0"
            enterTo="opacity-100"
            leave="ease-in duration-200"
            leaveFrom="opacity-100"
            leaveTo="opacity-0"
          >
            <div className="fixed inset-0 bg-black bg-opacity-25" />
          </Transition.Child>

          <div className="fixed inset-0 overflow-y-auto">
            <div className="flex min-h-full items-center justify-center p-4 text-center">
              <Transition.Child
                as={Fragment}
                enter="ease-out duration-300"
                enterFrom="opacity-0 scale-95"
                enterTo="opacity-100 scale-100"
                leave="ease-in duration-200"
                leaveFrom="opacity-100 scale-100"
                leaveTo="opacity-0 scale-95"
              >
                <Dialog.Panel className="w-full max-w-md transform overflow-hidden rounded-2xl bg-white p-6 text-left align-middle shadow-xl transition-all">
                  <button
                    onClick={closeModal}
                    className=" absolute right-5 top-4"
                  >
                    <svg width="30" height="30" viewBox="0 0 30 30" fill="none">
                      <path
                        d="M15 2.5C21.875 2.5 27.5 8.125 27.5 15C27.5 21.875 21.875 27.5 15 27.5C8.125 27.5 2.5 21.875 2.5 15C2.5 8.125 8.125 2.5 15 2.5Z"
                        stroke="#1B4083"
                        stroke-opacity="0.7"
                        strokeLinecap="round"
                        strokeLinejoin="round"
                      />
                      <path
                        d="M11.4624 11.4622L18.5374 18.5371"
                        stroke="#1B4083"
                        stroke-opacity="0.7"
                        strokeLinecap="round"
                        strokeLinejoin="round"
                      />
                      <path
                        d="M18.5374 11.4622L11.4624 18.5371"
                        stroke="#1B4083"
                        stroke-opacity="0.7"
                        strokeLinecap="round"
                        strokeLinejoin="round"
                      />
                    </svg>
                  </button>
                  <div className="px-6 py-10 flex items-center justify-center flex-col">
                    <h4 className="text-[20px] font-semibold text-primary mb-3">
                      Thank you!
                    </h4>
                    <p className="text-[12px] text-[#000]/[0.7] text-center">
                      Your request has been received and our Business Support
                      Specialist will call you within 24 business hours.
                    </p>
                  </div>
                </Dialog.Panel>
              </Transition.Child>
            </div>
          </div>
        </Dialog>
      </Transition>
      {/* Modal end */}
    </div>
  );
};

export default InsuranceList;
