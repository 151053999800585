import { Link } from "react-router-dom";
import WeightLoss from "../assets/images/goals/weight-loss.png";
import RelifPain from "../assets/images/goals/relief-pain.png";
import Stress from "../assets/images/goals/stress.png";
import Hypertension from "../assets/images/goals/Hypertension.png";

const SelectedGoal = () => (
  <div className="w-full pb-24 px-6">
    <div className="flex items-center mb-5">
      <Link to="" className="mr-3">
        <svg
          width="6"
          height="10"
          viewBox="0 0 10 17"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            d="M9.42402 16.2142C9.99277 15.6454 9.99277 14.7267 9.42402 14.1579L3.76569 8.49959L9.42402 2.84126C9.99277 2.27251 9.99277 1.35376 9.42402 0.785009C8.85527 0.216259 7.93652 0.216259 7.36777 0.785009L0.674024 7.47876C0.105274 8.04751 0.105274 8.96626 0.674024 9.53501L7.36777 16.2288C7.92194 16.7829 8.85527 16.7829 9.42402 16.2142Z"
            fill="#fff"
          />
        </svg>
      </Link>
      <h3 className="text-white text-base font-semibold">Selected Goals</h3>
    </div>
    <ul className="flex flex-wrap justify-between">
      <li className="multi-checkbox w-[48%] mb-4 relative">
        <label className="relative">
          <p className="absolute z-10 left-4 bottom-4 text-xs font-bold">
            Weight Loss
          </p>
          <img alt="" src={WeightLoss} className="w-full" />
        </label>
      </li>
      <li className="multi-checkbox w-[48%] mb-4">
        <label className="relative">
          <p className="absolute z-10 left-4 bottom-4 text-xs font-bold">
            Pain Relief
          </p>
          <img alt="" src={RelifPain} className="w-full" />
        </label>
      </li>
      <li className="multi-checkbox w-[48%] mb-4">
        <label className="relative">
          <p className="absolute z-10 left-4 bottom-4 text-xs font-bold">
            Stress Management
          </p>
          <img alt="" src={Stress} className="w-full" />
        </label>
      </li>
      <li className="multi-checkbox w-[48%] mb-4">
        <label className="relative">
          <p className="absolute z-10 left-4 bottom-4 text-xs font-bold">
            Hypertension Management
          </p>
          <img alt="" src={Hypertension} className="w-full" />
        </label>
      </li>
    </ul>
  </div>
);

export default SelectedGoal;
