import { useEffect, useState } from "react";
import { Link, useLocation } from "react-router-dom";
import { useAuth } from "../utils/auth";
import { sdkWrapperURL } from "../utils/api-url-list";
import { constants } from "../utils/ranges";
import LoadingScreen from "../components/LoadingScreen";
import { ReactComponent as BackArrow } from "../assets/icons/BackArrow.svg";
import { ReactComponent as FaceScan } from "../assets/icons/FaceScan.svg";
import { ReactComponent as FingerScan } from "../assets/icons/FingerScan.svg";

const scoreNames = {
  C001: "Vitals Index",
  C002: "Diabetes Insight",
  C003: "Heart Score",
  C004: "Mental Wellbeing Score",
  C005: "Digital Health Assessment Score",
};

const ScoreHistory = () => {
  const {
    state: { scoreID },
  } = useLocation();
  const {
    getToken,
    userData: { profile_id },
    subscriptionPlanDetails: { packageName },
  } = useAuth();

  const noOfScoreToDisplay = 6;
  const [currentPage, setCurrentPage] = useState(1);
  const [loading, setLoading] = useState(true);
  const [history, setHistory] = useState([]);

  useEffect(() => {
    const controller = new AbortController();
    (async () => {
      try {
        setLoading(true);
        const token = await getToken();
        const scoreResp = await fetch(sdkWrapperURL("/score/get-scores"), {
          method: "POST",
          headers: { "Content-Type": "application/json", Authorization: token },
          body: JSON.stringify({
            profile_id,
            scores_display_count: noOfScoreToDisplay,
            scores_display_count_offset: currentPage - 1,
            table_config_rows_per_page: 1,
            table_config_page_no:
              packageName === "FREEMIUM" || packageName === "LITE"
                ? 1
                : parseInt(scoreID?.slice?.(-1) ?? 0),
          }),
        });
        const scoreRespJSON = await scoreResp.json();
        if (scoreRespJSON?.statusCode?.toString().startsWith("2")) {
          if (scoreRespJSON.response?.[0]?.user_score_details?.length > 0)
            setHistory(
              scoreRespJSON.response?.[0]?.user_score_details?.map?.(
                (score) => {
                  const { color, label } = constants[
                    "Wellbeing Score"
                  ].ranges.reduce((p, c) =>
                    score.user_earned_score > c.point ? c : p
                  );
                  return {
                    timestamp: score.score_generation_time,
                    scanType: score.score_description?.device
                      ?.toLowerCase?.()
                      .includes("finger")
                      ? "Finger"
                      : "Face",
                    score: score.user_earned_score,
                    color,
                    label,
                  };
                }
              )
            );
          else setCurrentPage((p) => (p > 1 ? p - 1 : 1));
        }
      } catch (err) {
        console.error(err);
      } finally {
        setLoading(false);
      }
    })();
    return () => {
      controller.abort();
    };
  }, [getToken, profile_id, packageName, scoreID, currentPage]);

  return (
    <section className="px-6 pt-2 pb-24">
      <div className="flex items-center text-white">
        <Link className="flex-shrink-0 p-1 pr-2.5" to={-1}>
          <BackArrow className="h-2.5 w-2.5" />
        </Link>
        <h3 className="text-sm font-medium">{scoreNames[scoreID]} History</h3>
      </div>
      {loading ? (
        <LoadingScreen style={{ minHeight: "50vh" }} />
      ) : history?.length > 0 ? (
        <div className="my-6">
          {history.map((historyItem, index) => (
            <div
              key={`history-item-${index}`}
              className="mb-3 px-4 py-3 flex items-center space-x-4 rounded-2xl bg-gradient-transparent shadow-box"
            >
              {historyItem.scanType === "Face" ? (
                <FaceScan className="shrink-0 h-8 w-8" />
              ) : (
                <FingerScan className="shrink-0 h-8 w-8" />
              )}
              <div className="flex-grow space-y-1.5">
                <h4 className="text-white text-xs font-medium">
                  {historyItem.scanType}&nbsp;Scan
                </h4>
                <h5 className="text-white/60 text-xxs">
                  {new Date(historyItem.timestamp).toLocaleString(undefined, {
                    day: "2-digit",
                    month: "2-digit",
                    year: "numeric",
                    hour: "2-digit",
                    minute: "2-digit",
                    hour12: true,
                  })}
                </h5>
              </div>
              <div className="text-right font-semibold">
                <h4 className="text-[#C924A4] text-xl">
                  {historyItem.score} <span className="text-xs">%</span>
                </h4>
                <h6
                  className="text-xxs font-medium"
                  style={{ color: historyItem.color }}
                >
                  {historyItem.label}
                </h6>
              </div>
            </div>
          ))}

          <div className="mt-6 flex items-center max-w-max mx-auto text-white text-xs">
            <button
              type="button"
              disabled={loading || currentPage === 1}
              onClick={() => setCurrentPage((p) => p - 1)}
              className="disabled:text-lightgray"
            >
              &lt;&nbsp;Previous
            </button>
            <span className="mx-2 px-4 py-0.5 border-x border-primary text-[#C924A4] font-medium">
              Page&nbsp;{currentPage}
            </span>
            <button
              type="button"
              disabled={loading || history.length < noOfScoreToDisplay}
              onClick={() => setCurrentPage((p) => p + 1)}
              className="disabled:text-lightgray"
            >
              Next&nbsp;&gt;
            </button>
          </div>
        </div>
      ) : (
        <p className="my-6 p-4 rounded-2xl shadow-box bg-white text-primary/60 text-xs">
          No Data to show...
        </p>
      )}
    </section>
  );
};
export default ScoreHistory;
