import { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import LoadingScreen from "../components/LoadingScreen";
import { useAuth } from "../utils/auth";
import { sdkWrapperURL } from "../utils/api-url-list";
import Nodata from "../assets/images/goals/no_data_icon.svg";

const AddGoal = () => {
  const {
    userData: { profile_id },
    getToken,
  } = useAuth();
  const [loading, setLoading] = useState(false);
  const [selectedGoals, setSelectedGoals] = useState([]);

  useEffect(() => {
    setLoading(true);
    (async () => {
      try {
        const token = await getToken();
        const goalsListResp = await fetch(sdkWrapperURL("users/list/goals"), {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
            Authorization: token,
          },
          body: JSON.stringify({
            profile_id: profile_id,
            product_type_id: 23,
            table_config_rows_per_page: 20,
            table_config_page_no: 1,
            saved: 1,
          }),
        });
        const goalsRespJSON = await goalsListResp.json();
        if (goalsRespJSON?.statusCode?.toString().startsWith("2")) {
          setSelectedGoals(goalsRespJSON.response);
        } else {
          throw Error(goalsRespJSON.message ?? "Error in fetching Alerts.");
        }
      } catch (err) {
        console.error(err);
      } finally {
        setLoading(false);
      }
    })();
  }, [getToken, profile_id]);

  return (
    <div className="w-full pb-24 px-6">
      <div className="flex items-center mb-6">
        <Link to={-1} className="mr-3">
          <svg
            width="6"
            height="10"
            viewBox="0 0 10 17"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M9.42402 16.2142C9.99277 15.6454 9.99277 14.7267 9.42402 14.1579L3.76569 8.49959L9.42402 2.84126C9.99277 2.27251 9.99277 1.35376 9.42402 0.785009C8.85527 0.216259 7.93652 0.216259 7.36777 0.785009L0.674024 7.47876C0.105274 8.04751 0.105274 8.96626 0.674024 9.53501L7.36777 16.2288C7.92194 16.7829 8.85527 16.7829 9.42402 16.2142Z"
              fill="#fff"
            />
          </svg>
        </Link>
        <h3 className="text-white text-base font-semibold">My Goals</h3>
      </div>
      {loading ? (
        <LoadingScreen style={{ minHeight: "60vh" }} />
      ) : (
        <>
          <div className="mb-3">
            {selectedGoals?.length >= 1 && (
              <h4 className="text-lg text-[#663CC7] font-medium">
                Selected Goals
              </h4>
            )}
          </div>
          <div>
            {selectedGoals?.length <= 0 ? (
              <div className="flex flex-col items-center justify-between h-[60vh]">
                <div className="mt-24 flex flex-col items-center justify-center">
                  <img src={Nodata} alt="" className=" h-28" />
                  <p className="text-base text-[#663CC7] font-medium mt-2">
                    No Goals Available
                  </p>
                </div>
              </div>
            ) : (
              <ul className="flex flex-wrap justify-between">
                {selectedGoals?.map((item, i) => (
                  <li className="multi-checkbox w-[48%] mb-4 relative" key={i}>
                    <input type="checkbox" id="myCheckbox1" />
                    <label for="myCheckbox1" className="relative">
                      <p className="absolute z-10 left-4 bottom-4 text-xs font-bold">
                        {item.structure_val.name}
                      </p>
                      <img
                        alt=""
                        src={item.structure_val.logo}
                        className="w-full"
                      />
                    </label>
                  </li>
                ))}
              </ul>
            )}
            <Link
              to="/goal"
              state={{
                savedGoals: selectedGoals.map((item) => item.product_id),
              }}
              className="bg-gradient w-full h-10 text-sm font-medium text-white rounded-full flex items-center justify-center"
            >
              {selectedGoals?.length >= 1 ? "Edit Goals" : "Add Goals"}
            </Link>
          </div>
        </>
      )}
    </div>
  );
};

export default AddGoal;
