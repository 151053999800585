import { useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import { sdkWrapperURL } from "../utils/api-url-list";
import { useAuth } from "../utils/auth";
import { ReactComponent as Loader } from "../assets/icons/Loader.svg";

const Referal = () => {
  const { getToken } = useAuth();
  const navigate = useNavigate();
  const [hasReferalCode, setHasReferalCode] = useState(false);
  const [btnLoading, setBtnLoading] = useState(false);
  const [referalCode, setReferalCode] = useState();
  const [referalCodeError, setReferalCodeError] = useState("");

  const checkReferralCode = async () => {
    setReferalCodeError("");
    try {
      setBtnLoading(true);
      if (!referalCode) {
        setReferalCodeError("Please enter a Referral Code");
      } else {
        const token = await getToken();
        const referralResp = await fetch(
          sdkWrapperURL("/users/referral/update"),
          {
            method: "POST",
            headers: {
              "Content-Type": "application/json",
              Authorization: token,
            },
            body: JSON.stringify({ referral_code: referalCode }),
          }
        );
        const referralRespJSON = await referralResp.json();
        if (referralRespJSON?.statusCode?.toString().startsWith("2"))
          navigate("/basic-details", { replace: true, state: { self: true } });
        else
          throw new Error(
            referralRespJSON?.message ?? "Failed to save Referral code"
          );
      }
    } catch (err) {
      setReferalCodeError(err.message);
    } finally {
      setBtnLoading(false);
    }
  };

  return (
    <div className="min-h-screen p-8 relative bg-primary text-white">
      {hasReferalCode ? (
        <>
          <h1 className="text-2xl font-semibold">
            Enter Referral <span className="text-secondary">Code</span>
          </h1>
          <h4 className="mt-3 font-light">Enter your Referral Code</h4>
          <div>
            <input
              className="block w-full mt-6 px-4 py-2 rounded-lg outline-none border-2 focus:border-secondary bg-white text-primary text-sm"
              placeholder="Enter Referral Code"
              type="text"
              autoFocus
              onChange={(e) => setReferalCode(e.target.value.toUpperCase())}
              value={referalCode}
            />
            {referalCodeError.length > 0 && (
              <p className="mt-1.5 mx-1.5 text-xxs text-error">
                {referalCodeError}
              </p>
            )}
            <button
              className="w-full flex items-center justify-center space-x-2.5 mt-6 px-5 py-2.5 rounded-full outline-none bg-gradient disabled:bg-secondary text-white"
              type="button"
              onClick={checkReferralCode}
            >
              Submit{btnLoading && <Loader className="shrink-0 h-4 w-4" />}
            </button>
          </div>
        </>
      ) : (
        <>
          <h1 className="text-2xl font-semibold">Do you have an</h1>
          <h1 className="text-2xl font-semibold text-secondary">
            Invitation Code?
          </h1>
          <button
            className="w-full items-center justify-center space-x-2.5 mt-6 px-5 py-2.5 rounded-full outline-none bg-gradient disabled:bg-secondary text-white"
            type="button"
            onClick={() => setHasReferalCode(true)}
          >
            yes
          </button>
        </>
      )}
      <div className="w-full text-center mt-5">
        <h1 className="mt-3 font-light">Don’t have a referral code?</h1>
        <Link
          className="mt-3 mx-auto max-w-max font-light text-secondary"
          to="/basic-details"
          state={{ self: true }}
          replace
        >
          Click Here
        </Link>
      </div>
    </div>
  );
};

export default Referal;
