import { useEffect, useMemo, useState } from "react";
import { Link } from "react-router-dom";
import { Combobox } from "@headlessui/react";
import { Cell, Pie, PieChart } from "recharts";
import ProgressCircle from "../components/ProgressCircle";
import { constants } from "../utils/ranges";
import { useAuth } from "../utils/auth";
import { sdkWrapperURL } from "../utils/api-url-list";
import ChartIcon from "../assets/icons/insert_chart.svg";
import SearchIcon from "../assets/icons/search-icon.svg";
import PlusIcon from "../assets/icons/plus-icon.svg";
import MinusIcon from "../assets/icons/minus-icon.svg";
import WaterGlass from "../assets/images/glass.png";

const departments = [
  {
    id: 1,
    name: "Caesar Salad",
    weight: "159",
    cal: "179",
    fat: " 15 g ",
    carb: " 7.4 g",
    protein: "5.1 g",
  },
  {
    id: 2,
    name: "Greek Salad",
    weight: "159",
    cal: "179",
    fat: " 15 g ",
    carb: " 7.4 g",
    protein: "5.1 g",
  },
  {
    id: 3,
    name: "Caesar Salad",
    weight: "159",
    cal: "179",
    fat: " 15 g ",
    carb: " 7.4 g",
    protein: "5.1 g",
  },
  {
    id: 4,
    name: "Greek Salad",
    weight: "159",
    cal: "179",
    fat: " 15 g ",
    carb: " 7.4 g",
    protein: "5.1 g",
  },
  {
    id: 5,
    name: "Caesar Salad",
    weight: "159",
    cal: "179",
    fat: " 15 g ",
    carb: " 7.4 g",
    protein: "5.1 g",
  },
];

const Nutrition = ({ selectedDepartment, onChange }) => {
  const {
    getToken,
    userData: { employee_id },
  } = useAuth();
  const [loading, setLoading] = useState(true);
  const [lastScanResult, setLastResult] = useState({});
  const selectedScoreRange = useMemo(
    () =>
      constants["Wellbeing Score"].ranges.reduce((p, c) =>
        lastScanResult.wellness_score > c.point ? c : p
      ),
    [lastScanResult.wellness_score]
  );

  useEffect(() => {
    const controller = new AbortController();
    setLoading(true);
    (async () => {
      try {
        const token = await getToken();
        const scoreResp = await fetch(
          sdkWrapperURL("/users/sdk/test_api_private"),
          {
            method: "POST",
            headers: {
              "Content-Type": "application/json",
              Authorization: token,
            },
            body: JSON.stringify({
              action: "list_scan_UL",
              employee_id,
              table_config_rows_per_page: 1,
              table_config_page_no: 1,
            }),
            signal: controller.signal,
          }
        );
        const scoreRespJSON = await scoreResp.json();
        if (
          scoreRespJSON?.statusCode?.toString().startsWith("2") &&
          scoreRespJSON?.result?.length > 0
        )
          setLastResult(scoreRespJSON.result[0]);
      } catch (err) {
        console.error(err);
      } finally {
        setLoading(false);
      }
    })();
    return () => {
      controller.abort();
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  let [num, setNum] = useState(0);
  let incNum = () => {
    if (num < 10) {
      setNum(Number(num) + 1);
    }
  };
  let decNum = () => {
    if (num > 0) {
      setNum(num - 1);
    }
  };
  let handleChange = (e) => {
    setNum(e.target.value);
  };

  const data01 = [
    {
      name: "Carb",
      value: 45,
      color: "#EF587B",
    },
    {
      name: "Fat",
      value: 30,
      color: "#283B82",
    },
    {
      name: "Protein",
      value: 25,
      color: "#FAAD02",
    },
  ];
  const [query, setQuery] = useState("");
  const filteredDepartments =
    query === ""
      ? departments
      : departments.filter((department) => {
          return department.name.toLowerCase().includes(query.toLowerCase());
        });

  return (
    <div
      className="px-6 mb-28"
      data-aos="fade-up"
      data-aos-delay="100"
      data-aos-duration="1000"
    >
      <div className="flex items-center mb-8">
        <Link to="/" className="mr-3">
          <svg
            width="6"
            height="10"
            viewBox="0 0 10 17"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M9.42402 16.2142C9.99277 15.6454 9.99277 14.7267 9.42402 14.1579L3.76569 8.49959L9.42402 2.84126C9.99277 2.27251 9.99277 1.35376 9.42402 0.785009C8.85527 0.216259 7.93652 0.216259 7.36777 0.785009L0.674024 7.47876C0.105274 8.04751 0.105274 8.96626 0.674024 9.53501L7.36777 16.2288C7.92194 16.7829 8.85527 16.7829 9.42402 16.2142Z"
              fill="#192852"
            />
          </svg>
        </Link>
        <h3 className="text-primary text-[14px] font-semibold">
          Nutrition In-take
        </h3>
      </div>
      <div className="flex flex-col items-center">
        <ProgressCircle
          loading={loading}
          value={lastScanResult.wellness_score}
          color={selectedScoreRange.color}
          // subtitle={selectedScoreRange.label}
        />
        <h3 className="text-[14px] text-[#192852] font-medium mt-2">
          Nutrition In-take Score
        </h3>
        <h4 className="text-[16px] text-[#1A5701] font-medium">Excellent</h4>
        <p className="text-[11px] text-[#414141] mb-2">
          Last update August 01, 2022
        </p>
        <Link
          to=""
          className="text-[10px] text-secondary font-medium flex items-center"
        >
          <img src={ChartIcon} alt="chart icon" className="mr-1" /> View
          Analytics
        </Link>
        <p className="text-[11px] text-[#000]/[.7] text-justify mt-4">
          Nutrition intake score is used to assess the adequacy and quality of
          an individual's diet. Nutrition intake scores are typically based on
          the types and amounts of food and beverages consumed, and a Good score
          indicates a better quality diet that is more likely to meet an
          individual's nutrient needs and support overall health and well-being.
        </p>
      </div>
      <div className="w-full">
        <div className="flex items-center justify-between mb-3 mt-4">
          <h2 className="text-[16px] text-secondary font-semibold">
            Daily Assessment
          </h2>
          <Link
            to=""
            className="bg-[#EBF6FF] h-[25px] text-[12px] text-primary py-3 px-6 flex justify-center items-center rounded-full"
          >
            100 care pts
          </Link>
        </div>
        <p className="text-[12px] text-primary font-medium mb-2 asssment-text">
          Log your daily meals & assess your nutrional in-take
        </p>
        <div className="date-row flex items-center justify-center">
          <div className="date bg-[#EBF6FF] w-[41px] h-[41px] rounded-[5px] flex flex-col items-center justify-center hover:bg-secondary active:bg-secondary mx-1">
            <p className="text-[14px] text-[#4E4E4E] font-semibold leading-[12px] hover:text-white active:text-white">
              30
              <br />{" "}
              <span className="text-[10px] text-[#4E4E4E] font-medium hover:text-white active:text-white">
                JUL
              </span>
            </p>
          </div>
          <div className="date bg-[#EBF6FF] w-[41px] h-[41px] rounded-[5px] flex flex-col items-center justify-center hover:bg-secondary active:bg-secondary mx-1">
            <p className="text-[14px] text-[#4E4E4E] font-semibold leading-[12px] hover:text-white active:text-white">
              31
              <br />{" "}
              <span className="text-[10px] text-[#4E4E4E] font-medium hover:text-white active:text-white">
                JUL
              </span>
            </p>
          </div>
          <div className="date bg-[#EBF6FF] w-[41px] h-[41px] rounded-[5px] flex flex-col items-center justify-center hover:bg-secondary active:bg-secondary mx-1">
            <p className="text-[14px] text-[#4E4E4E] font-semibold leading-[12px] hover:text-white active:text-white">
              01
              <br />{" "}
              <span className="text-[10px] text-[#4E4E4E] font-medium hover:text-white active:text-white">
                AUG
              </span>
            </p>
          </div>
          <div className="date bg-[#EBF6FF] w-[41px] h-[41px] rounded-[5px] flex flex-col items-center justify-center hover:bg-secondary active:bg-secondary mx-1">
            <p className="text-[14px] text-[#4E4E4E] font-semibold leading-[12px] hover:text-white active:text-white">
              02
              <br />{" "}
              <span className="text-[10px] text-[#4E4E4E] font-medium hover:text-white active:text-white">
                AUG
              </span>
            </p>
          </div>
          <div className="date bg-[#EBF6FF] w-[41px] h-[41px] rounded-[5px] flex flex-col items-center justify-center hover:bg-secondary active:bg-secondary mx-1">
            <p className="text-[14px] text-[#4E4E4E] font-semibold leading-[12px] hover:text-white active:text-white">
              03
              <br />{" "}
              <span className="text-[10px] text-[#4E4E4E] font-medium hover:text-white active:text-white">
                AUG
              </span>
            </p>
          </div>
          <div className="date bg-secondary w-[41px] h-[41px] rounded-[5px] flex flex-col items-center justify-center hover:bg-secondary active:bg-secondary mx-1">
            <p className="text-[14px] text-[#fff] font-semibold leading-[12px] hover:text-white active:text-white">
              04
              <br />{" "}
              <span className="text-[10px] text-[#fff] font-medium hover:text-white active:text-white">
                AUG
              </span>
            </p>
          </div>
        </div>
        <div className="flex items-center justify-between">
          <div className="flex items-center justify-between">
            <div className="flex flex-col items-center justify-center mr-3">
              <img src={WaterGlass} alt="water glass" className="mb-1" />
              <p className="text-[10px] text-black font-medium text-center leading-3">
                Glass of water
                <br /> (250ml)
              </p>
            </div>
            <div className="input-group flex flex-col items-center justify-center">
              <div className="input-group-prepend">
                <button
                  className="btn btn-outline-primary w-[15px] h-[15px] bg-secondary rounded-full text-white flex items-center justify-center text-[10px] hover:bg-primary active:bg-primary"
                  type="button"
                  onClick={incNum}
                >
                  +
                </button>
              </div>
              <input
                type="text"
                className="form-control w-[32px] h-[24px] bg-[#D6E3E9] rounded-[5px] text-center text-[#000000] text-sm my-2"
                value={num}
                onChange={handleChange}
              />
              <div className="input-group-prepend">
                <button
                  className="btn btn-outline-primary w-[15px] h-[15px] bg-[#25b6d2] rounded-full text-white flex items-center justify-center text-[10px] hover:bg-primary active:bg-primary"
                  type="button"
                  onClick={decNum}
                >
                  -
                </button>
              </div>
            </div>
          </div>
          {/* graph */}
          <div className="flex items-center w-[45%] relative">
            <p className="text-[12px] text-black font-medium leading-[16px] absolute text-center w-[85%]">
              Calories <br />
              <span className="font-semibold">515</span>
            </p>
            <PieChart width={130} height={130}>
              <Pie
                data={data01}
                dataKey="value"
                nameKey="name"
                outerRadius="80%"
                innerRadius="60%"
              >
                <Cell fill="#EF587B" stroke="none" />
                <Cell fill="#283B82" stroke="none" />
                <Cell fill="#FAAD02" stroke="none" />
              </Pie>
            </PieChart>
          </div>
        </div>
        {/* select meal */}
        <div className="select-meal">
          <h3 className="text-sm text-secondary mb-3 mt-3">Select Meal Type</h3>
          <div className="meal-type flex flex-wrap">
            <button className="h-[25px] bg-[#EBF6FF] rounded-full text-[11px] text-primary flex items-center justify-center text-center mr-2 mb-2 hover:bg-primary active:bg-primary hover:text-white active:text-white ">
              Breakfast
            </button>
            <button className="h-[25px] bg-[#EBF6FF] rounded-full text-[11px] text-primary flex justify-center items-center mr-2 text-center mb-2 hover:bg-primary active:bg-primary hover:text-white active:text-white">
              Lunch
            </button>
            <button className="h-[25px] bg-[#EBF6FF] rounded-full text-[11px] text-primary flex justify-center items-center mr-2 text-center mb-2 hover:bg-primary active:bg-primary hover:text-white active:text-white">
              Snack
            </button>
            <button className="h-[25px] bg-[#EBF6FF] rounded-full text-[11px] text-primary flex justify-center items-center mr-2 text-center mb-2 hover:bg-primary active:bg-primary hover:text-white active:text-white">
              Dinner
            </button>
            <button className="h-[25px] bg-[#EBF6FF] rounded-full text-[11px] text-primary flex justify-center items-center mr-2 text-center mb-2 hover:bg-primary active:bg-primary hover:text-white active:text-white">
              Other
            </button>
          </div>
        </div>
        {/* select meal end */}

        <div className="search-meal">
          <h3 className="text-sm text-secondary mb-3 mt-6">Search a meal</h3>

          {/* auto complete list */}
          <Combobox value={selectedDepartment} by="id" onChange={onChange}>
            <div className="relative">
              <Combobox.Input
                className={`block w-full mt-2 px-4 py-2 rounded-lg border outline-none focus:border-secondary text-primary text-xs`}
                onChange={(event) => setQuery(event.target.value)}
                displayValue={(department) => department.name}
              />
              <span>
                <img
                  src={SearchIcon}
                  alt="search icon"
                  className=" absolute right-3 top-2"
                />
              </span>
            </div>
            <Combobox.Options className="border-[1px] border-[#C5E4FF] rounded-[5px] px-4 py-4">
              <ul className="flex flex-col">
                {filteredDepartments.map((department) => (
                  <Combobox.Option
                    key={department.id}
                    value={department}
                    className="flex py-3 px-3 bg-[#EBF6FF] border-[1px] border-[#BFE1FF] rounded-[5px] mb-2"
                  >
                    <div className="mr-3">
                      <img src={PlusIcon} alt="plus icon" />
                    </div>
                    <div className="w-[90%]">
                      <div className="flex justify-between items-start">
                        <div className="flex items-center">
                          <h3 className="text-[10px] text-primary mr-8">
                            {department.name}
                          </h3>
                          <h5 className="text-[10px] text-secondary">
                            {department.weight} gms
                          </h5>
                        </div>
                        <h4 className="text-[11px] text-primary font-semibold">
                          {department.cal} Cal
                        </h4>
                      </div>
                      <div className="mt-2">
                        <ul className="flex items-center">
                          <li className="flex items-center mr-4">
                            <span className="flex align-middle w-[6px] h-[6px] bg-[#283B82] rounded-full mt-[-4px] mr-1"></span>
                            <p className="text-[8px] text-primary">
                              Fat : {department.fat}
                            </p>
                          </li>
                          <li className="flex items-center mr-4">
                            <span className="flex align-middle w-[6px] h-[6px] bg-[#EF587B] rounded-full mt-[-4px] mr-1"></span>
                            <p className="text-[8px] text-primary">
                              Carb : {department.carb}
                            </p>
                          </li>
                          <li className="flex items-center mr-4">
                            <span className="flex align-middle w-[6px] h-[6px] bg-[#FAAD02] rounded-full mt-[-4px] mr-1"></span>
                            <p className="text-[8px] text-primary">
                              Protein : {department.protein}
                            </p>
                          </li>
                        </ul>
                      </div>
                    </div>
                  </Combobox.Option>
                ))}
              </ul>
            </Combobox.Options>
          </Combobox>

          {/* auto complete list end */}
          <div className="add-meal mt-6">
            <ul className="flex flex-col">
              <li className="flex py-3 px-3 bg-[#EBF6FF] border-[1px] border-[#BFE1FF] rounded-[5px] mb-2">
                <div className="mr-3">
                  <img src={MinusIcon} alt="plus icon" />
                </div>
                <div className="w-[90%]">
                  <div className="flex justify-between items-start">
                    <div className="flex items-center">
                      <h3 className="text-[10px] text-primary mr-8">
                        Caesar Salad
                      </h3>
                      <h5 className="text-[10px] text-secondary">213 gms</h5>
                    </div>
                    <h4 className="text-[11px] text-primary font-semibold">
                      94 Cal
                    </h4>
                  </div>
                  <div className="mt-2">
                    <ul className="flex items-center">
                      <li className="flex items-center mr-4">
                        <span className="flex align-middle w-[6px] h-[6px] bg-[#283B82] rounded-full mt-[-4px] mr-1"></span>
                        <p className="text-[8px] text-primary">Fat : 4.4 g</p>
                      </li>
                      <li className="flex items-center mr-4">
                        <span className="flex align-middle w-[6px] h-[6px] bg-[#EF587B] rounded-full mt-[-4px] mr-1"></span>
                        <p className="text-[8px] text-primary">Carb : 7.4 g</p>
                      </li>
                      <li className="flex items-center mr-4">
                        <span className="flex align-middle w-[6px] h-[6px] bg-[#FAAD02] rounded-full mt-[-4px] mr-1"></span>
                        <p className="text-[8px] text-primary">
                          Protein : 5.1 g
                        </p>
                      </li>
                    </ul>
                  </div>
                </div>
              </li>
              <li className="flex py-3 px-3 bg-[#EBF6FF] border-[1px] border-[#BFE1FF] rounded-[5px] mb-2">
                <div className="mr-3">
                  <img src={MinusIcon} alt="plus icon" />
                </div>
                <div className="w-[90%]">
                  <div className="flex justify-between items-start">
                    <div className="flex items-center">
                      <h3 className="text-[10px] text-primary mr-8">
                        Greek Salad
                      </h3>
                      <h5 className="text-[10px] text-secondary">159 gms</h5>
                    </div>
                    <h4 className="text-[11px] text-primary font-semibold">
                      179 Cal
                    </h4>
                  </div>
                  <div className="mt-2">
                    <ul className="flex items-center">
                      <li className="flex items-center mr-4">
                        <span className="flex align-middle w-[6px] h-[6px] bg-[#283B82] rounded-full mt-[-4px] mr-1"></span>
                        <p className="text-[8px] text-primary">Fat : 15 g</p>
                      </li>
                      <li className="flex items-center mr-4">
                        <span className="flex align-middle w-[6px] h-[6px] bg-[#EF587B] rounded-full mt-[-4px] mr-1"></span>
                        <p className="text-[8px] text-primary">Carb : 7.4 g</p>
                      </li>
                      <li className="flex items-center mr-4">
                        <span className="flex align-middle w-[6px] h-[6px] bg-[#FAAD02] rounded-full mt-[-4px] mr-1"></span>
                        <p className="text-[8px] text-primary">
                          Protein : 5.1 g
                        </p>
                      </li>
                    </ul>
                  </div>
                </div>
              </li>
            </ul>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Nutrition;
