import { Link } from "react-router-dom";
import { ReactComponent as BackArrow } from "../assets/icons/BackArrow.svg";
import MigrationPain from "../assets/images/pain/Stress.png";
import BackPain from "../assets/images/pain/back-pain.png";
import PeriodCamp from "../assets/images/pain/Polycystic-ovary-syndrome.png";
import Headache from "../assets/images/pain/Headache.png";
import KneePain from "../assets/images/pain/Knee-Pain.png";
import ShoulderPain from "../assets/images/pain/Shoulder-Pain.png";

const RelivePain = () => (
  <div className="px-6 pt-2 pb-24">
    <div className="flex items-center">
      <Link className="shrink-0 p-1 pr-2.5" to={-1}>
        <BackArrow className="h-2.5 w-2.5" />
      </Link>
      <h3 className="text-sm font-medium">Relieve Pain</h3>
    </div>
    {[
      {
        listID: 21,
        title: "Easing Migraine Pain",
        description: "Headache with a throbbing pain on 1 side",
        thumbnail: MigrationPain,
      },
      {
        listID: 22,
        title: "Releasing Back Pain",
        description: "Reduce your back pain with guidance",
        thumbnail: BackPain,
      },
      {
        listID: 23,
        title: "Lower Period Cramps",
        description: "Throbbing or cramping sort of pain in the lower abdomen",
        thumbnail: PeriodCamp,
      },
      {
        listID: 24,
        title: "Diminish Headache",
        description: "Get rid of your headache pain with simple steps",
        thumbnail: Headache,
      },
      {
        listID: 25,
        title: "Reducing Knee Pain",
        description: "Self-treating your knee pain with simple steps",
        thumbnail: KneePain,
      },
      {
        listID: 26,
        title: "Lessen Shoulder Pain",
        description: "Induce your shoulder pain with simple steps",
        thumbnail: ShoulderPain,
      },
    ].map(({ listID, ...item }) => (
      <Link
        to="/content-tabs"
        state={{ name: item.title, listID }}
        className="mt-6 p-4 flex items-center justify-between space-x-4 rounded-lg bg-gradient-transparent"
      >
        <div>
          <h3 className="text-base font-semibold">{item.title}</h3>
          <p className="text-xs">{item.description}</p>
        </div>
        <img
          className="shrink-0 h-16 w-16 rounded"
          src={item.thumbnail}
          alt={item.title}
        />
      </Link>
    ))}
  </div>
);

export default RelivePain;
