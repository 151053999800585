import { Link } from "react-router-dom";
const LiveChat = () => (
  <div className="px-6 flex flex-col mb-20">
    <div
      className="flex items-center mb-5"
      data-aos="fade-right"
      data-aos-duration="1000"
      data-aos-delay="50"
    >
      <Link to="/" className="mr-3">
        <svg
          width="7"
          height="12"
          viewBox="0 0 10 17"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            d="M9.42402 16.2142C9.99277 15.6454 9.99277 14.7267 9.42402 14.1579L3.76569 8.49959L9.42402 2.84126C9.99277 2.27251 9.99277 1.35376 9.42402 0.785009C8.85527 0.216259 7.93652 0.216259 7.36777 0.785009L0.674024 7.47876C0.105274 8.04751 0.105274 8.96626 0.674024 9.53501L7.36777 16.2288C7.92194 16.7829 8.85527 16.7829 9.42402 16.2142Z"
            fill="#3298F1"
          />
        </svg>
      </Link>
      <h3 className="text-secondary text-[16px] font-semibold">
        Care Assistant
      </h3>
    </div>
  </div>
);

export default LiveChat;
