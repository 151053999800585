import { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { useAuth } from "../utils/auth";
import { sdkWrapperURL } from "../utils/api-url-list";
import LoadingScreen from "../components/LoadingScreen";

const NutritionPackage = () => {
  const {
    getToken,
    userData: { profile_id },
  } = useAuth();
  const [loading, setLoading] = useState(true);
  const [packagesList, setpackagesList] = useState();
  useEffect(() => {
    (async () => {
      setLoading(true);
      try {
        const token = await getToken();
        const resp = await fetch(sdkWrapperURL("/users/list/products"), {
          method: "POST",
          headers: { "Content-Type": "application/json", Authorization: token },
          body: JSON.stringify({
            module_type: "content",
            profile_id,
            product_type_id: 33,
            table_config_rows_per_page: 100,
            table_config_page_no: 1,
          }),
        });
        const respJSON = await resp.json();
        if (respJSON?.statusCode?.toString().startsWith("2")) {
          setpackagesList(
            respJSON?.response?.sort(
              (a, b) =>
                (a.structure_val?.rank?.split?.("-")?.[1] ?? 0) -
                (b.structure_val?.rank?.split?.("-")?.[1] ?? 0)
            )
          );
        } else throw new Error(respJSON?.message ?? "Error in Fetching List");
      } catch (err) {
        console.error(err);
      } finally {
        setLoading(false);
      }
    })();
  }, [getToken, profile_id]);
  return (
    <div className="w-full pb-28">
      <div className="px-6">
        <div className="flex mb-5">
          <Link to={-1} className="mr-3">
            <svg
              className="mt-[5px]"
              width="6"
              height="10"
              viewBox="0 0 10 17"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M9.42402 16.2142C9.99277 15.6454 9.99277 14.7267 9.42402 14.1579L3.76569 8.49959L9.42402 2.84126C9.99277 2.27251 9.99277 1.35376 9.42402 0.785009C8.85527 0.216259 7.93652 0.216259 7.36777 0.785009L0.674024 7.47876C0.105274 8.04751 0.105274 8.96626 0.674024 9.53501L7.36777 16.2288C7.92194 16.7829 8.85527 16.7829 9.42402 16.2142Z"
                fill="#fff"
              />
            </svg>
          </Link>
          <h3 className="text-white text-sm font-medium">
            Choose your
            <br /> <span className="text-[#812EFD]">Nutritional</span> Approach
          </h3>
        </div>
      </div>
      {loading ? (
        <LoadingScreen style={{ minHeight: "60vh" }} />
      ) : (
        <div className="px-6">
          <ul className="nutrition-card">
            {packagesList?.map((el, index) => (
              <li
                className="rounded-[12px] relative mb-4 overflow-hidden bg-gradient-to-t from-[#5517A7]/30 to-[#331879]/50"
                key={index}
              >
                <Link
                  to="/nutrition-package-list"
                  state={{
                    name: el.product_name,
                    data: el.structure_val,
                  }}
                  className="flex items-center justify-between h-[100px]"
                >
                  <p className="pl-5 text-[14px] font-semibold text-white">
                    {el?.product_name}
                  </p>
                  <img
                    src={el?.structure_val?.thumbnail}
                    className="w-1/2"
                    alt={el?.product_name}
                  />
                </Link>
              </li>
            ))}
          </ul>
        </div>
      )}
    </div>
  );
};

export default NutritionPackage;
