import { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import Carousel from "react-elastic-carousel";
import { useAuth } from "../utils/auth";
import { sdkWrapperURL } from "../utils/api-url-list";
import LoadingScreen from "../components/LoadingScreen";

const Card = ({ img, title = "", subtitle = "" }) => {
  return (
    <div className="wellness-card">
      <img src={img} alt="Mental Wellbeing" className=" rounded-[10px]" />
      <div className="bg-gradient-to-t from-[#02102B]/80 to-[#5413A2]/90 flex flex-col justify-center w-full absolute left-0 bottom-0 rounded-[10px] h-[50px] p-2">
        <h4 className="font-medium text-[9px] text-white">{title}</h4>
        <p className="text-[7px] text-white">{subtitle}</p>
      </div>
    </div>
  );
};

const Wellbeing = () => {
  const {
    getToken,
    userData: { profile_id },
  } = useAuth();
  const [loading, setLoading] = useState(true);
  const [musicCards, setMusicCards] = useState([]);
  const [banners, setBanners] = useState([]);
  useEffect(() => {
    (async () => {
      setLoading(true);
      try {
        const token = await getToken();
        const resp = await fetch(sdkWrapperURL("/users/list/products"), {
          method: "POST",
          headers: { "Content-Type": "application/json", Authorization: token },
          body: JSON.stringify({
            module_type: "content",
            profile_id,
            product_type_id: 28,
            table_config_rows_per_page: 100,
            table_config_page_no: 1,
          }),
        });
        const respJSON = await resp.json();
        if (respJSON?.statusCode?.toString().startsWith("2")) {
          setBanners(
            respJSON?.response?.map?.((p) => ({
              product_id: p.product_id ?? "",
              ...(p.structure_val ?? {}),
            })) ?? []
          );
        } else
          throw new Error(respJSON?.message ?? "Error in Fetching List Data");
        const respp = await fetch(sdkWrapperURL("/users/list/products"), {
          method: "POST",
          headers: { "Content-Type": "application/json", Authorization: token },
          body: JSON.stringify({
            module_type: "content",
            profile_id,
            product_type_id: [29, 30, 31, 32],
            table_config_rows_per_page: 100,
            table_config_page_no: 1,
          }),
        });
        const respJSONN = await respp.json();
        if (respJSONN?.statusCode?.toString().startsWith("2")) {
          const products =
            respJSONN?.response?.map?.((p) => ({
              product_id: p.product_id ?? "",
              ...(p.structure_val ?? {}),
            })) ?? [];
          setMusicCards([
            {
              href: "/yoga-meditation",
              ...products.find((p) => p.title === "Yoga & Meditation"),
            },
            {
              href: "/visualization",
              ...products.find((p) => p.title === "Visualization Tranquility"),
            },
            {
              href: "/relaxation-music",
              ...products.find((p) => p.title === "Relaxation Music"),
            },
            {
              href: "/mindful-moments",
              ...products.find((p) => p.title === "Mindful Moments"),
            },
          ]);
        } else
          throw new Error(respJSON?.message ?? "Error in Fetching List Data");
      } catch (err) {
        console.error(err);
      } finally {
        setLoading(false);
      }
    })();
  }, [getToken, profile_id]);

  const breakPoints = [
    { width: 1, itemsToShow: 1.7 },
    { width: 550, itemsToShow: 1.7, itemsToScroll: 1, pagination: false },
    { width: 850, itemsToShow: 1.7 },
    { width: 1150, itemsToShow: 4, itemsToScroll: 1 },
    { width: 1450, itemsToShow: 5 },
    { width: 1750, itemsToShow: 6 },
  ];

  return (
    <div className="pb-28 w-full">
      <div className="px-6">
        <div className="flex items-center mb-5">
          <Link to={-1} className="mr-3">
            <svg
              width="6"
              height="10"
              viewBox="0 0 10 17"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M9.42402 16.2142C9.99277 15.6454 9.99277 14.7267 9.42402 14.1579L3.76569 8.49959L9.42402 2.84126C9.99277 2.27251 9.99277 1.35376 9.42402 0.785009C8.85527 0.216259 7.93652 0.216259 7.36777 0.785009L0.674024 7.47876C0.105274 8.04751 0.105274 8.96626 0.674024 9.53501L7.36777 16.2288C7.92194 16.7829 8.85527 16.7829 9.42402 16.2142Z"
                fill="#fff"
              />
            </svg>
          </Link>
          <h3 className="text-white text-[14px] font-semibold">
            Mental Wellbeing
          </h3>
        </div>
      </div>
      {loading ? (
        <LoadingScreen style={{ minHeight: "60vh" }} />
      ) : (
        <>
          <div className="w-full">
            <Carousel
              breakPoints={breakPoints}
              showArrows={false}
              pagination={false}
            >
              {banners?.map((el, index) => (
                <Link
                  key={`top-card-${index}`}
                  className="mr-2"
                  to="/content-details"
                  state={{
                    name: el.title,
                    data: el.content?.sort?.(
                      (a, b) => (a.content_rank ?? 0) - (b.content_rank ?? 0)
                    ),
                  }}
                >
                  <Card
                    img={el.thumbnail}
                    title={el.title}
                    subtitle={el.description}
                  />
                </Link>
              ))}
            </Carousel>
          </div>
          <div className="px-6 mt-4">
            <div className="flex flex-wrap justify-between">
              {musicCards?.map((el, index) => (
                <Link
                  key={`bottom-card-${index}`}
                  to={el.href}
                  className="w-[48%]"
                  state={{
                    name: el.title,
                    data: el.contents?.sort?.(
                      (a, b) => (a.content_rank ?? 0) - (b.content_rank ?? 0)
                    ),
                  }}
                >
                  <div
                    className="rounded-[20px] mb-3 flex flex-col justify-between items-center px-4 py-4 bg-gradient-to-t from-transparent to-[#5517A7]/50 h-[170px]"
                    // style={{ backgroundColor: `${el.color}` }}
                  >
                    {" "}
                    <img
                      src={el.thumbnail}
                      alt={el.title}
                      style={{ width: 100 }}
                    />
                    <h4 className=" text-[13px] text-[#9147FF] font-medium text-center">
                      {el.title}
                    </h4>
                  </div>
                </Link>
              ))}
            </div>
          </div>
        </>
      )}
    </div>
  );
};

export default Wellbeing;
