import { useCallback, useEffect, useMemo, useRef, useState } from "react";
import { useNavigate } from "react-router-dom";

const SimonTestCircle = () => {
  const navigate = useNavigate();

  const circle1 = useRef();
  const circle2 = useRef();
  const circle3 = useRef();
  const circle4 = useRef();
  const circle5 = useRef();
  const circles = useMemo(
    () => [circle1, circle2, circle3, circle4, circle5],
    []
  );

  const [gameInactive, setGameInactive] = useState(true);
  const attempts = useRef(0);
  const correctAnswers = useRef(0);
  const totalResponseTime = useRef(0);
  const [gameState, setGameState] = useState({
    color: "red",
    timestamp: performance.now(),
  });

  const clearCircles = useCallback(
    () => circles.forEach((c) => (c.current.style = undefined)),
    [circles]
  );
  const paintRandomCircle = useCallback(() => {
    const randomIndex = Math.floor(Math.random() * 5);
    const randomColor = Math.random() > 0.5 ? "red" : "green";
    circles[randomIndex].current.style.backgroundColor = randomColor;
    setGameState({ color: randomColor, timestamp: performance.now() });
  }, [circles]);

  const process = (selectedColor = "red") => {
    totalResponseTime.current += performance.now() - gameState.timestamp;
    if (selectedColor === gameState.color) correctAnswers.current++;
    attempts.current++;
    clearCircles();
    if (attempts.current < 20) paintRandomCircle();
    else {
      setGameInactive(true);
      navigate("/simon-score", {
        replace: true,
        state: {
          accuracy: (correctAnswers.current / attempts.current) * 100,
          averageResponseTime: `${Math.round(
            totalResponseTime.current / attempts.current
          )}ms`,
        },
      });
    }
  };

  useEffect(() => {
    setGameInactive(true);
    clearCircles();
    const timer = setTimeout(() => {
      paintRandomCircle();
      setGameInactive(false);
    }, 3000);
    return () => {
      clearTimeout(timer);
    };
  }, [clearCircles, paintRandomCircle]);

  return (
    <div className="px-6 pt-3 pb-24">
      <h4 className="text-xl font-semibold text-white mb-5 text-center">
        Simon Test
      </h4>
      <div className="grid grid-cols-2 gap-4 my-24">
        <div
          ref={circle1}
          className="mx-auto h-16 w-16 rounded-full bg-white"
        />
        <div
          ref={circle2}
          className="mx-auto h-16 w-16 rounded-full bg-white"
        />
        <div
          ref={circle3}
          className="col-span-2 mx-auto h-16 w-16 rounded-full bg-white"
        />
        <div
          ref={circle4}
          className="mx-auto h-16 w-16 rounded-full bg-white"
        />
        <div
          ref={circle5}
          className="mx-auto h-16 w-16 rounded-full bg-white"
        />
      </div>
      {gameInactive ? (
        <p className="text-xl text-center">Starting...</p>
      ) : (
        <div className="flex items-center justify-between">
          <button
            type="button"
            onClick={() => {
              process("red");
            }}
            disabled={gameInactive}
            className="flex w-[48%] h-12 rounded-full justify-center items-center px-6 text-base font-medium text-white bg-gradient-to-l from-[#5916AC] to-[#264AE9]"
          >
            Red
          </button>
          <button
            type="button"
            onClick={() => {
              process("green");
            }}
            disabled={gameInactive}
            className="flex w-[48%] h-12 rounded-full justify-center items-center px-6 text-base font-medium text-white bg-gradient-to-l from-[#5916AC] to-[#264AE9]"
          >
            Green
          </button>
        </div>
      )}
    </div>
  );
};

export default SimonTestCircle;
