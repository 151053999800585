import { useLocation, Link } from "react-router-dom";
import { Player } from "video-react";
import ReactMarkdown from "react-markdown";
import remarkBreaks from "remark-breaks";

const WellbeingContentDetails = () => {
  const {
    state: { name, desc, poster, video },
  } = useLocation();

  return (
    <div className="pb-28 w-full">
      <div className="px-6">
        <div className="flex items-center mb-5">
          <Link to={-1} className="mr-3">
            <svg
              width="6"
              height="10"
              viewBox="0 0 10 17"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M9.42402 16.2142C9.99277 15.6454 9.99277 14.7267 9.42402 14.1579L3.76569 8.49959L9.42402 2.84126C9.99277 2.27251 9.99277 1.35376 9.42402 0.785009C8.85527 0.216259 7.93652 0.216259 7.36777 0.785009L0.674024 7.47876C0.105274 8.04751 0.105274 8.96626 0.674024 9.53501L7.36777 16.2288C7.92194 16.7829 8.85527 16.7829 9.42402 16.2142Z"
                fill="#fff"
              />
            </svg>
          </Link>
          <h3 className="text-white text-[14px] font-semibold">{name}</h3>
        </div>
      </div>
      <div className="px-6">
        <div className="mb-6 w-full rounded-lg overflow-hidden">
          <Player poster={poster}>
            <source
              className="rounded-lg"
              src={video}
              controls
              controlsList="nodownload noplaybackrate"
              playsInline
              disablePictureInPicture
              disableRemotePlayback
            />
          </Player>
        </div>
        <ReactMarkdown
          className="prose prose-sm prose-neutral text-white/80 text-xs"
          children={desc}
          remarkPlugins={[remarkBreaks]}
        />
      </div>
    </div>
  );
};

export default WellbeingContentDetails;
