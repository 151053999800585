import React from 'react';
import SimonVideo from "../assets/images/simon-test-video-poster.png";
import { Link } from 'react-router-dom';
import { Player } from 'video-react';
import {
    Button,
    Dialog,
    DialogHeader,
    DialogBody,

  } from "@material-tailwind/react";

const SimonTest = () => {
    const [size, setSize] = React.useState(null);
 
    const handleOpen = (value) => setSize(value);
        return (
            <div className="px-6 pt-3 pb-24">
                <h4 className="text-xl font-semibold text-white mb-5 text-center">Simon Test</h4>
                <p className='text-xs text-white text-justify mb-6'>The cognitive Simon test is a neuropsychological assessment tool used to evaluate cognitive functions such as attention, response inhibition, and executive control. It typically involves presenting participants with stimuli and requiring them to respond based on specific rules, while manipulating factors like spatial location or stimulus type to create conflict. This test measures the participant's ability to inhibit automatic responses and maintain attention in the face of conflicting information, providing insights into cognitive processes related to multitasking and cognitive control.</p>
                <div className='flex flex-col justify-center items-center'>
                    <img src={SimonVideo} alt='' onClick={() => handleOpen("xxl")} />
                    <p className='text-base text-white font-medium mt-4'>How it works ?</p>
                    <p className='text-xs text-white text-justify mt-4 mb-6'>The Simon Test primarily analyzes cognitive functions related to attention and response inhibition, which fall under the domains of <span className='text-[#4867D6] font-bold'>Executive Function</span> and <span className='text-[#4867D6] font-bold'>Attention</span>.</p>
                    <Link to="/simon-circle" className="flex w-full h-12 rounded-full justify-center items-center px-6 text-base font-medium text-white bg-gradient-to-l from-[#5916AC] to-[#264AE9]">Start Test</Link>
                </div>
                <Dialog
        open={
          size === "xs" ||
          size === "sm" ||
          size === "md" ||
          size === "lg" ||
          size === "xl" ||
          size === "xxl"
        }
        size={size || "md"}
        handler={handleOpen}
      >
        <DialogHeader>Simon Test</DialogHeader>
        <DialogBody>
        <Player>
      <source src="https://media.w3.org/2010/05/sintel/trailer_hd.mp4" />
    </Player>
        </DialogBody>
        <div className='flex items-center justify-center'><Button
            variant="text"
            color="red"
            onClick={() => handleOpen(null)}
            className=" outline-none"
          >
            <span>Close</span>
          </Button></div>
      </Dialog>
            </div>
        );
    }


export default SimonTest;