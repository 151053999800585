import { useState } from "react";
import { useLocation } from "react-router-dom";
import ProgressCircle from "../components/ProgressCircle";
import { constants } from "../utils/ranges";

const SimonScore = () => {
  const { state } = useLocation();
  const score = state?.accuracy;
  const selectedScoreRange = constants["Wellbeing Score"].ranges.reduce(
    (p, c) => ((score ?? 0) > c.point ? c : p)
  );
  const [attention, setAttention] = useState({
    you: Math.round(Math.random() * 25 + 70),
    everyone: Math.round(Math.random() * 20 + 70),
  });
  const [executiveFunction, setExecutiveFunction] = useState({
    you: Math.round(Math.random() * 25 + 70),
    everyone: Math.round(Math.random() * 20 + 70),
  });

  return (
    <div className="px-6 pt-3 pb-24">
      <h4 className="text-xl font-semibold text-white mb-5 text-center">
        Your Score
      </h4>
      <div className="flex flex-col items-center justify-center mt-3">
        <ProgressCircle
          size={96}
          value={score}
          subtitle="Accuracy"
          color={selectedScoreRange.color}
        />
        <p
          className="mt-3 text-base"
          style={{ color: selectedScoreRange.color }}
        >
          {selectedScoreRange.label}
        </p>
        <p className="text-sm text-white font-bold">
          Average Response Time: {state?.averageResponseTime}
        </p>
      </div>
      <div className="flex items-end justify-between mb-3 mt-7">
        <div>
          <h2 className="text-2xl font-semibold text-white">{attention.you}</h2>
          <p className="text-lg text-[#722BC3]">Attention</p>
        </div>
        <div className="text-right">
          <p className="text-sm text-[#731DDD]">
            Population Percentile {attention.everyone}%
          </p>
          <p className="text-sm font-bold text-white">(Above Average)</p>
        </div>
      </div>
      <p className="text-xs text-white text-justify">
        Scoring {attention.you}% on the attention component of the Simon test
        indicates that you demonstrated strong attentional abilities during the
        task. You were likely able to maintain focus, sustain attention, and
        accurately respond to the stimuli presented, resulting in a high score
        relative to the normative sample.
      </p>
      <div className="flex items-end justify-between mb-3 mt-7">
        <div>
          <h2 className="text-2xl font-semibold text-white">
            {executiveFunction.you}
          </h2>
          <p className="text-lg text-[#722BC3]">
            Executive
            <br />
            Function
          </p>
        </div>
        <div className="text-right">
          <p className="text-sm text-[#731DDD]">
            Population Percentile {executiveFunction.everyone}%
          </p>
          <p className="text-sm font-bold text-white">(Below Average)</p>
        </div>
      </div>
      <p className="text-xs text-white text-justify">
        Scoring {executiveFunction.you}% on the executive function component
        suggests that you performed well in tasks requiring cognitive control,
        response inhibition, and problem-solving. Your ability to adapt to
        changing task demands and exhibit effective executive control
        contributed to the relatively high score in this domain.
      </p>
    </div>
  );
};

export default SimonScore;
