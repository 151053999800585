import { Link, useLocation } from "react-router-dom";
import { Tab } from "@headlessui/react";
import Slider from "react-slick";

const BedtimeStretching = () => {
  const {
    state: { name, data },
  } = useLocation();
  return (
    <div className="mb-28 w-full">
      <div className="px-6">
        <div className="flex items-center mb-5">
          <Link to={-1} className="mr-3">
            <svg
              width="6"
              height="10"
              viewBox="0 0 10 17"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M9.42402 16.2142C9.99277 15.6454 9.99277 14.7267 9.42402 14.1579L3.76569 8.49959L9.42402 2.84126C9.99277 2.27251 9.99277 1.35376 9.42402 0.785009C8.85527 0.216259 7.93652 0.216259 7.36777 0.785009L0.674024 7.47876C0.105274 8.04751 0.105274 8.96626 0.674024 9.53501L7.36777 16.2288C7.92194 16.7829 8.85527 16.7829 9.42402 16.2142Z"
                fill="#fff"
              />
            </svg>
          </Link>
          <h3 className="text-white text-[14px] font-semibold">{name}</h3>
        </div>
      </div>
      <Tab.Group>
        <Tab.List className="mb-6 px-6 flex space-x-2 overflow-x-scroll tab-link">
          {data.map((item) => (
            <Tab
              key={`tab-${item.content_rank}`}
              className="shrink-0 outline-none"
            >
              {({ selected }) => (
                <span
                  className={`h-[50px] w-[120px] p-2 rounded-xl flex items-center justify-center bg-gradient-to-b ${
                    selected ? "from-[#44148B]" : "from-[#2055F3]"
                  } to-transparent text-white text-center text-xs font-medium`}
                  // className="h-[50px] w-[120px] p-2 rounded-xl flex items-center justify-center text-white text-center text-xs font-semibold"
                  // style={{
                  //   backgroundColor: selected
                  //     ? item.content_color
                  //     : `${item.content_color}66`,
                  // }}
                >
                  {item.tab_heading}
                </span>
              )}
            </Tab>
          ))}
        </Tab.List>
        <Tab.Panels>
          {data.map((item) => {
            const {
              About: [title, desc],
              ...restItems
            } = item.tab_content_description;
            return (
              <Tab.Panel
                key={`tab-panel-${item.content_rank}`}
                data-aos="fade-up"
                data-aos-duration="1000"
                data-aos-delay="10"
              >
                <Slider
                  {...{
                    dots: true,
                    lazyLoad: true,
                    infinite: true,
                    speed: 300,
                    slidesToShow: 1,
                    slidesToScroll: 1,
                    initialSlide: 0,
                    autoplay: true,
                    Arrow: false,
                  }}
                >
                  {item.tab_content_poster?.map?.((imgSrc, index) => (
                    <img
                      key={`logo-${item.content_rank}-${index}`}
                      className="w-full"
                      src={imgSrc}
                      alt={`logo-${item.content_rank}-${index}`}
                    />
                  ))}
                </Slider>
                <div className="relative z-30 -mt-[70px] px-6 pt-8 rounded-t-3xl bg-gradient-to-t from-[#02102B] to-[#031434]">
                  <h4 className="text-[#812EFD] text-sm font-semibold">
                    {title}
                  </h4>
                  <p className="mt-2 text-xs text-white/80">{desc}</p>
                  {Object.entries(restItems).map(
                    ([heading, listData], index) => (
                      <div
                        className="mt-5 pb-10"
                        key={`paragraph-${item.content_rank}-${index}`}
                      >
                        <h5 className="text-[#812EFD] text-sm font-semibold">
                          {heading}
                        </h5>
                        <ul className="mt-3 ml-4 space-y-2 list-disc list-outside breathing-list">
                          {listData.map((item, index) => (
                            <li
                              key={`list-item-${index}`}
                              className="text-white/80 text-xs"
                            >
                              {item}
                            </li>
                          ))}
                        </ul>
                      </div>
                    )
                  )}
                </div>
              </Tab.Panel>
            );
          })}
        </Tab.Panels>
      </Tab.Group>
    </div>
  );
};

export default BedtimeStretching;
