import AbhaImg from "../assets/images/abha-img2.png";
import ListIcon from "../assets/icons/hand-point-right.svg";
import AbhaIcon1 from "../assets/icons/abha-icon1.svg";
import AbhaIcon2 from "../assets/icons/abha-icon2.svg";
import AbhaIcon3 from "../assets/icons/abha-icon3.svg";
import AbhaIcon4 from "../assets/icons/abha-icon4.svg";
import AbhaIcon5 from "../assets/icons/abha-icon5.svg";
import AbhaIcon6 from "../assets/icons/accessibility.svg";
import { Link } from "react-router-dom";

const Abha = () => (
  <div
    className="w-full mb-28"
    data-aos="fade-up"
    data-aos-delay="100"
    data-aos-duration="1000"
  >
    <div className="flex items-center mb-10 ml-6">
      <Link to="/" className="mr-3">
        <svg
          width="6"
          height="10"
          viewBox="0 0 10 17"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            d="M9.42402 16.2142C9.99277 15.6454 9.99277 14.7267 9.42402 14.1579L3.76569 8.49959L9.42402 2.84126C9.99277 2.27251 9.99277 1.35376 9.42402 0.785009C8.85527 0.216259 7.93652 0.216259 7.36777 0.785009L0.674024 7.47876C0.105274 8.04751 0.105274 8.96626 0.674024 9.53501L7.36777 16.2288C7.92194 16.7829 8.85527 16.7829 9.42402 16.2142Z"
            fill="#192852"
          />
        </svg>
      </Link>
      <h3 className="text-primary text-[14px] font-semibold">ABHA Details</h3>
    </div>
    <div className="flex justify-end">
      <img src={AbhaImg} alt="Abha" style={{ width: "85%" }} />
    </div>
    <div className="px-6">
      <h3 className="text-primary text-[14px] font-medium mb-2">
        What is ABHA Card or Health ID Card?
      </h3>
      <p className="text-[#192852]/[0.7] text-[11px] leading-4 mb-4">
        ABHA is a 14 digit unique number used to identify people, authenticate
        them, and thread their health records (only with their consent) across
        multiple healthcare service providers. Creating an ABHA ID is a must for
        users who want to save their health records stored digitally in the PHR
        app. ABHA(Health ID) is a combination of the ABHA number, PHR address,
        PHR app / health locker.
      </p>
      <h3 className="text-primary text-[14px] font-medium mb-2">
        It is important to note that:
      </h3>
      <ul>
        <li className="flex justify-start items-start mb-3">
          <img src={ListIcon} alt="" />
          <p className="text-[#192852]/[0.7] text-[11px] leading-4 ml-2">
            ABHA number Is a unique 14 digit number to identify a person and
            update their health records across multiple health service
            providers. PHR address or ABHA address is created along with the
            ABHA number during ABHA registration.
          </p>
        </li>
        <li className="flex justify-start items-start">
          <img src={ListIcon} alt="" />
          <p className="text-[#192852]/[0.7] text-[11px] leading-4 ml-2">
            ABHA address is a self-declared username just like the email address
            & is used to sign into the Health Information Exchange & consent
            manager. PHR app / Health Locker: Is used to receive, store & share
            medical records between patients & healthcare service providers.
          </p>
        </li>
      </ul>
      <h3 className="text-primary text-[14px] font-medium mb-2 mt-6">
        What are the benefits of ABHA Health Card?
      </h3>
      <p className="text-[#192852]/[0.7] text-[11px] leading-4 mb-6">
        Creating ABHA is the first step towards creating safer and efficient
        digital health records for you and your family. It provides you with:
      </p>
      <ul>
        <li
          className="bg-[#FAF5FE] flex items-center p-3 rounded-xl mb-3"
          data-aos="fade-up"
          data-aos-delay="50"
          data-aos-duration="1000"
        >
          <div className="bg-[#F4F0F8] flex items-center justify-center rounded-full min-w-[45px] h-[45px]">
            <img src={AbhaIcon1} alt="" />
          </div>
          <div className="ml-4">
            <h3 className="text-[#192852] text-[14px] font-medium mb-1">
              Digital Health Records
            </h3>
            <p className="text-[#192852)]/[0.7] font-normal text-[11px] leading-4">
              Access your information right from admission to treatment and
              discharge in a paperless manner.
            </p>
          </div>
        </li>
        <li
          className="bg-[#FAF5FE] flex items-center p-3 rounded-xl mb-3"
          data-aos="fade-up"
          data-aos-delay="100"
          data-aos-duration="1000"
        >
          <div className="bg-[#F4F0F8] flex items-center justify-center rounded-full min-w-[45px] h-[45px]">
            <img src={AbhaIcon2} alt="" />
          </div>
          <div className="ml-4">
            <h3 className="text-primary text-[14px] font-medium mb-1">
              Consent based access
            </h3>
            <p className="text-[#192852)]/[0.7] font-normal text-[11px] leading-4">
              Access to your health data is provided after your clear and
              informed consent. You have the ability to manage and revoke
              consent, if needed.
            </p>
          </div>
        </li>
        <li
          className="bg-[#FAF5FE] flex items-center p-3 rounded-xl mb-3"
          data-aos="fade-up"
          data-aos-delay="150"
          data-aos-duration="1000"
        >
          <div className="bg-[#F4F0F8] flex items-center justify-center rounded-full min-w-[45px] h-[45px]">
            <img src={AbhaIcon3} alt="" />
          </div>
          <div className="ml-4">
            <h3 className="text-primary text-[14px] font-medium mb-1">
              Secure and Private
            </h3>
            <p className="text-[#192852)]/[0.7] font-normal text-[11px] leading-4">
              Built with robust security and encryption mechanisms and no
              information is shared without your consent.
            </p>
          </div>
        </li>
        <li
          className="bg-[#FAF5FE] flex items-center p-3 rounded-xl mb-3"
          data-aos="fade-up"
          data-aos-delay="200"
          data-aos-duration="1000"
        >
          <div className="bg-[#F4F0F8] flex items-center justify-center rounded-full min-w-[45px] h-[45px]">
            <img src={AbhaIcon4} alt="" />
          </div>
          <div className="ml-4">
            <h3 className="text-primary text-[14px] font-medium mb-1">
              Voluntary Opt-In
            </h3>
            <p className="text-[#192852)]/[0.7] font-normal text-[11px] leading-4">
              Participate at your own free will and choose to create your ABHA
              voluntarily.
            </p>
          </div>
        </li>
        <li
          className="bg-[#FAF5FE] flex items-center p-3 rounded-xl mb-3"
          data-aos="fade-up"
          data-aos-delay="250"
          data-aos-duration="1000"
        >
          <div className="bg-[#F4F0F8] flex items-center justify-center rounded-full min-w-[45px] h-[45px]">
            <img src={AbhaIcon5} alt="" />
          </div>
          <div className="ml-4">
            <h3 className="text-primary text-[14px] font-medium mb-1">
              Personal Health Records (PHR)
            </h3>
            <p className="text-[#192852)]/[0.7] font-normal text-[11px] leading-4">
              Access and link your Personal Health Records (PHR) with ABHA to
              create longitudinal health history.
            </p>
          </div>
        </li>
        <li
          className="bg-[#FAF5FE] flex items-center p-3 rounded-xl mb-3"
          data-aos="fade-up"
          data-aos-delay="300"
          data-aos-duration="1000"
        >
          <div className="bg-[#F4F0F8] flex items-center justify-center rounded-full min-w-[45px] h-[45px]">
            <img src={AbhaIcon6} alt="" />
          </div>
          <div className="ml-4">
            <h3 className="text-primary text-[14px] font-medium mb-1">
              Inclusive access
            </h3>
            <p className="text-[#192852)]/[0.7] font-normal text-[11px] leading-4">
              Available to people with smartphones, feature phones, and even no
              phones using assisted methods.
            </p>
          </div>
        </li>
      </ul>
    </div>
  </div>
);

export default Abha;
