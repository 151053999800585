import { Fragment, useState } from "react";
import { Link, useLocation } from "react-router-dom";
import { Dialog, Disclosure, Transition } from "@headlessui/react";
import { useAuth } from "../utils/auth";
import { sdkWrapperURL } from "../utils/api-url-list";

const LabPackageDetail = () => {
  const {
    state: { packageDetail },
  } = useLocation();
  const { getToken, userData } = useAuth();

  const [isSuccessModalOpen, setSuccessModalOpen] = useState(false);
  const [btnLoading, setBtnLoading] = useState(false);
  const [pincode, setPincode] = useState("");
  const [pincodeAvailability, setPincodeAvailability] = useState("none");

  const checkPincode = async () => {
    setBtnLoading(true);
    try {
      const token = await getToken();
      const resp = await fetch(sdkWrapperURL("/users/check-pincode"), {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          Authorization: token,
        },
        body: JSON.stringify({
          product_id: packageDetail.product_id,
          pincode: pincode,
        }),
      });
      const resp_json = await resp.json();
      if (resp_json.statusCode?.toString().startsWith("2")) {
        setPincodeAvailability("Available");
      } else throw new Error(resp_json.message);
    } catch {
      setPincodeAvailability("Unavailable");
    } finally {
      setBtnLoading(false);
    }
  };

  const bookTest = async () => {
    setBtnLoading(true);
    try {
      const token = await getToken();
      const resp = await fetch(sdkWrapperURL("/users/add-saved-products"), {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          Authorization: token,
        },
        body: JSON.stringify({
          product_id: packageDetail.product_id,
          is_saved: 1,
          application_info: {
            Price: packageDetail.structure_val?.discounted_price ?? 0,
            Pincode: pincode,
            "Email ID": userData.email,
            "Phone No.": userData.phone,
            "Package Name": packageDetail.product_name ?? "",
            "Customer Name": userData.fname + " " + userData.lname,
          },
        }),
      });
      const resp_json = await resp.json();
      if (resp_json.statusCode?.toString().startsWith("2"))
        setSuccessModalOpen(true);
      else throw new Error(resp_json.message);
    } catch (err) {
      console.error(err);
    } finally {
      setBtnLoading(false);
    }
  };

  return (
    <div className="px-6 mb-28">
      <div
        className="flex items-center mb-5"
        data-aos="fade-right"
        data-aos-delay="50"
        data-aos-duration="1000"
      >
        <Link to={-1} className="mr-3">
          <svg
            width="6"
            height="10"
            viewBox="0 0 10 17"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M9.42402 16.2142C9.99277 15.6454 9.99277 14.7267 9.42402 14.1579L3.76569 8.49959L9.42402 2.84126C9.99277 2.27251 9.99277 1.35376 9.42402 0.785009C8.85527 0.216259 7.93652 0.216259 7.36777 0.785009L0.674024 7.47876C0.105274 8.04751 0.105274 8.96626 0.674024 9.53501L7.36777 16.2288C7.92194 16.7829 8.85527 16.7829 9.42402 16.2142Z"
              fill="#192852"
            />
          </svg>
        </Link>
        <h3 className="text-primary text-[14px] font-semibold">Lab Packages</h3>
      </div>
      <div
        className="mb-4 p-3 rounded-xl bg-[#F6F6F6]"
        data-aos="fade-up"
        data-aos-delay="10"
        data-aos-duration="1000"
        data-aos-offset="10"
        data-aos-easing="ease-in-out"
      >
        <div className="flex justify-between items-start space-x-4">
          <div className="shrink-0">
            <img
              className="w-20 h-20 rounded-lg"
              src={packageDetail.structure_val?.logo}
              alt="Company Logo"
            />
          </div>
          <div className="grow">
            <h4 className="text-secondary text-xs font-medium">
              {packageDetail.structure_val?.name}
              &nbsp;(
              {packageDetail.structure_val?.tests_included &&
                (packageDetail?.structure_val?.test_type === "grouped"
                  ? Object.values(
                      packageDetail.structure_val.tests_included
                    ).reduce((sum, arr) => sum + (arr?.length ?? 0), 0)
                  : packageDetail.structure_val.tests_included.length)}
              &nbsp;tests)
            </h4>
            <p className="mt-1 flex items-center">
              <span className="text-neutral-600 text-[10px] line-through">
                {parseInt(packageDetail.structure_val?.mrp ?? 0).toLocaleString(
                  undefined,
                  {
                    style: "currency",
                    currency: "INR",
                  }
                )}
              </span>
              <span className="ml-3 text-secondary text-xs font-semibold">
                {parseInt(
                  packageDetail.structure_val?.discounted_price ?? 0
                ).toLocaleString(undefined, {
                  style: "currency",
                  currency: "INR",
                })}
                /-
              </span>
              <span className="ml-6 text-[5px] text-black">
                inclusive all taxes
              </span>
            </p>
            <p className=" w-[75px] h-[30px] mt-1.5 px-2 flex items-center justify-center rounded-[5px] border border-dashed border-current bg-[#F3FBF4] text-[#1AAB2A] text-xs font-semibold">
              {Math.round(
                (1 -
                  (packageDetail.structure_val?.discounted_price ?? 0) /
                    (packageDetail.structure_val?.mrp ?? 0)) *
                  100
              )}
              % Off
            </p>
          </div>
        </div>
        <div className="my-6">
          <div className="flex items-center">
            <input
              className={`px-3 py-1.5 w-48 rounded-md border ${
                pincodeAvailability === "Unavailable"
                  ? "border-[#f00]"
                  : "border-[#bdbdbd]"
              } text-xs text-primary outline-none`}
              id="pincode"
              name="pincode"
              type="number"
              inputMode="numeric"
              maxLength={6}
              minLength={6}
              autoComplete="postal-code"
              placeholder="Enter Pincode"
              value={pincode}
              onChange={(e) => {
                setPincodeAvailability("none");
                if (e.target.value.length <= 6) setPincode(e.target.value);
              }}
              disabled={btnLoading}
            />
            {pincode?.length === 6 &&
              (pincodeAvailability === "Available" ? (
                <>
                  <svg
                    className="shrink-0 ml-2 h-4 w-4"
                    viewBox="0 0 15 15"
                    fill="none"
                  >
                    <path
                      d="M7.5 1.25C4.05 1.25 1.25 4.05 1.25 7.5C1.25 10.95 4.05 13.75 7.5 13.75C10.95 13.75 13.75 10.95 13.75 7.5C13.75 4.05 10.95 1.25 7.5 1.25ZM6.25 10.625L3.125 7.5L4.00625 6.61875L6.25 8.85625L10.9937 4.1125L11.875 5L6.25 10.625Z"
                      fill="#128A34"
                    />
                  </svg>
                  <span className="ml-1 text-[10px] text-[#373737] font-semibold">
                    Available
                  </span>
                </>
              ) : (
                pincodeAvailability === "Unavailable" && (
                  <>
                    <svg
                      className="shrink-0 ml-2 h-4 w-4"
                      viewBox="0 0 15 15"
                      fill="none"
                    >
                      <path
                        d="M7.5 1.25C4.05625 1.25 1.25 4.05625 1.25 7.5C1.25 10.9437 4.05625 13.75 7.5 13.75C10.9437 13.75 13.75 10.9437 13.75 7.5C13.75 4.05625 10.9437 1.25 7.5 1.25ZM9.6 8.9375C9.78125 9.11875 9.78125 9.41875 9.6 9.6C9.50625 9.69375 9.3875 9.7375 9.26875 9.7375C9.15 9.7375 9.03125 9.69375 8.9375 9.6L7.5 8.1625L6.0625 9.6C5.96875 9.69375 5.85 9.7375 5.73125 9.7375C5.6125 9.7375 5.49375 9.69375 5.4 9.6C5.21875 9.41875 5.21875 9.11875 5.4 8.9375L6.8375 7.5L5.4 6.0625C5.21875 5.88125 5.21875 5.58125 5.4 5.4C5.58125 5.21875 5.88125 5.21875 6.0625 5.4L7.5 6.8375L8.9375 5.4C9.11875 5.21875 9.41875 5.21875 9.6 5.4C9.78125 5.58125 9.78125 5.88125 9.6 6.0625L8.1625 7.5L9.6 8.9375Z"
                        fill="#FA5151"
                      />
                    </svg>
                    <span className="ml-1 text-[10px] text-[#373737] font-semibold">
                      Unavailable
                    </span>
                  </>
                )
              ))}
          </div>
          {pincode?.length !== 6 &&
            pincode?.length > 0 &&
            pincodeAvailability === "Unavailable" && (
              <p className=" mt-1.5 text-xxs text-error leading-tight">
                Invaild Pincode
              </p>
            )}
          <button
            className="mt-3 px-3 py-1.5 rounded-full bg-secondary text-white text-[10px]"
            type="button"
            onClick={
              pincodeAvailability === "Available" ? bookTest : checkPincode
            }
            disabled={btnLoading}
          >
            {pincodeAvailability === "Available"
              ? "Book Test"
              : "Check Availability"}
            &nbsp;&nbsp;&nbsp;&rarr;
          </button>
        </div>
        <div className="mt-3">
          <h5 className="text-[10px] text-secondary font-semibold">
            How Full Body Checkup helps you?
          </h5>
          <p className="mt-3 text-[8px] text-[#414141]">
            {
              packageDetail.structure_val?.description?.[
                "How Full Body Checkup helps you?"
              ]
            }
          </p>
        </div>
        <div className="mt-3">
          <h5 className="text-[10px] text-secondary font-semibold">
            Precautions
          </h5>
          <p className="mt-3 p-2 rounded-md border border-dashed border-current text-[8px] text-[#414141] bg-[#f5f5f5]">
            {packageDetail.structure_val?.description?.["Precautions"]}
          </p>
        </div>
        <div className="mt-6">
          <h4 className="text-[10px]">
            <span className="text-secondary font-semibold">
              Test includes&nbsp;
            </span>
            <span className="text-primary/[0.55] font-semibold">
              (
              {packageDetail?.structure_val?.test_type === "grouped"
                ? packageDetail.structure_val?.tests_included &&
                  Object.values(
                    packageDetail.structure_val.tests_included
                  ).reduce((sum, arr) => sum + (arr?.length ?? 0), 0)
                : packageDetail?.structure_val?.tests_included?.length}
              &nbsp;tests):
            </span>
          </h4>
          {packageDetail?.structure_val?.test_type === "grouped" ? (
            <div className="mt-4 max-w-[270px]">
              {Object.entries(
                packageDetail?.structure_val?.tests_included
              )?.map(([label, list]) => (
                <Disclosure as="div" className="mb-2.5">
                  <Disclosure.Button className="flex w-full justify-between items-start text-secondary text-[10px]">
                    {({ open }) => (
                      <>
                        <span className="text-left font-semibold">
                          {label}&nbsp;
                          <span className="text-primary/[0.55]">
                            (includes {list.length ?? 0} tests)
                          </span>
                        </span>
                        <svg
                          className={`shrink-0 h-5 w-5 ${
                            open ? "rotate-0" : "rotate-180"
                          }`}
                          viewBox="0 0 20 20"
                          fill="currentColor"
                        >
                          <path
                            d="M14.77 12.79a.75.75 0 01-1.06-.02L10 8.832 6.29 12.77a.75.75 0 11-1.08-1.04l4.25-4.5a.75.75 0 011.08 0l4.25 4.5a.75.75 0 01-.02 1.06z"
                            fillRule="evenodd"
                            clipRule="evenodd"
                          />
                        </svg>
                      </>
                    )}
                  </Disclosure.Button>
                  <Disclosure.Panel
                    as="ul"
                    className="mt-1 list-disc list-inside text-primary text-[10px] font-medium"
                  >
                    {list.map((listItem) => (
                      <li>{listItem}</li>
                    ))}
                  </Disclosure.Panel>
                </Disclosure>
              ))}
            </div>
          ) : (
            <div className="mt-4 max-w-[270px]">
              {packageDetail?.structure_val?.tests_included?.map((list) => (
                <li className="mt-1 list-disc list-inside text-primary text-[10px] font-medium">
                  {list}
                </li>
              ))}
            </div>
          )}
        </div>
      </div>
      <Transition appear show={isSuccessModalOpen} as={Fragment}>
        <Dialog
          as="div"
          className="relative z-10"
          onClose={() => setSuccessModalOpen(false)}
        >
          <Transition.Child
            as={Fragment}
            enter="ease-out duration-300"
            enterFrom="opacity-0"
            enterTo="opacity-100"
            leave="ease-in duration-200"
            leaveFrom="opacity-100"
            leaveTo="opacity-0"
          >
            <div className="fixed inset-0 bg-black bg-opacity-25" />
          </Transition.Child>

          <div className="fixed inset-0 overflow-y-auto">
            <div className="flex min-h-full items-center justify-center p-4 text-center">
              <Transition.Child
                as={Fragment}
                enter="ease-out duration-300"
                enterFrom="opacity-0 scale-95"
                enterTo="opacity-100 scale-100"
                leave="ease-in duration-200"
                leaveFrom="opacity-100 scale-100"
                leaveTo="opacity-0 scale-95"
              >
                <Dialog.Panel className="w-[300px] h-[200px] max-w-md transform overflow-hidden rounded-2xl bg-white p-6 text-left align-middle shadow-xl transition-all">
                  <div className=" flex flex-col items-center justify-center mt-5">
                    <h4 className="text-[12px] font-semibold text-primary mb-2">
                      Booking Successfull!
                    </h4>
                    <p className="text-[10px] text-primary/[0.5] text-center">
                      CarePlix booking team will contact you by phone within
                      next 2 hours to confirm your booking.
                    </p>
                  </div>
                  <Link
                    to="/"
                    replace
                    className="mt-6 mx-auto w-[60px] h-[35px] bg-secondary rounded-full flex items-center justify-center text-[12px] outline-none text-white font-medium hover:bg-primary active:bg-primary focus:outline-none"
                  >
                    Ok
                  </Link>
                </Dialog.Panel>
              </Transition.Child>
            </div>
          </div>
        </Dialog>
      </Transition>
    </div>
  );
};

export default LabPackageDetail;
