import { Fragment, useState } from "react";
import { Link, useLocation } from "react-router-dom";
import { Dialog, Transition } from "@headlessui/react";
import { useAuth } from "../utils/auth";
import { sdkWrapperURL } from "../utils/api-url-list";

const PackageDetails = () => {
  const { getToken } = useAuth();
  const location = useLocation();
  const data = location?.state;
  const [isSuccessModalOpen, setSuccessModalOpen] = useState(false);
  const [btnLoading, setBtnLoading] = useState(false);

  const buyProduct = async () => {
    setBtnLoading(true);
    try {
      const token = await getToken();
      const resp = await fetch(sdkWrapperURL("/users/add-saved-products"), {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          Authorization: token,
        },
        body: JSON.stringify({
          product_id: data.product_Id,
          application_info: {},
        }),
      });
      const resp_json = await resp.json();
      if (resp_json.statusCode?.toString().startsWith("2"))
        setSuccessModalOpen(true);
      else throw new Error(resp_json.message);
    } catch (err) {
      console.error(err);
    } finally {
      setBtnLoading(false);
    }
  };

  return (
    <div className="w-full mb-28">
      {/* back button */}
      <div className="px-6">
        <div
          className="flex items-center mb-5"
          data-aos="fade-right"
          data-aos-duration="1000"
          data-aos-delay="50"
        >
          <Link to={-1} className="mr-3">
            <svg
              width="6"
              height="10"
              viewBox="0 0 10 17"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M9.42402 16.2142C9.99277 15.6454 9.99277 14.7267 9.42402 14.1579L3.76569 8.49959L9.42402 2.84126C9.99277 2.27251 9.99277 1.35376 9.42402 0.785009C8.85527 0.216259 7.93652 0.216259 7.36777 0.785009L0.674024 7.47876C0.105274 8.04751 0.105274 8.96626 0.674024 9.53501L7.36777 16.2288C7.92194 16.7829 8.85527 16.7829 9.42402 16.2142Z"
                fill="#192852"
              />
            </svg>
          </Link>
          <h3 className="text-primary text-[14px] font-semibold">
            Package Details
          </h3>
        </div>
      </div>
      {/* back button end */}
      <div data-aos="fade-up" data-aos-duration="1000" data-aos-delay="100">
        <img src={data?.img} alt="" />
      </div>

      <div className="px-6">
        <h3
          className="text-black text-[16px] font-semibold mb-3 mt-5"
          data-aos="fade-up"
          data-aos-duration="1000"
          data-aos-delay="150"
        >
          {data?.name}
        </h3>
        <p
          className="mt-1 mb-4 text-[12px] text-secondary font-semibold flex items-center"
          data-aos="fade-up"
          data-aos-duration="1000"
          data-aos-delay="200"
        >
          Price: Rs. {data?.price}{" "}
          <button
            type="button"
            disabled={btnLoading}
            onClick={buyProduct}
            className="bg-secondary px-4 py-1 text-[11px] text-white rounded-full border border-secondary ml-4"
          >
            Buy Now
          </button>
        </p>
        <p
          className="text-[#000]/[.7] text-[11px] leading-[16px] mb-2 text-justify"
          data-aos="fade-up"
          data-aos-duration="1000"
          data-aos-delay="250"
        >
          {data?.description_Info}
        </p>
        {data?.key_Benefits?.length > 0 && (
          <>
            <h4
              className="mt-[20px] mb-3 text-[14px] font-semibold text-secondary"
              data-aos="fade-up"
              data-aos-duration="1000"
              data-aos-delay="250"
            >
              Key Benifits
            </h4>
            <ul className=" list-disc text-[#000]/[.7] text-[11px] leading-[16px] pl-3 mt-3">
              {data?.key_Benefits?.map((val, i) => {
                return (
                  <li
                    key={i}
                    className="mb-1"
                    data-aos="fade-up"
                    data-aos-duration="1000"
                    data-aos-delay="150"
                  >
                    {val}
                  </li>
                );
              })}
            </ul>
          </>
        )}
        {data?.membership_Duration?.length > 0 && (
          <p
            className="text-[#000]/[.7] text-[11px] leading-[16px] mt-4"
            data-aos="fade-up"
            data-aos-duration="1000"
            data-aos-delay="150"
          >
            {`Membership Duration : ${data?.membership_Duration}`}
          </p>
        )}
      </div>
      <Transition appear show={isSuccessModalOpen} as={Fragment}>
        <Dialog
          as="div"
          className="relative z-10"
          onClose={() => setSuccessModalOpen(false)}
        >
          <Transition.Child
            as={Fragment}
            enter="ease-out duration-300"
            enterFrom="opacity-0"
            enterTo="opacity-100"
            leave="ease-in duration-200"
            leaveFrom="opacity-100"
            leaveTo="opacity-0"
          >
            <div className="fixed inset-0 bg-black bg-opacity-25" />
          </Transition.Child>

          <div className="fixed inset-0 overflow-y-auto">
            <div className="flex min-h-full items-center justify-center p-4 text-center">
              <Transition.Child
                as={Fragment}
                enter="ease-out duration-300"
                enterFrom="opacity-0 scale-95"
                enterTo="opacity-100 scale-100"
                leave="ease-in duration-200"
                leaveFrom="opacity-100 scale-100"
                leaveTo="opacity-0 scale-95"
              >
                <Dialog.Panel className="w-[300px] h-[200px] max-w-md transform overflow-hidden rounded-2xl bg-white p-6 text-left align-middle shadow-xl transition-all">
                  <div className=" flex flex-col items-center justify-center mt-5">
                    <h4 className="text-[12px] font-semibold text-primary mb-2">
                      Product Saved Successfull!
                    </h4>
                    <p className="text-[10px] text-primary/[0.5] text-center">
                      CarePlix booking team will contact you by phone within
                      next 2 hours to confirm your booking.
                    </p>
                  </div>
                  <Link
                    to="/"
                    replace
                    className="mt-6 mx-auto w-[60px] h-[35px] bg-secondary rounded-full flex items-center justify-center text-[12px] outline-none text-white font-medium hover:bg-primary active:bg-primary focus:outline-none"
                  >
                    Ok
                  </Link>
                </Dialog.Panel>
              </Transition.Child>
            </div>
          </div>
        </Dialog>
      </Transition>
    </div>
  );
};

export default PackageDetails;
