import { useEffect, useState } from "react";
import { Link } from "react-router-dom";

import { useAuth } from "../utils/auth";
import { sdkWrapperURL } from "../utils/api-url-list";

import LoadingScreen from "../components/LoadingScreen";
import { ReactComponent as BackArrow } from "../assets/icons/BackArrow.svg";

const DiagnosticPackages = () => {
  const {
    getToken,
    userData: { profile_id },
    subscriptionPlanDetails: { packageName },
  } = useAuth();
  const [loading, setLoading] = useState(false);
  const [offers, setOffers] = useState([]);

  useEffect(() => {
    setLoading(true);
    (async () => {
      try {
        const token = await getToken();
        const product_type_id =
          { FREEMIUM: 9, LITE: 12, STANDARD: 11, PREMIUM: 10 }[packageName] ||
          9;
        const packagesResp = await fetch(
          sdkWrapperURL("/users/list/products"),
          {
            method: "POST",
            headers: {
              "Content-Type": "application/json",
              Authorization: token,
            },
            body: JSON.stringify({
              profile_id,
              product_type_id,
              module_type: "labs",
              saved: 0,
              table_config_rows_per_page: 100,
              table_config_page_no: 1,
            }),
          }
        );
        const packagesRespJSON = await packagesResp.json();
        if (packagesRespJSON?.statusCode?.toString().startsWith("2")) {
          setOffers(packagesRespJSON.response ?? []);
        } else {
          throw Error(packagesRespJSON.message ?? "Error in fetching Offers.");
        }
      } catch (err) {
        console.error(err);
      } finally {
        setLoading(false);
      }
    })();
  }, [getToken, profile_id, packageName]);

  return (
    <section className="px-6 py-2 mb-14">
      <div className="flex items-center mb-6 text-white">
        <Link className="flex-shrink-0 p-1 pr-2.5" to={-1}>
          <BackArrow className="h-3 w-3" />
        </Link>
        <h4 className="text-sm font-bold">Exclusive Deals</h4>
      </div>
      {loading ? (
        <LoadingScreen style={{ minHeight: "60vh" }} />
      ) : offers.length <= 0 ? (
        <p className="my-24 text-center text-lg text-darkgray">
          No More Available Offers
        </p>
      ) : (
        <>
          {offers.map((offer) => (
            <Link
              key={offer.product_id}
              state={{ offer }}
              to="/request-callback"
              className="block mb-6 rounded-2xl border border-[#680FAD] bg-[#680FAD]"
            >
              <img
                className="w-full"
                src={offer.structure_val.banner}
                alt={offer.product_name}
              />
              <p className="px-6 py-3 text-white text-center text-sm font-medium">
                Interested to Buy
              </p>
            </Link>
          ))}
        </>
      )}
    </section>
  );
};

export default DiagnosticPackages;
