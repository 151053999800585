import Exercise1 from "../assets/images/Exercise1.png";
import Exercise2 from "../assets/images/Exercise2.png";
import PlayBtn from "../assets/images/play-btn.png";
import { Link } from "react-router-dom";

const Exercise = () => (
  <div className="w-full px-6 mt-7">
    <div
      className="relative"
      data-aos="fade-up"
      data-aos-duration="1000"
      data-aos-delay="50"
    >
      <img src={Exercise1} alt="" />
      <div className="absolute flex items-center justify-center top-0 bottom-0 left-0 right-0">
        <Link to="">
          <img src={PlayBtn} alt="" />
        </Link>
      </div>
    </div>
    <h3
      className="text-black text-[14px] font-semibold mb-2 mt-5"
      data-aos="fade-up"
      data-aos-duration="1000"
      data-aos-delay="100"
    >
      Free Squats - 3 Sets (20 reps)
    </h3>
    <p
      className="text-[11px] text-[#000]/[.7] leading-[14px] mb-1"
      data-aos="fade-up"
      data-aos-duration="1000"
      data-aos-delay="150"
    >
      To perform a free squat, follow these steps:
    </p>
    <ol className="mb-6 list-decimal ml-4">
      <li
        className="text-[11px] text-[#000]/[0.7] mb-1 leading-[14px]"
        data-aos="fade-up"
        data-aos-duration="1000"
        data-aos-delay="200"
      >
        Stand with your feet shoulder-width apart and your toes pointing
        slightly outward.
      </li>
      <li
        className="text-[11px] text-[#000]/[0.7] mb-1"
        data-aos="fade-up"
        data-aos-duration="1000"
        data-aos-delay="250"
      >
        Engage your core muscles and keep your back straight.
      </li>
      <li
        className="text-[11px] text-[#000]/[0.7] leading-[14px]"
        data-aos="fade-up"
        data-aos-duration="1000"
        data-aos-delay="300"
      >
        Lower your body by bending your knees and pushing your hips back, as if
        you were sitting in a chair ....
      </li>
    </ol>
    <div
      className="relative"
      data-aos="fade-up"
      data-aos-duration="1000"
      data-aos-delay="350"
    >
      <img src={Exercise2} alt="" />
      <div className="absolute flex items-center justify-center top-0 bottom-0 left-0 right-0">
        <Link to="">
          <img src={PlayBtn} alt="" />
        </Link>
      </div>
    </div>
    <h3
      className="text-black text-[14px] font-semibold mb-2 mt-5"
      data-aos="fade-up"
      data-aos-duration="1000"
      data-aos-delay="400"
    >
      High Knees - 3 Sets (30 reps)
    </h3>
    <p
      className="text-[11px] text-[#000]/[.7] leading-[16px] mb-2"
      data-aos="fade-up"
      data-aos-duration="1000"
      data-aos-delay="450"
    >
      To perform high knees, follow these steps:
    </p>
    <ol className="mb-6 list-decimal ml-4">
      <li
        className="text-[11px] text-[#000]/[0.7] mb-1 leading-[14px]"
        data-aos="fade-up"
        data-aos-duration="1000"
        data-aos-delay="500"
      >
        Stand with your feet hip-width apart and your arms at your sides.
      </li>
      <li
        className="text-[11px] text-[#000]/[0.7] mb-1 leading-[14px]"
        data-aos="fade-up"
        data-aos-duration="1000"
        data-aos-delay="550"
      >
        Begin jogging in place, lifting your knees as high as you can with each
        step.
      </li>
      <li
        className="text-[11px] text-[#000]/[0.7] mb-1 leading-[14px]"
        data-aos="fade-up"
        data-aos-duration="1000"
        data-aos-delay="600"
      >
        Pump your arms back and forth in a running motion as you lift your
        knees.
      </li>
      <li
        className="text-[11px] text-[#000]/[0.7] leading-[14px]"
        data-aos="fade-up"
        data-aos-duration="1000"
        data-aos-delay="650"
      >
        Keep your core engaged and your back straight throughout the
        exercise....
      </li>
    </ol>
  </div>
);

export default Exercise;
