import { Link } from "react-router-dom";
import CareInsurance from "../assets/images/care-insurance-img.png";

const InsurancePolicy = () => (
  <div className="px-6 pb-28">
    <div className="flex items-center mb-5">
      <Link to="/" className="mr-3">
        <svg
          width="6"
          height="10"
          viewBox="0 0 10 17"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            d="M9.42402 16.2142C9.99277 15.6454 9.99277 14.7267 9.42402 14.1579L3.76569 8.49959L9.42402 2.84126C9.99277 2.27251 9.99277 1.35376 9.42402 0.785009C8.85527 0.216259 7.93652 0.216259 7.36777 0.785009L0.674024 7.47876C0.105274 8.04751 0.105274 8.96626 0.674024 9.53501L7.36777 16.2288C7.92194 16.7829 8.85527 16.7829 9.42402 16.2142Z"
            fill="#fff"
          />
        </svg>
      </Link>
      <h3 className="text-white text-[14px] font-semibold">Insurance Policy</h3>
    </div>
    <div className="bg-gradient-to-t from-[#0F3290]/50 to-[#5613A4]/50 p-4 rounded-[10px] mb-4">
      <div className="flex justify-between">
        <div>
          <p className="text-xxs text-[#9529B0]">Policy No.</p>
          <p className="text-sm text-white mb-5">189088761</p>
          <p className="text-xxs text-[#9529B0]">Member ID</p>
          <p className="text-sm text-white mb-5">CRE8901111</p>
        </div>
        <img className="h-[70px]" src={CareInsurance} alt="" />
      </div>
      <div className="flex items-center">
        <div className="mr-5">
          <p className="text-xxs text-[#9529B0]">DOB</p>
          <p className="text-sm text-white">30-01-1978</p>
        </div>
        <div>
          <p className="text-xxs text-[#9529B0]">Name</p>
          <p className="text-sm text-white">Subhabrata Paul</p>
        </div>
      </div>
    </div>
    <div className="bg-gradient-to-t from-[#331879]/30 to-[#5517A7]/30 p-4 rounded-[10px]">
      <p className="text-sm text-[#9529B0] mb-4">Addtional Details</p>
      <div className="flex items-center mb-3">
        <p className="text-xs text-[#FBECFF] font-light tracking-[4%] w-28">
          Policy Period
        </p>
        <p className="text-xs text-[#FBECFF] font-light tracking-[4%]">
          18-Jun-2023 to 17-Jun-2024
        </p>
      </div>
      <div className="flex items-center mb-3">
        <p className="text-xs text-[#FBECFF] font-light tracking-[4%] w-28">
          Sum Insured
        </p>
        <p className="text-xs text-[#FBECFF] font-light tracking-[4%]">
          1,00,000
        </p>
      </div>
      <div className="flex items-center mb-3">
        <p className="text-xs text-[#FBECFF] font-light tracking-[4%] w-28">
          PED Info
        </p>
        <p className="text-xs text-[#FBECFF]">None</p>
      </div>
      <div className="flex items-center mb-3">
        <p className="text-xs text-[#FBECFF] font-light tracking-[4%] w-28">
          Gender
        </p>
        <p className="text-xs text-[#FBECFF] font-light tracking-[4%]">Male</p>
      </div>
      <div className="flex items-center mb-3">
        <p className="text-xs text-[#FBECFF] font-light tracking-[4%] w-28">
          Nominee
        </p>
        <p className="text-xs text-[#FBECFF] font-extralight tracking-[4%]">
          Jayanta Paul
        </p>
      </div>
      <table>
        <tr>
          <td></td>
          <td></td>
        </tr>
      </table>
    </div>
  </div>
);

export default InsurancePolicy;
