import {
  useEffect,
  // useMemo,
  useState,
  Fragment,
} from "react";
import { Link, useNavigate } from "react-router-dom";
import Carousel from "react-elastic-carousel";
import { Dialog, Transition } from "@headlessui/react";
import { useAuth } from "../utils/auth";
import { sdkWrapperURL } from "../utils/api-url-list";
import { logAnalyticsEvent } from "../utils/analytics";
// import { constants } from "../utils/ranges";
import LoadingScreen from "../components/LoadingScreen";
// import ProgressCircle from "../components/ProgressCircle";
import Slider from "react-slick";
// import { Dialog, Transition } from "@headlessui/react";
// import Slider from "infinite-react-carousel";
// import BannerWoman from "../assets/images/BannerWoman.png";
// import { ReactComponent as Arrow } from "../assets/icons/Arrow.svg";
// import { ReactComponent as Chevron } from "../assets/icons/Chevron.svg";

import { ReactComponent as GlowingHeart } from "../assets/icons/GlowingHeart.svg";
import { ReactComponent as Info } from "../assets/icons/info-circle.svg";
// import UpArrow from "../assets/images/Arrow-circle-up.svg";
// import DArrow from "../assets/images/down-arrow.svg";
// import HealthScore from "../assets/images/Health-Score.svg";
// import WelbingScore from "../assets/images/Mental-welbing-score.svg";
// import DiabetesScore from "../assets/icons/Diabetes.svg";
// import CardiacScore from "../assets/icons/Cardiac.svg";
// import HealthIcon from "../assets/health-icon/health-icon.svg";
// import ConsultDoctor from "../assets/health-icon/Consult-Doctor.svg";
// import FindDoctor from "../assets/health-icon/find-doctor.svg";
// import HealthPackage from "../assets/health-icon/Health-package.svg";
// import LabTest from "../assets/health-icon/Lab-Test.svg";
// import Medicine from "../assets/health-icon/Buy-Medicine.svg";
import NextArrow from "../assets/icons/next-icon.svg";
// import Discount from "../assets/health-icon/discount.svg";
// import Depression from "../assets/images/Depression_Increase.png";
import StartScanBanner from "../assets/images/StartScanBanner.webp";
// import WeekelyChallengeImage from "../assets/icons/WeeklyChallengeBanner.svg";
// import NutritionImg from "../assets/images/Nutrition.png";
// import Nutrition from "../assets/images/Nutrition.svg";
// import LifeStyleScore from "../assets/images/Life-style.svg";

// import Walking from "../assets/icons/step.svg";
// import Energy from "../assets/icons/caloriesicon.svg";
// import Distance from "../assets/icons/distance.svg";
// import BMI from "../assets/images/BMI.svg";
// import ABHA from "../assets/images/abha-img.png";
// import Insurance from "../assets/images/health-insurance2.png";
// import RewardImg from "../assets/images/rewards.png";
import FitnessCard from "../assets/images/fitness-card.png";
import WellbeingCard from "../assets/images/mental-wellbing-card.png";
import NutrationCard from "../assets/images/nutrition-card.png";
import SleepCard from "../assets/images/better-sleep-card.png";
// import RewardCard from "../assets/images/rewards-card.png";
import DiagnosticIcon from "../assets/icons/diagnostic-lab.svg";
import DoctorConsultation from "../assets/icons/doctor-consultation.svg";
import UPScore from "../assets/icons/score-up.svg";
import DownScore from "../assets/icons/score-down.svg";
// import MentalWellbing from "../assets/images/mental-wellbing.png";
// import VitalsIndex from "../assets/images/vitals-index.png";
// import Mycare from "../assets/images/my-care.png";
// import HealthyHabits from "../assets/images/Healthy-Habits.png";
// import DiabetesInsights from "../assets/images/Diabetes-Insights.png";

// import Disorder from "../assets/images/health-program/Major-depressive-disorder.png";
// import Anxiety from "../assets/images/health-program/Anxiety.png";
// import Cholesterol from "../assets/images/health-program/High-cholesterol.png";
// import Weight from "../assets/images/health-program/Weight-management.png";
// import Respiratory from "../assets/images/health-program/Respiratory-Management.png";
// import Hypertension from "../assets/images/health-program/Hypertension.png";
// import Polycystic from "../assets/images/health-program/Polycystic-ovary-syndrome.png";
// import Diabetes from "../assets/images/health-program/Type-2-diabetes.png";
// import FattyLiver from "../assets/images/health-program/fatty-liver-disease.png";
import BreathLab from "../assets/images/Breath-lab.png";
// import MuscleCare from "../assets/images/muscle-care.png";
import RelivePain from "../assets/images/relive-pain.png";
// import Cognitive from "../assets/images/Cognitive-assessments.png";
// import Migraine from "../assets/images/pain/Stress.png";
// import BackPain from "../assets/images/pain/back-pain.png";
// import Cramps from "../assets/images/pain/Polycystic-ovary-syndrome.png";
// import Headache from "../assets/images/pain/Headache.png";
// import KneePain from "../assets/images/pain/Knee-Pain.png";
// import ShoulderPain from "../assets/images/pain/Shoulder-Pain.png";
// import HealthRaderChart from "../components/HealthRaderChart";
// import HealthCard from "../components/HealthCard";
// import CarePoints from "../components/CarePoints";
// import DailyMood from "../components/DailyMood";
import MyHealth from "../assets/icons/heart.svg";
import MoodTrack from "../assets/icons/mind.svg";
// import Target from "../assets/icons/target.svg";
// import ComingSoon from "../assets/images/coming-soon.svg";
// import { Button } from "@material-tailwind/react";

// import FaceScan from "../assets/images/FaceScan.png";
// import FingerScan from "../assets/images/FingerScan.png";
// import { Button } from "@material-tailwind/react";

const Dashboard = () => {
  const navigate = useNavigate();
  // let [isOpen, setIsOpen] = useState(false);
  // function closeModal() {
  //   setIsOpen(false);
  // }
  // function openModal() {
  //   setIsOpen(true);
  // }
  const {
    getToken,
    userData,
    subscriptionPlanDetails: { packageName },
  } = useAuth();
  const [loading, setLoading] = useState(true);
  const [lastScanResult, setLastResult] = useState({});
  const [scoreData, setScoreData] = useState([]);
  const [careScore, setCareScore] = useState();
  const [careScoreChange, setCareScoreChange] = useState(0);
  const [careScoreLastUpdated, setCareScoreLastUpdated] = useState();
  // const [selectedMoodResponse, setSelectedMoodResponse] = useState(-1);
  const [selectedActivityResponse, setSelectedActivityResponse] = useState(0);
  // const [surveyFilledDate, setLastSurveyFilledDate] = useState("");
  // const [graphinaData, setGraphinaData] = useState({});
  const [surveyId, setSurveyId] = useState("");
  // const [streak, setStreak] = useState(0);
  // const [isScanDoneToday, setScanDoneToday] = useState(false);
  // const [muscleUrl, setMuscleUrl] = useState("");
  const [dykList, setDYKList] = useState([]);
  const [isModalOpen, setModalOpen] = useState(false);
  const [isFreemiumModalOpen, setFreemiumModalOpen] = useState(false);
  const [isSuccessModalOpen, setSuccessModalOpen] = useState(false);
  // const [gFitDataLastUpdated, setGFitDataLastUpdated] = useState();

  useEffect(() => {
    const controller = new AbortController();
    setLoading(true);
    (async () => {
      try {
        const token = await getToken();
        const results = await Promise.allSettled([
          new Promise(async (resolve, reject) => {
            try {
              const scoreResp = await fetch(
                sdkWrapperURL("/score/get-scores"),
                {
                  method: "POST",
                  headers: {
                    "Content-Type": "application/json",
                    Authorization: token,
                  },
                  body: JSON.stringify({
                    profile_id: userData.profile_id,
                    scores_display_count: 2,
                    scores_display_count_offset: 0,
                    table_config_rows_per_page: 7,
                    table_config_page_no: 1,
                  }),
                  signal: controller.signal,
                }
              );
              const scoreRespJSON = await scoreResp.json();
              if (scoreRespJSON?.statusCode?.toString().startsWith("2")) {
                setScoreData(
                  scoreRespJSON.response?.map?.((s) => ({
                    scoreID: s.score_code,
                    scoreName: s.score_name,
                    score: s.user_score_details?.[0]?.user_earned_score,
                    date: s.user_score_details?.[0]?.score_generation_time,
                  }))
                );
                // setGraphinaData(
                //   Object.fromEntries(
                //     scoreRespJSON.response?.map?.((scoreDetail) => [
                //       scoreDetail.score_code,
                //       {
                //         you:
                //           scoreDetail.user_score_details?.[0]?.user_earned_score ??
                //           0,
                //         everyone: scoreDetail.score_average?.average ?? 0,
                //       },
                //     ])
                //   )
                // );
                const careScoreList = scoreRespJSON.response?.find?.(
                  (s) => s.score_code === "C005"
                )?.user_score_details;
                setCareScoreLastUpdated(
                  careScoreList?.[0]?.score_generation_time
                );
                setCareScore(careScoreList?.[0]?.user_earned_score ?? 0);
                if (careScoreList?.length > 1)
                  setCareScoreChange(
                    careScoreList[0].user_earned_score -
                      careScoreList[1].user_earned_score
                  );
                resolve();
              } else
                throw new Error(
                  scoreRespJSON?.message ?? "Error in Fetching Scores"
                );
            } catch (err) {
              reject(err);
            }
          }),
          new Promise(async (resolve, reject) => {
            try {
              const surveyResp = await fetch(
                sdkWrapperURL("/users/v1/get_surveys"),
                {
                  method: "POST",
                  headers: {
                    "Content-Type": "application/json",
                    Authorization: token,
                  },
                  body: JSON.stringify({
                    module_type: "daily_check_in",
                    profile_id: userData.profile_id,
                    action: "temporary",
                    required_surveys: "all",
                    table_config_rows_per_page: 10,
                    table_config_page_no: 1,
                  }),
                  signal: controller.signal,
                }
              );
              const surveyRespJSON = await surveyResp.json();
              if (surveyRespJSON?.statusCode?.toString().startsWith("2")) {
                const selectedSurvey = surveyRespJSON.response.find(
                  (s) => s.survey_name === "CarePlix_Mood_Tracking"
                );
                setSurveyId(selectedSurvey.survey_id);
                // setLastSurveyFilledDate(selectedSurvey.last_answered);
                // setSelectedMoodResponse(
                //   (parseInt(
                //     selectedSurvey?.questions_detail?.[0]?.last_answer_given?.match?.(
                //       /\d/
                //     )?.[0],
                //     10
                //   ) || 0) - 1
                // );
                setSelectedActivityResponse(
                  selectedSurvey?.last_answered?.split?.("T")[0] ===
                    new Date().toISOString().split("T")[0]
                    ? (parseInt(
                        selectedSurvey?.questions_detail?.[1]?.last_answer_given?.match?.(
                          /\d/
                        )?.[0],
                        10
                      ) || 1) - 1
                    : 0
                );
                if (selectedSurvey?.is_fillable) {
                  const nextSurveyFillTime =
                    localStorage.getItem("nextPopupTime");
                  const currentTime = Date.now();
                  if (currentTime >= nextSurveyFillTime)
                    navigate("/mood-tracking", {
                      state: {
                        token,
                        qtm_id: selectedSurvey.survey_id,
                        questions_details: selectedSurvey.questions_detail.sort(
                          (a, b) => a.question_order - b.question_order
                        ),
                      },
                    });
                }
                resolve();
              } else
                throw new Error(
                  surveyRespJSON?.message ?? "Error in Fetching Survey"
                );
            } catch (err) {
              reject(err);
            }
          }),
          new Promise(async (resolve, reject) => {
            try {
              const resp = await fetch(
                sdkWrapperURL("/users/sdk/test_api_private"),
                {
                  method: "POST",
                  headers: {
                    "Content-Type": "application/json",
                    Authorization: token,
                  },
                  body: JSON.stringify({
                    action: "list_scan_UL",
                    employee_id: userData.user_id,
                    profile_id: userData.profile_id,
                    table_config_rows_per_page: 1,
                    table_config_page_no: 1,
                  }),
                  signal: controller.signal,
                }
              );
              const respJSON = await resp.json();
              if (
                respJSON?.statusCode?.toString().startsWith("2") &&
                respJSON?.result?.length > 0
              ) {
                setLastResult(respJSON.result[0]);
                resolve();
              } else
                throw new Error(
                  respJSON?.message ?? "Error in Fetching Last Scan Result"
                );
            } catch (err) {
              reject(err);
            }
          }),
          // new Promise(async (resolve, reject) => {
          //   try {
          //     const currentDate = new Date();
          //     const timeZoneOffsetInMinutes = currentDate.getTimezoneOffset();
          //     const timeZoneOffsetHours = Math.floor(
          //       Math.abs(timeZoneOffsetInMinutes) / 60
          //     )
          //       .toString()
          //       .padStart(2, "0");
          //     const timeZoneOffsetMinutes = (Math.abs(timeZoneOffsetInMinutes) % 60)
          //       .toString()
          //       .padStart(2, "0");
          //     const timeZoneOffsetSign = timeZoneOffsetInMinutes >= 0 ? "-" : "+";
          //     const start_date = `${currentDate
          //       .toISOString()
          //       .slice(
          //         0,
          //         -1
          //       )}${timeZoneOffsetSign}${timeZoneOffsetHours}:${timeZoneOffsetMinutes}`;
          //     const streakResp = await fetch(sdkWrapperURL("/users/user-streaks"), {
          //       method: "POST",
          //       headers: {
          //         "Content-Type": "application/json",
          //         Authorization: token,
          //       },
          //       body: JSON.stringify({
          //         profile_id: userData.profile_id,
          //         start_date,
          //         streak_code: "CS-07",
          //       }),
          //     });
          //     const streakRespJSON = await streakResp.json();
          //     if (streakRespJSON?.statusCode?.toString().startsWith("2")) {
          //       const streak_count = streakRespJSON.streak_count;
          //       const scanDone = streakRespJSON.completed_today;
          //       if (scanDone) {
          //         if (streak_count === 0) setStreak(1);
          //         else setStreak(streak_count);
          //       } else {
          //         if (streak_count >= 7) setStreak(0);
          //         else setStreak(streak_count);
          //       }
          //       setScanDoneToday(scanDone);
          //       resolve();
          //     } else
          //       throw new Error(
          //         streakRespJSON?.message ?? "Error in Fetching Streak Data"
          //       );
          //   } catch (err) {
          //     reject(err);
          //   }
          // }),
          // new Promise(async (resolve, reject) => {
          //   try {
          //     const resp = await fetch(sdkWrapperURL("/msk/get_url"), {
          //       method: "POST",
          //       headers: {
          //         "Content-Type": "application/json",
          //         Authorization: token,
          //       },
          //       body: JSON.stringify({ profile_id: userData.profile_id }),
          //       signal: controller.signal,
          //     });
          //     const respJSON = await resp.json();
          //     if (respJSON?.statusCode?.toString().startsWith("2"))
          //       setMuscleUrl(respJSON.data);
          //     else
          //       throw new Error(respJSON?.message ?? "Error in Fetching MSK URL");
          //     resolve();
          //   } catch (err) {
          //     reject(err);
          //   }
          // }),
          new Promise(async (resolve, reject) => {
            try {
              const resp = await fetch(sdkWrapperURL("/users/list/products"), {
                method: "POST",
                headers: {
                  "Content-Type": "application/json",
                  Authorization: token,
                },
                body: JSON.stringify({
                  profile_id: userData.profile_id,
                  module_type: "content",
                  product_type_id: 17,
                  table_config_page_no: 1,
                  table_config_rows_per_page: 100,
                }),
                signal: controller.signal,
              });
              const respJSON = await resp.json();
              if (respJSON?.statusCode?.toString().startsWith("2"))
                setDYKList(respJSON.response);
              else
                throw new Error(
                  respJSON?.message ?? "Error in Fetching Did-you-know List"
                );
              resolve();
            } catch (err) {
              reject(err);
            }
          }),
          // new Promise(async (resolve, reject) => {
          //   try {
          //     const resp = await fetch(
          //       "https://retail-api-dev-sdk-wrapper-app.carenow.healthcare/activity/get-last-activity",
          //       {
          //         method: "POST",
          //         headers: {
          //           "Content-Type": "application/json",
          //           Authorization:
          //             "eyJ0eXAiOiJKV1QiLCJhbGciOiJIUzI1NiJ9.eyJleHAiOjE3MTkwNTA3NTIsInVzZXJfaWQiOiI4Mjg2YjRhYy1iOGYyLTQxNjktOTM1NC1hNTViNTA4YjY0NjAiLCJ1c2VybmFtZSI6Ijk4MzAzNzQ5NzIiLCJ1c2VyX3R5cGUiOiIzIiwiY2xpZW50X2lkIjoiNjkyMWMxZGQtZTA5Yi00YjdjLTg3YWEtMzMyYjU4Y2I4NjBhIiwiZmx5eV9pZCI6ImNwejcyXzgyODZiNGFjLWI4ZjItNDE2OS05MzU0LWE1NWI1MDhiNjQ2MCIsInNjb3JlX2lkIjoiTm9uZSIsImxhc3RfbG9naW4iOiIyMDI0LTA1LTIzVDEwOjA1OjUwLjc1MDk5OCswMDowMCIsImxvZ2luX3R5cGUiOiJhdXRoIiwiZmlyc3RfbmFtZSI6Ik5vbmUiLCJsYXN0X25hbWUiOiJOb25lIiwiY291bnRyeV9pZCI6IjEwMSIsImRldmljZV9pZCI6Ik5vbmUiLCJhY2NvdW50X2V4cGlyeV9kYXRlIjoiMjAyNC0xMi0zMVQwMDowMDowMCswMDowMCIsInBob25lY29kZSI6IjkxIiwicGVybWlzc2lvbnNfYWxsb3dlZCI6WzEwLDIsNCw1LDYsMSwxOCwxM10sInN1YnNjcmlwdGlvbl9kZXRhaWxzIjp7InN1YnNjcmliZWRfcGFja2FnZV9pZCI6IjE3NWQ4OWJhLTBmMmItNDhhMy1iOWQ4LTI5MTIzZDlmMTcyMSIsImNvbXBhbnlfaWQiOiI2OTkxNjNiMy04ZjExLTQwY2YtYjhjMC1iMzY0NzE4MDk2YmIiLCJ2YWxpZF9mcm9tIjoiMjAyNC0wNS0yMyAxMDowMjo1NC4zMTE0MjArMDA6MDAiLCJ2YWxpZF90aWxsIjoiMjAyNC0wNS0zMCAxMDowMjo1NC4zMTE0MjYrMDA6MDAifX0.pXOF_jCpx2a8YWjoKox1RNh6gAs3OxOkbQX-P6N2IUk",
          //         },
          //         body: JSON.stringify({
          //           profile_id: userData.profile_id,
          //           action: "get_fitness_activity",
          //           fit_params: ["STEPS", "CALORIES", "DISTANCE"],
          //         }),
          //         signal: controller.signal,
          //       }
          //     );
          //     const respJSON = await resp.json();
          //     if (respJSON?.statusCode?.toString().startsWith("2")) {
          //       setGFitDataLastUpdated(respJSON?.response);
          //       window.ReactNativeWebView.postMessage(
          //         JSON.stringify({
          //           event: "fetch-googlefit-data",
          //           lastUpdated: respJSON.response[0].last_collection_time,
          //           profile_id: userData.profile_id,
          //         })
          //       );
          //     } else throw new Error(respJSON?.message ?? "Error in Fetching Data");
          //     resolve();
          //   } catch (err) {
          //     reject(err);
          //   }
          // }),
        ]);
        results.forEach((result) => {
          if (result.status === "rejected") console.error(result.reason);
        });
      } catch (err) {
        console.error(err);
      } finally {
        setLoading(false);
      }
    })();
    return () => {
      controller.abort();
    };
  }, [navigate, getToken, userData]);

  const handleNoScanDone = (event) => {
    if (Object.keys(lastScanResult).length === 0) {
      event.preventDefault();
      setModalOpen(true);
    }
  };

  const handlePayWallBlock = (event) => {
    event.preventDefault();
    setFreemiumModalOpen(true);
  };

  const subscribe = async () => {
    try {
      const token = await getToken();
      const resp = await fetch(sdkWrapperURL("/users/add-saved-products"), {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          Authorization: token,
        },
        body: JSON.stringify({
          profile_id: userData.profile_id,
          product_id: "a5a5e1b5-fe4a-4224-b1bb-a37a9c4ca2d6",
          interest_filtration: "ACCEPT",
          application_info: {},
          module_type: "content",
        }),
      });
      const resp_json = await resp.json();
      if (resp_json.statusCode?.toString().startsWith("2")) {
        setSuccessModalOpen(true);
        setFreemiumModalOpen(false);
      } else throw new Error(resp_json.message);
    } catch (err) {
      console.error(err);
    }
  };

  const breakPoints = [
    { width: 1, itemsToShow: 1.5 },
    { width: 550, itemsToShow: 1.5, itemsToScroll: 1, pagination: false },
    { width: 850, itemsToShow: 1.5, itemsToScroll: 1, pagination: false },
  ];
  // const breakPoints2 = [
  //   { width: 1, itemsToShow: 1 },
  //   { width: 550, itemsToShow: 1, itemsToScroll: 1, pagination: false },
  //   { width: 850, itemsToShow: 1 },
  //   { width: 1150, itemsToShow: 4, itemsToScroll: 1 },
  //   { width: 1450, itemsToShow: 5 },
  //   { width: 1750, itemsToShow: 6 },
  // ];
  const breakPoints3 = [
    { width: 1, itemsToShow: 1.1 },
    { width: 550, itemsToShow: 1.1, itemsToScroll: 1, pagination: false },
    { width: 850, itemsToShow: 1.1 },
  ];
  // *** //
  // const settings = {
  //   dots: true,
  //   lazyLoad: true,
  //   infinite: true,
  //   speed: 500,
  //   slidesToShow: 1,
  //   slidesToScroll: 1,
  //   initialSlide: 0,
  //   autoplay: false,
  //   Arrow: false,
  //   autoplaySpeed: 2500,
  //   pauseOnDotsHover: false,
  // };
  // **** //
  const settings2 = {
    dots: false,
    lazyLoad: true,
    infinite: true,
    speed: 1000,
    slidesToShow: 1,
    slidesToScroll: 1,
    initialSlide: 0,
    autoplay: true,
    Arrow: false,
    autoplaySpeed: 2500,
    pauseOnDotsHover: false,
  };
  // *****  Activity **** //
  // const Activity = {
  //   lazyLoad: true,
  //   infinite: false,
  //   speed: 500,
  //   slidesToShow: 1.7,
  //   slidesToScroll: 1,
  //   initialSlide: 0,
  //   autoplay: false,
  //   arrows: false,
  //   autoplaySpeed: 2500,
  //   pauseOnDotsHover: false,
  // };

  return loading ? (
    <LoadingScreen style={{ minHeight: "60vh" }} />
  ) : (
    <section className="pb-24" style={{ overflow: "hidden" }}>
      {/* <div
        className="pl-0"
        data-aos="fade-right"
        data-aos-delay="10"
        data-aos-duration="1000"
        data-aos-offset="10"
        data-aos-easing="ease-in-out"
      >
        <Slider {...settings}>
          <div className="slider-item"> */}
      <div className="mx-auto w-[90%] min-h-[143px] flex items-center space-x-4 px-6 py-4 rounded-xl bg-gradient-transparent text-white">
        <GlowingHeart className="shrink-0 h-28 w-28" />
        <div>
          <p className="flex items-center space-x-2">
            <span className="text-3xl font-bold">
              {careScore > 0 ? careScore : ""}
            </span>
            {careScoreChange !== 0 && (
              <span
                className={`${
                  careScoreChange > 0 ? "text-success" : "text-error"
                } text-xs font-semibold flex items-center`}
              >
                {careScoreChange > 0 ? (
                  <img src={UPScore} alt="" />
                ) : (
                  <img src={DownScore} alt="" />
                )}
                <span className="ml-1">{Math.abs(careScoreChange)}%</span>
              </span>
            )}
          </p>
          <p className="text-secondary font-semibold leading-tight text-[15px]">
            Digital Health Assessment Score
          </p>
          <p className="mt-1 text-xxs font-light leading-snug whitespace-pre-line">
            {careScoreLastUpdated?.length > 0
              ? `Last updated:\n${new Date(
                  careScoreLastUpdated
                ).toLocaleDateString(undefined, {
                  month: "long",
                  day: "numeric",
                  year: "numeric",
                })}, ${new Date(careScoreLastUpdated).toLocaleTimeString(
                  undefined,
                  { hour: "2-digit", minute: "2-digit", hour12: true }
                )}`
              : "No scan data avaiable"}
          </p>
        </div>
      </div>
      {/* </div>
          <div className="slider-item">
            <div className="relative flex items-center justify-between bg-gradient-to-t from-[#5916B1]/0 to-[#B913D4]/75 rounded-[12px] overflow-hidden pr-4 py-4 w-[90%] health-slide">
              <div className="graph-info ml-3">
                <p className="text-white text-sm font-semibold flex mb-1">
                  Health Graphina
                </p>

                <p className="text-[9px] text-white flex items-center mt-3 mb-1">
                  <span className="w-[8px] h-[8px] rounded-full bg-[#DB822F] mr-[5px]"></span>
                  &nbsp;You
                </p>
                <p className="text-[9px] text-white flex items-center mb-4">
                  <span className="w-[8px] h-[8px] rounded-full bg-[#3266FF] mr-[5px]"></span>
                  &nbsp;Everyone else
                </p>
                <p className="text-[#9650FF] text-[9px]">
                  {careScoreLastUpdated?.length > 0
                    ? `Last updated ${new Date(
                        careScoreLastUpdated
                      ).toLocaleDateString(undefined, {
                        month: "long",
                        day: "numeric",
                        year: "numeric",
                      })}`
                    : "You haven't done any scan yet"}
                </p>
              </div>
              <div className="w-[58%] relative" data-aos="zoom-in">
                <div className="relative z-40">
                  <HealthRaderChart data={graphinaData} />
                </div>
              </div>
              {Object.keys(graphinaData).length < 1 && (
                <div className="absolute top-0 bottom-0 right-0 left-0 flex justify-center items-center bg-[#9650FF] text-sm font-medium">
                  You haven't done any scan yet
                </div>
              )}
            </div>
          </div>
          <div className="slider-item">
            <div className="flex justify-center bg-gradient-to-t from-[#5916B1]/0 to-[#5033FF]/75 rounded-[12px] pr-4 py-4 w-[90%] min-h-[143px] health-slide">
              <CarePoints streak={streak} isScanDoneToday={isScanDoneToday} />
            </div>
          </div>
          <div className="slider-item">
            <div className="relative flex flex-col justify-center bg-gradient-to-t from-[#812EFD]/0 to-[#2970DA]/75 rounded-[12px] overflow-hidden py-1 w-[90%] min-h-[143px] health-slide">
              <DailyMood
                selected={selectedMoodResponse}
                filledDate={surveyFilledDate}
              />
              {selectedMoodResponse === -1 && (
                <div className="absolute top-0 bottom-0 right-0 left-0 flex justify-center items-center bg-[#9650FF] text-sm font-medium">
                  No Mood Data Available
                </div>
              )}
            </div>
          </div>
        </Slider>
      </div> */}
      {/* Digital Health Assessment Score modal */}
      {/* <Transition appear show={isOpen} as={Fragment}>
        <Dialog as="div" className="relative z-10" onClose={closeModal}>
          <Transition.Child
            as={Fragment}
            enter="ease-out duration-300"
            enterFrom="opacity-0"
            enterTo="opacity-100"
            leave="ease-in duration-200"
            leaveFrom="opacity-100"
            leaveTo="opacity-0"
          >
            <div className="fixed inset-0 bg-black bg-opacity-25" />
          </Transition.Child>

          <div className="fixed inset-0 overflow-y-auto">
            <div className="flex min-h-full items-center justify-center p-4 text-center">
              <Transition.Child
                as={Fragment}
                enter="ease-out duration-300"
                enterFrom="opacity-0 scale-95"
                enterTo="opacity-100 scale-100"
                leave="ease-in duration-200"
                leaveFrom="opacity-100 scale-100"
                leaveTo="opacity-0 scale-95"
              >
                <Dialog.Panel className="w-full max-w-md transform overflow-hidden rounded-2xl bg-white p-6 text-left align-middle shadow-xl transition-all">
                  <div className="w-full flex flex-col justify-center items-center">
                    <p className="text-[12px] text-primary">
                      My Digital Health Assessment Score is a numerical measurement of an
                      individual's overall well-being, considering various
                      aspects of physical, mental, and emotional health. A
                      'good' well-being score typically refers to a score that
                      indicates a high level of well-being, with low levels of
                      stress, anxiety, and negative emotions, and high levels of
                      physical and mental energy, resilience, and
                      satisfaction with life.
                    </p>
                    <Button
                      className="w-[90px] bg-gradient text-white rounded-full mt-5 text-[12px] font-normal h-[35px] p-0 tracking-wider capitalize"
                      onClick={closeModal}
                    >
                      Close
                    </Button>
                  </div>
                </Dialog.Panel>
              </Transition.Child>
            </div>
          </div>
        </Dialog>
      </Transition> */}

      {/* <div className="flex items-center justify-center mt-1 mb-2 health-icon-col px-6 flex-wrap"> */}
      {/* <button
          onClick={handleVitalScore}
          className="flex items-center justify-start flex-col w-[25%] mb-3"
          data-aos="fade-left"
          data-aos-duration="1000"
        >
          <span className="Health-icon">
            <img src={HealthScore} alt="Health Score" />
          </span>
          <h3 className="text-xs font-semibold">56%</h3>
          <p>Vitals Index</p>
        </button>

        <button
          onClick={handleMentalWellbeing}
          className="flex items-center justify-start flex-col w-[25%] mb-3"
          data-aos="fade-left"
          data-aos-duration="1000"
        >
          <span className="Health-icon">
            <img src={WelbingScore} alt="Mental Wellbeing Score" />
          </span>
          <h3 className="text-xs font-semibold">22%</h3>
          <p>
            Mental Wellbeing
            <br /> Score
          </p>
        </button>

        <button
          onClick={handleDiabetes}
          className="flex items-center justify-start flex-col w-[25%] mb-3"
          data-aos="fade-left"
          data-aos-duration="1000"
        >
          <span className="Health-icon">
            <img src={DiabetesScore} alt="Diabetes Score" />
          </span>
          <h3 className="text-xs font-semibold">89%</h3>
          <p>
            Diabetes Control
            <br /> Score
          </p>
        </button>
        <button
          onClick={handleHeartScore}
          className="flex items-center justify-start flex-col w-[25%] mb-3"
          data-aos="fade-left"
          data-aos-duration="1000"
        >
          <span className="Health-icon">
            <img src={CardiacScore} alt="Cardiac Score" />
          </span>
          <h3 className="text-xs font-semibold">80%</h3>
          <p>Heart Health</p>
        </button> */}

      {/* <Link
          to=""
          className="flex items-center justify-start flex-col w-[25%]"
          data-aos="fade-left"
          data-aos-duration="1000"
        >
          <span className="Health-icon">
            <img src={Nutrition} alt="Nutrition" />
          </span>
          <h3 className="text-xs font-semibold">78%</h3>
          <p>
            Nutrition In-take
            <br /> Score
          </p>
        </Link>

        <Link
          to="/Activity"
          className="flex items-center justify-start flex-col w-[25%]"
          data-aos="fade-left"
          data-aos-duration="1000"
        >
          <span className="Health-icon">
            <img src={LifeStyleScore} alt="Lifestyle Score" />
          </span>
          <h3 className="text-xs font-semibold">89%</h3>
          <p>Activity Score</p>
        </Link> */}
      {/* </div> */}
      {/* <div className="px-6">
        <Link
          to="/health-challenge"
          className="rounded-[12px] overflow-hidden relative outline-none border-spacing-0 p-0 mb-0 h-[100px] justify-between text-[14px] text-white flex items-center font-normal capitalize w-full"
          // style={{
          //   backgroundImage: `url(${})`,
          //   backgroundPosition: "center",
          //   backgroundSize: "cover",
          //   backgroundRepeat: "no-repeat",
          // }}
        >
          <img src={WeekelyChallengeImage} alt="" className="w-full" /> */}
      {/* <div className="flex items-center ml-3">
            <img src={Target} alt="" width="40" />
            <div className=" flex flex-col items-start justify-start">
              <p className="pl-2 font-medium">Weekly Challenges</p>
              <p className="text-[10px] text-white/[0.8] pl-2">
                Take on different challenges and earn care coins.
              </p>
            </div>
          </div> */}
      {/* <img src={NextArrow} alt="" className="mr-3" /> */}
      {/* </Link>
      </div> */}

      <Link
        to="/start-scan"
        className="my-3 flex items-center space-x-4 px-6 py-4 rounded-2xl bg-gradient-transparent text-white w-[90%] mx-auto"
      >
        <div>
          <h3 className="text-sm font-bold leading-tight tracking-tight">
            Scan vitals to personalize your health experience
          </h3>
          <p className="mt-1.5 text-xxs leading-tight tracking-tight">
            Do a self-health check in
            <br />
            less than one minute.
          </p>
          <p className="max-w-40 mt-3 px-4 py-2 rounded-full bg-white text-secondary text-sm text-center font-medium">
            Start Scan
          </p>
        </div>
        <img
          className="shrink-0 h-[7.5rem]"
          src={StartScanBanner}
          alt="Banner"
        />
      </Link>

      {/* <div
        className="px-6"
        data-aos="fade-up"
        data-aos-duration="1000"
        data-aos-delay="200"
      >
        <h2 className="text-white text-sm font-medium flex justify-between items-center mb-4 mt-4">
          Digital Health Scan
        </h2>
        <div className="flex items-center justify-between">
          <Link
            className="p-4 flex flex-col rounded-[25px] bg-[#e8f3e9] w-[48%] health-box border border-[#ddeedf]"
            to="/scan-instructions"
            state={{ scanType: "face" }}
          >
            <img
              className="flex-shrink-0 h-8 w-8 mb-3"
              src={FaceScan}
              alt="face-scan"
            />
            <div>
              <h4 className="text-white text-sm font-semibold">Face Scan</h4>
              <p
                className="mt-1 text-darkgray text-xxs"
                style={{ lineHeight: "12px" }}
              >
                Selfie-based vitals in 60 seconds
              </p>
            </div>
          </Link>
          <Link
            className="p-4 rounded-[25px] flex flex-col bg-[#e0d9ff] w-[48%] health-box border border-[#d8d0f9]"
            to="/scan-instructions"
            state={{ scanType: "finger" }}
          >
            <img
              className="flex-shrink-0 h-8 w-8 mb-3"
              src={FingerScan}
              alt="finger-scan"
            />
            <div>
              <h4 className="text-white text-sm font-semibold">Finger Scan</h4>
              <p
                className="mt-1 text-darkgray text-xxs"
                style={{ lineHeight: "12px" }}
              >
                Finger-based vitals in 60 seconds
              </p>
            </div>
          </Link>
        </div>
      </div> */}

      {/* <div
        className="px-6 scan-col"
        data-aos="fade-right"
        data-aos-delay="150"
        data-aos-duration="1000"
        data-aos-easing="ease-in-out"
      >
        <Link
          to="/select-option"
          className="mb-3 bg-gradient-to-r from-secondary to-white text-white rounded-xl flex items-center justify-between"
        >
          <img
            className="self-end flex-shrink-0 h-20"
            src={BannerWoman}
            alt="Banner"
          />
          <div className="mx-1.5 space-y-1">
            <h3 className="text-xs font-semibold">Know Your Health</h3>
            <p className="text-xxs leading-tight">
              Do a self-health check in less than one minute.
            </p>
          </div>
          <div className="flex-shrink-0 flex items-center space-x-1 rounded-full mr-3 px-2 py-1 bg-white text-white">
            <span className="flex-shrink-0 text-xxs font-medium">
              Start A Scan
            </span>
            <Arrow className="flex-shrink-0 h-2.5 w-2.5" />
          </div>
        </Link>
      </div> */}
      {/* {lastScanResult.created_at?.length > 0 && (
        <Link
          to="/scan-result"
          state={{
            result: {
              scan_id: lastScanResult.scan_id,
              posture: lastScanResult.posture,
              wellness_score: lastScanResult.wellness_score,
              vitals: lastScanResult.vitals,
              metadata: {
                heart_scores: lastScanResult.heart_scores,
                cardiovascular: lastScanResult.cardiovascular,
                physiological_scores: lastScanResult.physiological_scores,
              },
            },
          }}
          className="flex items-center justify-between py-4 px-6 rounded-xl bg-[#2B2B2B] text-white"
        >
          <span className="text-sm">View Last Assessment Result</span>
          <Chevron className="flex-shrink-0 h-4 w-4 -rotate-90" />
        </Link>
      )} */}
      {/* daily wel-bing */}
      {/* <h2
        className="text-white mb-1 text-sm font-medium flex justify-between items-center pl-6 mt-6"
        data-aos="fade-right"
        data-aos-duration="1000"
        data-aos-delay="200"
      >
        Daily Well-being
      </h2> */}

      <div
        className="flex justify-between items-center px-6"
        data-aos="fade-left"
        data-aos-duration="1000"
      >
        <Link
          to="/my-health"
          state={{
            lastScanResult,
            allScore: scoreData?.filter?.((v) => v.scoreID !== "C004"),
          }}
          onClick={handleNoScanDone}
          className={`rounded-[12px] overflow-hidden relative outline-none bg-gradient-to-b from-transparent to-[#0A39C3]/50 border-spacing-0 p-3 mb-3 h-[115px] text-[14px] text-white flex flex-col font-normal capitalize w-[47%] ${
            Object.keys(lastScanResult)?.length === 0 && "opacity-50"
          }`}
        >
          <div className="flex justify-between">
            <span className="w-12 h-12 p-2 rounded-full bg-gradient-to-t from-transparent to-[#3866ED] flex justify-center items-center">
              <img src={MyHealth} alt="" className="w-full h-full" />
            </span>
            <img src={NextArrow} alt="" className="w-5 h-5" />
          </div>
          <p className="font-light font-sm mt-3">Vitality Hub</p>
        </Link>
        <Link
          to="/My-mind"
          state={{
            stressIndexData: {
              stress_index: lastScanResult.heart_scores?.stress_index ?? 0,
              rmssd: lastScanResult.heart_scores?.rmssd ?? "--",
              heart_rate: lastScanResult.vitals?.heart_rate ?? "--",
              bp_sys: lastScanResult.vitals?.bp_sys ?? "--",
              bp_dia: lastScanResult.vitals?.bp_dia ?? "--",
            },
            surveyID: surveyId,
            mentalData: scoreData?.find?.((v) => v.scoreID === "C004"),
          }}
          onClick={
            packageName === "FREEMIUM" ? handlePayWallBlock : handleNoScanDone
          }
          className={`rounded-[12px] overflow-hidden relative outline-none bg-gradient-to-b from-transparent to-[#389CF9]/50 border-spacing-0 p-3 mb-3 h-[115px] text-[14px] text-white flex flex-col font-normal capitalize w-[47%] ${
            Object.keys(lastScanResult)?.length === 0 && "opacity-50"
          } ${packageName === "FREEMIUM" ? "grayscale" : ""}`}
        >
          <div className="flex justify-between">
            <span className="w-12 h-12 p-2 rounded-full bg-gradient-to-t from-transparent to-[#5991AA] flex justify-center items-center">
              <img src={MoodTrack} alt="" className="w-full h-full" />
            </span>

            <img src={NextArrow} alt="" className="w-5 h-5" />
          </div>
          <p className="font-light font-sm mt-3">Mind Matters Hub</p>
        </Link>
      </div>

      {/* My Google fit */}
      {/* <div className="mt-0" data-aos="fade-left" data-aos-duration="1000">
        <div className="flex items-center justify-between px-6 mb-2">
          <h2 className="text-white text-sm font-medium">
            Health Overview
            <br />
            <Link>
              <p className="date-time">
                <span className="text-[#8731FC]">Last Updated:</span>{" "}
                {`${new Date(
                  gFitDataLastUpdated?.[2]?.last_collection_time
                ).toLocaleDateString(undefined, {
                  month: "long",
                  day: "numeric",
                  year: "numeric",
                })}, ${new Date(
                  gFitDataLastUpdated?.[2]?.last_collection_time
                ).toLocaleTimeString(undefined, {
                  hour: "2-digit",
                  minute: "2-digit",
                  hour12: true,
                })}`}{" "}
                &#x3e;
              </p>
            </Link>
          </h2>
        </div>
        <div className="mb-3">
          <Slider {...Activity}>
            {gFitDataLastUpdated?.map((item) => (
              <div
                className="ml-6 pr-2"
                onClick={() =>
                  window.ReactNativeWebView.postMessage(
                    JSON.stringify({
                      event: "view-googlefit-data",
                      data: item.fitness_parameter,
                      value: item.fitness_data[0].value,
                      lastUpdated: item?.last_collection_time,
                      profile_id: userData.profile_id,
                    })
                  )
                }
              >
                <div className="flex justify-start flex-col bg-gradient-to-t from-[#331879]/10 to-[#5517A7]/50 rounded-xl px-4 py-3 w-[90%]">
                  <p className="text-sm tetx-white font-light text-left mb-2">
                    {item.fitness_parameter === "CALORIES"
                      ? "Calories"
                      : item.fitness_parameter === "DISTANCE"
                      ? "Distance"
                      : "Steps"}
                  </p>

                  <div className="flex items-center">
                    {" "}
                    <span className="">
                      <img
                        src={
                          item.fitness_parameter === "CALORIES"
                            ? Energy
                            : item.fitness_parameter === "DISTANCE"
                            ? Distance
                            : Walking
                        }
                        alt="Walking"
                        className="h-6 mr-3"
                      />
                    </span>
                    {item.fitness_parameter === "CALORIES" ? (
                      <h4 className="text-xs font-semibold">
                        <span className="text-[#FFC400]">
                          {(item.fitness_data[0].value / 1000).toFixed(2)}
                        </span>
                        /5000 kcal
                      </h4>
                    ) : item.fitness_parameter === "DISTANCE" ? (
                      <h4 className="text-xs font-semibold">
                        <span className="text-[#2970DA]">
                          {(item.fitness_data[0].value / 1000).toFixed(2)}
                        </span>
                        /20 km
                      </h4>
                    ) : (
                      <h4 className="text-xs font-light">
                        <span className="text-[#812EFD]">
                          {item.fitness_data[0]?.value?.toFixed(0)}
                        </span>
                        /10000 steps
                      </h4>
                    )}
                  </div>
                </div>
              </div>
            ))}
          </Slider>
        </div>
      </div> */}
      {/* My Google fit  End*/}

      {/* ABHA ID */}
      {/* <div className="px-6" data-aos="fade-right" data-aos-duration="1000">
        <div className="abha-col flex">
          <img src={ABHA} alt="ABHA" />
          <div className="Abha-info">
            <h3>Learn about ABHA ID</h3>
            <p>
              Know more about Ayushman Bharat Health Account & create your ABHA
              ID today.
            </p>
            <Link to="/Abha-Details" className="learn-btn">
              Learn More <Arrow className="flex-shrink-0 h-2.5 w-2.5" />
            </Link>
          </div>
        </div>
      </div> */}
      {/* ABHA ID end */}
      <div className="px-6">
        <Slider {...settings2}>
          <div className="slider-item">
            <Link
              to="/content-tabs"
              state={{ name: "Breath Lab", listID: 18 }}
              className={`rounded-[12px] px-4 breathing-btn mx-[2px] h-[145px] justify-between text-[14px] text-white flex items-center font-normal capitalize w-full ${
                packageName === "FREEMIUM" ? "grayscale" : ""
              }`}
              onClick={
                packageName === "FREEMIUM" ? handlePayWallBlock : undefined
              }
              style={{
                backgroundImage: `url(${BreathLab})`,
                backgroundPosition: "center",
                backgroundSize: "cover",
                backgroundRepeat: "no-repeat",
              }}
            >
              <div className="flex items-center font-medium text-xl">
                Breath Lab
              </div>
            </Link>
          </div>
          {/* <div className="slider-item">
            <Link
              to="/digital-clinic"
              state={{ mskUrl: muscleUrl }}
              className="rounded-[12px] px-4 breathing-btn h-[145px] mx-[2px] justify-between text-[14px] text-white flex items-center font-normal capitalize w-full"
              style={{
                backgroundImage: `url(${MuscleCare})`,
                backgroundPosition: "center",
                backgroundSize: "cover",
                backgroundRepeat: "no-repeat",
              }}
            >
              <div className="w-[65%]">
                <h3 className="text-base font-semibold text-white">
                  Digital Clinic for
                  <br /> Joint & Muscle Care
                </h3>
                <p className="text-xs text-white leading-[18px] mt-3">
                  The next-generation MSK solution that integrates into the
                  existing care landscape.
                </p>
              </div>
            </Link>
          </div> */}
          <div className="slider-item">
            <Link
              to="/relive-pain"
              className={`rounded-[12px] px-4 breathing-btn h-[145px] mx-[2px] justify-between text-[14px] text-white flex items-center font-normal capitalize w-full ${
                packageName === "FREEMIUM" ? "grayscale" : ""
              }`}
              onClick={
                packageName === "FREEMIUM" ? handlePayWallBlock : undefined
              }
              style={{
                backgroundImage: `url(${RelivePain})`,
                backgroundPosition: "center",
                backgroundSize: "cover",
                backgroundRepeat: "no-repeat",
              }}
            >
              <div className="w-[65%]">
                <h3 className="text-base font-semibold text-white mb-8">
                  Relieve Pain
                </h3>
                <p className="text-xs text-white leading-[18px]">
                  Find relief from pain and reclaim comfort with targeted
                  solutions.
                </p>
              </div>
            </Link>
          </div>
          {/* <div className="slider-item">
            <Link
              to="/cognitive-score"
              className="rounded-[12px] px-4 breathing-btn h-[145px] mx-[2px] justify-between text-[14px] text-white flex items-center font-normal capitalize w-full"
              style={{
                backgroundImage: `url(${Cognitive})`,
                backgroundPosition: "center",
                backgroundSize: "cover",
                backgroundRepeat: "no-repeat",
              }}
            >
              <div className="w-[65%]">
                <h3 className="text-base font-semibold text-white">
                  Cognitive
                  <br />
                  Assessments
                </h3>
                <p className="text-xxs text-white mt-3">
                  A cognitive assessment evaluates an individual's cognitive
                  abilities, aiding in diagnosis and intervention strategies for
                  cognitive impairments.
                </p>
              </div>
            </Link>
          </div> */}
        </Slider>
      </div>

      <div
        className="wellness-col mb-0 mt-2"
        data-aos="fade-right"
        data-aos-duration="1000"
      >
        <div className="px-6 flex items-center justify-between">
          <h2 className="mb-2 text-secondary text-sm font-medium">
            Do you know?
          </h2>
          <Link
            to="/do-you-know"
            state={{ list: dykList }}
            className="text-ternary text-sm font-semibold"
          >
            View All
          </Link>
        </div>

        <Carousel
          className="mb-2"
          breakPoints={breakPoints3}
          showArrows={false}
          pagination={false}
        >
          {dykList.slice(0, 5).map((dyk) => (
            <Link
              key={dyk.product_id}
              to="/dyk-detail"
              state={{
                name: dyk.product_name,
                content: dyk.structure_val,
              }}
              className="h-32 mr-3 p-4 overflow-hidden rounded-xl bg-white flex items-center space-x-3"
            >
              <div className="relative shrink-0 h-20 w-20">
                <img
                  src={dyk.structure_val.content_thumbnail}
                  alt="Thumbnail"
                  className="h-full w-full rounded-xl object-cover"
                />
                <div className="absolute top-4 -left-1.5 h-4 w-4 rounded-full bg-secondary" />
              </div>
              <div className="grow flex flex-col items-stretch justify-between">
                <h4 className="line-clamp-2 text-secondary text-xs leading-tight font-medium">
                  {dyk.structure_val.heading}
                </h4>
                <p className="my-2 line-clamp-3 text-[8px] text-primary leading-tight">
                  {dyk.structure_val.meta_description}
                </p>
                <p className="text-secondary text-xs">View Details &gt;</p>
              </div>
            </Link>
          ))}
        </Carousel>
      </div>

      {/* wellness score */}
      {/* <div
        className="wellness-col mb-6 mt-5"
        data-aos="fade-right"
        data-aos-duration="1000"
      >
        <h2 className="text-white mb-3 text-sm font-medium flex justify-between items-center pl-6">
          My Scores & Insights
        </h2>
        <div className="scroll-col">
          <div className="fle-scroll">
            <Carousel
              data-aos="fade-right"
              data-aos-duration="1000"
              breakPoints={breakPoints}
              showArrows={false}
              pagination={false}
            >
              <Link to="/vitals-index">
                <WellnessCard
                  img={VitalsIndex}
                  tittle="Vitals Index"
                  subtittle="Complete the assessment to get your mental wellbeing score."
                  color="rgba(211, 79, 118, 0.8)"
                />
              </Link>

              <Link to="/heart-score">
                <WellnessCard
                  img={HealthHeart}
                  tittle="Heart Health"
                  subtittle="Find out if your nutrition in-take is sufficient."
                  color="rgba(221, 187, 13, 0.8)"
                />
              </Link>

              <Link to="/diabetes-insight">
                <WellnessCard
                  img={DiabetesInsights}
                  tittle="Diabetes Insights"
                  subtittle="Healthy habits tracks your daily activity."
                  color="rgba(40, 129, 171, 0.8)"
                />
              </Link>

              <Link to="/mental-wellbing">
                <WellnessCard
                  img={MentalWellbing}
                  tittle="Mental Well-being"
                  subtittle="Complete the assessment to get your mental wellbeing score."
                  color="rgba(208, 80, 31, 0.8)"
                />
              </Link>
              <Link to="/my-care">
                <WellnessCard
                  img={Mycare}
                  tittle="My Digital Health Assessment Score™"
                  subtittle="My Digital Health Assessment Score is a numerical measurement of an individual's"
                  color="rgba(25, 40, 82, 0.8)"
                />
              </Link>
              
            </Carousel>
          </div>
        </div>
      </div> */}
      {/*  */}
      {/* <div
        className="wellness-col mb-5 mt-5"
        data-aos="fade-left"
        data-aos-duration="1000"
      >
        <h2 className="text-white mb-2 mt-7 text-sm font-medium flex justify-between items-center pl-6">
          What would you like to focus on today?
        </h2>
        <div className="scroll-col">
          <div className="fle-scroll">
            <Carousel
              breakPoints={breakPoints}
              showArrows={false}
              pagination={false}
            >
              <Link to="/Wellbing-details">
                <WellnessCard
                  img={MindBody}
                  tittle="Meditaton"
                  subtittle="A comprehensive wellness program featuring nutrition ...."
                  color="rgba(113, 197, 197, 0.8)"
                />
              </Link>
              <Link to="">
                <WellnessCard
                  img={Nourish}
                  tittle="Better Sleep"
                  subtittle="A holistic wellness program including personalized nutrition ..."
                  color="rgba(214, 71, 102, 0.8)"
                />
              </Link>
              <Link to="/Breath-lab">
                <WellnessCard
                  img={BreathLab}
                  tittle="Breath Lab"
                  subtittle="A program centered on the power of breathwork and ...."
                  color="rgba(118, 186, 47, 0.8)"
                />
              </Link>
              <Link to="/">
                <WellnessCard
                  img={RadiantHealth}
                  tittle="Nutriment"
                  subtittle="A program aimed at achieving optimal health and wellness ....."
                  color="rgba(31, 40, 55, 0.8)"
                />
              </Link>
              <Link to="/Analysis-Data">
                <WellnessCard
                  img={HeartCare}
                  tittle="Heart Care"
                  subtittle="A program aimed at achieving optimal health and wellness ....."
                  color="rgba(255, 153, 102, 0.8)"
                />
              </Link>
            </Carousel>
          </div>
        </div>
      </div> */}
      {/* wellness score end*/}
      {/* Digital Therapeutics */}
      {/* <div
        className="wellness-col mb-5 mt-5"
        data-aos="fade-left"
        data-aos-duration="1000"
      >
        <h2 className="text-white mb-2 text-sm font-medium flex justify-between items-center pl-6">
          Digital Therapeutics
        </h2>
        <div className="scroll-col">
          <div className="fle-scroll">
            <Carousel
              breakPoints={breakPoints}
              showArrows={false}
              pagination={false}
            >
              <Link to="/">
                <WellnessCard
                  img={Disorder}
                  tittle="Depressive Disorder"
                  subtittle="Complete the assessment to get your mental wellbeing score."
                  color="rgba(204, 102, 51, 0.8)"
                />
              </Link>

              <Link to="/">
                <WellnessCard
                  img={Anxiety}
                  tittle="Anxiety & Stress"
                  subtittle="Stress can be defined as a state of worry or mental tension"
                  color="rgba(221, 187, 13, 0.8)"
                />
              </Link>

              <Link to="/">
                <WellnessCard
                  img={Cholesterol}
                  tittle="High Cholesterol"
                  subtittle="Healthy habits tracks your daily activity."
                  color="rgba(40, 129, 171, 0.8)"
                />
              </Link>

              <Link to="/mental-wellbing">
                <WellnessCard
                  img={Weight}
                  tittle="Weight Management"
                  subtittle="Welcome to our 12 week weight management programme"
                  color="rgba(51, 0, 0, 0.8)"
                />
              </Link>
              <Link to="/my-care">
                <WellnessCard
                  img={Respiratory}
                  tittle="Respiratory"
                  subtittle="Respiratory distress may ensue, characterised by an increase"
                  color="rgba(25, 40, 82, 0.8)"
                />
              </Link>
              <Link to="/my-care">
                <WellnessCard
                  img={Hypertension}
                  tittle="Hypertension"
                  subtittle="Hypertension (high blood pressure) is when the pressure in your blood"
                  color="rgba(113, 197, 197, 0.8)"
                />
              </Link>
              <Link to="/my-care">
                <WellnessCard
                  img={Diabetes}
                  tittle="Type 2 Diabetes"
                  subtittle="Complete the assessment to get your mental wellbeing score."
                  color="rgba(51, 51, 204, 0.8)"
                />
              </Link>
              <Link to="/my-care">
                <WellnessCard
                  img={Polycystic}
                  tittle="Polycystic Ovary Syndrome"
                  subtittle=""
                  color="rgba(51, 102, 153, 0.8)"
                />
              </Link>
              <Link to="/my-care">
                <WellnessCard
                  img={FattyLiver}
                  tittle="Non-alcoholic fatty liver disease"
                  subtittle=""
                  color="rgba(25, 40, 82, 0.8)"
                />
              </Link>
            </Carousel>
          </div>
        </div>
      </div> */}
      {/* Digital Therapeutics end */}

      {/* pain */}
      <div
        className="wellness-col mb-3"
        data-aos="fade-left"
        data-aos-duration="1000"
      >
        <h2 className="text-[#812EFD] mb-1 text-sm font-medium flex justify-between items-center pl-6">
          Daily well-being
        </h2>
        <div className="fle-scroll">
          <Carousel
            breakPoints={breakPoints}
            showArrows={false}
            pagination={false}
          >
            <div className="w-full mr-3">
              <Link
                to="/fitness-package"
                className={`rounded-[12px] px-4 breathing-btn h-[145px] justify-between text-[14px] text-white flex items-center font-normal capitalize w-full ${
                  packageName === "FREEMIUM" ? "grayscale" : ""
                }`}
                onClick={
                  packageName === "FREEMIUM" ? handlePayWallBlock : undefined
                }
                style={{
                  backgroundImage: `url(${FitnessCard})`,
                  backgroundPosition: "center",
                  backgroundSize: "cover",
                  backgroundRepeat: "no-repeat",
                }}
              >
                <div className="w-full">
                  <h3 className="text-base font-semibold text-white mb-8">
                    Fitness
                  </h3>
                  <p className="text-sm text-white leading-[18px]">
                    Push yourself without exceeding your limits.
                  </p>
                </div>
              </Link>
            </div>
            <div className="w-full mr-3">
              <Link
                to="/wellbing"
                className={`rounded-[12px] px-4 breathing-btn h-[145px] justify-between text-[14px] text-white flex items-center font-normal capitalize w-full ${
                  packageName === "FREEMIUM" ? "grayscale" : ""
                }`}
                onClick={
                  packageName === "FREEMIUM" ? handlePayWallBlock : undefined
                }
                style={{
                  backgroundImage: `url(${WellbeingCard})`,
                  backgroundPosition: "center",
                  backgroundSize: "cover",
                  backgroundRepeat: "no-repeat",
                }}
              >
                <div className="w-full">
                  <h3 className="text-base font-semibold text-white mb-8">
                    Mental Wellbeing
                  </h3>
                  <p className="text-sm text-white leading-[18px]">
                    Enhance your mental
                    <br /> health and your resilience.
                  </p>
                </div>
              </Link>
            </div>
            <div className="w-full mr-3">
              <Link
                to="/nutration-list"
                state={{ activity: selectedActivityResponse }}
                className={`rounded-[12px] px-4 breathing-btn h-[145px] justify-between text-[14px] text-white flex items-center font-normal capitalize w-full ${
                  packageName === "FREEMIUM" ? "grayscale" : ""
                }`}
                onClick={
                  packageName === "FREEMIUM" ? handlePayWallBlock : undefined
                }
                style={{
                  backgroundImage: `url(${NutrationCard})`,
                  backgroundPosition: "center",
                  backgroundSize: "cover",
                  backgroundRepeat: "no-repeat",
                }}
              >
                <div className="w-full">
                  <h3 className="text-base font-semibold text-white mb-8">
                    Nutrition
                  </h3>
                  <p className="text-sm text-white leading-[18px]">
                    Your health starts with
                    <br /> what you eat.
                  </p>
                </div>
              </Link>
            </div>
            <div className="w-full mr-3">
              <Link
                to="/better-sleep"
                className={`rounded-[12px] px-4 breathing-btn h-[145px] justify-between text-[14px] text-white flex items-center font-normal capitalize w-full ${
                  packageName === "FREEMIUM" ? "grayscale" : ""
                }`}
                onClick={
                  packageName === "FREEMIUM" ? handlePayWallBlock : undefined
                }
                style={{
                  backgroundImage: `url(${SleepCard})`,
                  backgroundPosition: "center",
                  backgroundSize: "cover",
                  backgroundRepeat: "no-repeat",
                }}
              >
                <div className="w-full">
                  <h3 className="text-base font-semibold text-white mb-8">
                    Better Sleep
                  </h3>
                  <p className="text-sm text-white leading-[18px]">
                    Enhance the quality of
                    <br /> your sleep.
                  </p>
                </div>
              </Link>
            </div>
          </Carousel>
        </div>
      </div>
      {/* Rewards */}
      <div className="px-6 w-full">
        {/* <button
          type="button"
          onClick={(e) => {
            if (Object.keys(lastScanResult).length === 0) {
              e.preventDefault();
              setModalOpen(true);
            } else if (
              typeof window?.ReactNativeWebView?.postMessage === "function"
            )
              window.ReactNativeWebView.postMessage(
                JSON.stringify({
                  event: "view-rewards",
                  data: { userID: userData.flyy_id },
                })
              );
            else {
              window.flyySDK?.openRewardsScreen({ fullScreen: true });
            }
          }}
          className={`w-full h-[145px] mb-5 px-4 rounded-xl text-white text-right text-xl font-medium ${
            Object.keys(lastScanResult)?.length === 0 && "opacity-50"
          }`}
          style={{
            backgroundImage: `url(${RewardCard})`,
            backgroundPosition: "center",
            backgroundSize: "cover",
            backgroundRepeat: "no-repeat",
          }}
        >
          Rewards & Offers
        </button> */}
        <div
          className={`bg-gradient-to-t from-transparent to-[#255F82] p-4 flex justify-between items-start rounded-xl ${
            Object.keys(lastScanResult)?.length === 0 && "opacity-50"
          }`}
        >
          <div className="mr-4">
            <h4 className="mb-3 text-white font-semibold">
              Exclusive Discounts on Diagnostic Labs & Pharmacy
            </h4>
            <Link
              to="/diagnostic-packages"
              onClick={(e) => {
                if (Object.keys(lastScanResult).length === 0) {
                  e.preventDefault();
                  setModalOpen(true);
                } else logAnalyticsEvent("diagnostic_packages_banner");
              }}
              className="block w-full py-2 px-4 rounded-full outline-none bg-[#106EBF] text-white text-center text-xs font-medium"
            >
              View Offers
            </Link>
          </div>
          <img
            className="shrink-0 w-1/4"
            src={DiagnosticIcon}
            alt="Diagnostic Packages"
          />
        </div>
        <div
          className={`bg-gradient-to-t from-transparent to-[#315DDD] mt-6 p-4 flex justify-between items-start rounded-xl ${
            Object.keys(lastScanResult)?.length === 0 && "opacity-50"
          } ${packageName === "FREEMIUM" ? "grayscale" : ""}`}
        >
          <div className="mr-4">
            <h4 className="mb-3 text-white font-semibold">
              Connect with Our In-Network Doctors and Professionals
            </h4>
            <Link
              to="/doctor-consultation"
              onClick={(e) => {
                if (packageName === "FREEMIUM") handlePayWallBlock(e);
                else {
                  if (Object.keys(lastScanResult).length === 0) {
                    e.preventDefault();
                    setModalOpen(true);
                  } else logAnalyticsEvent("doctor_consultation_banner");
                }
              }}
              className="block w-full py-2 px-4 rounded-full outline-none bg-[#5716A9] text-white text-center text-xs font-medium"
            >
              Find a Doctor or Specialist
            </Link>
          </div>
          <img
            className="self-end -mb-4 shrink-0 w-1/4"
            src={DoctorConsultation}
            alt="Doctor Consultation"
          />
        </div>
      </div>

      {/* Reward end */}
      {/* Consult A Doctor */}
      {/* <div
        className="consult-col px-6 relative"
        data-aos="fade-right"
        data-aos-duration="1000"
      >
        <div className="flex items-center mb-2">
          <img src={HealthIcon} width="50" alt="health icon" />
          <div className="ml-2">
            <h4 className="text-sm font-medium">CarePlix</h4>
            <h2 className="text-[18px] text-white font-bold mt-[-5px]">
              Health
            </h2>
          </div>
        </div>
        <div className="w-full flex flex-wrap justify-between">
          <div className="w-[48%]">
            <Link to="http://13.127.253.127/talk-to-a-doctor/" target="_blank">
              <HealthCard
                img={ConsultDoctor}
                tittle="Talk to a Doctor"
                subtittle={"Healthcare whenever\n you need"}
              />
            </Link>
          </div>
          <div className="w-[48%]">
            <Link to="http://13.127.253.127/appointment/" target="_blank">
              <HealthCard
                img={FindDoctor}
                tittle="Find Doctor"
                subtittle="Choose your doctor based on speciality/symptoms"
              />
            </Link>
          </div>
          <div className="w-[48%]">
            <Link to="/Package-list">
              <HealthCard
                img={HealthPackage}
                tittle="Wellness Packages"
                subtittle="You will find exactly what you need"
              />
            </Link>
          </div>
          <div className="w-[48%]">
            <Link to="/Lab-packages">
              <HealthCard
                img={LabTest}
                tittle="Lab Test"
                subtittle="Lab tests at home and same day report"
              />
            </Link>
          </div>
          <div className="w-[48%] relative">
            <div className=" absolute right-2 top-1 text-[12px] text-white font-semibold">
              <img src={ComingSoon} width="40" alt="" />
            </div>
            <HealthCard
              img={Medicine}
              tittle="Buy Medicine"
              subtittle="Order medicine and get same day delivery"
            />
          </div>
          <div className="w-[48%]">
            <Link to="/discount-center">
              <HealthCard
                img={Discount}
                tittle="Discount Center"
                subtittle={"Deals specially designed\n for you"}
              />
            </Link>
          </div>
        </div>
      </div> */}
      {/* Consult A Doctor end */}
      {/* Lab test */}
      {/* <div
        className="px-6 mb-28 mt-4"
        data-aos="fade-right"
        data-aos-duration="1000"
      >
        <div className="pl-4 pr-4 pt-3 pb-3 flex items-center bg-[#f1f4f7] rounded-[12px] overflow-hidden relative">
          <div className=" relative z-40">
            <h3 className="text-white text-[15px] font-semibold mb-2 leading-[16px]">
              We've found 18 Insurance Plans for you
            </h3>
            <p className="text-[10px] text-[#000]/[0.7] mb-2">
              Congratulations! You're eligible for up to 15% discounts based on
              your health scores.
            </p>
            <Link
              to="/Insurance-list"
              className=" text-white text-[11px] bg-secondary rounded-tr-[15px] rounded-bl-[15px] px-5 py-1 rounded-tl-[5px] rounded-br-[5px]"
            >
              View All
            </Link>
          </div>
          <div className=" relative z-40">
            <img src={Insurance} alt="Health insurance" width="250" />
          </div>
          <svg
            className=" absolute bottom-0 left-0 right-0 z-0"
            version="1.1"
            id="Layer_1"
            xmlns="http://www.w3.org/2000/svg"
            viewBox="0 0 220 60"
            width="100%"
          >
            <path
              d="M0,38.7c0,0,16,9.2,53.2,1c11.2-2.5,33.4-6.3,55.2,0.6c8.9,2.8,25.8-1.8,34.4-9.3S174.3,1,220,10.4
              c-0.2,18.5,0,49.9,0,49.9H0V38.7z"
              fill="#d5edff"
            />
          </svg>
        </div>
      </div> */}
      {/* Lab test end */}
      {/* <div className="my-8">
        <h2 className="mb-4 text-white text-sm font-semibold">About CarePlix</h2>
        <iframe
          className="rounded-xl w-full aspect-video bg-black"
          title="Product Video"
          src="https://www.youtube.com/embed/1cbEVfUtyLw"
        />
      </div> */}

      {/* <div className="my-8">
        <h2 className="mb-4 text-white text-sm font-semibold">About CarePlix Scan</h2>
        <p className="text-darkgray text-xs text-justify">
          Our Digital Health Scan is like having a personal health check-up at your fingertips! With just a quick scan,
          you can monitor important vitals like heart rate, blood pressure, and more. Our Vitals Monitoring feature
          makes it easy to stay on top of your well-being, so you can feel confident and in control every step of the
          way.
        </p>
      </div> */}
      <Transition show={isModalOpen} as={Fragment}>
        <Dialog as={Fragment} onClose={() => {}}>
          <Transition.Child
            className="fixed top-0 bottom-0 left-0 right-0 bg-black/70 flex items-center justify-center"
            enter="duration-300"
            enterFrom="opacity-0"
            enterTo="opacity-100"
            leave="duration-300 delay-150"
            leaveFrom="opacity-100"
            leaveTo="opacity-0"
          >
            <Transition.Child
              as={Fragment}
              enter="delay-150 duration-200"
              enterFrom="opacity-0 scale-50"
              enterTo="opacity-100 scale-100"
              leave="duration-100"
              leaveFrom="opacity-50 scale-50"
              leaveTo="opacity-0 scale-0"
            >
              <Dialog.Panel className="w-2/3 bg-white rounded-2xl p-6 flex flex-col text-center">
                <Dialog.Title className="text-primary text-sm font-medium mx-auto">
                  <Info />
                </Dialog.Title>

                <Dialog.Description className="mt-4 text-black font-normal text-sm">
                  You haven’t done any scan yet
                </Dialog.Description>

                <div className="mt-4 w-full flex justify-evenly">
                  <button
                    className="w-24 px-4 py-2 rounded-full bg-white text-[#FA6363] border border-[#FA6363] text-xs font-medium"
                    type="button"
                    onClick={() => {
                      setModalOpen(false);
                    }}
                  >
                    Close
                  </button>
                  <button
                    className="w-24 px-4 py-2 rounded-full bg-gradient text-white text-xs font-medium"
                    type="button"
                    onClick={() => navigate("/start-scan")}
                  >
                    Scan Now
                  </button>
                </div>
              </Dialog.Panel>
            </Transition.Child>
          </Transition.Child>
        </Dialog>
      </Transition>
      <Transition show={isFreemiumModalOpen} as={Fragment}>
        <Dialog as={Fragment} onClose={() => setFreemiumModalOpen(false)}>
          <Transition.Child
            className="fixed top-0 bottom-0 left-0 right-0 bg-black/70 flex items-center justify-center"
            enter="duration-300"
            enterFrom="opacity-0"
            enterTo="opacity-100"
            leave="duration-300 delay-150"
            leaveFrom="opacity-100"
            leaveTo="opacity-0"
          >
            <Transition.Child
              as={Fragment}
              enter="delay-150 duration-200"
              enterFrom="opacity-0 scale-50"
              enterTo="opacity-100 scale-100"
              leave="duration-100"
              leaveFrom="opacity-50 scale-50"
              leaveTo="opacity-0 scale-0"
            >
              <Dialog.Panel className="w-4/5 bg-white rounded-2xl p-6 flex flex-col items-center text-center">
                <Dialog.Title className="text-secondary font-semibold">
                  Available Only for Members
                </Dialog.Title>
                <Dialog.Description className="mt-5 text-darkgray text-sm font-light">
                  Join our community to unlock this feature & other exclusive
                  wellness offerings. Become a member today and take your health
                  journey to the next level!
                </Dialog.Description>
                <button
                  className="mt-6 px-6 py-3 rounded-full outline-none bg-gradient text-white text-sm font-medium"
                  type="button"
                  onClick={subscribe}
                >
                  Request Membership
                </button>
              </Dialog.Panel>
            </Transition.Child>
          </Transition.Child>
        </Dialog>
      </Transition>
      <Transition show={isSuccessModalOpen} as={Fragment}>
        <Dialog as={Fragment} onClose={() => setSuccessModalOpen(false)}>
          <Transition.Child
            className="fixed top-0 bottom-0 left-0 right-0 bg-black/70 flex items-center justify-center"
            enter="duration-300"
            enterFrom="opacity-0"
            enterTo="opacity-100"
            leave="duration-300 delay-150"
            leaveFrom="opacity-100"
            leaveTo="opacity-0"
          >
            <Transition.Child
              as={Fragment}
              enter="delay-150 duration-200"
              enterFrom="opacity-0 scale-50"
              enterTo="opacity-100 scale-100"
              leave="duration-100"
              leaveFrom="opacity-50 scale-50"
              leaveTo="opacity-0 scale-0"
            >
              <Dialog.Panel className="w-4/5 bg-white rounded-2xl p-6 flex flex-col items-center text-center">
                <Dialog.Title className="text-secondary font-semibold">
                  Request Submitted Successfully!
                </Dialog.Title>
                <Dialog.Description className="mt-5 text-darkgray text-sm font-light">
                  Your membership request has been submitted successfully. Our
                  team will get back to you shortly. Welcome to the path of
                  better health and wellness!
                </Dialog.Description>
                <button
                  className="mt-6 px-6 py-3 rounded-full outline-none bg-gradient text-white text-sm font-medium"
                  type="button"
                  onClick={() => setSuccessModalOpen(false)}
                >
                  Ok
                </button>
              </Dialog.Panel>
            </Transition.Child>
          </Transition.Child>
        </Dialog>
      </Transition>
    </section>
  );
};

export default Dashboard;
