import { Link, useLocation } from "react-router-dom";
import { Tab } from "@headlessui/react";
import { ReactComponent as PlayIcon } from "../assets/icons/MusicPlay.svg";

const YogaMeditation = () => {
  const {
    state: { name, data },
  } = useLocation();
  return (
    <div className="w-full pb-28">
      <div className="px-6">
        <div className="flex items-center mb-5">
          <Link to={-1} className="mr-3">
            <svg
              width="6"
              height="10"
              viewBox="0 0 10 17"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M9.42402 16.2142C9.99277 15.6454 9.99277 14.7267 9.42402 14.1579L3.76569 8.49959L9.42402 2.84126C9.99277 2.27251 9.99277 1.35376 9.42402 0.785009C8.85527 0.216259 7.93652 0.216259 7.36777 0.785009L0.674024 7.47876C0.105274 8.04751 0.105274 8.96626 0.674024 9.53501L7.36777 16.2288C7.92194 16.7829 8.85527 16.7829 9.42402 16.2142Z"
                fill="#fff"
              />
            </svg>
          </Link>
          <h3 className="text-white text-[14px] font-semibold">{name}</h3>
        </div>
      </div>
      <Tab.Group>
        <Tab.List className="mb-6 px-6 flex space-x-4 overflow-x-auto">
          {data.map((item) => (
            <Tab
              key={`tab-${item.content_rank}`}
              className="flex-1 outline-none"
            >
              {({ selected }) => (
                <div
                  className={`h-[50px] p-2 rounded-xl flex items-center justify-center bg-gradient-to-b ${
                    selected ? "from-[#44148B]" : "from-[#2055F3]"
                  } to-transparent text-white text-center text-xs font-medium`}
                  // className="h-[50px] w-[120px] p-2 rounded-xl flex items-center justify-center text-white"
                  // style={{
                  //   backgroundColor: selected
                  //     ? item.content_color
                  //     : `${item.content_color}66`,
                  // }}
                >
                  <img
                    className="h-6 w-6 shrink-0 mr-2"
                    src={item.content_icon}
                    alt={item.content_tab_heading}
                  />
                  <span className="text-xs font-semibold">
                    {item.content_tab_heading}
                  </span>
                </div>
              )}
            </Tab>
          ))}
        </Tab.List>
        <Tab.Panels>
          {data?.map((item) => (
            <Tab.Panel key={`panel-${item.content_rank}`} className="px-6 mt-7">
              {item.contents?.map((content, index) => (
                <Link
                  key={`yoga-${index}`}
                  className="block mb-5"
                  to="/wellbeing-detail"
                  state={{
                    name: content.tab_heading,
                    desc: content.tab_content,
                    poster: content.tab_content_poster,
                    video: content.tab_content_video,
                  }}
                >
                  <div className="relative rounded-xl overflow-hidden">
                    <img
                      src={content.tab_content_poster}
                      alt={content.tab_heading}
                    />
                    <PlayIcon className="absolute top-1/2 left-1/2 -translate-x-1/2 -translate-y-1/2 w-10 p-1 rounded-full text-white bg-[#5D14B0]/50" />
                  </div>
                  <h3 className="mt-2 text-sm text-[#812EFD] font-medium">
                    {content.tab_heading}
                  </h3>
                </Link>
              ))}
            </Tab.Panel>
          ))}
        </Tab.Panels>
      </Tab.Group>
    </div>
  );
};

export default YogaMeditation;
