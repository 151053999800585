import { useState } from "react";
import { Link, useLocation, useNavigate } from "react-router-dom";
import { RadioGroup } from "@headlessui/react";
import Awful from "../assets/mood-trak/very-sad.svg";
import Sad from "../assets/mood-trak/sad.svg";
import Normal from "../assets/mood-trak/normal.svg";
import Good from "../assets/mood-trak/good.svg";
import Exicted from "../assets/mood-trak/excited.svg";
import Gifanimation from "../assets/images/top-animation.gif";
import Bedsleep from "../assets/icons/bed-sleep.svg";
import { useAuth } from "../utils/auth";
import { sdkWrapperURL } from "../utils/api-url-list";
import { logAnalyticsEvent } from "../utils/analytics";

const stepTexts = [
  {
    title: "CarePlix: The app that adapts to you.",
    subtitile: "Answer a few questions to personalize your experience.",
  },
  {
    title: "Choose your current mood for today.",
    subtitile: "Sharing your feelings aids us in personalizing your profile.",
  },
  {
    title: "Did you engage in any physical activity today?",
    subtitile:
      "Let us know your today's activity level to personalise your profile.",
  },
  {
    title: "How would you rate your quality of sleep last night?",
    subtitile:
      "Assessing sleep quality helps understand the sleep patterns and their impact on overall health and well-being",
  },
  {
    title: "Have you consumed a balanced diet today?",
    subtitile:
      "This question evaluates the individual's dietary habits and adherence to a balanced and nutritious diet",
  },
  {
    title: "Did you experience any physical discomfort or pain today?",
    subtitile:
      "Assessing any physical discomfort or pain helps identify potential health issues or injuries that may require attention or intervention",
  },
  {
    title: "Building your personalized profile",
    subtitile: "For optimal health and well-being.",
  },
  {
    title: "Check-In Complete",
    subtitile: "Remember to check in regularly to spot patterns.",
  },
];
const emojiSet = [Awful, Sad, Normal, Good, Exicted];

const MoodTracking = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const { qtm_id, questions_details } = location.state;
  const {
    getToken,
    userData: { profile_id },
  } = useAuth();
  const [activeStep, setActiveStep] = useState(0);
  const [answers, setAnswers] = useState({});
  const [error, setError] = useState("");

  const skipToDashboard = () => {
    localStorage.setItem("nextPopupTime", Date.now() + 35 * 60 * 1000);
    navigate("/");
  };

  const save = async () => {
    try {
      const token = await getToken();
      const storeAns = await fetch(sdkWrapperURL("users/v1/question_answers"), {
        method: "POST",
        headers: { "Content-Type": "application/json", Authorization: token },
        body: JSON.stringify({
          module_type: "daily_check_in",
          version: "v2",
          action: "temporary",
          questions_answers: answers,
          qtm_id: qtm_id,
          profile_id,
        }),
      });
      const submitRespJSON = await storeAns.json();
      if (submitRespJSON?.statusCode?.toString().startsWith("2")) {
        logAnalyticsEvent("daily_checkin_completed");
        localStorage.removeItem("nextPopupTime");
        setTimeout(() => setActiveStep(7), 5000);
      } else
        throw new Error(submitRespJSON?.message ?? "Failed to Save Answers.");
    } catch (err) {
      console.error(err);
      setError(err.message);
      setActiveStep(5);
    }
  };

  const handleNext = () => {
    setError("");
    setActiveStep((currentStep) => {
      if (currentStep === 0) return 1;
      else if (currentStep === 5) {
        save();
        return 6;
      } else {
        const qID = questions_details[currentStep - 1].question_id;
        if (answers[qID]?.length > 0) return currentStep + 1;
        else {
          setError("Please select a range before proceeding.");
          return currentStep;
        }
      }
    });
  };
  const handlePrev = () => {
    setError("");
    setActiveStep((currentStep) => {
      const qID = questions_details[currentStep - 1].question_id;
      setAnswers((a) => ({ ...a, [qID]: undefined }));
      return currentStep - 1;
    });
  };

  return (
    <div className="min-h-screen p-8 flex flex-col justify-center bg-primary text-white">
      <div className="min-h-[60vh] flex flex-col justify-center text-center">
        {activeStep === 0 ? (
          <img
            className="self-center shrink-0 w-40 h-40 rounded-full bg-[#9ed0f9]"
            src={Gifanimation}
            alt="animation"
          />
        ) : activeStep === 1 ? (
          <RadioGroup
            className="flex items-center justify-evenly space-x-2"
            value={answers[questions_details[0].question_id]?.[0] ?? ""}
            onChange={(v) =>
              setAnswers({ [questions_details[0].question_id]: [v] })
            }
          >
            {Object.entries(questions_details[0].question_options).map(
              ([index, value]) => (
                <RadioGroup.Option
                  key={`mood-${index}`}
                  className={({ checked }) =>
                    `w-14 h-14 p-1 shadow-box shadow-[#680FAD] rounded-full border-2 ${
                      checked ? "border-secondary" : "border-transparent"
                    }`
                  }
                  value={index}
                  as="img"
                  src={emojiSet[index - 1]}
                  alt={value}
                />
              )
            )}
          </RadioGroup>
        ) : activeStep === 2 || activeStep === 4 || activeStep === 5 ? (
          <RadioGroup
            className="space-y-4"
            value={
              answers[questions_details[activeStep - 1].question_id]?.[0] ?? ""
            }
            onChange={(v) =>
              setAnswers((a) => ({
                ...a,
                [questions_details[activeStep - 1].question_id]: [v],
              }))
            }
          >
            {Object.entries(
              questions_details[activeStep - 1].question_options
            ).map(([index, value]) => (
              <RadioGroup.Option
                key={`radio-${activeStep - 1}-${index}`}
                className={({ checked }) =>
                  `px-6 py-3 rounded-full ${
                    checked
                      ? "border-4 border-secondary"
                      : "border border-white"
                  } text-sm`
                }
                value={index}
              >
                {value}
              </RadioGroup.Option>
            ))}
          </RadioGroup>
        ) : activeStep === 3 ? (
          <>
            <img className="self-center w-36" src={Bedsleep} alt="Sleep" />
            <p className="my-6 text-sm font-light">
              <span className="text-2xl font-semibold">
                {answers[questions_details[activeStep - 1].question_id]?.[0] ||
                  "--"}
              </span>
              &nbsp;out of 10
            </p>
            <input
              className="bg-transparent"
              type="range"
              min={1}
              max={10}
              step={1}
              value={
                answers[questions_details[activeStep - 1].question_id]?.[0] || 0
              }
              onChange={(e) =>
                setAnswers((a) => ({
                  ...a,
                  [questions_details[activeStep - 1].question_id]: [
                    e.target.value,
                  ],
                }))
              }
            />
          </>
        ) : activeStep === 6 ? (
          <div className="self-center flex space-x-4">
            <div
              className="h-12 w-12 rounded-full bg-gradient-to-b from-[#5817B1] to-transparent animate-bounce"
              style={{ animationDelay: "0.5s" }}
            />
            <div
              className="h-12 w-12 rounded-full bg-gradient-to-b from-[#1534C1] to-transparent animate-bounce"
              style={{ animationDelay: "0.25s" }}
            />
            <div
              className="h-12 w-12 rounded-full bg-gradient-to-b from-[#5817B1] to-transparent animate-bounce"
              style={{ animationDelay: "0s" }}
            />
          </div>
        ) : (
          <svg
            xmlns="http://www.w3.org/2000/svg"
            className="self-center shrink-0 h-48 w-48"
            viewBox="0 0 24 24"
          >
            <circle
              style={{
                animation: "success-circle 0.8s ease-in-out 1.2s forwards",
              }}
              opacity="0"
              fill="none"
              stroke="url(#svg-gradient)"
              strokeDasharray="75 75"
              cx="12"
              cy="12"
              r="11.5"
            />
            <polyline
              style={{ animation: "success-tick 0.9s ease 2.2s forwards" }}
              opacity="0"
              fill="none"
              strokeDasharray="15 15"
              strokeLinecap="round"
              strokeLinejoin="round"
              strokeMiterlimit="10"
              stroke="url(#svg-gradient)"
              points="17,8.5 9.5,15.5 7,13"
            />
            <defs>
              <linearGradient
                id="svg-gradient"
                x1="50%"
                y1="0%"
                x2="50%"
                y2="100%"
                gradientUnits="userSpaceOnUse"
              >
                <stop stopColor="#6013AF" />
                <stop offset="100%" stopColor="#1037C2" />
              </linearGradient>
            </defs>
          </svg>
        )}
        <h1 className="mt-8 font-semibold whitespace-pre-line">
          {stepTexts[activeStep].title}
        </h1>
        <p className="mt-2.5 text-xs opacity-80 whitespace-pre-line">
          {stepTexts[activeStep].subtitile}
        </p>
      </div>

      {activeStep !== 6 &&
        (activeStep !== 7 ? (
          <div className="mt-10">
            {error.length > 0 && (
              <p className="mb-4 flex items-center justify-center space-x-3 text-error">
                <svg
                  className="shrink-0 h-4 w-4"
                  viewBox="0 0 48 48"
                  fill="none"
                >
                  <path
                    d="M24.382 47.9988C27.6797 48.0348 30.7585 47.2827 33.7167 45.9868C36.1088 44.9388 38.2428 43.5063 40.1826 41.7493C42.0579 40.0507 43.5839 38.0943 44.8506 35.9192C46.2362 33.5401 47.1122 30.9714 47.6405 28.2812C48.0446 26.223 48.0599 24.1267 47.9204 22.0546C47.7384 19.352 47.0534 16.7461 45.9385 14.2586C45.0341 12.2408 43.8653 10.4037 42.4814 8.68179C41.0362 6.88381 39.3338 5.36917 37.445 4.0874C34.8749 2.34319 32.0434 1.18096 28.9937 0.505681C26.6613 -0.0107977 24.3101 -0.072722 21.9636 0.0600864C19.6087 0.193371 17.3181 0.78029 15.119 1.66503C12.9651 2.53152 10.9491 3.64205 9.15655 5.12898C8.36089 5.78897 7.54599 6.43736 6.82746 7.17636C5.42485 8.61891 4.21713 10.2169 3.19576 11.9612C1.9631 14.0664 1.11479 16.3195 0.546531 18.6757C0.0541109 20.7176 -0.0355888 22.8052 0.0107863 24.9037C0.0727396 27.7069 0.610511 30.4131 1.72886 32.9743C2.39501 34.4999 3.18275 35.9824 4.03582 37.4126C4.58275 38.3296 5.33139 39.13 6.01932 39.9579C7.29788 41.4966 8.82901 42.7629 10.4628 43.8969C11.9187 44.9073 13.4875 45.7278 15.1401 46.3745C16.8195 47.0317 18.5481 47.5488 20.3344 47.7764C21.655 47.9446 22.9987 47.9312 24.382 47.9988ZM20.3879 13.9499C20.406 13.5045 20.3795 13.0518 20.4501 12.6149C20.7279 10.8949 22.4135 9.58974 24.227 9.70208C25.9112 9.8064 27.5786 11.2842 27.5608 13.089C27.5484 14.351 27.3727 15.611 27.2818 16.8727C27.205 17.9397 27.1568 19.0088 27.0752 20.0754C26.9524 21.6795 26.8175 23.2828 26.6755 24.8853C26.6163 25.5533 26.5796 26.2303 26.4366 26.882C26.2402 27.7763 25.1625 28.8307 23.7611 28.7428C22.8662 28.6867 21.6152 27.706 21.4993 26.7473C21.3516 25.5261 21.286 24.2949 21.1873 23.0678C21.149 22.5923 21.1245 22.1155 21.0808 21.6406C20.9532 20.2533 20.8157 18.867 20.6885 17.4797C20.5836 16.3352 20.4878 15.1898 20.3879 13.9499ZM26.6743 36.8322C25.7069 38.3149 24.3529 38.4566 23.2874 38.2495C21.869 37.9737 20.8007 36.5696 20.8712 35.0794C20.9652 33.0918 22.7695 31.6691 24.7641 32.1692C26.9389 32.7144 27.649 35.0715 26.6743 36.8322Z"
                    fill="currentColor"
                  />
                </svg>
                <span className="text-xs">{error}</span>
              </p>
            )}
            <button
              onClick={handleNext}
              className="w-full rounded-full outline-none px-5 py-2.5 bg-gradient text-white"
            >
              Next
            </button>
            <button
              onClick={activeStep === 0 ? skipToDashboard : handlePrev}
              className="block mx-auto mt-4 outline-none text-sm font-medium"
            >
              {activeStep === 0 ? "Skip" : "Back"}
            </button>
          </div>
        ) : (
          <Link
            to="/"
            replace
            className="mt-10 px-5 py-2.5 rounded-full bg-gradient text-white text-center"
          >
            Go to Dashboard
          </Link>
        ))}
    </div>
  );
};

export default MoodTracking;
