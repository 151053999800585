import { Link } from "react-router-dom";
import Simon from "../assets/images/simon-test.png";
import MemoryTest from "../assets/images/memory-test.png";
import Hanoi from "../assets/images/hanoi.png";
import Attention from "../assets/images/attention-test.png";

const CognitiveAssessments = () => (
  <div className="px-6 pt-3 pb-24">
    <h4 className="text-xl font-semibold text-white mb-5 text-center">
      Cognitive Assessments
    </h4>
    <div className="flex flex-col">
      <Link
        to="/simon-test"
        className="rounded-[12px] px-4 breathing-btn mb-4 h-[145px] justify-between text-[14px] text-white flex items-center font-normal capitalize w-full"
        style={{
          backgroundImage: `url(${Simon})`,
          backgroundPosition: "center",
          backgroundSize: "cover",
          backgroundRepeat: "no-repeat",
        }}
      >
        <div className="flex items-center font-medium text-xl">Simon Test</div>
      </Link>
      <Link
        to=""
        className="rounded-[12px] px-4 breathing-btn mb-4 h-[145px] justify-between text-[14px] text-white flex items-center font-normal capitalize w-full"
        style={{
          backgroundImage: `url(${MemoryTest})`,
          backgroundPosition: "center",
          backgroundSize: "cover",
          backgroundRepeat: "no-repeat",
        }}
      >
        <div className="flex items-center font-medium text-xl">
          Multimodal Lexical <br />
          Memory Test
        </div>
      </Link>
      <Link
        to=""
        className="rounded-[12px] px-4 breathing-btn mb-4 h-[145px] justify-between text-[14px] text-white flex items-center font-normal capitalize w-full"
        style={{
          backgroundImage: `url(${Hanoi})`,
          backgroundPosition: "center",
          backgroundSize: "cover",
          backgroundRepeat: "no-repeat",
        }}
      >
        <div className="flex items-center font-medium text-xl">
          Tower of Hanoi
        </div>
      </Link>
      <Link
        to=""
        className="rounded-[12px] px-4 breathing-btn mb-4 h-[145px] justify-between text-[14px] text-white flex items-center font-normal capitalize w-full"
        style={{
          backgroundImage: `url(${Attention})`,
          backgroundPosition: "center",
          backgroundSize: "cover",
          backgroundRepeat: "no-repeat",
        }}
      >
        <div className="flex items-center font-medium text-xl">
          Divided Attention Test
        </div>
      </Link>
    </div>
  </div>
);

export default CognitiveAssessments;
