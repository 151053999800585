import { Fragment, useMemo, useState } from "react";
import { Link, useLocation, useNavigate } from "react-router-dom";
import { Dialog, Transition } from "@headlessui/react";
import Datepicker from "react-tailwindcss-datepicker";

import { sdkWrapperURL } from "../utils/api-url-list";
import { useAuth } from "../utils/auth";

import CallbackBanner from "../assets/images/RequestCallback.png";
import { ReactComponent as Close } from "../assets/icons/Close.svg";
import { ReactComponent as Calendar } from "../assets/icons/Calendar.svg";
import { ReactComponent as Chevron } from "../assets/icons/Chevron.svg";

const RequestCallback = () => {
  const navigate = useNavigate();
  const {
    state: { offer },
  } = useLocation();
  const {
    getToken,
    userData: { profile_id },
  } = useAuth();

  const [date, setDate] = useState("");
  const [dateError, setDateError] = useState("");
  const minimumDate = useMemo(() => {
    const today = new Date();
    today.setDate(today.getDate() + 1);
    return today;
  }, []);
  const maximumDate = useMemo(() => {
    const today = new Date();
    today.setMonth(today.getMonth() + 1);
    return today;
  }, []);
  const [time, setTime] = useState("");
  const [timeError, setTimeError] = useState("");
  const [isSaving, setSaving] = useState(false);
  const [isModalOpen, setModalOpen] = useState(false);

  const interested = async (id = "", preffered = true) => {
    setDateError("");
    setTimeError("");
    setSaving(true);
    try {
      if (!(id.length > 0)) {
        throw new Error("Invalid Product ID provided.");
      }
      let err = false;
      if (preffered) {
        if (date.length <= 0) {
          setDateError("Please select a Date.");
          err = true;
        }
        if (time.length <= 0) {
          setTimeError("Please select a Time Slot.");
          err = true;
        }
      }
      if (!err) {
        const token = await getToken();
        const saveResp = await fetch(sdkWrapperURL("/users/save-products"), {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
            Authorization: token,
          },
          body: JSON.stringify({
            module_type: "labs",
            product_id: id,
            profile_id: profile_id,
            interest_filtration: "ACCEPT",
            application_info: preffered ? { date, time } : {},
          }),
        });
        const saveRespJSON = await saveResp.json();
        if (saveRespJSON?.statusCode?.toString().startsWith("2"))
          setModalOpen(true);
        else throw new Error(saveRespJSON?.message ?? "Failed to Save Product");
      }
    } catch (err) {
      console.error(err);
    } finally {
      setSaving(false);
    }
  };

  return (
    <section>
      <img className="w-full" src={CallbackBanner} alt="banner" />
      <Link to={-1} className="fixed top-2 right-4 w-8 h-8 z-50">
        <Close className="text-white" />
      </Link>
      <div className="p-6">
        <h6 className="text-white text-lg font-medium leading-6">
          Thank You for showing interest in
          <br />
          <span className="text-secondary font-semibold">
            {offer.vendor_name} - {offer.product_name}
          </span>
        </h6>
        <p className="mt-3 text-white">
          Please provide the date & time slot you'd like a callback:
        </p>
        <div className="mt-6 px-6">
          <div
            className={`relative mb-4 px-4 py-2 rounded-lg border ${
              dateError.length > 0
                ? "border-error bg-error/5 text-error"
                : "border-lightgray/20 text-primary"
            }`}
          >
            <Datepicker
              inputId="preffered-date"
              primaryColor="blue"
              containerClassName="w-full flex items-center justify-between"
              inputClassName="basis-full grow shrink-0 text-white placeholder:text-white/50 text-xs bg-transparent outline-none"
              toggleClassName="hidden basis-0 shrink-0 grow-0 pointer-events-none"
              toggleIcon={() => <></>}
              value={date.length > 0 && { startDate: date, endDate: date }}
              onChange={(date) => setDate(date.startDate)}
              useRange={false}
              asSingle
              readOnly
              placeholder="Select Date"
              displayFormat="DD/MM/YYYY"
              startFrom={minimumDate}
              maxDate={maximumDate}
              minDate={minimumDate}
            />
            <label
              htmlFor="preffered-date"
              className="absolute top-2 bottom-2 right-4 flex justify-center items-center"
            >
              <Calendar className="shrink-0 h-4 w-4 text-white" />
            </label>
          </div>
          {dateError.length > 0 && (
            <p className="mt-1.5 text-xxs text-error">{dateError}</p>
          )}

          <div
            className={`relative mb-4 px-4 py-2 rounded-lg border ${
              dateError.length > 0
                ? "border-error bg-error/5 text-error"
                : "border-lightgray/20 text-primary"
            }`}
          >
            <select
              id="preffered-time"
              name="preffered-time"
              className={`block w-full bg-transparent ${
                time.length > 0 ? "text-white" : "text-lightgray"
              } text-xs outline-none appearance-none`}
              placeholder="Select Time Slot"
              value={time}
              onChange={(e) => setTime(e.target.value)}
            >
              <option value="" disabled hidden>
                Select Time Slot
              </option>
              {["9am - 12pm", "12pm - 3pm", "3pm - 6pm"].map((opt, index) => (
                <option key={index} value={opt}>
                  {opt}
                </option>
              ))}
            </select>
            <label
              htmlFor="preffered-time"
              className="absolute -z-10 top-2 bottom-2 right-4 flex justify-center items-center"
            >
              <Chevron className="shrink-0 p-0.5 h-4 w-4" />
            </label>
          </div>
          {timeError.length > 0 && (
            <p className="mt-1.5 text-xxs text-error">{timeError}</p>
          )}

          <button
            className="block w-full mt-6 px-4 py-2 rounded-full bg-gradient text-white text-sm font-medium"
            type="button"
            disabled={isSaving}
            onClick={() => interested(offer.product_id)}
          >
            Submit
          </button>
          <button
            className="block w-full mt-3 px-4 py-2 rounded-full bg-ternary text-white text-sm font-medium"
            type="button"
            disabled={isSaving}
            onClick={() => interested(offer.product_id, false)}
          >
            No Preffered Date &amp; Time
          </button>
          <button
            className="block mx-auto mt-3 text-white text-sm font-medium"
            type="button"
            disabled={isSaving}
            onClick={() => navigate(-1)}
          >
            Not Interested
          </button>
        </div>
      </div>
      <Transition show={isModalOpen} as={Fragment}>
        <Dialog as={Fragment} onClose={() => {}}>
          <Transition.Child
            className="fixed top-0 bottom-0 left-0 right-0 bg-black/70 flex items-center justify-center"
            enter="duration-300"
            enterFrom="opacity-0"
            enterTo="opacity-100"
            leave="duration-300 delay-150"
            leaveFrom="opacity-100"
            leaveTo="opacity-0"
          >
            <Transition.Child
              as={Fragment}
              enter="delay-150 duration-200"
              enterFrom="opacity-0 scale-50"
              enterTo="opacity-100 scale-100"
              leave="duration-100"
              leaveFrom="opacity-50 scale-50"
              leaveTo="opacity-0 scale-0"
            >
              <Dialog.Panel className="w-4/5 bg-white rounded-2xl p-6 flex flex-col items-center text-center">
                <Dialog.Title className="text-primary text-sm font-medium">
                  Callback Request Placed
                </Dialog.Title>
                <Dialog.Description className="mt-2 text-primary/70 text-xs">
                  We have successfully captured your request for a callback, our
                  team will contact you.
                </Dialog.Description>
                <button
                  className="mt-4 px-4 py-2 rounded-full bg-gradient text-white text-xs font-medium"
                  type="button"
                  onClick={() => {
                    setModalOpen(false);
                    navigate(-1);
                  }}
                >
                  Ok
                </button>
              </Dialog.Panel>
            </Transition.Child>
          </Transition.Child>
        </Dialog>
      </Transition>
    </section>
  );
};

export default RequestCallback;
