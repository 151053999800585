import { useState, Fragment } from "react";
import { Link } from "react-router-dom";
import { Dialog, Transition } from "@headlessui/react";
import Nutrition1 from "../assets/images/nutrition1.png";
import Nutrition2 from "../assets/images/nutrition2.png";
import Nutrition3 from "../assets/images/nutrition3.png";
import PlayBtn from "../assets/images/play-btn.png";
import data from "../screens/Data";

const Nutrition = () => {
  const [showMore, setShowMore] = useState(false);
  const [showMore2, setShowMore2] = useState(false);
  const [showMore3, setShowMore3] = useState(false);
  const { NutritionText1, NutritionText2, NutritionText3 } = data;

  let [isOpen, setIsOpen] = useState(false);
  function closeModal() {
    setIsOpen(false);
  }
  function openModal() {
    setIsOpen(true);
  }

  return (
    <div className="w-full">
      <div
        className="relative"
        data-aos="fade-up"
        data-aos-duration="1000"
        data-aos-delay="150"
      >
        <img src={Nutrition1} alt="" />
        <div className="absolute flex items-center justify-center top-0 bottom-0 left-0 right-0">
          <Link to="" onClick={openModal}>
            <img src={PlayBtn} alt="" />
          </Link>
        </div>
      </div>
      <h3
        className="text-black text-[14px] font-semibold mb-2 mt-5"
        data-aos="fade-up"
        data-aos-duration="1000"
        data-aos-delay="200"
      >
        Learn how to cook healthy salad
      </h3>
      <p
        className="text-[11px] text-[#000]/[.7] leading-[16px] mb-6"
        data-aos="fade-up"
        data-aos-duration="1000"
        data-aos-delay="250"
      >
        {showMore ? NutritionText1 : `${NutritionText1.substring(0, 160)}...`}
        <button
          className="text-[11px] text-secondary hover:text-primary ml-1"
          onClick={() => setShowMore(!showMore)}
        >
          {showMore ? "Read less" : "Read more"}
        </button>
      </p>
      <img
        src={Nutrition2}
        alt=""
        data-aos="fade-up"
        data-aos-duration="1000"
        data-aos-delay="300"
      />
      <h3
        className="text-black text-[14px] font-semibold mb-2 mt-5"
        data-aos="fade-up"
        data-aos-duration="1000"
        data-aos-delay="350"
      >
        Addition of banana to your diet
      </h3>
      <p
        className="text-[11px] text-[#000]/[.7] leading-[16px] mb-6"
        data-aos="fade-up"
        data-aos-duration="1000"
        data-aos-delay="400"
      >
        {showMore2 ? NutritionText2 : `${NutritionText2.substring(0, 98)}....`}
        <button
          className="text-[11px] text-secondary hover:text-primary ml-1"
          onClick={() => setShowMore2(!showMore2)}
        >
          {showMore2 ? "Read less" : "Read more"}
        </button>
      </p>
      <img
        src={Nutrition3}
        alt=""
        data-aos="fade-up"
        data-aos-duration="1000"
        data-aos-delay="450"
      />
      <h3
        className="text-black text-[14px] font-semibold mb-2 mt-5"
        data-aos="fade-up"
        data-aos-duration="1000"
        data-aos-delay="500"
      >
        How much water do you drink daily?
      </h3>
      <p
        className="text-[11px] text-[#000]/[.7] leading-[16px] mb-6"
        data-aos="fade-up"
        data-aos-duration="1000"
        data-aos-delay="550"
      >
        {showMore3 ? NutritionText3 : `${NutritionText3.substring(0, 124)}....`}
        <button
          className="text-[11px] text-secondary hover:text-primary ml-1"
          onClick={() => setShowMore3(!showMore3)}
        >
          {showMore3 ? "Read less" : "Read more"}
        </button>
      </p>
      {/* modal */}
      <Transition appear show={isOpen} as={Fragment}>
        <Dialog as="div" className="relative z-10" onClose={closeModal}>
          <Transition.Child
            as={Fragment}
            enter="ease-out duration-300"
            enterFrom="opacity-0"
            enterTo="opacity-100"
            leave="ease-in duration-200"
            leaveFrom="opacity-100"
            leaveTo="opacity-0"
          >
            <div className="fixed inset-0 bg-black bg-opacity-25" />
          </Transition.Child>

          <div className="fixed inset-0 overflow-y-auto">
            <div className="flex min-h-full items-center justify-center p-2 text-center">
              <Transition.Child
                as={Fragment}
                enter="ease-out duration-300"
                enterFrom="opacity-0 scale-95"
                enterTo="opacity-100 scale-100"
                leave="ease-in duration-200"
                leaveFrom="opacity-100 scale-100"
                leaveTo="opacity-0 scale-95"
              >
                <Dialog.Panel className="w-full max-w-md transform overflow-hidden rounded-2xl bg-white p-5 text-left align-middle shadow-xl transition-all">
                  <button
                    onClick={closeModal}
                    className=" absolute right-5 top-4"
                  >
                    <svg width="30" height="30" viewBox="0 0 30 30" fill="none">
                      <path
                        d="M15 2.5C21.875 2.5 27.5 8.125 27.5 15C27.5 21.875 21.875 27.5 15 27.5C8.125 27.5 2.5 21.875 2.5 15C2.5 8.125 8.125 2.5 15 2.5Z"
                        stroke="#1B4083"
                        stroke-opacity="0.7"
                        strokeLinecap="round"
                        strokeLinejoin="round"
                      />
                      <path
                        d="M11.4624 11.4622L18.5374 18.5371"
                        stroke="#1B4083"
                        stroke-opacity="0.7"
                        strokeLinecap="round"
                        strokeLinejoin="round"
                      />
                      <path
                        d="M18.5374 11.4622L11.4624 18.5371"
                        stroke="#1B4083"
                        stroke-opacity="0.7"
                        strokeLinecap="round"
                        strokeLinejoin="round"
                      />
                    </svg>
                  </button>
                  <iframe
                    className="mt-10"
                    width="100%"
                    height="200"
                    src="https://www.youtube.com/embed/rQblUItn-m8"
                    title="Your Favorite Protein Salad Recipe"
                    frameborder="0"
                    allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
                    allowfullscreen
                  ></iframe>
                </Dialog.Panel>
              </Transition.Child>
            </div>
          </div>
        </Dialog>
      </Transition>
    </div>
  );
};

export default Nutrition;
