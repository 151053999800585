import { useState } from "react";
import { Navigate, useNavigate } from "react-router-dom";
import PlusIcon from "../assets/icons/Add circle.svg";
import { useAuth } from "../utils/auth";

const SelectUser = () => {
  const {
    logout,
    userData: { profile_id },
    profiles,
    subscriptionPlanDetails: { maxAllowedProfiles },
    selectProfile,
    getToken,
  } = useAuth();
  const navigate = useNavigate();
  const [selectedUser, setSelectedUser] = useState(profile_id);
  const [error, setError] = useState("");

  const chooseProfile = async () => {
    try {
      if (selectedUser.length > 0) {
        const token = await getToken();
        selectProfile(selectedUser, token);
        navigate("/", { replace: true });
      } else throw new Error("Please select an User.");
    } catch (err) {
      setError(err.message);
    }
  };
  return profiles.length > 0 ? (
    <div className="min-h-screen p-8 relative bg-primary text-white">
      <div className="flex justify-between">
        <h1 className="text-2xl font-semibold">
          Select <span className="text-secondary">User</span>
        </h1>
        {profiles.length < maxAllowedProfiles && (
          <button
            className="flex items-center justify-between space-x-1.5 p-1.5 rounded-full bg-gradient text-xs font-medium"
            type="button"
            onClick={() => {
              navigate("/basic-details", { state: { self: false } });
            }}
          >
            <img src={PlusIcon} alt="+" />
            <span>Add User</span>
          </button>
        )}
      </div>
      <div className="mt-8 space-y-4">
        {profiles.map((profile) => (
          <button
            key={profile.member_profile_id}
            className={`w-full flex items-center justify-between px-6 py-3 rounded-xl ${
              profile.member_profile_id === selectedUser
                ? "border-2 border-secondary bg-gradient-transparent"
                : "border border-white/50"
            }`}
            type="button"
            onClick={() => setSelectedUser(profile.member_profile_id)}
          >
            <span className="text-sm">
              {profile.first_name} {profile.last_name}
            </span>
            <p className="w-20 p-1 rounded-full bg-gradient-to-b from-[#4051A4] to-transparent text-xxs">
              {profile.relation}
            </p>
          </button>
        ))}
      </div>
      {error.length > 0 && (
        <p className="mt-6 -mb-4 text-center text-xs text-error">{error}</p>
      )}
      <button
        className="block mt-8 w-full rounded-full outline-none px-5 py-2.5 bg-gradient disabled:bg-secondary text-white"
        type="button"
        onClick={chooseProfile}
      >
        Next
      </button>
      <button
        className="block mx-auto mt-4 outline-none text-sm text-center font-medium"
        type="button"
        onClick={logout}
      >
        Logout
      </button>
    </div>
  ) : (
    <Navigate to="/referal" replace />
  );
};

export default SelectUser;
