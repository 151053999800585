import { Fragment, useEffect, useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import { Dialog, Disclosure, Transition } from "@headlessui/react";
import { Button } from "@material-tailwind/react";
import LoadingScreen from "../components/LoadingScreen";
import { sdkWrapperURL } from "../utils/api-url-list";
import { useAuth } from "../utils/auth";
import FileIcon from "../assets/icons/file-upload.svg";
import CloseIcon from "../assets/icons/close-icon.svg";
import FileImg from "../assets/icons/icon_upload_file.svg";
import NextArrow from "../assets/icons/icon_arrows.svg";
import FAQ from "../assets/icons/icon_help.svg";

const LabPackages = () => {
  const navigate = useNavigate();
  const {
    getToken,
    userData: { profile_id },
  } = useAuth();
  const [loading, setLoading] = useState(true);
  const [packages, setPackages] = useState([]);

  useEffect(() => {
    setLoading(true);
    (async () => {
      try {
        const token = await getToken();
        const resp = await fetch(sdkWrapperURL("/users/list/products"), {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
            Authorization: token,
          },
          body: JSON.stringify({
            profile_id,
            product_type_id: 1,
            table_config_rows_per_page: 100,
            table_config_page_no: 1,
          }),
        });
        const resp_json = await resp.json();
        if (resp_json.statusCode?.toString().startsWith("2")) {
          setPackages(resp_json.response);
        } else throw new Error(resp_json.message);
      } catch {
        setPackages([]);
      } finally {
        setLoading(false);
      }
    })();
  }, [getToken, profile_id]);

  // **** file upload **** //
  const [isSuccessModalOpen, setSuccessModalOpen] = useState(false);
  const [showButton, setShowButton] = useState(true);
  const [uploadedFile, setUploadedFile] = useState(null);

  const [btnLoading, setBtnLoading] = useState(false);
  const [pincode, setPincode] = useState("");
  const [error, setError] = useState("");

  const uploadData = async () => {
    setBtnLoading(true);
    try {
      if (/^\d{6}$/.test(pincode)) {
        const token = await getToken();
        const res = await fetch(
          sdkWrapperURL("/users/user-upload-prescription"),
          {
            method: "POST",
            headers: {
              "Content-Type": "application/json",
              Authorization: token,
            },
            body: JSON.stringify({
              action: "upload",
              file_name: uploadedFile?.name ?? "",
              pincode: pincode,
            }),
          }
        );
        const respJson = await res.json();
        if (respJson?.statusCode?.toString().startsWith("2")) {
          const formdata = new FormData();
          Object.entries({
            ...(respJson.presigned_post?.fields ?? {}),
            file: uploadedFile,
          }).forEach(([key, value]) => formdata.append(key, value));
          const uploadResp = await fetch(respJson.presigned_post?.url ?? "", {
            method: "POST",
            body: formdata,
          });
          if (uploadResp.ok) setSuccessModalOpen(true);
          else throw new Error("Failed to Upload the File");
        } else
          throw new Error(
            respJson?.message ?? "Failed to fetch Uploading Link"
          );
      } else setError("Invalid Pincode");
    } catch (e) {
      console.error(e);
    } finally {
      setBtnLoading(false);
    }
  };

  return (
    <div className="px-6 pb-28">
      <div
        className="flex items-center mb-5 justify-between"
        data-aos="fade-right"
        data-aos-delay="50"
        data-aos-duration="1000"
      >
        <div className="flex items-center">
          <button
            type="button"
            onClick={() => navigate(-1)}
            className="mr-3 outline-none"
          >
            <svg
              width="6"
              height="10"
              viewBox="0 0 10 17"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M9.42402 16.2142C9.99277 15.6454 9.99277 14.7267 9.42402 14.1579L3.76569 8.49959L9.42402 2.84126C9.99277 2.27251 9.99277 1.35376 9.42402 0.785009C8.85527 0.216259 7.93652 0.216259 7.36777 0.785009L0.674024 7.47876C0.105274 8.04751 0.105274 8.96626 0.674024 9.53501L7.36777 16.2288C7.92194 16.7829 8.85527 16.7829 9.42402 16.2142Z"
                fill="#192852"
              />
            </svg>
          </button>
          <h3 className="text-primary text-[14px] font-medium">Lab Tests</h3>
        </div>
        <div className="">
          <a
            href="#faq"
            className="text-[12px] text-secondary font-semibold p-0 bg-transparent flex items-center"
          >
            <img src={FAQ} alt="" className="mr-2" /> FAQ
          </a>
        </div>
      </div>
      <div className="Prescription-upload relative">
        {showButton && (
          <Button
            className=" rounded-[12px] px-3 upload-btn mb-5 h-[55px] justify-between text-[14px] text-white flex items-center font-normal capitalize w-full"
            onClick={() => setShowButton(false)}
            data-aos="fade-right"
            data-aos-delay="70"
            data-aos-duration="1000"
          >
            <div className="flex items-center upload-btn-text">
              <img src={FileImg} alt="" className="mr-2" /> Do you have a
              prescription to upload ?
            </div>
            <img src={NextArrow} alt="" />
          </Button>
        )}
        {!showButton && !uploadedFile && (
          <div
            className="upload-btn-wrapper"
            data-aos="fade-up"
            data-aos-delay="70"
            data-aos-duration="1000"
          >
            <button className="btn flex flex-col items-center justify-center h-[130px] border-[1.5px] mb-5 border-secondary border-dashed">
              <img src={FileIcon} alt="" className="mb-2" />
              Click To Upload Prescription
              <br />
              <span className="text-[10px] text-[#6AB3F2] font-light mt-1">
                A Care team member will connect with you shortly.
              </span>
            </button>
            <input
              type="file"
              name="myfile"
              accept="image/png, image/jpeg, application/pdf, image/heic, image/heif, image/tiff"
              onChange={(e) => setUploadedFile(e.target?.files?.[0])}
            />
          </div>
        )}
        {uploadedFile && (
          <div
            className=" w-full top-0 px-4 mb-5 bg-white flex flex-col border-[1.5px] border-secondary border-dashed rounded-[12px]"
            data-aos="fade-right"
            data-aos-delay="70"
            data-aos-duration="1000"
          >
            <div className=" relative w-full flex">
              <p className="block w-full mt-6 pl-4 pr-[30px] py-2 rounded-lg outline-none overflow-hidden truncate focus:border-[#609862] border border-[#609862] text-sm text-primary placeholder:text-lightgray">
                {uploadedFile.name}
              </p>
              <button
                onClick={() => setUploadedFile(null)}
                className=" absolute right-2 top-[33px]"
              >
                <img src={CloseIcon} alt="" />
              </button>
            </div>
            <div className="my-4">
              <div className="flex items-center">
                <input
                  className={`px-3 py-1.5 w-48 rounded-md border ${
                    error.length > 0 ? "border-[#f00]" : "border-[#bdbdbd]"
                  } text-xs text-primary outline-none`}
                  id="pincode"
                  name="pincode"
                  type="number"
                  inputMode="numeric"
                  maxLength={6}
                  minLength={6}
                  autoComplete="postal-code"
                  placeholder="Enter Pincode"
                  value={pincode}
                  onChange={(e) => {
                    setError("");
                    if (e.target.value.length <= 6) setPincode(e.target.value);
                  }}
                  disabled={btnLoading}
                />
              </div>
              {error.length > 0 && (
                <p className="mt-2 text-error text-xs font-light">{error}</p>
              )}
              <button
                className="mt-3 px-3 py-1.5 rounded-full bg-primary text-white text-[10px]"
                type="button"
                onClick={uploadData}
                disabled={btnLoading}
              >
                Upload Prescription&nbsp;&nbsp;&rarr;
              </button>
              <p className="text-[10px] text-[#6AB3F2] font-light mt-3">
                A Care team member will connect with you shortly.
              </p>
            </div>
          </div>
        )}
      </div>

      {showButton && (
        <>
          <div
            className="flex items-center mb-5"
            data-aos="fade-right"
            data-aos-delay="50"
            data-aos-duration="1000"
          >
            <h3 className="text-primary text-[14px] font-medium">
              Lab Packages
            </h3>
          </div>
          {loading ? (
            <div className="h-screen">
              <LoadingScreen />
            </div>
          ) : (
            packages.map((p) => (
              <div
                key={p.product_id}
                className="flex justify-between items-center space-x-4 mb-4 p-3 rounded-xl bg-[#FCFCFC] border border-[#DFDFDF]/[0.5]"
                data-aos="fade-up"
                data-aos-delay="10"
                data-aos-duration="1000"
                data-aos-offset="10"
                data-aos-easing="ease-in-out"
              >
                <div className="shrink-0">
                  <img
                    className="w-20 h-20 rounded-lg"
                    src={p.structure_val?.logo}
                    alt="Company Logo"
                  />
                </div>
                <div className="grow">
                  <h4 className="text-primary text-xs font-medium">
                    {p.structure_val?.name}
                    &nbsp;(
                    {p.structure_val?.tests_included &&
                      (p.structure_val.test_type === "grouped"
                        ? Object.values(p.structure_val.tests_included).reduce(
                            (sum, arr) => sum + (arr?.length ?? 0),
                            0
                          )
                        : p.structure_val.tests_included.length)}
                    &nbsp;tests)
                  </h4>
                  <p>
                    <span className="text-neutral-600 text-[10px] line-through">
                      {parseInt(p.structure_val?.mrp ?? 0).toLocaleString(
                        undefined,
                        {
                          style: "currency",
                          currency: "INR",
                        }
                      )}
                    </span>
                    <span className="ml-3 text-secondary text-xs font-semibold">
                      {parseInt(
                        p.structure_val?.discounted_price ?? 0
                      ).toLocaleString(undefined, {
                        style: "currency",
                        currency: "INR",
                      })}
                      /-
                    </span>
                  </p>
                  <Link
                    to="/lab-package-detail"
                    className="mt-1 px-3 py-1.5 rounded-full bg-secondary text-white text-[10px]"
                    state={{ packageDetail: p }}
                  >
                    Book Now&nbsp;&nbsp;&nbsp;&rarr;
                  </Link>
                </div>
                <div className="shrink-0 h-14 w-14 p-1 flex flex-col justify-center items-center rounded-full border border-dashed border-secondary bg-[#fff]">
                  {/* <p className="text-secondary text-[10px] font-medium">Upto</p> */}
                  <p className="text-primary text-xs font-bold mt-[5px]">
                    {Math.round(
                      (1 -
                        (p.structure_val?.discounted_price ?? 0) /
                          (p.structure_val?.mrp ?? 0)) *
                        100
                    )}
                    %
                  </p>
                  <p className="text-primary text-[10px] font-medium">off</p>
                </div>
              </div>
            ))
          )}
        </>
      )}

      <Transition appear show={isSuccessModalOpen} as={Fragment}>
        <Dialog
          as="div"
          className="relative z-10"
          onClose={() => setSuccessModalOpen(false)}
        >
          <Transition.Child
            as={Fragment}
            enter="ease-out duration-300"
            enterFrom="opacity-0"
            enterTo="opacity-100"
            leave="ease-in duration-200"
            leaveFrom="opacity-100"
            leaveTo="opacity-0"
          >
            <div className="fixed inset-0 bg-black bg-opacity-25" />
          </Transition.Child>

          <div className="fixed inset-0 overflow-y-auto">
            <div className="flex min-h-full items-center justify-center p-4 text-center">
              <Transition.Child
                as={Fragment}
                enter="ease-out duration-300"
                enterFrom="opacity-0 scale-95"
                enterTo="opacity-100 scale-100"
                leave="ease-in duration-200"
                leaveFrom="opacity-100 scale-100"
                leaveTo="opacity-0 scale-95"
              >
                <Dialog.Panel className="w-[300px] h-[200px] max-w-md transform overflow-hidden rounded-2xl bg-white p-6 text-left align-middle shadow-xl transition-all">
                  <div className=" flex flex-col items-center justify-center mt-5">
                    <h4 className="text-[12px] font-semibold text-primary mb-2">
                      Booking Successfull!
                    </h4>
                    <p className="text-[10px] text-primary/[0.5] text-center">
                      CarePlix booking team will contact you by phone within
                      next 2 hours to confirm your booking.
                    </p>
                  </div>
                  <Link
                    to="/"
                    replace
                    className="mt-6 mx-auto w-[60px] h-[35px] bg-secondary rounded-full flex items-center justify-center text-[12px] outline-none text-white font-medium hover:bg-primary active:bg-primary focus:outline-none"
                  >
                    Ok
                  </Link>
                </Dialog.Panel>
              </Transition.Child>
            </div>
          </div>
        </Dialog>
      </Transition>
      <div id="faq" className="relative z-0 pt-8 bg-white">
        <h3 className="mb-4 text-sm text-secondary font-medium">
          Frequently Asked Questions
        </h3>
        {[
          {
            label: "How do I get the reports for my order?",
            desc: "You will receive a soft copy of the reports via email to the registered email id. The maximum timeframe is 48 hours.\n\nPlease reach out to us on 033-41816533 for any further assistance.",
          },
          {
            label: "How do I check my diagnostics order status?",
            desc: "Please reach out to us on 033-41816533 for any further assistance.",
          },
          {
            label: "Will I need a prescription for booking a test?",
            desc: "Careplix allows you to get the test done even when you don't have a prescription. You can directly select your desired test from the wide lists of options available in the App. We'll call you to confirm the test/s once the test is booked.\n\nPlease reach out to us on 033-41816533 for any further assistance.",
          },
          {
            label:
              "How can I modify the Tests or Appointment time in my diagnostics order?",
            desc: "If you wish to modify any of the tests or the package ordered in your Diagnostics order, please reach out to us on 033-41816533. We will try our best to accommodate your request as much as possible.",
          },
        ].map((listItem, listItemIndex) => (
          <Disclosure
            key={`faq-${listItemIndex}`}
            as="div"
            className={`mb-2.5 p-2.5 ${
              listItemIndex % 2 ? "bg-[#F3F3F3]" : "bg-[#F2F7FB]"
            }`}
          >
            <Disclosure.Button className="flex w-full justify-between items-start">
              {({ open }) => (
                <>
                  <span className="text-primary text-xs text-left">
                    {listItem.label}
                  </span>
                  <svg
                    className={`shrink-0 ml-3 h-5 w-5 text-secondary ${
                      open ? "rotate-0" : "rotate-180"
                    }`}
                    viewBox="0 0 20 20"
                    fill="currentColor"
                  >
                    <path
                      d="M14.77 12.79a.75.75 0 01-1.06-.02L10 8.832 6.29 12.77a.75.75 0 11-1.08-1.04l4.25-4.5a.75.75 0 011.08 0l4.25 4.5a.75.75 0 01-.02 1.06z"
                      fillRule="evenodd"
                      clipRule="evenodd"
                    />
                  </svg>
                </>
              )}
            </Disclosure.Button>
            <Disclosure.Panel
              as="p"
              className="mt-1 mr-8 whitespace-pre-line text-primary/75 text-[10px]"
            >
              {listItem.desc}
            </Disclosure.Panel>
          </Disclosure>
        ))}
      </div>
    </div>
  );
};

export default LabPackages;
