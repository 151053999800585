import { Link } from "react-router-dom";
import { useEffect, useMemo, useState } from "react";
import ProgressCircle from "../components/ProgressCircle";
import { constants } from "../utils/ranges";
import { useAuth } from "../utils/auth";
import { sdkWrapperURL } from "../utils/api-url-list";
import ChartIcon from "../assets/icons/insert_chart.svg";
import Walking from "../assets/images/Walking.svg";
import Energy from "../assets/images/Energy.svg";
import Sleep from "../assets/images/Sleep.svg";
import BMI from "../assets/images/BMI.svg";

const Activity = () => {
  const {
    getToken,
    userData: { employee_id },
  } = useAuth();
  const [loading, setLoading] = useState(true);
  const [lastScanResult, setLastResult] = useState({});
  const selectedScoreRange = useMemo(
    () =>
      constants["Wellbeing Score"].ranges.reduce((p, c) =>
        lastScanResult.wellness_score > c.point ? c : p
      ),
    [lastScanResult.wellness_score]
  );

  useEffect(() => {
    const controller = new AbortController();
    setLoading(true);
    (async () => {
      try {
        const token = await getToken();
        const scoreResp = await fetch(
          sdkWrapperURL("/users/sdk/test_api_private"),
          {
            method: "POST",
            headers: {
              "Content-Type": "application/json",
              Authorization: token,
            },
            body: JSON.stringify({
              action: "list_scan_UL",
              employee_id,
              table_config_rows_per_page: 1,
              table_config_page_no: 1,
            }),
            signal: controller.signal,
          }
        );
        const scoreRespJSON = await scoreResp.json();
        if (
          scoreRespJSON?.statusCode?.toString().startsWith("2") &&
          scoreRespJSON?.result?.length > 0
        )
          setLastResult(scoreRespJSON.result[0]);
      } catch (err) {
        console.error(err);
      } finally {
        setLoading(false);
      }
    })();
    return () => {
      controller.abort();
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <div className="px-6 pb-28">
      <div className="flex items-center mb-8">
        <Link to="/" className="mr-3">
          <svg
            width="6"
            height="10"
            viewBox="0 0 10 17"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M9.42402 16.2142C9.99277 15.6454 9.99277 14.7267 9.42402 14.1579L3.76569 8.49959L9.42402 2.84126C9.99277 2.27251 9.99277 1.35376 9.42402 0.785009C8.85527 0.216259 7.93652 0.216259 7.36777 0.785009L0.674024 7.47876C0.105274 8.04751 0.105274 8.96626 0.674024 9.53501L7.36777 16.2288C7.92194 16.7829 8.85527 16.7829 9.42402 16.2142Z"
              fill="#192852"
            />
          </svg>
        </Link>
        <h3 className="text-primary text-[14px] font-semibold">Activity</h3>
      </div>
      <div
        className="flex flex-col items-center"
        data-aos="fade-up"
        data-aos-duration="1000"
        data-aos-delay="50"
      >
        <ProgressCircle
          loading={loading}
          value={lastScanResult.wellness_score}
          color={selectedScoreRange.color}
          // subtitle={selectedScoreRange.label}
        />
        <h3 className="text-[14px] text-[#192852] font-medium mt-2">
          Activity Score
        </h3>
        <h4 className="text-[16px] text-[#FFC400] font-medium">MODERATE</h4>
        <p className="text-[11px] text-[#414141] mb-2">
          Last update August 04, 2022
        </p>
        <Link
          to=""
          className="text-[10px] text-secondary font-medium flex items-center"
        >
          <img src={ChartIcon} alt="chart icon" className="mr-1" /> View
          Analytics
        </Link>
        <p className="text-[11px] text-[#000]/[.7] text-justify mt-4">
          Activity score is a measure of an individual's physical activity
          level. It is calculated based on factors such as the number of steps
          taken per day, duration of sleep, active energy in conjunction with
          BMI to assess overall activity or healthy habit status.
        </p>
      </div>
      <div className="w-full">
        <div
          className="flex items-center justify-between mb-3 mt-4"
          data-aos="fade-up"
          data-aos-duration="1000"
          data-aos-delay="200"
        >
          <h2 className="text-[16px] text-secondary font-semibold">
            Daily Activities
          </h2>
          <Link
            to=""
            className="bg-[#EBF6FF] h-[25px] text-[12px] text-primary py-3 px-6 flex justify-center items-center rounded-full"
          >
            100 care pts
          </Link>
        </div>
        <ul className="mt-6 flex flex-col">
          <li
            className="flex justify-between border items-center border-[#A1D3FF] rounded-[10px] px-3 py-3 mb-2.5"
            data-aos="fade-up"
            data-aos-duration="1000"
            data-aos-delay="300"
          >
            <div className="flex">
              <span className="Health-icon">
                <img src={Walking} alt="Walking" />
              </span>
              <div className="ml-3">
                <h4 className="text-[12px] text-black font-light">Walking</h4>
                <h3 className="text-[16px] text-secondary font-semibold">
                  1356{" "}
                  <span className="text-[10px] text-black font-normal">
                    steps
                  </span>
                </h3>
              </div>
            </div>
            <div className="flex items-center justify-between">
              <p className="text-[10px] text-[#414141] leading-[14px] mr-7">
                Last update <br />
                August 04, 2022
              </p>

              <Link to="/Walking" className="mr-1">
                <svg
                  width="10"
                  height="17"
                  viewBox="0 0 10 17"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="M0.575977 0.783872C0.00722657 1.35262 0.00722657 2.27137 0.575977 2.84012L6.23431 8.49845L0.575977 14.1568C0.00722657 14.7255 0.00722657 15.6443 0.575977 16.213C1.14473 16.7818 2.06348 16.7818 2.63223 16.213L9.32598 9.51929C9.89473 8.95054 9.89473 8.03179 9.32598 7.46304L2.63223 0.769288C2.07806 0.215122 1.14473 0.215122 0.575977 0.783872Z"
                    fill="#3298F1"
                  />
                </svg>
              </Link>
            </div>
          </li>
          <li
            className="flex justify-between border items-center border-[#A1D3FF] rounded-[10px] px-3 py-3 mb-2.5"
            data-aos="fade-up"
            data-aos-duration="1000"
            data-aos-delay="350"
          >
            <div className="flex">
              <span className="Health-icon">
                <img src={Sleep} alt="Sleep" />
              </span>
              <div className="ml-3">
                <h4 className="text-[12px] text-black font-light">Sleep</h4>
                <h3 className="text-[16px] text-secondary font-semibold">
                  7.2{" "}
                  <span className="text-[10px] text-black font-normal">
                    hours
                  </span>
                </h3>
              </div>
            </div>
            <div className="flex items-center justify-between">
              <p className="text-[10px] text-[#414141] leading-[14px] mr-7">
                Last update <br />
                August 04, 2022
              </p>

              <Link to="" className="mr-1">
                <svg
                  width="10"
                  height="17"
                  viewBox="0 0 10 17"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="M0.575977 0.783872C0.00722657 1.35262 0.00722657 2.27137 0.575977 2.84012L6.23431 8.49845L0.575977 14.1568C0.00722657 14.7255 0.00722657 15.6443 0.575977 16.213C1.14473 16.7818 2.06348 16.7818 2.63223 16.213L9.32598 9.51929C9.89473 8.95054 9.89473 8.03179 9.32598 7.46304L2.63223 0.769288C2.07806 0.215122 1.14473 0.215122 0.575977 0.783872Z"
                    fill="#3298F1"
                  />
                </svg>
              </Link>
            </div>
          </li>
          <li
            className="flex justify-between border items-center border-[#A1D3FF] rounded-[10px] px-3 py-3 mb-2.5"
            data-aos="fade-up"
            data-aos-duration="1000"
            data-aos-delay="400"
          >
            <div className="flex">
              <span className="Health-icon">
                <img src={Energy} alt="Energy" />
              </span>
              <div className="ml-3">
                <h4 className="text-[12px] text-black font-light">Energy</h4>
                <h3 className="text-[16px] text-secondary font-semibold">
                  35{" "}
                  <span className="text-[10px] text-black font-normal">
                    kcal
                  </span>
                </h3>
              </div>
            </div>
            <div className="flex items-center justify-between">
              <p className="text-[10px] text-[#414141] leading-[14px] mr-7">
                Last update <br />
                August 04, 2022
              </p>

              <Link to="" className="mr-1">
                <svg
                  width="10"
                  height="17"
                  viewBox="0 0 10 17"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="M0.575977 0.783872C0.00722657 1.35262 0.00722657 2.27137 0.575977 2.84012L6.23431 8.49845L0.575977 14.1568C0.00722657 14.7255 0.00722657 15.6443 0.575977 16.213C1.14473 16.7818 2.06348 16.7818 2.63223 16.213L9.32598 9.51929C9.89473 8.95054 9.89473 8.03179 9.32598 7.46304L2.63223 0.769288C2.07806 0.215122 1.14473 0.215122 0.575977 0.783872Z"
                    fill="#3298F1"
                  />
                </svg>
              </Link>
            </div>
          </li>
          <li
            className="flex justify-between border items-center border-[#A1D3FF] rounded-[10px] px-3 py-3 mb-2.5"
            data-aos="fade-up"
            data-aos-duration="1000"
            data-aos-delay="450"
          >
            <div className="flex">
              <span className="Health-icon">
                <img src={BMI} alt="BMI" />
              </span>
              <div className="ml-3">
                <h4 className="text-[12px] text-black font-light">BMI</h4>
                <h3 className="text-[16px] text-secondary font-semibold">
                  28.26{" "}
                  <span className="text-[10px] text-black font-normal">
                    kg/m2
                  </span>
                </h3>
              </div>
            </div>
            <div className="flex items-center justify-between">
              <p className="text-[10px] text-[#414141] leading-[14px] mr-7">
                Last update <br />
                August 04, 2022
              </p>

              <Link to="" className="mr-1">
                <svg
                  width="10"
                  height="17"
                  viewBox="0 0 10 17"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="M0.575977 0.783872C0.00722657 1.35262 0.00722657 2.27137 0.575977 2.84012L6.23431 8.49845L0.575977 14.1568C0.00722657 14.7255 0.00722657 15.6443 0.575977 16.213C1.14473 16.7818 2.06348 16.7818 2.63223 16.213L9.32598 9.51929C9.89473 8.95054 9.89473 8.03179 9.32598 7.46304L2.63223 0.769288C2.07806 0.215122 1.14473 0.215122 0.575977 0.783872Z"
                    fill="#3298F1"
                  />
                </svg>
              </Link>
            </div>
          </li>
        </ul>
      </div>
    </div>
  );
};

export default Activity;
