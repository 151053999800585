import { Link } from "react-router-dom";
import GraphinaChart from "../components/GraphinaChart";
import Cognitive from "../assets/images/Cognitive.png";
import Explore from "../assets/images/explore.png";

const CognitiveScore = () => (
  <div className="px-6 pt-3 pb-24">
    <div className="flex justify-between items-center">
      <div className="w-[70%]">
        <h4 className="text-white font-semibold text-lg leading-5">
          Your Overall
          <br />
          Cognitive Score
        </h4>
      </div>
      <div className="flex items-center justify-center w-[30%]">
        <h2 className="text-[#5916AC] font-bold text-5xl">78</h2>
      </div>
    </div>
    <p className="text-xs font-light text-[#949494] mt-2">
      Last Updated: 21 Dec 2023, 07:35pm
    </p>
    <div className="mt-6 w-full">
      <p className="text-sm font-semibold text-white">Cognitive Graphina</p>
      <div className="flex justify-between items-center">
        <div>
          <p className="text-[11px] text-white flex items-center mt-3 mb-1">
            <span className="w-[11px] h-[11px] rounded-full bg-[#FFC061] mr-[5px]"></span>
            &nbsp;You
          </p>
          <p className="text-[11px] text-white flex items-center mb-4">
            <span className="w-[11px] h-[11px] rounded-full bg-[#5C1BC0] mr-[5px]"></span>
            &nbsp;Everyone else
          </p>
        </div>
        <GraphinaChart />
      </div>
    </div>
    <h5 className="text-lg font-semibold text-white mb-3 mt-4">Assessments</h5>
    <Link
      to=""
      className="flex h-12 rounded-[10px] justify-between items-center px-6 text-base font-medium text-white bg-gradient-to-l from-[#264AE9] to-[#5916AC]"
    >
      Assessments History
      <svg
        className="h-4"
        viewBox="0 0 11 8"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M10.7124 3.97651L7.36419 0.628296L6.88874 1.10374L9.66776 3.87941H0.0952148V4.54906H9.66776L6.89209 7.32473L7.36419 7.79682L10.7124 4.44861C10.7748 4.38588 10.8098 4.30101 10.8098 4.21256C10.8098 4.1241 10.7748 4.03924 10.7124 3.97651V3.97651Z"
          fill="#fff"
        />
      </svg>
    </Link>

    <Link
      to="/cognitive-assessment"
      className="rounded-[12px] px-4 breathing-btn mt-3 mx-[2px] h-[145px] justify-between text-[14px] text-white flex items-center font-normal capitalize w-full"
      style={{
        backgroundImage: `url(${Cognitive})`,
        backgroundPosition: "center",
        backgroundSize: "cover",
        backgroundRepeat: "no-repeat",
      }}
    >
      <div className="flex items-center font-medium text-xl">
        Cognitive
        <br />
        Assessments
      </div>
    </Link>

    <h4 className="text-xl font-semibold text-white mb-3 mt-3">Explore</h4>
    <Link to="">
      <img src={Explore} alt="" />
    </Link>
  </div>
);

export default CognitiveScore;
