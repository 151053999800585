import { useMemo } from "react";
import { Link } from "react-router-dom";

const PackageCard = ({
  color = "#fff8",
  img,
  tittle = "MindBody Fuel",
  subtittle = "A comprehensive wellness program featuring nutrition ....",
  packageData,
}) => {
  const packageDetails = useMemo(
    () => ({
      img: packageData?.structure_val?.large_icon,
      name: packageData?.product_name,
      price: packageData?.structure_val?.mrp,
      product_Id: packageData?.product_id,
      description_Info: packageData?.structure_val?.description["About Plan"],
      key_Benefits: packageData?.structure_val?.key_benefits,
      membership_Duration: packageData?.structure_val?.membership_duration,
    }),
    [packageData]
  );
  return (
    <div className="package-card bg-[#fff] rounded-[12px] mb-4 overflow-hidden flex border border-[#ccc] relative box-shadow">
      <div className="w-[35%] flex">
        <img src={img} alt="" className="rounded-tr-[12px]" />
      </div>
      <div className="ml-1 w-[65%] px-3 py-3">
        <h3 className="text-[14px] font-semibold text-primary">{tittle}</h3>
        <p className="text-[10px] text-[#000]/[0.7] pb-5 mt-1">{subtittle}</p>
        <div className=" absolute right-0 bottom-[1px]">
          <Link
            to="/Package-details"
            state={packageDetails}
            className="px-4 py-1 text-[11px] text-white bg-secondary rounded-br-[12px] rounded-tl-[12px]"
          >
            View More
          </Link>
        </div>
      </div>
    </div>
  );
};

export default PackageCard;
