import { useEffect, useMemo, useState } from "react";
import { Link, useLocation } from "react-router-dom";
import { useAuth } from "../utils/auth";
import { sdkWrapperURL } from "../utils/api-url-list";
import { ReactComponent as Chevron } from "../assets/icons/Chevron.svg";
import LogMeals from "../assets/images/log-your-meals.png";
import Recipes from "../assets/images/recipes.png";
import Nutration from "../assets/images/choose-nutration.png";
import Drink from "../assets/images/drink.png";
import Breakfast from "../assets/icons/breakfast.svg";
import Lunch from "../assets/icons/lunch.svg";
import Dinner from "../assets/icons/dinner.svg";

const NutrationList = () => {
  const { state } = useLocation();
  const { getToken, userData } = useAuth();
  const [trackingDate, setTrackingDate] = useState(new Date());
  const [nutritionData, setNutritionData] = useState({
    breakfast_calories: 0,
    lunch_calories: 0,
    dinner_calories: 0,
    total_protein: 0,
    total_carbohydrate: 0,
    total_fat: 0,
    total_calories: 0,
  });

  useEffect(() => {
    const controller = new AbortController();
    const timer = setTimeout(async () => {
      setNutritionData({
        breakfast_calories: 0,
        lunch_calories: 0,
        dinner_calories: 0,
        total_protein: 0,
        total_carbohydrate: 0,
        total_fat: 0,
        total_calories: 0,
      });
      try {
        const year = trackingDate.getFullYear();
        const month = (trackingDate.getMonth() + 1).toString().padStart(2, "0");
        const day = trackingDate.getDate().toString().padStart(2, "0");
        const date = `${year}-${month}-${day}`;
        const token = await getToken();
        const resp = await fetch(
          sdkWrapperURL("/nutrition/get-nutrition-history"),
          {
            method: "POST",
            headers: {
              "Content-Type": "application/json",
              Authorization: token,
            },
            body: JSON.stringify({
              table_config_rows_per_page: 3,
              table_config_page_no: 1,
              nutrition_type: "meal",
              profile_id: userData.profile_id,
              start_date: date,
              end_date: date,
            }),
            signal: controller.signal,
          }
        );
        const respJSON = await resp.json();
        if (respJSON?.statusCode?.toString().startsWith("2")) {
          const total = respJSON?.response?.reduce?.(
            (t, m) => {
              const totalObj =
                m.meal_info?.find?.((mi) => mi.name === "total") ?? {};
              t.total_protein += totalObj.protein_g || 0;
              t.total_carbohydrate += totalObj.carbohydrates_total_g || 0;
              t.total_fat += totalObj.fat_total_g || 0;
              t.total_calories += totalObj.calories || 0;
              return t;
            },
            {
              total_protein: 0,
              total_carbohydrate: 0,
              total_fat: 0,
              total_calories: 0,
            }
          );
          setNutritionData({
            breakfast_calories:
              respJSON?.response
                ?.find?.((m) => m.meal_type === "breakfast")
                ?.meal_info?.find?.((mi) => mi.name === "total")?.calories ?? 0,
            lunch_calories:
              respJSON?.response
                ?.find?.((m) => m.meal_type === "lunch")
                ?.meal_info?.find?.((mi) => mi.name === "total")?.calories ?? 0,
            dinner_calories:
              respJSON?.response
                ?.find?.((m) => m.meal_type === "dinner")
                ?.meal_info?.find?.((mi) => mi.name === "total")?.calories ?? 0,
            ...total,
          });
        } else
          throw new Error(
            respJSON?.message ?? "Failed to fetch Nutritional details."
          );
      } catch (err) {
        console.error(err);
      }
    }, 600);
    return () => {
      controller.abort();
      clearTimeout(timer);
    };
  }, [getToken, userData.profile_id, trackingDate]);

  const amr = useMemo(() => {
    try {
      const ageDifMs = Date.now() - new Date(userData.dob).getTime();
      const ageDate = new Date(ageDifMs);
      const age = Math.abs(ageDate.getUTCFullYear() - 1970);
      const bmr =
        userData.gender.toLowerCase() === "female"
          ? 655.1 +
            9.563 * userData.weight +
            1.85 * userData.height -
            4.676 * age
          : 66.47 +
            13.75 * userData.weight +
            5.003 * userData.height -
            6.755 * age;
      const ratio = [1.2, 1.375, 1.55, 1.725][state?.activity ?? 0];
      return bmr * ratio;
    } catch {
      return 0;
    }
  }, [state, userData]);

  const mrMax = useMemo(() => Math.max(amr - (amr % 500) + 500, 2500), [amr]);

  return (
    <div className="px-6 w-full pb-24">
      <div className="flex items-center mb-6">
        <Link to={-1} className="mr-3">
          <svg
            width="6"
            height="10"
            viewBox="0 0 10 17"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M9.42402 16.2142C9.99277 15.6454 9.99277 14.7267 9.42402 14.1579L3.76569 8.49959L9.42402 2.84126C9.99277 2.27251 9.99277 1.35376 9.42402 0.785009C8.85527 0.216259 7.93652 0.216259 7.36777 0.785009L0.674024 7.47876C0.105274 8.04751 0.105274 8.96626 0.674024 9.53501L7.36777 16.2288C7.92194 16.7829 8.85527 16.7829 9.42402 16.2142Z"
              fill="#fff"
            />
          </svg>
        </Link>
        <h3 className="text-white text-base font-semibold">Nutrition</h3>
      </div>
      <div className="border border-[#4C129E] overflow-hidden rounded-lg p-3 mb-3">
        <div className="mb-5 grid grid-cols-7 gap-2 justify-items-center">
          {[...Array(7).keys()].map((idx) => {
            const dt = new Date(trackingDate);
            dt.setDate(trackingDate.getDate() + (idx - 3));
            return (
              <button
                key={`date-${idx}`}
                type="button"
                onClick={() => setTrackingDate(dt)}
                className={`h-10 w-10 rounded-full ${
                  idx === 3 ? "bg-ternary" : "bg-secondary"
                } text-white text-xxs leading-tight font-bold whitespace-pre-line`}
              >
                {dt
                  .toLocaleDateString(undefined, {
                    weekday: "short",
                    day: "numeric",
                  })
                  .replace(" ", "\n")}
              </button>
            );
          })}
        </div>
        <div className="mb-4 flex items-center justify-between">
          <button
            className="shrink-0 h-5 w-5"
            type="button"
            onClick={() =>
              setTrackingDate((dt) => {
                const date = new Date(dt);
                date.setDate(dt.getDate() - 1);
                return date;
              })
            }
          >
            <Chevron className="rotate-90" />
          </button>
          <p className="text-sm font-medium">
            {trackingDate.toLocaleDateString(undefined, {
              weekday: "short",
              day: "numeric",
              month: "long",
            })}
          </p>
          <button
            className="shrink-0 h-5 w-5"
            type="button"
            onClick={() =>
              setTrackingDate((dt) => {
                const date = new Date(dt);
                date.setDate(dt.getDate() + 1);
                return date;
              })
            }
          >
            <Chevron className="-rotate-90" />
          </button>
        </div>
        <p className="text-sm font-light text-white text-center">
          Track your calories
        </p>
        <div className="mt-8 mb-12 relative">
          <p
            className="absolute -top-5 -translate-x-1/2 text-xxs whitespace-nowrap"
            style={{
              left:
                nutritionData.total_calories > 0
                  ? `${(nutritionData.total_calories / mrMax) * 100}%`
                  : "50%",
            }}
          >
            {nutritionData.total_calories?.toFixed(0)}&nbsp;cal
          </p>
          <div className="relative h-2.5 rounded-full overflow-hidden bg-white">
            <div
              className="absolute top-0 bottom-0 left-0 rounded-full bg-gradient-to-r from-[#B2D919] to-[#64BD40]"
              style={{
                width: `${(nutritionData.total_calories / mrMax) * 100}%`,
              }}
            />
            <div
              className="absolute top-0 bottom-0 bg-gradient-to-r from-[#46119B]/80 to-[#160E68]/80"
              style={{
                left: `${((amr - 500) / mrMax) * 100}%`,
                width: `${(500 / mrMax) * 100}%`,
              }}
            />
          </div>
          {/* <p
            className="absolute -bottom-5 -translate-x-1/2 text-xxs whitespace-nowrap"
            style={{ left: `${((amr - 500) / mrMax) * 100}%` }}
          >
            {(amr - 500).toFixed(0)}&nbsp;cal
          </p> */}
          {/* <p
            className="absolute -bottom-5 -translate-x-1/2 text-xxs whitespace-nowrap"
            style={{ left: `${(amr / mrMax) * 100}%` }}
          >
            {amr.toFixed(0)}&nbsp;cal
          </p> */}
          <p
            className="absolute top-4 -translate-x-1/2 max-w-24 text-xxs text-center truncate"
            style={{ left: `${((amr - 500 / 2) / mrMax) * 100}%` }}
          >
            Weight loss zone
            <br />
            {(amr - 500).toFixed(0)} to {amr.toFixed(0)}&nbsp;cal
          </p>
        </div>
        <div className="flex items-center justify-between px-3">
          <div className="flex flex-col items-center justify-center">
            <img src={Breakfast} alt="" />
            <p className="text-white text-xs font-medium mt-2">Breakfast</p>
            <p className="text-white text-xxs font-light">
              <span className="text-xs font-bold text-[#5366C6]">
                {nutritionData.breakfast_calories?.toFixed(0)}
              </span>
              &nbsp;cal
            </p>
          </div>
          <div className="flex flex-col justify-center items-center">
            <img src={Lunch} alt="" />
            <p className="text-white text-xs font-medium mt-2">Lunch</p>
            <p className="text-white text-xxs font-light">
              <span className="text-xs font-bold text-[#5366C6]">
                {nutritionData.lunch_calories?.toFixed(0)}
              </span>
              &nbsp;cal
            </p>
          </div>
          <div className="flex flex-col justify-center items-center">
            <img src={Dinner} alt="" />
            <p className="text-white text-xs font-medium mt-2">Dinner</p>
            <p className="text-white text-xxs font-light">
              <span className="text-xs font-bold text-[#5366C6]">
                {nutritionData.dinner_calories?.toFixed(0)}
              </span>
              &nbsp;cal
            </p>
          </div>
        </div>
      </div>
      <div className="border border-[#4C129E] rounded-lg p-3 mb-6 flex items-center justify-between">
        <div>
          <p className="text-xs font-semibold text-[#FF7F40]">
            {nutritionData.total_protein?.toFixed(0)}g
          </p>
          <p className="text-xxs text-white font-light">Protein</p>
        </div>
        <div>
          <p className="text-xs font-semibold text-[#FFCA40]">
            {nutritionData.total_carbohydrate?.toFixed(0)}g
          </p>
          <p className="text-xxs text-white font-light">Carbohydrate</p>
        </div>
        <div>
          <p className="text-xs font-semibold text-[#5366C6]">
            {nutritionData.total_fat?.toFixed(0)}g
          </p>
          <p className="text-xxs text-white font-light">Fat</p>
        </div>
        <div>
          <p className="text-xs font-semibold text-[#9AD026]">
            {nutritionData.total_calories?.toFixed(0)}cal
          </p>
          <p className="text-xxs text-white font-light">Calories</p>
        </div>
      </div>

      <Link to="/nutrition-results">
        <div className="bg-gradient-to-t from-[#812EFD]/0 to-[#5413A2] rounded-lg px-4 py-3 flex items-center justify-between mb-3">
          <div className="w-[65%]">
            <h3 className="text-base text-white font-semibold leading-[18px] mb-2">
              Log Your Meals
            </h3>
          </div>
          <img src={LogMeals} className="h-14" alt="" />
        </div>
      </Link>
      <Link to="/water-tracker">
        <div className="bg-gradient-to-t from-[#812EFD]/0 to-[#5413A2] rounded-lg px-4 py-3 flex items-center justify-between mb-3">
          <div className="w-[65%]">
            <h3 className="text-base text-white font-semibold leading-[18px] mb-2">
              Log Water Intake
            </h3>
          </div>
          <img src={Drink} className="h-14" alt="" />
        </div>
      </Link>
      <Link to="/nutration-recipes-list">
        <div className="bg-gradient-to-t from-[#812EFD]/0 to-[#5413A2] rounded-lg p-4 flex items-center justify-between mb-3">
          <div className="w-[65%]">
            <h3 className="text-base text-white font-semibold leading-[18px] mb-2">
              Top 10 Recommended Recipes
            </h3>
          </div>
          <img src={Recipes} className="h-14" alt="" />
        </div>
      </Link>
      <Link to="/nutrition-package">
        <div className="bg-gradient-to-t from-[#812EFD]/0 to-[#5413A2] rounded-lg p-4 flex items-center justify-between mb-3">
          <div className="w-[65%]">
            <h3 className="text-base text-white font-semibold leading-[18px] mb-2">
              Choose Your Nutritional Approach
            </h3>
          </div>
          <img src={Nutration} className="h-14" alt="" />
        </div>
      </Link>
    </div>
  );
};

export default NutrationList;
