const info = {
  Meditation1:
    "Here is a recipe for a flat lay salad with chicken and sesame seeds: Ingredients: 2 boneless, skinless chicken breasts, 2 tablespoons soy sauce, 2 tablespoons honey, 1 tablespoon sesame oil",
  Meditation2:
    "Good source of vitamins and minerals: Bananas are high in several vitamins and minerals, including vitamin C, vitamin B6, potassium, and magnesium",
  Meditation3:
    "Drinking enough water is an important part of maintaining good health, as it helps to keep the body hydrated, regulate body temperature, and support many important bodily",
  NutritionText1:
    "Here is a recipe for a flat lay salad with chicken and sesame seeds: Ingredients: 2 boneless, skinless chicken breasts, 2 tablespoons soy sauce, 2 tablespoons honey, 1 tablespoon sesame oil",
  NutritionText2:
    "Good source of vitamins and minerals: Bananas are high in several vitamins and minerals, including vitamin C, vitamin B6, potassium, and magnesium",
  NutritionText3:
    "Drinking enough water is an important part of maintaining good health, as it helps to keep the body hydrated, regulate body temperature, and support many important bodily",
  ExerciseText1:
    "Lower your body by bending your knees and pushing your hips back, as if you were sitting in a chair",
  ExerciseText2:
    "Keep your core engaged and your back straight throughout the exercise",
};

export default info;
