import { useState } from "react";
import { Link } from "react-router-dom";
import Medication1 from "../assets/images/medication/medication1.png";
import Medication2 from "../assets/images/medication/medication2.png";
import Medication3 from "../assets/images/medication/medication3.png";
import PlayBtn from "../assets/images/play-btn.png";
import data from "../screens/Data";

const MedicationCard = () => {
  const [showMore, setShowMore] = useState(false);
  const [showMore2, setShowMore2] = useState(false);
  const [showMore3, setShowMore3] = useState(false);
  const { Meditation1, Meditation2, Meditation3 } = data;
  return (
    <div>
      <div className="w-full">
        <div
          className="relative"
          data-aos="fade-up"
          data-aos-duration="1000"
          data-aos-delay="50"
        >
          <img src={Medication1} alt="" />
          <div className="absolute flex items-center justify-center top-0 bottom-0 left-0 right-0">
            <Link to="">
              <img src={PlayBtn} alt="" />
            </Link>
          </div>
        </div>
        <h3
          className="text-black text-[14px] font-semibold mb-2 mt-5"
          data-aos="fade-up"
          data-aos-duration="1000"
          data-aos-delay="100"
        >
          Mindful Breathing Technique
        </h3>
        <p
          className="text-[11px] text-[#000]/[.7] leading-[16px] mb-6"
          data-aos="fade-up"
          data-aos-duration="1000"
          data-aos-delay="150"
        >
          {showMore ? Meditation1 : `${Meditation1.substring(0, 145)}....`}
          <button
            className="text-[11px] text-secondary hover:text-primary ml-1"
            onClick={() => setShowMore(!showMore)}
          >
            {showMore ? "Show less" : "Show more"}
          </button>
        </p>
        <img
          src={Medication2}
          alt=""
          data-aos="fade-up"
          data-aos-duration="1000"
          data-aos-delay="200"
        />
        <h3
          className="text-black text-[14px] font-semibold mb-2 mt-5"
          data-aos="fade-up"
          data-aos-duration="1000"
          data-aos-delay="250"
        >
          Mindful Walking
        </h3>
        <p
          className="text-[11px] text-[#000]/[.7] leading-[16px] mb-6"
          data-aos="fade-up"
          data-aos-duration="1000"
          data-aos-delay="300"
        >
          {showMore2 ? Meditation2 : `${Meditation2.substring(0, 120)}....`}
          <button
            className="text-[11px] text-secondary hover:text-primary ml-1"
            onClick={() => setShowMore2(!showMore2)}
          >
            {showMore2 ? "Show less" : "Show more"}
          </button>
        </p>
        <div className="relative">
          <img
            src={Medication3}
            alt=""
            data-aos="fade-up"
            data-aos-duration="1000"
            data-aos-delay="350"
          />
          <div className="absolute flex items-center justify-center top-0 bottom-0 left-0 right-0">
            <Link to="">
              <img src={PlayBtn} alt="" />
            </Link>
          </div>
        </div>
        <h3
          className="text-black text-[14px] font-semibold mb-2 mt-5"
          data-aos="fade-up"
          data-aos-duration="1000"
          data-aos-delay="400"
        >
          Movement Meditation
        </h3>
        <p
          className="text-[11px] text-[#000]/[.7] leading-[16px] mb-6"
          data-aos="fade-up"
          data-aos-duration="1000"
          data-aos-delay="450"
        >
          {showMore3 ? Meditation3 : `${Meditation3.substring(0, 125)}....`}
          <button
            className="text-[11px] text-secondary hover:text-primary ml-1"
            onClick={() => setShowMore3(!showMore3)}
          >
            {showMore3 ? "Show less" : "Show more"}
          </button>
        </p>
      </div>
    </div>
  );
};

export default MedicationCard;
