import { Link } from "react-router-dom";
import EducationCard from "../components/EducationCard";
import Education1 from "../assets/images/education/education1.png";
import Education2 from "../assets/images/education/education2.png";
import Education3 from "../assets/images/education/education3.png";
import Education4 from "../assets/images/education/education4.png";
import Education5 from "../assets/images/education/education5.png";
import Education6 from "../assets/images/education/education6.png";

const Education = () => (
  <div className="px-6 flex flex-col mb-20">
    <div
      className="flex items-center mb-5"
      data-aos="fade-right"
      data-aos-duration="1000"
      data-aos-delay="50"
    >
      <Link to="/" className="mr-3">
        <svg
          width="6"
          height="10"
          viewBox="0 0 10 17"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            d="M9.42402 16.2142C9.99277 15.6454 9.99277 14.7267 9.42402 14.1579L3.76569 8.49959L9.42402 2.84126C9.99277 2.27251 9.99277 1.35376 9.42402 0.785009C8.85527 0.216259 7.93652 0.216259 7.36777 0.785009L0.674024 7.47876C0.105274 8.04751 0.105274 8.96626 0.674024 9.53501L7.36777 16.2288C7.92194 16.7829 8.85527 16.7829 9.42402 16.2142Z"
            fill="#192852"
          />
        </svg>
      </Link>
      <h3 className="text-primary text-[14px] font-semibold">Education</h3>
    </div>
    <div
      className="w-full"
      data-aos="fade-up"
      data-aos-duration="1000"
      data-aos-delay="100"
    >
      <Link to="/Education-details">
        <EducationCard
          img={Education1}
          tittle="The importance of regular exercise"
          subtittle="Regular exercise has numerous benefits for both physical and mental health, including reducing ...."
          color="rgba(241, 79, 92, 0.8)"
        />
      </Link>
    </div>
    <div
      className="w-full"
      data-aos="fade-up"
      data-aos-duration="1000"
      data-aos-delay="150"
    >
      <EducationCard
        img={Education2}
        tittle="Healthy eating habits"
        subtittle="A balanced diet that includes a variety of fruits, vegetables, whole grains, lean protein sources ...."
        color="rgba(255, 151, 61, 0.8)"
      />
    </div>
    <div
      className="w-full"
      data-aos="fade-up"
      data-aos-duration="1000"
      data-aos-delay="200"
    >
      <EducationCard
        img={Education3}
        tittle="Stress Management"
        subtittle="Chronic stress can have negative effects on physical and mental health. Encourage individuals ...."
        color="rgba(120, 65, 98, 0.8)"
      />
    </div>
    <div
      className="w-full"
      data-aos="fade-up"
      data-aos-duration="1000"
      data-aos-delay="250"
    >
      <EducationCard
        img={Education4}
        tittle="Know about sleep hygine"
        subtittle="Getting adequate sleep is important for maintaining good physical and mental health ...."
        color="rgba(13, 59, 164, 0.8)"
      />
    </div>
    <div
      className="w-full"
      data-aos="fade-up"
      data-aos-duration="1000"
      data-aos-delay="300"
    >
      <EducationCard
        img={Education5}
        tittle="Health risks of smoking"
        subtittle="Smoking is a major risk factor for many chronic diseases such as lung cancer, heart disease, and stroke ...."
        color="rgba(74, 19, 32, 0.8)"
      />
    </div>
    <div
      className="w-full"
      data-aos="fade-up"
      data-aos-duration="1000"
      data-aos-delay="350"
    >
      <EducationCard
        img={Education6}
        tittle="Omicron subvariant “Arcturus”"
        subtittle="There's a new SARS-CoV-2 variant in town, nicknamed Arcturus after the brightest star in the northern celestial hemisphere ..."
        color="rgba(251, 49, 78, 0.8)"
      />
    </div>

    <p
      className="mt-3 mb-6 text-[12px] text-[#3298F1] text-center"
      data-aos="fade-up"
      data-aos-duration="1000"
      data-aos-delay="350"
    >
      <Link to="" className="text-secondary">
        Load More
      </Link>
    </p>
  </div>
);

export default Education;
