import { Fragment, useEffect, useRef, useState } from "react";
import { Dialog, Transition } from "@headlessui/react";

import { api_key, sdkWrapperURL } from "../utils/api-url-list";
import { useAuth } from "../utils/auth";
import { logAnalyticsEvent } from "../utils/analytics";

// import { ReactComponent as Logo } from "../assets/icons/logo.svg";
// import { ReactComponent as GlowingHeart } from "../assets/icons/GlowingHeart.svg";
import AuthBackground from "../assets/images/AuthBackground.png";
import { ReactComponent as Loader } from "../assets/icons/Loader.svg";

const Login = () => {
  const { login } = useAuth();
  const [isModalOpen, setModalOpen] = useState(true);
  const [isLoading, setLoading] = useState(false);
  const [phoneNumber, setPhoneNumber] = useState("");
  const [phoneNumberError, setPhoneNumberError] = useState("");
  const [isOTPSent, setOTPSent] = useState(false);
  const otpTimerRef = useRef(null);
  const [otp, setOTP] = useState("");
  const [otpError, setOTPError] = useState("");
  const [otpTimer, setOTPTimer] = useState(0);

  const sendOTP = async () => {
    try {
      setLoading(true);
      setPhoneNumberError("");
      if (!/^\d{10}$/.test(phoneNumber)) {
        setPhoneNumber(phoneNumber.trim());
        setPhoneNumberError("Please enter a valid phone number.");
      } else {
        const resp = await fetch(sdkWrapperURL("/auth/send-otp"), {
          method: "POST",
          headers: { "Content-Type": "application/json" },
          body: JSON.stringify({
            api_key,
            action: "send",
            channel_id: "login_mobile",
            phone_no: phoneNumber,
          }),
        });
        const resp_json = await resp.json();
        if (resp_json.statusCode?.toString().startsWith("2")) {
          setOTPTimer(120);
          clearInterval(otpTimerRef.current);
          otpTimerRef.current = setInterval(
            () => setOTPTimer((t) => t - 1),
            1000
          );
          setOTP("");
          setOTPSent(true);
          logAnalyticsEvent("login_otp_requested", { phoneNumber });
        } else throw new Error(resp_json.message);
      }
    } catch (err) {
      cancelOTP();
      setPhoneNumberError(err.message);
    } finally {
      setLoading(false);
    }
  };

  const verifyOTP = async () => {
    try {
      setLoading(true);
      setOTPError("");
      if (!/^\d{6}$/.test(otp)) {
        setOTP(otp.trim());
        setOTPError("Incorrect OTP provided. Please try again");
      } else {
        const resp = await fetch(sdkWrapperURL("/auth/sign-in"), {
          method: "POST",
          headers: { "Content-Type": "application/json" },
          body: JSON.stringify({
            api_key,
            login_type: "auth",
            login_method: "otp_mobile",
            phone_no: phoneNumber,
            otp: otp,
          }),
        });
        const resp_json = await resp.json();
        if (resp_json.statusCode?.toString().startsWith("2")) {
          await login({
            id_token: resp_json.user.id_token,
            refresh_token: resp_json.user.refresh_token,
          });
          logAnalyticsEvent("login_otp_verified", { phoneNumber });
        } else throw new Error(resp_json.message);
      }
    } catch (err) {
      setOTPError(err.message);
    } finally {
      setLoading(false);
    }
  };

  const cancelOTP = (goback = true) => {
    setOTPTimer(0);
    clearInterval(otpTimerRef.current);
    setOTP("");
    setOTPError("");
    if (goback) {
      setOTPSent(false);
    }
  };

  useEffect(() => {
    if (otpTimer <= 0) {
      cancelOTP(false);
    }
  }, [otpTimer]);

  return (
    <section className="min-h-screen p-8 relative bg-primary text-white">
      <Transition
        show={isModalOpen}
        appear
        leave="transition-opacity duration-500"
        leaveFrom="opacity-100"
        leaveTo="opacity-0"
        as={Fragment}
      >
        <Dialog as={Fragment} onClose={() => {}}>
          <Dialog.Panel className="fixed inset-0 bg-primary flex flex-col items-stretch justify-end">
            <img
              className="h-full w-full object-contain object-bottom"
              style={{ height: "calc(100dvh - 10.75rem)" }}
              src={AuthBackground}
              alt="banner"
            />
            <div className="relative px-8 py-16 bg-gradient-to-b from-[#0B2990] to-[#680FAD]">
              <button
                className="block w-full px-4 py-2 outline-none rounded-full bg-white text-primary text-lg"
                type="button"
                onClick={() => {
                  logAnalyticsEvent("get_started");
                  setModalOpen(false);
                }}
              >
                Get Started
              </button>
            </div>
          </Dialog.Panel>
        </Dialog>
      </Transition>
      {isOTPSent ? (
        <>
          <h1 className="text-2xl font-semibold">
            Verify <span className="text-secondary">OTP</span>
          </h1>
          <h4 className="mt-3 font-light">
            Enter the 6-digits OTP sent to your phone number
          </h4>
          <form
            onSubmit={(e) => {
              e.preventDefault();
              if (!isLoading) {
                if (otpTimer > 0) verifyOTP();
                else sendOTP();
              }
            }}
          >
            <input
              id="otp"
              name="otp"
              className="block w-full mt-6 px-4 py-2 rounded-lg outline-none border-2 focus:border-secondary bg-white text-primary text-sm"
              placeholder={otpTimer > 0 ? "Enter OTP" : "Timed Out"}
              type="text"
              inputMode="numeric"
              autoComplete="one-time-code"
              autoFocus
              maxLength={6}
              onChange={(e) =>
                e.target.value.length <= 6 && setOTP(e.target.value)
              }
              value={otp}
              disabled={isLoading || otpTimer <= 0}
            />
            {otpError.length > 0 && (
              <p className="mt-1.5 mx-1.5 text-xxs text-error">{otpError}</p>
            )}
            {otpTimer > 0 && (
              <p className="mt-1.5 mx-1.5 font-light text-sm text-right">
                Resend OTP in {Math.floor(otpTimer / 60)}:
                {(otpTimer % 60).toString().padStart(2, "0")}
              </p>
            )}
            <button
              className="w-full flex items-center justify-center space-x-2.5 mt-6 px-5 py-2.5 rounded-full outline-none bg-gradient disabled:bg-secondary text-white"
              type="submit"
              disabled={isLoading}
            >
              <span>{otpTimer > 0 ? "Verify OTP" : "Resend OTP"}</span>
              {isLoading && <Loader className="shrink-0 h-4 w-4" />}
            </button>
            <button
              className="block mx-auto mt-6 outline-none text-sm font-medium"
              type="button"
              onClick={cancelOTP}
              disabled={isLoading}
            >
              Cancel
            </button>
          </form>
        </>
      ) : (
        <>
          <h1 className="text-2xl font-semibold">
            Get <span className="text-secondary">Started</span>
          </h1>
          <h4 className="mt-3 font-light">
            Login or sign up by simply verifying your phone number
          </h4>
          <form
            onSubmit={(e) => {
              e.preventDefault();
              if (!isLoading) sendOTP();
            }}
          >
            <input
              id="phone"
              name="phone"
              className="block w-full mt-6 px-4 py-2 rounded-lg outline-none border-2 focus:border-secondary bg-white text-primary text-sm"
              placeholder="Enter Phone Number"
              type="tel"
              inputMode="numeric"
              autoComplete="tel-national"
              maxLength={10}
              onChange={(e) =>
                e.target.value.length <= 10 && setPhoneNumber(e.target.value)
              }
              value={phoneNumber}
              disabled={isLoading}
            />
            {phoneNumberError.length > 0 && (
              <p className="mt-1.5 mx-1.5 text-xxs text-error">
                {phoneNumberError}
              </p>
            )}
            <button
              className="w-full flex items-center justify-center space-x-2.5 mt-6 px-5 py-2.5 rounded-full outline-none bg-gradient disabled:bg-secondary text-white"
              type="submit"
              disabled={isLoading}
            >
              <span>Send OTP</span>
              {isLoading && <Loader className="shrink-0 h-4 w-4" />}
            </button>
          </form>
        </>
      )}
    </section>
  );
};

export default Login;
