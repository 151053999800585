import { Fragment, useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import { Dialog, Transition } from "@headlessui/react";
import { useAuth } from "../utils/auth";
import { sdkWrapperURL } from "../utils/api-url-list";
// import EducationCard from "../components/EducationCard";
// import Wellbing1 from "../assets/images/wellbing/wellbing1.png";
// import Wellbing2 from "../assets/images/wellbing/wellbing2.png";
// import Wellbing3 from "../assets/images/wellbing/wellbing3.png";
// import Wellbing4 from "../assets/images/wellbing/wellbing4.png";
// import Wellbing5 from "../assets/images/wellbing/wellbing5.png";
// import RecommendationCard from "../components/RecommendationCard";
import FitnessCard from "../assets/images/fitness-card.png";
import WellbeingCard from "../assets/images/mental-wellbing-card.png";
import NutrationCard from "../assets/images/nutrition-card.png";
import SleepCard from "../assets/images/better-sleep-card.png";
// import MentalWellbeing from "../assets/health-icon/Mental-Wellbeing.svg";
// import Nutration from "../assets/health-icon/nutration.svg";
// import sleep from "../assets/health-icon/sleep.svg";

const DailyWellbing = () => {
  const navigate = useNavigate();
  const {
    userData: { profile_id },
    subscriptionPlanDetails: { packageName },
    getToken,
  } = useAuth();
  const [isSuccessModalOpen, setSuccessModalOpen] = useState(false);

  const subscribe = async () => {
    try {
      const token = await getToken();
      const resp = await fetch(sdkWrapperURL("/users/add-saved-products"), {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          Authorization: token,
        },
        body: JSON.stringify({
          profile_id,
          product_id: "a5a5e1b5-fe4a-4224-b1bb-a37a9c4ca2d6",
          interest_filtration: "ACCEPT",
          application_info: {},
          module_type: "content",
        }),
      });
      const resp_json = await resp.json();
      if (resp_json.statusCode?.toString().startsWith("2"))
        setSuccessModalOpen(true);
      else throw new Error(resp_json.message);
    } catch (err) {
      console.error(err);
    }
  };

  const closeModal = () => {
    setSuccessModalOpen(false);
    navigate(-1);
  };

  return (
    <div className="px-6 mb-28">
      <div
        className="flex items-center mb-5"
        data-aos="fade-right"
        data-aos-duration="1000"
        data-aos-delay="50"
      >
        <Link to={-1} className="mr-3">
          <svg
            width="6"
            height="10"
            viewBox="0 0 10 17"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M9.42402 16.2142C9.99277 15.6454 9.99277 14.7267 9.42402 14.1579L3.76569 8.49959L9.42402 2.84126C9.99277 2.27251 9.99277 1.35376 9.42402 0.785009C8.85527 0.216259 7.93652 0.216259 7.36777 0.785009L0.674024 7.47876C0.105274 8.04751 0.105274 8.96626 0.674024 9.53501L7.36777 16.2288C7.92194 16.7829 8.85527 16.7829 9.42402 16.2142Z"
              fill="#fff"
            />
          </svg>
        </Link>
        <h3 className="text-white text-sm font-semibold">Daily Well-being</h3>
      </div>
      {packageName === "FREEMIUM" ? (
        <div className="flex flex-col items-center p-6 rounded-2xl bg-gradient-to-b from-[#063BC4] to-[#680FAD]/90 text-white text-center">
          <h4 className="font-semibold">Available Only for Members</h4>
          <p className="mt-5 text-sm font-light">
            Join our community to unlock this feature & other exclusive wellness
            offerings. Become a member today and take your health journey to the
            next level!
          </p>
          <button
            className="mt-6 px-6 py-3 rounded-full outline-none bg-white text-secondary text-sm font-medium"
            type="button"
            onClick={subscribe}
          >
            Request Membership
          </button>
          <Transition show={isSuccessModalOpen} as={Fragment}>
            <Dialog as={Fragment} onClose={closeModal}>
              <Transition.Child
                className="fixed top-0 bottom-0 left-0 right-0 bg-black/70 flex items-center justify-center"
                enter="duration-300"
                enterFrom="opacity-0"
                enterTo="opacity-100"
                leave="duration-300 delay-150"
                leaveFrom="opacity-100"
                leaveTo="opacity-0"
              >
                <Transition.Child
                  as={Fragment}
                  enter="delay-150 duration-200"
                  enterFrom="opacity-0 scale-50"
                  enterTo="opacity-100 scale-100"
                  leave="duration-100"
                  leaveFrom="opacity-50 scale-50"
                  leaveTo="opacity-0 scale-0"
                >
                  <Dialog.Panel className="w-4/5 bg-white rounded-2xl p-6 flex flex-col items-center text-center">
                    <Dialog.Title className="text-secondary font-semibold">
                      Request Submitted Successfully!
                    </Dialog.Title>
                    <Dialog.Description className="mt-5 text-darkgray text-sm font-light">
                      Your membership request has been submitted successfully.
                      Our team will get back to you shortly. Welcome to the path
                      of better health and wellness!
                    </Dialog.Description>
                    <button
                      className="mt-6 px-6 py-3 rounded-full outline-none bg-gradient text-white text-sm font-medium"
                      type="button"
                      onClick={closeModal}
                    >
                      Ok
                    </button>
                  </Dialog.Panel>
                </Transition.Child>
              </Transition.Child>
            </Dialog>
          </Transition>
        </div>
      ) : (
        <>
          <div
            className="w-full mb-4"
            data-aos="fade-up"
            data-aos-duration="1000"
            data-aos-delay="100"
          >
            <Link
              to="/fitness-package"
              className="rounded-[12px] px-4 breathing-btn h-[145px] justify-between text-[14px] text-white flex items-center font-normal capitalize w-full"
              style={{
                backgroundImage: `url(${FitnessCard})`,
                backgroundPosition: "center",
                backgroundSize: "cover",
                backgroundRepeat: "no-repeat",
              }}
            >
              <div className="w-full">
                <h3 className="text-base font-semibold text-white mb-8">
                  Fitness
                </h3>
                <p className="text-sm text-white leading-[18px]">
                  Push yourself without
                  <br /> exceeding your limits.
                </p>
              </div>
            </Link>
          </div>
          <div
            className="w-full mb-4"
            data-aos="fade-up"
            data-aos-duration="1000"
            data-aos-delay="100"
          >
            <Link
              to="/wellbing"
              className="rounded-[12px] px-4 breathing-btn h-[145px] justify-between text-[14px] text-white flex items-center font-normal capitalize w-full"
              style={{
                backgroundImage: `url(${WellbeingCard})`,
                backgroundPosition: "center",
                backgroundSize: "cover",
                backgroundRepeat: "no-repeat",
              }}
            >
              <div className="w-full">
                <h3 className="text-base font-semibold text-white mb-8">
                  Mental Wellbeing
                </h3>
                <p className="text-sm text-white leading-[18px]">
                  Enhance your mental
                  <br /> health and your resilience.
                </p>
              </div>
            </Link>
          </div>
          <div
            className="w-full mb-4"
            data-aos="fade-up"
            data-aos-duration="1000"
            data-aos-delay="100"
          >
            <Link
              to="/nutration-list"
              className="rounded-[12px] px-4 breathing-btn h-[145px] justify-between text-[14px] text-white flex items-center font-normal capitalize w-full"
              style={{
                backgroundImage: `url(${NutrationCard})`,
                backgroundPosition: "center",
                backgroundSize: "cover",
                backgroundRepeat: "no-repeat",
              }}
            >
              <div className="w-full">
                <h3 className="text-base font-semibold text-white mb-8">
                  Nutrition
                </h3>
                <p className="text-sm text-white leading-[18px]">
                  Your health starts with
                  <br /> what you eat.
                </p>
              </div>
            </Link>
          </div>
          <div
            className="w-full mb-4"
            data-aos="fade-up"
            data-aos-duration="1000"
            data-aos-delay="100"
          >
            <Link
              to="/better-sleep"
              className="rounded-[12px] px-4 breathing-btn h-[145px] justify-between text-[14px] text-white flex items-center font-normal capitalize w-full"
              style={{
                backgroundImage: `url(${SleepCard})`,
                backgroundPosition: "center",
                backgroundSize: "cover",
                backgroundRepeat: "no-repeat",
              }}
            >
              <div className="w-full">
                <h3 className="text-base font-semibold text-white mb-8">
                  Better Sleep
                </h3>
                <p className="text-sm text-white leading-[18px]">
                  Enhance the quality of
                  <br /> your sleep.
                </p>
              </div>
            </Link>
          </div>
        </>
      )}
      {/* <div data-aos="fade-up" data-aos-duration="1000" data-aos-delay="100">
        <Link to="/Wellbing-details">
          <EducationCard
            img={Wellbing1}
            tittle="Meditation"
            subtittle="Learn practices that involves focusing the mind and achieving a state of mental clarity and emotional calm"
            color="rgba(176, 31, 72, 0.8)"
          />
        </Link>
      </div>
      <div data-aos="fade-up" data-aos-duration="1000" data-aos-delay="150">
        <Link to="/Breath-lab">
          <EducationCard
            img={Wellbing2}
            tittle="Breathing & other exercises"
            subtittle="Practice breathing & other exercises to relax, reduce stress, and improve overall well-being"
            color="rgba(158, 132, 17, 0.8)"
          />
        </Link>
      </div> */}
      {/* <div data-aos="fade-up" data-aos-duration="1000" data-aos-delay="250">
        <EducationCard
          img={Wellbing3}
          tittle="For better sleep"
          subtittle="Listening to calming sounds and stories can be a great way to help you relax and fall asleep faster"
          color="rgba(15, 79, 134, 0.8)"
        />
      </div>
      <div data-aos="fade-up" data-aos-duration="1000" data-aos-delay="250">
        <EducationCard
          img={Wellbing4}
          tittle="Nutriment"
          subtittle="A program aimed at achieving optimal health and wellness ....."
          color="rgba(31, 40, 55, 0.8)"
        />
      </div>
      <div data-aos="fade-up" data-aos-duration="1000" data-aos-delay="250">
        <Link to="/Analysis-Data">
          <EducationCard
            img={Wellbing5}
            tittle="Heart Care"
            subtittle="A program aimed at achieving optimal health and wellness ....."
            color="rgba(214, 71, 102, 0.8)"
          />
        </Link>
      </div> */}
    </div>
  );
};

export default DailyWellbing;
