import { useMemo } from "react";
import { Link } from "react-router-dom";
import { Disclosure } from "@headlessui/react";
import {
  Bar,
  BarChart,
  Cell,
  ResponsiveContainer,
  Tooltip,
  XAxis,
  YAxis,
} from "recharts";

import { ReactComponent as Chevron } from "../assets/icons/Chevron.svg";
import { ReactComponent as Info } from "../assets/icons/Info.svg";

const dateConfig = (type) => {
  switch (type) {
    case "yearly":
      return { month: "short" };
    case "monthly":
      return { day: "numeric", month: "short" };
    default:
    case "weekly":
      return { weekday: "short" };
    case "daily":
      return { hour: "2-digit", minute: "2-digit", hour12: true };
  }
};

export const Chart = ({ type = "weekly", data = [], unit = "" }) => (
  <ResponsiveContainer width="100%" height={200}>
    <BarChart data={data} margin={{ right: 5, left: -25, top: 0, bottom: 0 }}>
      <XAxis
        dataKey="label"
        type="category"
        // interval="equidistantPreserveStart"
        interval="preserveStartEnd"
        tickLine={false}
        tickFormatter={(v) =>
          new Date(v).toLocaleString(undefined, dateConfig(type))
        }
        stroke="#A977F9"
        fontFamily="Poppins"
        fontWeight="400"
        fontSize="10px"
      />
      <YAxis
        dataKey="value"
        type="number"
        stroke="#A977F9"
        fontFamily="Poppins"
        fontWeight="400"
        fontSize="10px"
      />
      <Bar dataKey="value" unit={unit} barSize={8} radius={[10, 10, 0, 0]}>
        {data.map((entry, index) => (
          <Cell key={`cell-${index}`} fill={entry.color} />
        ))}
      </Bar>
      <Tooltip
        cursor={false}
        content={({ active, payload }) =>
          active &&
          payload?.length > 0 && (
            <div className="px-1 py-0.5 rounded-md leading-none border border-secondary bg-white text-center">
              <p>
                <span
                  className="text-secondary text-xs font-semibold"
                  style={{ color: payload?.[0]?.payload?.color }}
                >
                  {payload?.[0]?.payload?.value}
                </span>
                &nbsp;
                <span className="text-xxs text-primary font-medium">
                  {payload?.[0]?.unit}
                </span>
              </p>
              <p className="text-xxs text-primary/70">
                {new Date(payload?.[0]?.payload?.label).toLocaleString(
                  undefined,
                  dateConfig(type)
                )}
              </p>
            </div>
          )
        }
      />
    </BarChart>
  </ResponsiveContainer>
);

const Graph = ({
  icon,
  label = "",
  chartType = "weekly",
  chartData = [],
  legend = [],
  unit = "",
  defaultExpanded = false,
  viewInfo = () => {},
  historyDetail = {},
}) => {
  const chartDataNotEmpty = useMemo(() => {
    if (chartData?.length > 0) {
      if (label === "Blood Pressure")
        return (
          chartData[0].some((d) => Boolean(d.value)) ||
          chartData[1].some((d) => Boolean(d.value))
        );
      else return chartData.some((d) => Boolean(d.value));
    } else return false;
  }, [chartData, label]);

  return (
    <Disclosure
      defaultOpen={defaultExpanded}
      as="div"
      className="px-6 py-4 rounded-2xl bg-[#031434] shadow-box"
    >
      <Disclosure.Button as="div" className="flex items-center space-x-4">
        {({ open }) => (
          <>
            <div className="flex-shrink-0 p-2.5 rounded-full bg-gradient-to-b from-[#5517A7] to-[#031434] text-white">
              {icon}
            </div>
            <div className="flex-grow flex items-center space-x-2">
              <h2 className="text-white text-sm font-medium">{label}</h2>
              <button
                type="button"
                className="flex-shrink-0 h-4 w-4 text-white/60"
                onClick={(e) => {
                  e.stopPropagation();
                  viewInfo();
                }}
              >
                <Info className="h-full w-full" />
              </button>
            </div>
            <Chevron
              className={`flex-shrink-0 h-4 w-4 text-white/60 ${
                open ? "rotate-180" : "rotate-0"
              }`}
            />
          </>
        )}
      </Disclosure.Button>
      <Disclosure.Panel className="mt-6">
        {chartDataNotEmpty ? (
          label === "Blood Pressure" ? (
            <>
              <Chart type={chartType} data={chartData[0]} unit={unit} />
              <Chart type={chartType} data={chartData[1]} unit={unit} />
            </>
          ) : (
            <Chart type={chartType} data={chartData} unit={unit} />
          )
        ) : (
          <p className="px-6 py-6 bg-[#05193f] rounded-lg text-center text-xs text-white/50">
            No data available
          </p>
        )}
        <div className="mt-4 flex items-center justify-between space-x-1">
          <div className="flex flex-wrap items-center justify-start">
            {legend.map(({ label, color }, index) => (
              <div
                key={`legend-item-${index}`}
                className="mr-3 my-1 flex items-center space-x-1.5"
              >
                <div
                  className="flex-shrink-0 h-3 w-3 rounded-full"
                  style={{ backgroundColor: color }}
                />
                <h6 className="text-xxs leading-3 text-white">{label}</h6>
              </div>
            ))}
          </div>
          <Link
            className="flex-shrink-0 px-3 py-1.5 rounded-xl bg-gradient-to-r from-[#063BC4] to-[#680FAD] text-white text-xs"
            to="/history"
            state={historyDetail}
          >
            View History
          </Link>
        </div>
      </Disclosure.Panel>
    </Disclosure>
  );
};

export default Graph;
