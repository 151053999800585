import { useLocation, Link } from "react-router-dom";

import { constants } from "../../utils/ranges";

import { ReactComponent as BackArrow } from "../../assets/icons/BackArrow.svg";

const StressIndex = () => {
  const location = useLocation();

  const stressLevel = location?.state?.stress_index;
  const selectedRangeIndex = constants["Stress Level"].ranges.reduce(
    (p, c, ci) => (stressLevel > c.point ? ci : p),
    0
  );
  const selectedRange = constants["Stress Level"].ranges[selectedRangeIndex];
  let detailTitle = "";
  let detailDescription = "";
  if ((location?.state?.rmssd ?? "--") !== "--") {
    if (location?.state?.rmssd < 65) {
      if (
        location?.state?.heart_rate > 100 ||
        location?.state?.bp_sys > 130 ||
        location?.state?.bp_dia > 90
      ) {
        detailTitle = "More Sympathetic Interactions";
        detailDescription =
          "Although HRV manifests as a function of your heart rate, it actually originates from your nervous system. Your autonomic nervous system, which controls the involuntary aspects of your physiology, has two branches, parasympathetic (deactivating) and sympathetic (activating).\n\nThe sympathetic nervous system (often called “fight or flight”) reflects responses to things like stress and exercise, and increases your heart rate and blood pressure.";
      } else {
        detailTitle = "More Parasympathetic Interactions";
        detailDescription =
          "Although HRV manifests as a function of your heart rate, it actually originates from your nervous system. Your autonomic nervous system, which controls the involuntary aspects of your physiology, has two branches, parasympathetic (deactivating) and sympathetic (activating)\n\nThe parasympathetic nervous system (often referred to as “rest and digest”) handles inputs from internal organs, like digestion or your fingernails and hair growing.";
      }
    } else {
      detailTitle = "Balanced ANS Interactions";
      detailDescription =
        "When you have high heart rate variability, it means that your body is responsive to both sets of inputs (parasympathetic and sympathetic). This is a sign that your nervous system is balanced, and that your body is very capable of adapting to its environment and performing at its best.";
    }
  } else {
    detailDescription =
      "Not enought data to process. This happens when the signal strength of the scan is poor due to improper light or too much movements.";
  }

  return (
    <section className="px-6 pb-24">
      <div className="flex items-center text-white">
        <Link className="flex-shrink-0 p-1 pr-2.5" to={-1}>
          <BackArrow className="h-2.5 w-2.5" />
        </Link>
        <h3 className="text-sm font-medium">Stress Index</h3>
      </div>

      <div className="py-6 space-y-6">
        <div className="px-6 py-4 rounded-2xl bg-gradient-to-b from-[#5517A7] to-[#02102B] shadow-box">
          <div className="flex items-center justify-between space-x-4">
            {selectedRange.icon}
            <div className="flex-grow">
              <h2 className="text-white text-xs font-medium">Stress Level</h2>
              <h4
                className="mt-1 text-xxs font-semibold uppercase"
                style={{ color: selectedRange.color }}
              >
                {selectedRange.label}
              </h4>
            </div>
            <div className="flex-shrink-0 text-right font-semibold">
              <h4 className="text-[#C924A4] text-xl">{stressLevel}</h4>
              <h5 className="text-white/80 text-xxs">
                {constants["Stress Level"].unit}
              </h5>
            </div>
          </div>
          <div className="mt-4">
            <h5 className="text-[#812EFD] text-xs font-medium">
              Your Stress Level is {selectedRange.label}
            </h5>
            <p className="mt-1.5 text-white/80 text-xxs">
              {constants["Stress Level"].description}
            </p>
          </div>
        </div>
        <div className="p-6 rounded-xl bg-[#031434] space-y-1.5">
          {detailTitle.length > 0 && (
            <h3 className="text-[#812EFD] text-sm font-medium">
              {detailTitle}
            </h3>
          )}
          <p className="text-white text-xxs text-justify">
            {detailDescription}
          </p>
          <h5 className="text-[#812EFD] text-xs text-center font-medium bg-[#02102b] rounded-3xl py-2 mt-4">
            Autonomic Nervous Systems (ANS)
          </h5>
        </div>
      </div>
      <Link
        className="block max-w-max mx-auto bg-gradient-to-r from-[#063BC4] to-[#680FAD] rounded-[40px] px-8 py-2 text-[11px] text-white hover:bg-secondary active:bg-secondary hover:text-white active:text-white focus:outline-none"
        to="/history"
        state={{
          title: "Stress Index",
          ranges: constants["Stress Level"].ranges.map(
            ({ icon, ...rest }) => rest
          ),
          keys: ["heart_scores", "stress_index"],
        }}
      >
        View History
      </Link>
    </section>
  );
};

export default StressIndex;
