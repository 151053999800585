import { Link } from "react-router-dom";
import MyVitals from "../assets/icons/my-vitals.svg";
import Appointment from "../assets/icons/appointment.svg";
import Medication from "../assets/icons/medication.svg";
import Documents from "../assets/icons/documents.svg";
import Doctor from "../components/Doctor";
import Avatar from "../assets/images/doctor1.png";
import Avatar2 from "../assets/images/doctor2.png";
import Avatar3 from "../assets/images/doctor3.png";
import Avatar4 from "../assets/images/doctor4.png";

const DoctorList = () => (
  <div className="px-6">
    <div className="flex items-center mb-10">
      <Link to="/" className="mr-3">
        <svg
          width="6"
          height="10"
          viewBox="0 0 10 17"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            d="M9.42402 16.2142C9.99277 15.6454 9.99277 14.7267 9.42402 14.1579L3.76569 8.49959L9.42402 2.84126C9.99277 2.27251 9.99277 1.35376 9.42402 0.785009C8.85527 0.216259 7.93652 0.216259 7.36777 0.785009L0.674024 7.47876C0.105274 8.04751 0.105274 8.96626 0.674024 9.53501L7.36777 16.2288C7.92194 16.7829 8.85527 16.7829 9.42402 16.2142Z"
            fill="#192852"
          />
        </svg>
      </Link>
      <h3 className="text-secondary text-[14px] font-semibold">
        Consult Doctor
      </h3>
    </div>
    <ul className="flex items-center justify-between">
      <li
        className="relative"
        data-aos="fade-right"
        data-aos-delay="200"
        data-aos-duration="1000"
      >
        <Link to="" className="flex items-center justify-center flex-col">
          <div className="bg-[#4682EF] rounded-[5px] w-[50px] h-[50px] flex items-center justify-center">
            <span className="bg-[#6598F6] w-[13px] h-[13px] rounded-full posi absolute right-[-3px] top-[-3px] z-50"></span>
            <img src={MyVitals} alt="My Vitals" />
          </div>
          <h4 className="text-[#182A4E]/[.8] text-[11px] font-medium mt-3">
            My Vitals
          </h4>
        </Link>
      </li>
      <li
        className="relative"
        data-aos="fade-right"
        data-aos-delay="150"
        data-aos-duration="1000"
      >
        <Link to="" className="flex items-center justify-center flex-col">
          <div className="bg-[#DCEDFF] rounded-[5px] w-[50px] h-[50px] flex items-center justify-center">
            <img src={Appointment} alt="My Appointments" />
            <span className="bg-[#F0F7FF] w-[13px] h-[13px] rounded-full posi absolute right-[22px] top-[-3px] z-50"></span>
          </div>
          <h4 className="text-[#182A4E]/[.8] text-[11px] font-medium mt-3">
            My Appointments
          </h4>
        </Link>
      </li>
      <li
        className="relative"
        data-aos="fade-right"
        data-aos-delay="100"
        data-aos-duration="1000"
      >
        <Link
          to="/Medication"
          className="flex items-center justify-center flex-col"
        >
          <div className="bg-[#FB7754] rounded-[5px] w-[50px] h-[50px] flex items-center justify-center">
            <img src={Medication} alt="Medications/Labs" />
            <span className="bg-[#FF8E70] w-[13px] h-[13px] rounded-full posi absolute right-[22px] top-[-3px] z-50"></span>
          </div>
          <h4 className="text-[#182A4E]/[.8] text-[11px] font-medium mt-3">
            Medications/Labs
          </h4>
        </Link>
      </li>
      <li
        className="relative"
        data-aos="fade-right"
        data-aos-delay="50"
        data-aos-duration="1000"
      >
        <Link to="" className="flex items-center justify-center flex-col">
          <div className="bg-[#1B4083] rounded-[5px] w-[50px] h-[50px] flex items-center justify-center">
            <img src={Documents} alt="Documents" />
            <span className="bg-[#315BA8] w-[13px] h-[13px] rounded-full posi absolute right-[5px] top-[-3px] z-50"></span>
          </div>
          <h4 className="text-[#182A4E]/[.8] text-[11px] font-medium mt-3">
            Documents
          </h4>
        </Link>
      </li>
    </ul>
    <div className="mt-5">
      <h5
        className="text-secondary text-[14px] font-medium"
        data-aos="fade-up"
        data-aos-delay="50"
        data-aos-duration="1000"
      >
        Find A Doctor
      </h5>
      <input
        id="phone"
        name="phone"
        className="block w-full mt-2 px-4 py-2 rounded-lg outline-none focus:border-secondary border text-[12px] text-primary placeholder:text-[#5C6984]/[.6]"
        placeholder="Search by doctor’s name or speciality"
        type="text"
        inputMode="text"
        autoComplete="text"
        autoFocus
        maxLength={10}
        data-aos="fade-up"
        data-aos-delay="100"
        data-aos-duration="1000"
      />
    </div>
    <div className="w-full mt-6 mb-28">
      <h5
        className="text-secondary text-[14px] font-medium mb-3"
        data-aos="fade-up"
        data-aos-delay="150"
        data-aos-duration="1000"
      >
        Recent Active Doctors
      </h5>
      <ul className="flex flex-col">
        <li data-aos="fade-up" data-aos-delay="150" data-aos-duration="1000">
          <Doctor
            img={Avatar}
            tittle="Dr. A K Bardhan"
            subtittle="M.B.B.S,  F.R.C.S   (24yrs Exp)"
            designation="General Physician"
            time="12:00 PM-07:00 PM"
            date="Mon-Fri"
          />
        </li>
        <li data-aos="fade-up" data-aos-delay="150" data-aos-duration="1000">
          <Doctor
            img={Avatar2}
            tittle="Dr. Smita Sen"
            subtittle="MD (Cal)   (20yrs Exp)"
            designation="Cardiac Surgeon"
            time="10:00 AM-02:00 PM"
            date="Mon, Tue, Fri"
          />
        </li>
        <li data-aos="fade-up" data-aos-delay="150" data-aos-duration="1000">
          <Doctor
            img={Avatar3}
            tittle="Dr. Ganeshan Iyer"
            subtittle="M.B.B.S   (34yrs Exp)"
            designation="General Physician"
            time="12:00 PM-04:00 PM"
            date="Mon-Fri"
          />
        </li>
        <li data-aos="fade-up" data-aos-delay="150" data-aos-duration="1000">
          <Doctor
            img={Avatar4}
            tittle="Dr. A Banerjee"
            subtittle="M.B.B.S,  MD   (12yrs Exp)"
            designation="General Physician"
            time="12:00 PM-07:00 PM"
            date="Mon-Fri"
          />
        </li>
      </ul>
    </div>
  </div>
);

export default DoctorList;
