import { Link } from "react-router-dom";
import StartScanIcon from "../assets/images/StartScan.webp";
import { logAnalyticsEvent } from "../utils/analytics";

const StartScan = () => (
  <section className="min-h-[85vh] px-8 pt-2 pb-24 flex flex-col items-center justify-center text-center">
    <img className="w-4/5" src={StartScanIcon} alt="Start Scan Illustration" />
    <h1 className="mt-6 text-xl font-bold text-white">
      Scan vitals to personalize your health experience
    </h1>
    <h3 className="mt-3 font-light  text-white">
      Do a self-health check in less than one minute.
    </h3>
    <Link
      className="block mt-6 w-full rounded-full outline-none px-5 py-2.5 bg-gradient text-white"
      to="/select-scan"
      replace
      onClick={() => {
        window?.ReactNativeWebView?.postMessage?.("prompt-camera-permission");
        logAnalyticsEvent("start_scan");
      }}
    >
      Start Scan
    </Link>
    <Link to={-1} className="block mt-4 text-sm font-medium  text-white">
      Back
    </Link>
  </section>
);

export default StartScan;
