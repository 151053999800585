import { Link } from "react-router-dom";
import DoctorDetails from "../components/DoctorDetails";
import Avatar from "../assets/images/doctor1.png";
import ClinicLogo1 from "../assets/images/clinic-img.png";

const DoctorAppointment = () => (
  <div
    className="px-6 flex justify-between flex-col h-[85vh]"
    data-aos="fade-up"
    data-aos-delay="100"
    data-aos-duration="1000"
  >
    <div>
      <div className="flex items-center mb-10">
        <Link to="/Doctor-list" className="mr-3">
          <svg
            width="6"
            height="10"
            viewBox="0 0 10 17"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M9.42402 16.2142C9.99277 15.6454 9.99277 14.7267 9.42402 14.1579L3.76569 8.49959L9.42402 2.84126C9.99277 2.27251 9.99277 1.35376 9.42402 0.785009C8.85527 0.216259 7.93652 0.216259 7.36777 0.785009L0.674024 7.47876C0.105274 8.04751 0.105274 8.96626 0.674024 9.53501L7.36777 16.2288C7.92194 16.7829 8.85527 16.7829 9.42402 16.2142Z"
              fill="#192852"
            />
          </svg>
        </Link>
        <h3 className="text-primary text-[14px] font-semibold">
          Doctor Appointment
        </h3>
      </div>
      <DoctorDetails
        img={Avatar}
        tittle="Dr. A K Bardhan"
        subtittle="M.B.B.S,  F.R.C.S   (24yrs Exp)"
        designation="General Physician"
      />
      <div className="flex items-center justify-between mt-5 mb-6">
        <div className="">
          <h3 className="text-[#AAADAE] text-[12px] font-medium mb-1">
            Clinic Name:
          </h3>
          <p className="text-[#182A4E] text-[11px]">Apolo Clinic</p>
        </div>
        <div>
          <img src={ClinicLogo1} alt="" />
        </div>
      </div>
      <div className="">
        <h3 className="text-[#AAADAE] text-[12px] font-medium mb-1">
          Clinic Address:
        </h3>
        <p className="text-[#182A4E] text-[11px] mb-6">
          B.M Dutta Road, North Dumdum, Kolkata.
        </p>
        <h3 className="text-[#AAADAE] text-[12px] font-medium mb-1">Fees</h3>
        <p className="text-[#182A4E] text-[11px] mb-6">INR 1000/-</p>
        <h3 className="text-[#AAADAE] text-[12px] font-medium mb-1">
          Biography:
        </h3>
        <p className="text-[#182A4E] text-[11px] mb-6 leading-4">
          Board certification has become one of the most trusted indicators used
          to identify highly qualified and reputable physicians.
        </p>
        <h3 className="text-[#AAADAE] text-[12px] font-medium mb-1">
          Working Time:
        </h3>
        <p className="text-[#182A4E] text-[11px] mb-6">
          Mon - Fri ( 12.00 PM to 7.00 PM )
        </p>
      </div>
    </div>
    <button
      className="w-full flex items-center justify-center space-x-2 mt-6 px-4 py-2 rounded-full bg-secondary disabled:bg-darkgray text-white text-[12px]"
      type="submit"
    >
      Get Appointment{" "}
    </button>
  </div>
);

export default DoctorAppointment;
