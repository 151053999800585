import { Link, useLocation } from "react-router-dom";
import { ReactComponent as PlayIcon } from "../assets/icons/MusicPlay.svg";

const VisualizationTranquility = () => {
  const {
    state: { name, data },
  } = useLocation();
  return (
    <div className="pb-28 w-full">
      <div className="px-6">
        <div className="flex items-center mb-5">
          <Link to={-1} className="mr-3">
            <svg
              width="6"
              height="10"
              viewBox="0 0 10 17"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M9.42402 16.2142C9.99277 15.6454 9.99277 14.7267 9.42402 14.1579L3.76569 8.49959L9.42402 2.84126C9.99277 2.27251 9.99277 1.35376 9.42402 0.785009C8.85527 0.216259 7.93652 0.216259 7.36777 0.785009L0.674024 7.47876C0.105274 8.04751 0.105274 8.96626 0.674024 9.53501L7.36777 16.2288C7.92194 16.7829 8.85527 16.7829 9.42402 16.2142Z"
                fill="#fff"
              />
            </svg>
          </Link>
          <h3 className="text-white text-[14px] font-semibold">{name}</h3>
        </div>
      </div>
      <div className="relative px-6 flex flex-col">
        {data.map((visualization, index) => (
          <Link
            key={`visualization-${index}`}
            className="block mb-5"
            to="/wellbeing-detail"
            state={{
              name: visualization.tab_heading,
              desc: visualization.tab_content,
              poster: visualization.tab_content_poster,
              video: visualization.tab_content_video,
            }}
          >
            <div className="relative rounded-xl overflow-hidden">
              <img
                src={visualization.tab_content_poster}
                alt={visualization.tab_heading}
              />
              <PlayIcon className="absolute top-1/2 left-1/2 -translate-x-1/2 -translate-y-1/2 w-10 p-1 rounded-full text-white bg-[#5D14B0]/50" />
            </div>
            <h3 className="text-sm mt-2 text-[#812EFD] font-medium">
              {visualization.tab_heading}
            </h3>
          </Link>
        ))}
      </div>
    </div>
  );
};

export default VisualizationTranquility;
