import { Link } from "react-router-dom";
import User1 from "../assets/images/user1.png";
import User2 from "../assets/images/user2.png";
import User3 from "../assets/images/user3.png";
import Breathing from "../assets/images/health-habits/breathing.svg";
import EXERCISE1 from "../assets/images/health-habits/strengthen.svg";
import EXERCISE2 from "../assets/images/health-habits/relax-your-lungs.svg";
import EXERCISE3 from "../assets/images/health-habits/clean-your-lungh.svg";

const HealthDetails = () => (
  <div className="px-6 mb-24">
    <div
      className="flex items-center mb-5"
      data-aos="fade-right"
      data-aos-delay="50"
      data-aos-duration="1000"
    >
      <Link to="/Health-hebits" className="mr-3">
        <svg
          width="6"
          height="10"
          viewBox="0 0 10 17"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            d="M9.42402 16.2142C9.99277 15.6454 9.99277 14.7267 9.42402 14.1579L3.76569 8.49959L9.42402 2.84126C9.99277 2.27251 9.99277 1.35376 9.42402 0.785009C8.85527 0.216259 7.93652 0.216259 7.36777 0.785009L0.674024 7.47876C0.105274 8.04751 0.105274 8.96626 0.674024 9.53501L7.36777 16.2288C7.92194 16.7829 8.85527 16.7829 9.42402 16.2142Z"
            fill="#192852"
          />
        </svg>
      </Link>
      <h3 className="text-primary text-[14px] font-semibold">
        breathing exercise
      </h3>
    </div>
    {/* health details */}
    <div
      className="bg-[#F0F8FF] rounded-[12px] flex flex-col items-center px-4 py-4 relative mb-5"
      data-aos="fade-up"
      data-aos-delay="150"
      data-aos-duration="1000"
    >
      <img src={Breathing} alt="" width="60%" />
      <p className="text-[11px] text-primary leading-[16px] text-center px-5 mt-2 mb-1">
        If you have a severe respiratory condition, please talk to your doctor
        before performing any of these breathing exercises.
      </p>
      <div className=" absolute right-[15px] top-[15px]">
        <div className="flex justify-end">
          <div className="w-[40px] h-[40px] rounded-full overflow-hidden">
            <img src={User1} alt="" />
          </div>
          <div className="w-[40px] h-[40px] rounded-full overflow-hidden ml-[-15px]">
            <img src={User2} alt="" />
          </div>
          <div className="w-[40px] h-[40px] rounded-full overflow-hidden ml-[-15px]">
            <img src={User3} alt="" />
          </div>
        </div>
        <h4 className="text-[#227ED2] text-[13px] font-bold mt-1 text-right">
          36.2k
        </h4>
        <p className="text-primary text-[11px] font-semibold text-right">
          User Completed
        </p>
      </div>
    </div>
    {/* end */}
    <div className=" flex flex-wrap w-full justify-between">
      {/* health card */}
      <div
        className="flex flex-col border items-center justify-center border-[#EAEAEA] rounded-[12px] mb-3 w-[48%] pb-4"
        data-aos="fade-up"
        data-aos-delay="200"
        data-aos-duration="1000"
      >
        <div className="bg-[#F0F8FF] rounded-t-[11px] h-[115px] w-[100%] flex items-center relative">
          <p className="text-[10px] font-medium text-primary absolute top-3 left-3">
            EXERCISE1
          </p>
          <img src={EXERCISE1} alt="" className="mt-7" />
        </div>
        <h3 className="text-[13px] text-primary leading-[18px] text-center font-medium mt-3 mb-3 h-[35px]">
          Strengthen your <br />
          lungs
        </h3>
        <button className="w-[133px] h-[30px] bg-secondary flex items-center justify-center text-[11px] text-white font-semibold rounded-full">
          Under 1 mins
        </button>
      </div>
      {/* health card */}
      <div
        className="flex flex-col border items-center justify-center border-[#EAEAEA] rounded-[12px] mb-3 w-[48%] pb-4"
        data-aos="fade-up"
        data-aos-delay="250"
        data-aos-duration="1000"
      >
        <div className="bg-[#F0F8FF] rounded-t-[11px] h-[115px] w-[100%] flex items-center justify-center relative">
          <p className="text-[10px] font-medium text-primary absolute top-3 left-3">
            EXERCISE2
          </p>
          <img src={EXERCISE2} alt="" width="40%" className="mt-3" />
        </div>
        <h3 className="text-[13px] text-primary leading-[18px] text-center font-medium mt-3 mb-3 h-[35px]">
          Relax Your <br />
          lungs
        </h3>
        <button className="w-[133px] h-[30px] bg-secondary flex items-center justify-center text-[11px] text-white font-semibold rounded-full">
          Under 1 mins
        </button>
      </div>
      {/* health card */}
      <div
        className="flex flex-col border items-center justify-center border-[#EAEAEA] rounded-[12px] mb-2 w-[48%] pb-4"
        data-aos="fade-up"
        data-aos-delay="300"
        data-aos-duration="1000"
      >
        <div className="bg-[#F0F8FF] rounded-t-[11px] h-[115px] w-[100%] flex items-center justify-center relative">
          <p className="text-[10px] font-medium text-primary absolute top-3 left-3">
            EXERCISE3
          </p>
          <img src={EXERCISE3} alt="" className="mt-4" />
        </div>
        <h3 className="text-[13px] text-primary leading-[18px] text-center font-medium mt-3 mb-3 h-[35px]">
          Get fitter lungs
        </h3>
        <button className="w-[133px] h-[30px] bg-secondary flex items-center justify-center text-[11px] text-white font-semibold rounded-full">
          Under 1 mins
        </button>
      </div>
      {/* health card */}
      <div
        className="flex flex-col border items-center justify-center border-[#EAEAEA] rounded-[12px] mb-2 w-[48%] pb-4"
        data-aos="fade-up"
        data-aos-delay="350"
        data-aos-duration="1000"
      >
        <div className="bg-[#F0F8FF] rounded-t-[11px] h-[115px] w-[100%] flex items-center justify-center relative">
          <p className="text-[10px] font-medium text-primary absolute top-3 left-3">
            EXERCISE4
          </p>
          <img src={Breathing} alt="" className="mt-4" width="70%" />
        </div>
        <h3 className="text-[13px] text-primary leading-[18px] text-center font-medium mt-3 mb-3 h-[35px]">
          Cleanse your <br />
          lungs
        </h3>
        <button className="w-[133px] h-[30px] bg-secondary flex items-center justify-center text-[11px] text-white font-semibold rounded-full">
          Under 1 mins
        </button>
      </div>
      {/* card end */}
    </div>
  </div>
);

export default HealthDetails;
