const EducationCard = ({ img, tittle, subtittle, color = "#fff8" }) => (
  <div className="relative flex flex-col mb-4">
    <div className="rounded-[10px]">
      <img src={img} alt="" />
    </div>
    <div
      className="rounded-[10px] absolute bottom-0 left-0 right-0 p-[12px] h-[82px]"
      style={{ backgroundColor: color }}
    >
      <h3 className="text-[14px] font-medium text-white mb-1">{tittle}</h3>
      <p className="text-[10px] font-light text-white leading-[11px]">
        {subtittle}
      </p>
    </div>
  </div>
);

export default EducationCard;
