// Import the functions you need from the SDKs you need
import { initializeApp } from "firebase/app";
import { getAnalytics, logEvent, setUserId } from "firebase/analytics";

// Your web app's Firebase configuration
// For Firebase JS SDK v7.20.0 and later, measurementId is optional
const firebaseConfig = {
  apiKey: "AIzaSyAImBiK7J3ns-CfMFpDVj429h-2-4jhQxA",
  authDomain: "careplix-retail-subscription.firebaseapp.com",
  projectId: "careplix-retail-subscription",
  storageBucket: "careplix-retail-subscription.appspot.com",
  messagingSenderId: "263655595500",
  appId: "1:263655595500:web:5334131b39826b709e74c9",
  measurementId: "G-WZ4XZ8JV1P",
};

// Initialize Firebase
const app = initializeApp(firebaseConfig);
const analytics = getAnalytics(app);

export const logAnalyticsEvent = (name = "", data) => {
  if (name?.length > 0) {
    let hasParams = false;
    try {
      if (typeof data === "object" && Object.keys(data)?.length > 0)
        hasParams = true;
      else hasParams = false;
    } catch {
      hasParams = false;
    }
    if (window?.ReactNativeWebView)
      window.ReactNativeWebView.postMessage?.(
        `log-analytics-event:::${JSON.stringify({
          name,
          params: hasParams ? data : undefined,
        })}`
      );
    else if (hasParams) logEvent(analytics, name, data);
    else logEvent(analytics, name);
  } else console.error("Invalid Analytics Event.");
};

export const setAnalyticsUserIdentity = (id = "") => {
  if (id?.length > 0) {
    if (window?.ReactNativeWebView)
      window.ReactNativeWebView.postMessage?.(`set-analytics-user-id:::${id}`);
    else setUserId(analytics, id);
  } else console.error("Invalid Analytics User Identity.");
};
