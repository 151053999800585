import { Link } from "react-router-dom";

const BmiHistory = () => (
  <div className=" px-6">
    <div
      className="flex items-center mb-6"
      data-aos="fade-right"
      data-aos-duration="1000"
      data-aos-delay="50"
    >
      <Link to="/Walking" className="mr-3">
        <svg
          width="6"
          height="10"
          viewBox="0 0 10 17"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            d="M9.42402 16.2142C9.99277 15.6454 9.99277 14.7267 9.42402 14.1579L3.76569 8.49959L9.42402 2.84126C9.99277 2.27251 9.99277 1.35376 9.42402 0.785009C8.85527 0.216259 7.93652 0.216259 7.36777 0.785009L0.674024 7.47876C0.105274 8.04751 0.105274 8.96626 0.674024 9.53501L7.36777 16.2288C7.92194 16.7829 8.85527 16.7829 9.42402 16.2142Z"
            fill="#192852"
          />
        </svg>
      </Link>
      <h3 className="text-primary text-[14px] font-semibold">BMI History</h3>
    </div>
    <ul className="flex flex-col">
      <li
        className=" border border-[#E4E4E4] h-[45] mb-2 rounded-[10px] py-2 px-4 flex items-center justify-between"
        data-aos="fade-up"
        data-aos-duration="1000"
        data-aos-delay="100"
      >
        <p className="text-[11px] text-[#192852]/[.6]">04-08-2022</p>
        <div className=" flex flex-col">
          <h3 className="text-[16px] text-secondary font-medium">
            17.5{" "}
            <span className="text-[11px] text-[#192852]/[.6]">kg/m&#178;</span>
          </h3>
          <p className="text-[9px] text-[#FFC400] text-right">Underweight</p>
        </div>
      </li>
      <li
        className=" border border-[#E4E4E4] h-[45] mb-2 rounded-[10px] py-2 px-4 flex items-center justify-between"
        data-aos="fade-up"
        data-aos-duration="1000"
        data-aos-delay="100"
      >
        <p className="text-[11px] text-[#192852]/[.6]">03-08-2022 </p>
        <div className=" flex flex-col">
          <h3 className="text-[16px] text-secondary font-medium">
            36.5{" "}
            <span className="text-[11px] text-[#192852]/[.6]">kg/m&#178;</span>
          </h3>
          <p className="text-[9px] text-[#C7212A] text-right">Obese</p>
        </div>
      </li>
      <li
        className=" border border-[#E4E4E4] h-[45] mb-2 rounded-[10px] py-2 px-4 flex items-center justify-between"
        data-aos="fade-up"
        data-aos-duration="1000"
        data-aos-delay="200"
      >
        <p className="text-[11px] text-[#192852]/[.6]">02-08-2022</p>
        <div className=" flex flex-col">
          <h3 className="text-[16px] text-secondary font-medium">
            25.5{" "}
            <span className="text-[11px] text-[#192852]/[.6]">kg/m&#178;</span>
          </h3>
          <p className="text-[9px] text-[#FFC400] text-right">Underweight</p>
        </div>
      </li>
      <li
        className=" border border-[#E4E4E4] h-[45] mb-2 rounded-[10px] py-2 px-4 flex items-center justify-between"
        data-aos="fade-up"
        data-aos-duration="1000"
        data-aos-delay="250"
      >
        <p className="text-[11px] text-[#192852]/[.6]">28-07-2022 </p>
        <div className=" flex flex-col">
          <h3 className="text-[16px] text-secondary font-medium">
            24.44{" "}
            <span className="text-[11px] text-[#192852]/[.6]">kg/m&#178;</span>
          </h3>
          <p className="text-[9px] text-[#64BD40] text-right">Normal</p>
        </div>
      </li>
      <li
        className=" border border-[#E4E4E4] h-[45] mb-2 rounded-[10px] py-2 px-4 flex items-center justify-between"
        data-aos="fade-up"
        data-aos-duration="1000"
        data-aos-delay="300"
      >
        <p className="text-[11px] text-[#192852]/[.6]">22-07-2022 </p>
        <div className=" flex flex-col">
          <h3 className="text-[16px] text-secondary font-medium">
            24.24{" "}
            <span className="text-[11px] text-[#192852]/[.6]">kg/m&#178;</span>
          </h3>
          <p className="text-[9px] text-[#64BD40] text-right">Normal</p>
        </div>
      </li>
      <li
        className=" border border-[#E4E4E4] h-[45] mb-2 rounded-[10px] py-2 px-4 flex items-center justify-between"
        data-aos="fade-up"
        data-aos-duration="1000"
        data-aos-delay="350"
      >
        <p className="text-[11px] text-[#192852]/[.6]">12-06-2022 </p>
        <div className=" flex flex-col">
          <h3 className="text-[16px] text-secondary font-medium">
            25.5{" "}
            <span className="text-[11px] text-[#192852]/[.6]">kg/m&#178;</span>
          </h3>
          <p className="text-[9px] text-[#FFC400] text-right">Underweight</p>
        </div>
      </li>
    </ul>
    <div
      className="pagination w-full flex items-center justify-center mt-10"
      data-aos="fade-up"
      data-aos-duration="1000"
      data-aos-delay="400"
    >
      <Link to="" className="text-[11px] text-[#C5C5C5]">
        <span className="text-[12px]">&lsaquo;</span> Previous{" "}
      </Link>{" "}
      <span className="text-[10px] text-black mx-[4px]">|</span>
      <p className="text-secondary text-[11px] font-medium">
        Page <span>1</span>
      </p>{" "}
      <span className="text-[10px] text-black mx-[4px]">|</span>
      <Link to="" className="text-[11px] text-black">
        Next <span className="text-[12px]">&rsaquo;</span>
      </Link>
    </div>
  </div>
);

export default BmiHistory;
