import { Link } from "react-router-dom";
import NoData from "../assets/images/no-data.svg";

const DiscountCenter = () => (
  <div
    className="mb-28 w-full"
    data-aos="fade-up"
    data-aos-duration="1000"
    data-aos-delay="5"
  >
    <div
      className="px-6"
      data-aos="fade-right"
      data-aos-duration="1000"
      data-aos-delay="50"
    >
      <div className="flex items-center mb-5">
        <Link to="/" className="mr-3">
          <svg
            width="6"
            height="10"
            viewBox="0 0 10 17"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M9.42402 16.2142C9.99277 15.6454 9.99277 14.7267 9.42402 14.1579L3.76569 8.49959L9.42402 2.84126C9.99277 2.27251 9.99277 1.35376 9.42402 0.785009C8.85527 0.216259 7.93652 0.216259 7.36777 0.785009L0.674024 7.47876C0.105274 8.04751 0.105274 8.96626 0.674024 9.53501L7.36777 16.2288C7.92194 16.7829 8.85527 16.7829 9.42402 16.2142Z"
              fill="#192852"
            />
          </svg>
        </Link>
        <h3 className="text-primary text-[14px] font-semibold">
          Discount Center
        </h3>
      </div>
    </div>
    <div className="px-6 flex items-center flex-col justify-center mt-14">
      <img src={NoData} width="200" alt="" />
      <h4 className=" text-xl text-primary font-bold mt-6">No Data Found</h4>
      <p className="text-[12px] text-primary/[0.7] text-center mt-3">
        Whoops... this information is not
        <br />
        available for a moment
      </p>
      <Link
        to="/"
        className=" bg-secondary p-0 w-[100px] h-[35px] flex items-center justify-center rounded-full mt-6 text-[11px] text-white font-medium"
      >
        Go Back
      </Link>
    </div>
  </div>
);

export default DiscountCenter;
