import { useState } from "react";
import { Stepper, Step, Button, Typography } from "@material-tailwind/react";

const MoodtrackStep = () => {
  const [activeStep, setActiveStep] = useState(0);
  const [isLastStep, setIsLastStep] = useState(false);
  const [isFirstStep, setIsFirstStep] = useState(false);

  const handleNext = () => !isLastStep && setActiveStep((cur) => cur + 1);
  const handlePrev = () => !isFirstStep && setActiveStep((cur) => cur - 1);

  return (
    <div>
      <div className="w-full px-6 py-4">
        <Stepper
          className="step1 flex flex-col"
          activeStep={activeStep}
          isLastStep={(value) => setIsLastStep(value)}
          isFirstStep={(value) => setIsFirstStep(value)}
        >
          <Step onClick={() => setActiveStep(0)} className="step">
            <div className="top-0 bottom-0 text-center left-0 right-0 bg-white w-full">
              <Typography
                color={activeStep === 0 ? "blue-gray" : "gray"}
                className="font-normal text-[#ff0000] text-2xl"
              >
                Details about yout account2.
              </Typography>
            </div>
          </Step>
          <Step onClick={() => setActiveStep(1)}>
            <div className=" top-0 bottom-0 text-center">
              <Typography
                color={activeStep === 1 ? "blue-gray" : "gray"}
                className="font-normal"
              >
                Details about yout account3.
              </Typography>
            </div>
          </Step>
          <Step onClick={() => setActiveStep(2)}>
            <div className=" top-0 bottom-0 text-center">
              <Typography
                color={activeStep === 2 ? "blue-gray" : "gray"}
                className="font-normal"
              >
                Details about yout account4.
              </Typography>
            </div>
          </Step>
        </Stepper>
        <div className="mt-32 flex justify-between">
          <Button onClick={handlePrev} disabled={isFirstStep}>
            Prev
          </Button>
          <Button onClick={handleNext} disabled={isLastStep}>
            Next
          </Button>
        </div>
      </div>
    </div>
  );
};

export default MoodtrackStep;
