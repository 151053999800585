import React from 'react';

const DoctorDetails = ({img,tittle="",subtittle="",designation=""}) => {

        return (
            <div className='bg-[#EFF2FF] rounded-[20px] p=[15px] flex items-start justify-start p-5'>
                <img src={img} alt='' />
                <div className='ml-5'>
                    <h4 className='text-secondary font-medium text-[15px]'>Hi I'm</h4>
                    <h5 className='text-primary text-[12px] font-medium'>{tittle}</h5>
                    <p className='text-[#5C6984] text-[10px] mb-1'>{subtittle}</p>
                    <p className='text-secondary text-[11px] font-medium'>{designation}</p>
                </div>
            </div>
        );
    
}

export default DoctorDetails;