import { Link, useLocation } from "react-router-dom";
import { ReactComponent as BackArrow } from "../assets/icons/BackArrow.svg";

const DiseaseList = () => {
  const { state } = useLocation();
  return (
    <div className="w-full px-6 pt-2 pb-24">
      <div className="flex items-center">
        <Link className="shrink-0 p-1 pr-2.5" to={-1}>
          <BackArrow className="h-2.5 w-2.5" />
        </Link>
        <h3 className="text-white text-base font-semibold">Do you Know?</h3>
      </div>

      {state?.list?.map?.((dyk) => (
        <Link
          key={dyk.product_id}
          to="/dyk-detail"
          state={{
            name: dyk.product_name,
            content: dyk.structure_val,
          }}
          className="mt-5 h-32 p-4 overflow-hidden rounded-xl bg-white flex items-center space-x-3"
        >
          <div className="relative shrink-0 h-20 w-20">
            <img
              src={dyk.structure_val.content_thumbnail}
              alt="Thumbnail"
              className="h-full w-full rounded-xl object-cover"
            />
            <div className="absolute top-4 -left-1.5 h-4 w-4 rounded-full bg-secondary" />
          </div>
          <div className="grow flex flex-col items-stretch justify-between">
            <h4 className="line-clamp-2 text-secondary text-xs leading-tight font-medium">
              {dyk.structure_val.heading}
            </h4>
            <p className="my-2 line-clamp-3 text-[8px] text-primary leading-tight">
              {dyk.structure_val.meta_description}
            </p>
            <p className="text-secondary text-xs">View Details &gt;</p>
          </div>
        </Link>
      ))}
    </div>
  );
};

export default DiseaseList;
