import { useState } from "react";
import { Link } from "react-router-dom";
import { Tab, Tabs, TabList, TabPanel } from "react-tabs";
import Nutrition from "../components/Nutrition";
import Exercise from "../components/Exercise";

const JoinProgram = () => {
  const [tabIndex, setTabIndex] = useState(0);
  return (
    <div className="w-full mb-28">
      <div className="px-6">
        <div
          className="flex items-center mb-5"
          data-aos="fade-right"
          data-aos-duration="1000"
          data-aos-delay="50"
        >
          <Link to="/" className="mr-3">
            <svg
              width="6"
              height="10"
              viewBox="0 0 10 17"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M9.42402 16.2142C9.99277 15.6454 9.99277 14.7267 9.42402 14.1579L3.76569 8.49959L9.42402 2.84126C9.99277 2.27251 9.99277 1.35376 9.42402 0.785009C8.85527 0.216259 7.93652 0.216259 7.36777 0.785009L0.674024 7.47876C0.105274 8.04751 0.105274 8.96626 0.674024 9.53501L7.36777 16.2288C7.92194 16.7829 8.85527 16.7829 9.42402 16.2142Z"
                fill="#192852"
              />
            </svg>
          </Link>
          <h3 className="text-primary text-[16px] font-semibold">
            Nourish Your Life
          </h3>
        </div>
      </div>
      <Tabs
        selectedIndex={tabIndex}
        onSelect={(tabIndex) => setTabIndex(tabIndex)}
      >
        <TabList
          data-aos="fade-right"
          data-aos-duration="1000"
          data-aos-delay="100"
        >
          <Tab>
            <h4>Nutrition</h4>
            <p>About healthy eating habits, balanced meal planning.</p>
          </Tab>
          <Tab>
            <h4>Exercise</h4>
            <p>focus on exercise that can be done at home or in a gym.</p>
          </Tab>
          <Tab>
            <h4>Lifestyle Change</h4>
            <p>About stress, sleep, and other lifestyle modifications.</p>
          </Tab>
          <Tab>
            <h4>Self Coaching</h4>
            <p>
              Learn about goal setting, progress tracking, and daily check-ins.
            </p>
          </Tab>
        </TabList>
        <TabPanel>
          <div className="px-6 mt-7">
            <Nutrition />
          </div>
        </TabPanel>
        <TabPanel>
          <Exercise />
        </TabPanel>
        <TabPanel>
          <div className="px-6 mt-6">
            <h4>Lifestyle Change</h4>
          </div>
        </TabPanel>
        <TabPanel>
          <div className="px-6 mt-6">
            <h4>Self Coaching</h4>
          </div>
        </TabPanel>
      </Tabs>
    </div>
  );
};

export default JoinProgram;
