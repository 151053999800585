import { Link, useLocation } from "react-router-dom";
import { Tab } from "@headlessui/react";
import ReactMarkdown from "react-markdown";
import remarkBreaks from "remark-breaks";
import { Player } from "video-react";

const ContentDetails = () => {
  const {
    state: { name, data },
  } = useLocation();
  return (
    <div className="pb-28 w-full">
      <div className="px-6">
        <div className="flex items-center mb-5">
          <Link to={-1} className="mr-3">
            <svg
              width="6"
              height="10"
              viewBox="0 0 10 17"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M9.42402 16.2142C9.99277 15.6454 9.99277 14.7267 9.42402 14.1579L3.76569 8.49959L9.42402 2.84126C9.99277 2.27251 9.99277 1.35376 9.42402 0.785009C8.85527 0.216259 7.93652 0.216259 7.36777 0.785009L0.674024 7.47876C0.105274 8.04751 0.105274 8.96626 0.674024 9.53501L7.36777 16.2288C7.92194 16.7829 8.85527 16.7829 9.42402 16.2142Z"
                fill="#fff"
              />
            </svg>
          </Link>
          <h3 className="text-white text-[14px] font-semibold">{name}</h3>
        </div>
      </div>
      <Tab.Group>
        <Tab.List className="mb-6 px-6 flex space-x-4 overflow-x-auto">
          {data?.map((value) => (
            <Tab
              key={`tab-${value.content_rank}`}
              className="flex-1 outline-none"
              style={{ fontSize: 14 }}
            >
              {({ selected }) => (
                <span
                  className={`h-[50px] p-2 rounded-xl flex items-center justify-center bg-gradient-to-b ${
                    selected ? "from-[#44148B]" : "from-[#2055F3]"
                  } to-transparent text-white text-center text-xs font-medium`}
                >
                  {value?.tab_heading}
                </span>
              )}
            </Tab>
          ))}
        </Tab.List>
        <Tab.Panels>
          {data?.map((value) => (
            <Tab.Panel
              key={`tab-panel-${value.content_rank}`}
              className="px-6 mt-7"
            >
              <div className="mb-6 w-full rounded-lg overflow-hidden">
                <Player poster={value?.tab_content_poster}>
                  <source
                    className="rounded-lg"
                    src={value?.tab_content_video}
                    controls
                    controlsList="nodownload noplaybackrate"
                    playsInline
                    disablePictureInPicture
                    disableRemotePlayback
                  />
                </Player>
              </div>
              <ReactMarkdown
                className="prose-sm prose-neutral text-xs text-white/80"
                children={value?.tab_content}
                remarkPlugins={[remarkBreaks]}
              />
            </Tab.Panel>
          ))}
        </Tab.Panels>
      </Tab.Group>
    </div>
  );
};

export default ContentDetails;
