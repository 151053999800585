import { useEffect } from "react";
import { Navigate } from "react-router-dom";

const DownloadAPP = () => {
  useEffect(() => {
    const URL = /iPhone|iPad|iPod|Macintosh|Mac/i.test(navigator.userAgent)
      ? "https://apps.apple.com/in/app/careplix/id6445972293"
      : "https://play.google.com/store/apps/details?id=com.careplixvitals";
    window.location.href = URL;
  }, []);

  return <Navigate to="/" replace />;
};

export default DownloadAPP;
