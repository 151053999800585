import { useState, useEffect, useMemo } from "react";
import { Link, useLocation } from "react-router-dom";

import {
  LineChart,
  PieChart,
  Pie,
  Cell,
  Line,
  XAxis,
  YAxis,
  Bar,
  BarChart,
  CartesianGrid,
  ResponsiveContainer,
} from "recharts";
import {
  Accordion,
  AccordionHeader,
  AccordionBody,
} from "@material-tailwind/react";
import Mood0 from "../assets/mood-trak/sad.svg";
import Mood1 from "../assets/mood-trak/very-sad.svg";
import Mood2 from "../assets/mood-trak/normal.svg";
import Mood3 from "../assets/mood-trak/good.svg";
import Mood4 from "../assets/mood-trak/excited.svg";
import { ReactComponent as BackArrow } from "../assets/icons/BackArrow.svg";
import { sdkWrapperURL } from "../utils/api-url-list";
import { useAuth } from "../utils/auth";
import LoadingScreen from "../components/LoadingScreen";

const moodList = [
  { icon: Mood0, color: "#fe9166" },
  { icon: Mood1, color: "#ffd04e" },
  { icon: Mood2, color: "#fee394" },
  { icon: Mood3, color: "#a1d229" },
  { icon: Mood4, color: "#23b840" },
];
const weekdays = ["Sun", "Mon", "Tue", "Wed", "Thu", "Fri", "Sat"];

const Container = ({ title = "", desc = "", ...props }) => {
  const [isOpen, setOpen] = useState(true);
  return (
    <Accordion
      open={isOpen}
      icon={
        <svg
          xmlns="http://www.w3.org/2000/svg"
          className={`${
            isOpen ? "rotate-180" : ""
          } h-4 w-4 transition-transform`}
          fill="none"
          viewBox="0 0 24 24"
          stroke="#A977F9"
          strokeWidth={2}
        >
          <path
            strokeLinecap="round"
            strokeLinejoin="round"
            d="M19 9l-7 7-7-7"
          />
        </svg>
      }
      className="bg-[#031434] rounded-[12px] px-4 overflow-hidden relative box-shadow"
    >
      <AccordionHeader
        onClick={() => setOpen((s) => !s)}
        className="flex border-0 h-[70px]"
      >
        <div className="flex flex-col">
          <h3 className="text-[#812EFD] text-sm font-semibold">{title}</h3>
          <p className="text-xxs leading-tight text-white/80 font-normal">
            {desc}
          </p>
        </div>
      </AccordionHeader>
      <AccordionBody className="relative">{props.children}</AccordionBody>
    </Accordion>
  );
};

const MoodInsight = () => {
  const { state } = useLocation();
  const qtm_id = state?.surveyID ?? "";
  const {
    getToken,
    userData: { profile_id },
  } = useAuth();
  const [loading, setLoading] = useState(true);
  const [answerList, setAnswerList] = useState([]);
  const moodData = useMemo(
    () =>
      answerList
        ?.find((q) => q.question_order === 1)
        ?.answer_details?.map((a) => ({
          timestamp: new Date(a.answered_on ?? 0).getTime(),
          value: (parseInt(a.answer?.[0]) || 0) - 1,
        })) ?? [],
    [answerList]
  );
  const moodCounts = useMemo(
    () =>
      moodData.reduce(
        (moodCnt, moodEntry) => {
          moodCnt[moodEntry.value]++;
          return moodCnt;
        },
        [0, 0, 0, 0, 0]
      ),
    [moodData]
  );
  const totalMoodEntries = useMemo(
    () => moodCounts.reduce((sum, v) => sum + v, 0),
    [moodCounts]
  );
  const averageDailyMood = useMemo(
    () =>
      moodData
        .reduce(
          (avgDailyMood, data) => {
            const day = new Date(data.timestamp).getDay();
            avgDailyMood[day].sum += data.value;
            avgDailyMood[day].count += 1;
            return avgDailyMood;
          },
          [
            { sum: 0, count: 0 },
            { sum: 0, count: 0 },
            { sum: 0, count: 0 },
            { sum: 0, count: 0 },
            { sum: 0, count: 0 },
            { sum: 0, count: 0 },
            { sum: 0, count: 0 },
          ]
        )
        .map((v, i) => ({
          day: weekdays[i],
          value: v.count === 0 ? 0 : v.sum / v.count,
        })),
    [moodData]
  );

  useEffect(() => {
    (async () => {
      setLoading(true);
      try {
        const today = new Date();
        const end_date = today.toISOString().split("T")[0];
        today.setMonth(today.getMonth() - 1);
        const start_date = today.toISOString().split("T")[0];
        const token = await getToken();
        const surveyResp = await fetch(
          sdkWrapperURL("/users/v1/list/answers"),
          {
            method: "POST",
            headers: {
              "Content-Type": "application/json",
              Authorization: token,
            },
            body: JSON.stringify({
              module_type: "daily_check_in",
              action: "temporary",
              answer_type: "all",
              qtm_id,
              table_config_rows_per_page: 30,
              table_config_page_no: 1,
              start_date,
              end_date,
              profile_id,
            }),
          }
        );
        const surveyRespJSON = await surveyResp.json();
        if (surveyRespJSON?.statusCode?.toString().startsWith("2"))
          setAnswerList(surveyRespJSON.response);
        else
          throw new Error(
            surveyRespJSON?.message ?? "Error in Fetching Survey"
          );
      } catch (err) {
        console.error(err);
      } finally {
        setLoading(false);
      }
    })();
  }, [getToken, profile_id, qtm_id]);

  return (
    <div className="px-6 pt-2 pb-24">
      <div className="flex items-center mb-5">
        <Link className="shrink-0 p-1 pr-2.5" to={-1}>
          <BackArrow className="h-2.5 w-2.5" />
        </Link>
        <h3 className="text-sm font-medium">Mood Insight</h3>
      </div>
      {loading ? (
        <LoadingScreen style={{ marginTop: "-8rem" }} />
      ) : (
        <div className="w-full space-y-6">
          <Container title="Mood Chart" desc="How your mood changes over time">
            <div className="flex justify-between items-stretch">
              <div className="basis-5 shrink-0 flex flex-col items-center justify-between space-y-1 mb-3">
                {moodList
                  .map(({ icon }, index) => (
                    <img
                      key={`mood-${index}`}
                      className="shrink-0 w-5 h-5"
                      src={icon}
                      alt={`mood-${index}`}
                    />
                  ))
                  .reverse()}
              </div>
              <ResponsiveContainer className="grow" width="100%" height={150}>
                <LineChart
                  data={moodData.slice(0, 7)}
                  margin={{ top: 10, bottom: -10, right: 20, left: 20 }}
                >
                  <XAxis
                    type="category"
                    dataKey="timestamp"
                    reversed
                    tickLine={false}
                    tickFormatter={(v) =>
                      new Date(v).toLocaleDateString(undefined, {
                        day: "numeric",
                        month: "2-digit",
                      })
                    }
                    axisLine={{ stroke: "#192852", strokeOpacity: 0.3 }}
                    color="#192852"
                    opacity={0.75}
                    fontSize={10}
                    fontWeight={500}
                  />
                  <YAxis
                    type="number"
                    dataKey="value"
                    hide
                    domain={[0, 4]}
                    tickCount={5}
                    tickLine={false}
                    axisLine={false}
                  />
                  <Line
                    type="monotone"
                    dataKey="value"
                    stroke="#A977F9"
                    activeDot={{ r: 8 }}
                  />
                </LineChart>
              </ResponsiveContainer>
            </div>
            {/* <Link
              to="/mood-history"
              state={{ answerList }}
              className="block max-w-max ml-auto mr-0 mt-4 px-4 py-1.5 rounded-full bg-gradient-to-r from-[#063BC4] to-[#680FAD] text-xxs text-white font-semibold"
            >
              View History
            </Link> */}
          </Container>
          <Container title="Mood Count" desc="Frequent feelings logged">
            <div className="w-full pb-6 flex flex-col items-center justify-center">
              <div
                className="relative flex flex-col items-center justify-center"
                style={{ width: 330, height: 150, marginTop: "-40px" }}
              >
                <ResponsiveContainer>
                  <PieChart>
                    <Pie
                      data={moodCounts.map((value) => ({ value }))}
                      dataKey="value"
                      cx={162}
                      cy={100}
                      innerRadius={60}
                      outerRadius={80}
                      startAngle={180}
                      endAngle={0}
                    >
                      {moodList.map(({ color }, index) => (
                        <Cell key={`cell-${index}`} fill={color} />
                      ))}
                    </Pie>
                  </PieChart>
                </ResponsiveContainer>
                <div className="absolute w-full items-center left-0 right-0 top-[37%] justify-center flex flex-col">
                  <h4 className="text-lg font-bold text-[#C924A4] h-[20px]">
                    {totalMoodEntries}
                  </h4>
                  <p className=" text-[11px] text-white/[0.7] mt-2">
                    Mood Entries
                  </p>
                </div>
              </div>
              <div className="flex items-center h-[40px] w-full absolute justify-center bottom-6">
                {moodList.map((mood, index) => (
                  <div key={`mood-${index}`} className="relative">
                    <span
                      className=" absolute flex items-center justify-center right-0 top-[-5px] w-[20px] h-[20px] rounded-full bg-opacity-80 border leading-3 text-[9px] text-white font-semibold"
                      style={{
                        backgroundColor: mood.color,
                        borderColor: mood.color,
                      }}
                    >
                      {moodCounts[index]}
                    </span>
                    <img
                      src={mood.icon}
                      alt={`mood-${index}`}
                      className="mr-[5px] w-[40px]"
                    />
                  </div>
                ))}
              </div>
            </div>
          </Container>
          <Container title="Overall Trends" desc="Know your positive trends">
            <div className="relative px-4 py-2 rounded-2xl overflow-hidden flex items-center justify-between bg-gradient-to-b from-[#5517A7] to-[#031434]">
              <div
                className="absolute top-0 bottom-0 left-0 rounded-2xl"
                style={{
                  background: "linear-gradient(to right, #063BC4 60%, #5517A7)",
                  right: `${
                    (1 -
                      Math.floor(
                        moodCounts[2] * 0.5 + moodCounts[1] + moodCounts[0]
                      ) /
                        totalMoodEntries) *
                    100
                  }%`,
                }}
              />
              <div className="relative z-10 flex flex-col items-center justify-between">
                <img src={moodList[0].icon} width="40" alt="" />
                <p className="text-xxs text-white font-medium">
                  {Math.floor(
                    moodCounts[2] * 0.5 + moodCounts[1] + moodCounts[0]
                  )}
                  &nbsp;Days
                </p>
              </div>
              <div className="relative z-10 flex flex-col items-center justify-between">
                <img src={moodList[4].icon} width="40" alt="" />
                <p className="text-xxs text-white font-medium">
                  {Math.ceil(
                    moodCounts[2] * 0.5 + moodCounts[3] + moodCounts[4]
                  )}
                  &nbsp;Days
                </p>
              </div>
            </div>
          </Container>
          <Container
            title="Average Daily Mood"
            desc="Find out which day of the week is your best!"
          >
            <div className="flex justify-between items-stretch">
              <div className="basis-5 shrink-0 flex flex-col items-center justify-between space-y-1 mb-3">
                {moodList
                  .map(({ icon }, index) => (
                    <img
                      key={`mood-${index}`}
                      className="shrink-0 w-5 h-5"
                      src={icon}
                      alt={`mood-${index}`}
                    />
                  ))
                  .reverse()}
              </div>
              <ResponsiveContainer width="100%" height={200}>
                <BarChart
                  data={averageDailyMood}
                  margin={{ top: 10, bottom: -10, right: 8, left: 8 }}
                >
                  <CartesianGrid
                    horizontal
                    vertical={false}
                    stroke="#A977F9"
                    strokeOpacity={0.25}
                  />
                  <XAxis
                    type="category"
                    dataKey="day"
                    tickLine={false}
                    axisLine={{ stroke: "#A977F9", strokeOpacity: 0.3 }}
                    color="#A977F9"
                    opacity={1}
                    fontSize={10}
                    fontWeight={500}
                  />
                  <YAxis
                    type="number"
                    dataKey="value"
                    hide
                    domain={[0, 4]}
                    allowDecimals={false}
                    tickCount={5}
                    tickLine={false}
                    axisLine={false}
                  />
                  <Bar dataKey="value" barSize={12} radius={[10, 10, 10, 10]}>
                    {averageDailyMood.map(({ value }, index) => (
                      <Cell
                        key={index}
                        fill={moodList[Math.round(value)]?.color ?? "#000"}
                      />
                    ))}
                  </Bar>
                </BarChart>
              </ResponsiveContainer>
            </div>
          </Container>
        </div>
      )}
    </div>
  );
};

export default MoodInsight;
