import { Fragment, useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { Player } from "video-react";
import { useAuth } from "../utils/auth";
import { sdkWrapperURL } from "../utils/api-url-list";
import LoadingScreen from "../components/LoadingScreen";
import { ReactComponent as BackArrow } from "../assets/icons/BackArrow.svg";
import { ReactComponent as Close } from "../assets/icons/Close.svg";
import { Dialog, Transition } from "@headlessui/react";

const FitnessPackage = () => {
  const {
    getToken,
    userData: { profile_id },
  } = useAuth();
  const [isLoading, setLoading] = useState(true);
  const [packageList, setPackageList] = useState([]);
  const [selectedVideo, setSelectedVideo] = useState(undefined);

  useEffect(() => {
    (async () => {
      setLoading(true);
      try {
        const token = await getToken();
        const resp = await fetch(sdkWrapperURL("/users/list/products"), {
          method: "POST",
          headers: { "Content-Type": "application/json", Authorization: token },
          body: JSON.stringify({
            module_type: "content",
            profile_id,
            product_type_id: 27,
            table_config_page_no: 1,
            table_config_rows_per_page: 100,
          }),
        });
        const respJSON = await resp.json();
        if (respJSON?.statusCode?.toString().startsWith("2"))
          setPackageList(respJSON.response[0].structure_val.contents);
        else
          throw new Error(
            respJSON?.message ?? "Error in Fetching Did-you-know List"
          );
      } catch (err) {
        console.error(err);
      } finally {
        setLoading(false);
      }
    })();
  }, [getToken, profile_id]);

  return isLoading ? (
    <LoadingScreen style={{ minHeight: "60vh" }} />
  ) : (
    <div className="px-6 pt-2 pb-28">
      <div className="flex items-center text-white">
        <Link className="shrink-0 p-1 pr-2.5" to={-1}>
          <BackArrow className="h-2.5 w-2.5" />
        </Link>
        <h3 className="text-base font-semibold">Fitness</h3>
      </div>

      {packageList.map((p) => (
        <div className="mt-6">
          <p className="text-secondary text-xs font-semibold">
            {p.tab_heading}
          </p>
          <button
            className="mt-3 w-full rounded-lg overflow-hidden"
            type="button"
            onClick={() =>
              setSelectedVideo({
                video: p.tab_content_video,
                poster: p.tab_content_poster,
              })
            }
          >
            <span className="sr-only">{p.tab_heading} - Video</span>
            <img src={p.tab_content_poster} alt={p.tab_heading} />
          </button>
        </div>
      ))}

      <Transition show={typeof selectedVideo !== "undefined"} as={Fragment}>
        <Dialog as={Fragment} onClose={() => {}}>
          <Transition.Child
            className="fixed inset-0 z-50 bg-gradient-to-b from-[#0D38C2] to-[#5618B2] flex flex-col items-stretch justify-center fitness-video-thumb"
            enter="duration-300"
            enterFrom="opacity-0"
            enterTo="opacity-100"
            leave="duration-300 delay-150"
            leaveFrom="opacity-100"
            leaveTo="opacity-0"
          >
            <Transition.Child
              as={Fragment}
              enter="delay-150 duration-200"
              enterFrom="opacity-0 scale-50"
              enterTo="opacity-100 scale-100"
              leave="duration-100"
              leaveFrom="opacity-50 scale-50"
              leaveTo="opacity-0 scale-0"
            >
              <Dialog.Panel>
                <button
                  type="button"
                  onClick={() => setSelectedVideo(undefined)}
                  className="absolute top-4 z-50 right-6 p-1.5 rounded-full bg-black/50 text-white modal-close"
                >
                  <span className="sr-only">Close</span>
                  <Close className="h-6 w-6" />
                </button>
                <Player
                  className="fitness-video-thumb"
                  src={selectedVideo?.video}
                  poster={selectedVideo?.poster}
                  fluid
                  autoPlay
                  muted
                  playsInline
                />
              </Dialog.Panel>
            </Transition.Child>
          </Transition.Child>
        </Dialog>
      </Transition>
    </div>
  );
};

export default FitnessPackage;
