import React from "react";
import ReactDOM from "react-dom/client";
import "./index.css";
import "./Video.css";
import reportWebVitals from "./reportWebVitals";
import App from "./App";

const root = ReactDOM.createRoot(document.getElementById("root"));
root.render(
  <React.StrictMode>
    <App />
  </React.StrictMode>
);

reportWebVitals(console.log);

if ("serviceWorker" in navigator) {
  navigator.serviceWorker.ready
    .then((registration) => {
      const success = registration.unregister();
      if (success) console.log("Serviceworker unregistered.", registration);
      else console.error("Failed to unregister serviceworker.", registration);
    })
    .catch((err) => console.error("Failed to unregister serviceworker.", err));
} else console.log("Serviceworkers are not supported in this browser.");
