import { Fragment, useState } from "react";
import { Link, useLocation } from "react-router-dom";
import { Dialog, Transition } from "@headlessui/react";
import { Player } from "video-react";

import { ReactComponent as PlayIcon } from "../assets/icons/MusicPlay.svg";

const MindfulMoments = () => {
  const {
    state: { name, data },
  } = useLocation();
  const [selectedVideo, setSelectedVideo] = useState(undefined);
  return (
    <div className="w-full mb-36">
      <div className="px-6">
        <div className="flex items-center mb-5">
          <Link to={-1} className="mr-3">
            <svg
              width="6"
              height="10"
              viewBox="0 0 10 17"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M9.42402 16.2142C9.99277 15.6454 9.99277 14.7267 9.42402 14.1579L3.76569 8.49959L9.42402 2.84126C9.99277 2.27251 9.99277 1.35376 9.42402 0.785009C8.85527 0.216259 7.93652 0.216259 7.36777 0.785009L0.674024 7.47876C0.105274 8.04751 0.105274 8.96626 0.674024 9.53501L7.36777 16.2288C7.92194 16.7829 8.85527 16.7829 9.42402 16.2142Z"
                fill="#fff"
              />
            </svg>
          </Link>
          <h3 className="text-[#fff] text-[14px] font-semibold">{name}</h3>
        </div>
      </div>
      <div className="px-6 space-y-4">
        {data.map((item) => (
          <button
            key={`video-${item.content_rank}`}
            type="button"
            onClick={() => setSelectedVideo(item)}
            className="relative rounded-xl overflow-hidden"
          >
            <div className="relative">
              <img
                className="w-full"
                src={item.tab_content_poster}
                alt={item.tab_heading}
              />
              <PlayIcon className="absolute top-1/2 left-1/2 -translate-x-1/2 -translate-y-1/2 w-10 p-1 rounded-full text-white bg-[#9300ff]/40" />
            </div>
            <p className="px-4 py-2 text-xs text-left text-white bg-[#9300ff]">
              {item.tab_heading}
            </p>
          </button>
        ))}
      </div>

      <Transition show={typeof selectedVideo !== "undefined"} as={Fragment}>
        <Dialog
          as="div"
          className="relative z-10"
          onClose={() => setSelectedVideo(undefined)}
        >
          <Transition.Child
            as={Fragment}
            enter="ease-out duration-300"
            enterFrom="opacity-0"
            enterTo="opacity-100"
            leave="ease-in duration-200"
            leaveFrom="opacity-100"
            leaveTo="opacity-0"
          >
            <div className="fixed inset-0 bg-black/70" />
          </Transition.Child>

          <div className="fixed inset-0 overflow-y-auto">
            <div className="flex min-h-full items-center justify-center p-1 text-center">
              <Transition.Child
                as={Fragment}
                enter="ease-out duration-300"
                enterFrom="opacity-0 scale-95"
                enterTo="opacity-100 scale-100"
                leave="ease-in duration-200"
                leaveFrom="opacity-100 scale-100"
                leaveTo="opacity-0 scale-95"
              >
                <Dialog.Panel className="max-w-4xl w-full mx-2 rounded-lg overflow-hidden">
                  <Player autoPlay poster={selectedVideo?.tab_content_poster}>
                    <source
                      className="rounded-lg"
                      src={selectedVideo?.tab_content_video}
                      controls
                      controlsList="nodownload noplaybackrate"
                      playsInline
                      disablePictureInPicture
                      disableRemotePlayback
                    />
                  </Player>
                </Dialog.Panel>
              </Transition.Child>
            </div>
          </div>
        </Dialog>
      </Transition>
    </div>
  );
};

export default MindfulMoments;
