import { Link } from "react-router-dom";
import Sleep from "../assets/images/health-habits/sleep-img.svg";
import WaterIntake from "../assets/images/health-habits/water-intake.svg";
import Breathing from "../assets/images/health-habits/breathing.svg";
import PeakFlow from "../assets/images/health-habits/peak-flow.svg";
import Steps from "../assets/images/health-habits/steps.svg";
import Playbtn from "../assets/icons/play-button.svg";

const HealthHabbits = () => (
  <div className="px-6 mb-24">
    <div
      className="flex items-center mb-5"
      data-aos="fade-right"
      data-aos-delay="50"
      data-aos-duration="1000"
    >
      <Link to="/" className="mr-3">
        <svg
          width="6"
          height="10"
          viewBox="0 0 10 17"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            d="M9.42402 16.2142C9.99277 15.6454 9.99277 14.7267 9.42402 14.1579L3.76569 8.49959L9.42402 2.84126C9.99277 2.27251 9.99277 1.35376 9.42402 0.785009C8.85527 0.216259 7.93652 0.216259 7.36777 0.785009L0.674024 7.47876C0.105274 8.04751 0.105274 8.96626 0.674024 9.53501L7.36777 16.2288C7.92194 16.7829 8.85527 16.7829 9.42402 16.2142Z"
            fill="#192852"
          />
        </svg>
      </Link>
      <h3 className="text-primary text-[14px] font-semibold">
        Build Health Habits
      </h3>
    </div>
    {/* health card */}
    <Link to="">
      <div
        className="flex justify-around items-center mb-3 rounded-[12px] px-4 py-4 health-card"
        data-aos="fade-up"
        data-aos-delay="150"
        data-aos-duration="1000"
      >
        <div className="mr-4 w-[75%]">
          <h3 className="text-[14px] text-[#192852] font-semibold mb-1">
            Sleep Better
          </h3>
          <p className="text-[11px] text-[#2C385C] mb-3">
            Lorem Ipsum is simply dummy
          </p>
          <button className="bg-white rounded-full w-[100px] h-[30px] flex justify-between pl-2 pr-3 items-center text-[12px] font-medium text-secondary">
            <img src={Playbtn} alt="" /> 21 Days
          </button>
        </div>
        <div className="w-[25%]">
          <img src={Sleep} alt="" />
        </div>
      </div>
    </Link>
    {/* health card end */}
    {/* health card */}
    <Link to="">
      <div
        className="flex justify-around items-center mb-3 rounded-[12px] px-4 py-4 health-card"
        data-aos="fade-up"
        data-aos-delay="200"
        data-aos-duration="1000"
      >
        <div className="mr-4 w-[75%]">
          <h3 className="text-[14px] text-[#192852] font-semibold mb-1">
            Water Intake
          </h3>
          <p className="text-[11px] text-[#2C385C] mb-3">
            Lorem Ipsum is simply dummy
          </p>
          <button className="bg-white rounded-full w-[100px] h-[30px] flex justify-between pl-2 pr-3 items-center text-[12px] font-medium text-secondary">
            <img src={Playbtn} alt="" /> 21 Days
          </button>
        </div>
        <div className="w-[25%]">
          <img src={WaterIntake} alt="" />
        </div>
      </div>
    </Link>
    {/* health card end */}
    {/* health card */}
    <Link to="/Health-details">
      <div
        className="flex justify-around items-center mb-3 rounded-[12px] px-4 py-4 health-card"
        data-aos="fade-up"
        data-aos-delay="250"
        data-aos-duration="1000"
      >
        <div className="mr-4 w-[75%]">
          <h3 className="text-[14px] text-[#192852] font-semibold mb-1">
            Breathing Exercise
          </h3>
          <p className="text-[11px] text-[#2C385C] mb-3">
            Lorem Ipsum is simply dummy
          </p>
          <button className="bg-white rounded-full w-[100px] h-[30px] flex justify-between pl-2 pr-3 items-center text-[12px] font-medium text-secondary">
            <img src={Playbtn} alt="" /> 21 Days
          </button>
        </div>
        <div className="w-[25%]">
          <img src={Breathing} alt="" />
        </div>
      </div>
    </Link>
    {/* health card end */}
    {/* health card */}
    <Link to="">
      <div
        className="flex justify-around items-center mb-3 rounded-[12px] px-4 py-4 health-card"
        data-aos="fade-up"
        data-aos-delay="300"
        data-aos-duration="1000"
      >
        <div className="mr-4 w-[75%]">
          <h3 className="text-[14px] text-[#192852] font-semibold mb-1">
            Peak Flow Rate
          </h3>
          <p className="text-[11px] text-[#2C385C] mb-3">
            Lorem Ipsum is simply dummy
          </p>
          <button className="bg-white rounded-full w-[100px] h-[30px] flex justify-between pl-2 pr-3 items-center text-[12px] font-medium text-secondary">
            <img src={Playbtn} alt="" /> 21 Days
          </button>
        </div>
        <div className="w-[25%]">
          <img src={PeakFlow} alt="" />
        </div>
      </div>
    </Link>
    {/* health card end */}
    {/* health card */}
    <Link to="">
      <div
        className="flex justify-around items-center mb-3 rounded-[12px] px-4 py-4 health-card"
        data-aos="fade-up"
        data-aos-delay="350"
        data-aos-duration="1000"
      >
        <div className="mr-4 w-[75%]">
          <h3 className="text-[14px] text-[#192852] font-semibold mb-1">
            Steps
          </h3>
          <p className="text-[11px] text-[#2C385C] mb-3">
            Lorem Ipsum is simply dummy
          </p>
          <button className="bg-white rounded-full w-[100px] h-[30px] flex justify-between pl-2 pr-3 items-center text-[12px] font-medium text-secondary">
            <img src={Playbtn} alt="" /> 21 Days
          </button>
        </div>
        <div className="w-[25%]">
          <img src={Steps} alt="" />
        </div>
      </div>
    </Link>
    {/* health card end */}
  </div>
);

export default HealthHabbits;
