import { useState } from "react";
import { Link, useLocation } from "react-router-dom";
import { PieChart, Pie, Legend, Cell, ResponsiveContainer } from "recharts";
import {
  Accordion,
  AccordionHeader,
  AccordionBody,
} from "@material-tailwind/react";
import { useAuth } from "../utils/auth";
import { sdkWrapperURL } from "../utils/api-url-list";
import ViewHistory from "../assets/icons/History_icon.svg";

const COLORS = ["#5782ff", "#ff8b6f", "#ffb23e"];

const RADIAN = Math.PI / 180;
const renderCustomizedLabel = ({
  cx,
  cy,
  midAngle,
  innerRadius,
  outerRadius,
  percent,
  index,
}) => {
  const radius = innerRadius + (outerRadius - innerRadius) * 0.5;
  const x = cx + radius * Math.cos(-midAngle * RADIAN);
  const y = cy + radius * Math.sin(-midAngle * RADIAN);

  return (
    <text
      x={x}
      y={y}
      fill="white"
      textAnchor={x > cx ? "start" : "end"}
      dominantBaseline="central"
    >
      {`${(percent * 100).toFixed(0)}%`}
    </text>
  );
};
function Icon({ isOpen = false }) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      fill="none"
      viewBox="0 0 24 24"
      strokeWidth={2}
      stroke="#fff"
      className={`${isOpen ? "rotate-180 " : ""}h-5 w-5 transition-transform`}
    >
      <path
        strokeLinecap="round"
        strokeLinejoin="round"
        d="M19.5 8.25l-7.5 7.5-7.5-7.5"
      />
    </svg>
  );
}
const getDate = (dateType = "today") => {
  const formatDate = (date = new Date()) => {
    const year = date.getFullYear();
    const month = (date.getMonth() + 1).toString().padStart(2, "0");
    const day = date.getDate().toString().padStart(2, "0");
    return `${year}-${month}-${day}`;
  };
  const today = new Date();
  switch (dateType) {
    case "yesterday":
      const yesterday = new Date();
      yesterday.setDate(today.getDate() - 1);
      return formatDate(yesterday);
    default:
    case "today":
      return formatDate(today);
    case "tomorrow":
      const tomorrow = new Date();
      tomorrow.setDate(today.getDate() + 1);
      return formatDate(tomorrow);
  }
};

const NutritionResults = () => {
  const {
    getToken,
    userData: { profile_id },
  } = useAuth();
  const { state } = useLocation();
  const [loading, setLoading] = useState(false);
  const [mealType, setMealType] = useState(state?.logMealType ?? "breakfast");
  const [mealDate, setMealDate] = useState(state?.logMealDay ?? "today");
  const [query, setQuery] = useState(state?.query ?? "");
  const [results, setResults] = useState([]);
  const [open, setOpen] = useState(-1);
  const [fetchError, setFetchError] = useState("");
  const [savingError, setSavingError] = useState("");
  const getNutritionData = async () => {
    setLoading(true);
    setFetchError("");
    setResults([]);
    setOpen(-1);
    try {
      const token = await getToken();
      const resp = await fetch(sdkWrapperURL("/nutrition/get-food-nutrition"), {
        method: "POST",
        headers: { "Content-Type": "application/json", Authorization: token },
        body: JSON.stringify({ profile_id, food_items: query }),
      });
      const respJSON = await resp.json();
      if (respJSON?.statusCode?.toString().startsWith("2")) {
        if (respJSON.response?.length > 0) {
          const total = respJSON.response.reduce((sum, item) => {
            for (const key in item)
              sum[key] =
                key === "name" ? "total" : (sum[key] || 0) + (item[key] || 0);
            return sum;
          }, {});
          setResults([...respJSON.response, total]);
          setOpen(respJSON.response.length);
        } else throw new Error("No items returned.");
      } else
        throw new Error(
          respJSON?.message ?? "Failed to fetch Nutritional details."
        );
    } catch (err) {
      setFetchError(err.message);
    } finally {
      setLoading(false);
    }
  };

  const logMeal = async () => {
    setLoading(true);
    setSavingError("");
    try {
      const token = await getToken();
      const resp = await fetch(sdkWrapperURL("/nutrition/update-nutrition"), {
        method: "POST",
        headers: { "Content-Type": "application/json", Authorization: token },
        body: JSON.stringify({
          profile_id,
          nutrition_type: "meal",
          meal_date: getDate(mealDate),
          meal_type: mealType,
          meal_info: results,
          meal_query: query,
        }),
      });
      const respJSON = await resp.json();
      if (respJSON?.statusCode?.toString().startsWith("2")) {
        setMealType("breakfast");
        setMealDate("today");
        setQuery("");
        setResults([]);
        setOpen(-1);
      } else
        throw new Error(
          respJSON?.message ?? "Failed to log your Meal details."
        );
    } catch (err) {
      setSavingError(err.message);
    } finally {
      setLoading(false);
    }
  };

  return (
    <div className="mb-28 w-full px-6">
      <div className="flex items-center mb-5">
        <Link to={-1} className="mr-3">
          <svg
            width="6"
            height="10"
            viewBox="0 0 10 17"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M9.42402 16.2142C9.99277 15.6454 9.99277 14.7267 9.42402 14.1579L3.76569 8.49959L9.42402 2.84126C9.99277 2.27251 9.99277 1.35376 9.42402 0.785009C8.85527 0.216259 7.93652 0.216259 7.36777 0.785009L0.674024 7.47876C0.105274 8.04751 0.105274 8.96626 0.674024 9.53501L7.36777 16.2288C7.92194 16.7829 8.85527 16.7829 9.42402 16.2142Z"
              fill="#fff"
            />
          </svg>
        </Link>
        <h3 className="text-white text-base font-semibold">Log Your Meals</h3>
      </div>
      <div className="bg-gradient-to-t from-[#5E14B0]/5 to-[#5717B1] px-6 py-6 rounded-[10px] border-2 border-[#3C158E] mb-6">
        <div className="mb-2">
          <input
            className="block w-full px-4 py-2 outline-none rounded-lg border border-secondary bg-white text-primary text-sm"
            type="text"
            placeholder="Enter Your Meal Details"
            required
            value={query}
            onChange={(e) => setQuery(e.target.value)}
          />
        </div>
        {fetchError.length > 0 && (
          <p className="-mt-0.5 mb-1.5 text-xs font-normal text-error">
            {fetchError}
          </p>
        )}
        <div className="flex items-center justify-between">
          <div className="w-[52.5%]">
            <label
              className="block mb-2 text-sm font-medium text-white"
              htmlFor="mealType"
            >
              Select Type
            </label>
            <select
              className="block w-full p-2.5 outline-none rounded-lg border border-secondary bg-white text-primary text-sm"
              id="mealType"
              value={mealType}
              onChange={(e) => setMealType(e.target.value)}
            >
              <option value="breakfast">Breakfast</option>
              <option value="lunch">Lunch</option>
              <option value="dinner">Dinner</option>
            </select>
          </div>
          <div className="w-[42.5%]">
            <label
              className="block mb-2 text-sm font-medium text-white"
              htmlFor="mealDate"
            >
              Select Date
            </label>
            <select
              className="block w-full p-2.5 outline-none rounded-lg border border-secondary bg-white text-primary text-sm"
              id="mealDate"
              value={mealDate}
              onChange={(e) => setMealDate(e.target.value)}
            >
              <option value="today">Today</option>
              <option value="yesterday">Yesterday</option>
              <option value="tomorrow">Tomorrow</option>
            </select>
          </div>
        </div>
        <div className="flex items-center justify-between">
          <button
            className="block w-32 mt-6 px-4 py-2 rounded-full bg-[#0A39C3] text-white text-sm"
            type="button"
            onClick={getNutritionData}
            disabled={loading}
          >
            Get Details
          </button>
          <Link
            to="/nutrition-history"
            className="text-sm flex items-center py-2 mt-6"
          >
            <img src={ViewHistory} alt="" className="mr-2" /> View History
          </Link>
        </div>
      </div>
      {results?.length > 0 && (
        <div className="bg-gradient-to-t from-[#5E14B0]/5 to-[#5717B1] px-6 py-6 rounded-[10px] border-2 border-[#3C158E] mb-8">
          <h4 className="text-sm text-white">Nutrition Results</h4>
          {results.map((item, index) => {
            const chartData = [
              { name: "Fats", value: item.fat_total_g },
              { name: "Protiens", value: item.protein_g },
              { name: "Carbohydrates", value: item.carbohydrates_total_g },
            ];
            return (
              <Accordion
                key={`accordion-${index}`}
                open={open === index}
                icon={<Icon isOpen={open === index} />}
                className="text-sm text-white accordin-tab"
              >
                <AccordionHeader
                  onClick={() => setOpen((op) => (op === index ? -1 : index))}
                  className="text-sm text-white font-semibold capitalize"
                >
                  {item.name}
                </AccordionHeader>
                <AccordionBody>
                  <div className="w-50 h-56 -mt-3 mb-5">
                    <ResponsiveContainer width="100%" height="100%">
                      <PieChart width={400} height={400}>
                        <Pie
                          data={chartData}
                          cx="50%"
                          cy="50%"
                          labelLine={false}
                          label={renderCustomizedLabel}
                          outerRadius={80}
                          dataKey="value"
                        >
                          {chartData.map((entry, index) => (
                            <Cell
                              style={{
                                outline: "none",
                              }}
                              key={`cell-${index}`}
                              fill={COLORS[index % COLORS.length]}
                              stroke="#3a087f"
                              strokeWidth={3}
                            />
                          ))}
                        </Pie>
                        <Legend />
                      </PieChart>
                    </ResponsiveContainer>
                  </div>
                  <p className="flex items-center justify-between text-xs text-white mb-1">
                    Approx. Serving Size&nbsp;
                    <span>{item.serving_size_g?.toFixed(2) ?? 0}g</span>
                  </p>
                  <p className="flex items-center justify-between text-xs text-white mb-1">
                    Calories&nbsp;
                    <span>{item.calories?.toFixed(2) ?? 0}cal</span>
                  </p>
                  <p className="flex items-center justify-between text-xs text-white mb-1">
                    Total Fat&nbsp;
                    <span>{item.fat_total_g?.toFixed(2) ?? 0}g</span>
                  </p>
                  <p className="flex items-center justify-between text-xs text-white mb-1">
                    Saturated Fat&nbsp;
                    <span>{item.fat_saturated_g?.toFixed(2) ?? 0}g</span>
                  </p>
                  <p className="flex items-center justify-between text-xs text-white mb-1">
                    Cholesterol&nbsp;
                    <span>{item.cholesterol_mg?.toFixed(2) ?? 0}mg</span>
                  </p>
                  <p className="flex items-center justify-between text-xs text-white mb-1">
                    Sodium&nbsp;<span>{item.sodium_mg?.toFixed(2) ?? 0}mg</span>
                  </p>
                  <p className="flex items-center justify-between text-xs text-white mb-1">
                    Carbohydrates&nbsp;
                    <span>{item.carbohydrates_total_g?.toFixed(2) ?? 0}g</span>
                  </p>
                  <p className="flex items-center justify-between text-xs text-white mb-1">
                    Fiber&nbsp;<span>{item.fiber_g?.toFixed(2) ?? 0}g</span>
                  </p>
                  <p className="flex items-center justify-between text-xs text-white mb-1">
                    Sugar&nbsp;<span>{item.sugar_g?.toFixed(2) ?? 0}g</span>
                  </p>
                  <p className="flex items-center justify-between text-xs text-white mb-1">
                    Protein&nbsp;<span>{item.protein_g?.toFixed(2) ?? 0}g</span>
                  </p>
                </AccordionBody>
              </Accordion>
            );
          })}
          {savingError.length > 0 && (
            <p className="-mb-2 text-center text-xs font-normal text-error">
              {savingError}
            </p>
          )}
          <button
            className="block w-40 mx-auto mt-6 px-4 py-2 rounded-full bg-[#0A39C3] text-white text-sm"
            type="button"
            onClick={logMeal}
            disabled={loading}
          >
            Save Details
          </button>
        </div>
      )}
    </div>
  );
};

export default NutritionResults;
