import { useState, Fragment, useEffect } from "react";
import { Link, useLocation } from "react-router-dom";
import { Transition, Listbox } from "@headlessui/react";
import { useAuth } from "../utils/auth";
import { sdkWrapperURL } from "../utils/api-url-list";
import { ReactComponent as Chevron } from "../assets/icons/Chevron.svg";
import { ReactComponent as Loader } from "../assets/icons/Loader.svg";
import LoadingScreen from "../components/LoadingScreen";
import { constants } from "../utils/ranges";

const AlertSetting = () => {
  const {
    userData: { profile_id },
    getToken,
  } = useAuth();
  const { state } = useLocation();
  const [loading, setLoading] = useState(false);
  const [btnLoading, setBtnLoading] = useState(false);
  const [sysLow, setSysLow] = useState("");
  const [sysHigh, setSysHigh] = useState("");
  const [diaLow, setDiaLow] = useState("");
  const [diaHigh, setDiaHigh] = useState("");
  const [hrLow, setHRLow] = useState("");
  const [hrHigh, setHRHigh] = useState("");
  const [rrLow, setRRLow] = useState("");
  const [rrHigh, setRRHigh] = useState("");
  const [hba1cLow, setHbA1cLow] = useState("");
  const [hba1cHigh, setHbA1cHigh] = useState("");
  const [stressHigh, setStressHigh] = useState("");
  const [hasSaved, setSaved] = useState(false);
  const [error, setError] = useState("");

  useEffect(() => {
    setLoading(true);
    (async () => {
      try {
        const token = await getToken();
        const alertResp = await fetch(sdkWrapperURL("/alert/list-alert"), {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
            Authorization: token,
          },
          body: JSON.stringify({
            generated_for: state?.selectedUser,
            viewer: profile_id,
          }),
        });
        const alertRespJSON = await alertResp.json();
        if (alertRespJSON?.statusCode?.toString().startsWith("2")) {
          const alertValue = alertRespJSON.alerts;
          setSysLow(alertValue.bp_sys.value[0]);
          setSysHigh(alertValue.bp_sys.value[1]);
          setDiaLow(alertValue.bp_dia.value[0]);
          setDiaHigh(alertValue.bp_dia.value[1]);
          setHRLow(alertValue.heart_rate.value[0]);
          setHRHigh(alertValue.heart_rate.value[1]);
          setRRLow(alertValue.resp_rate.value[0]);
          setRRHigh(alertValue.resp_rate.value[1]);
          setHbA1cLow(alertValue.hba1c.value[0]);
          setHbA1cHigh(alertValue.hba1c.value[1]);
          setStressHigh(alertValue.stress_index.value[0]);
        } else {
          throw Error(alertRespJSON.message ?? "Error in fetching Alerts.");
        }
      } catch (err) {
        console.error(err);
      } finally {
        setLoading(false);
      }
    })();
  }, [getToken, profile_id, state?.selectedUser]);

  const updateAlert = async () => {
    try {
      setError("");
      setBtnLoading(true);
      if ((sysLow && !sysHigh) || (!sysLow && sysHigh)) {
        setError("Both Blood Pressure Systolic must be filled");
        return;
      }
      if ((diaLow && !diaHigh) || (!diaLow && diaHigh)) {
        setError("Both Blood Pressure Diastolic must be filled");
        return;
      }
      if ((hrLow && !hrHigh) || (!hrLow && hrHigh)) {
        setError("Both Heart Rate must be filled");
        return;
      }
      if ((rrLow && !rrHigh) || (!rrLow && rrHigh)) {
        setError("Both Respiration Rate must be filled");
        return;
      }
      if ((hba1cLow && !hba1cHigh) || (!hba1cLow && hba1cHigh)) {
        setError("Both HbA1c must be filled");
        return;
      }
      const filledFieldsCount = [
        sysLow,
        sysHigh,
        diaLow,
        diaHigh,
        hrLow,
        hrHigh,
        rrLow,
        rrHigh,
        hba1cLow,
        hba1cHigh,
      ].filter((value) => value !== "").length;
      if (filledFieldsCount < 4) {
        setError("Please fill at least Two Parameters.");
        return;
      } else {
        const token = await getToken();
        const alertResp = await fetch(sdkWrapperURL("/alert/create-alert"), {
          method: "POST",
          headers: { "Content-Type": "application/json", Authorization: token },
          body: JSON.stringify({
            generated_for: state?.selectedUser,
            generated_by: profile_id,
            alert_inputs: {
              heart_rate: {
                condition: "except_range",
                value: [hrLow, hrHigh],
                type: "digital_health_alert",
              },
              bp_sys: {
                condition: "except_range",
                value: [sysLow, sysHigh],
                type: "digital_health_alert",
              },
              bp_dia: {
                condition: "except_range",
                value: [diaLow, diaHigh],
                type: "digital_health_alert",
              },
              resp_rate: {
                condition: "except_range",
                value: [rrLow, rrHigh],
                type: "digital_health_alert",
              },
              hba1c: {
                condition: "except_range",
                value: [hba1cLow, hba1cHigh],
                type: "digital_health_alert",
              },
              stress_index: {
                condition: "gte",
                value: [stressHigh],
                type: "digital_health_alert",
              },
            },
          }),
        });
        const RespJSON = await alertResp.json();
        if (RespJSON?.statusCode?.toString().startsWith("2")) {
          setSaved(true);
          setTimeout(() => setSaved(false), 2500);
        } else throw new Error(RespJSON.message);
      }
    } catch (e) {
      setError(e.message);
      console.error(e);
    } finally {
      setBtnLoading(false);
    }
  };
  const StressValues = constants["Stress Level"].ranges
    .slice(0, -1)
    .map((r) => ({
      name: r.label,
      value: r.point.toFixed(1),
    }));

  return loading ? (
    <LoadingScreen style={{ minHeight: "60vh" }} />
  ) : (
    <div className="px-6 pt-2 pb-24">
      <div className="flex items-center">
        <Link to={-1} className="mr-3">
          <svg
            width="6"
            height="10"
            viewBox="0 0 10 17"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M9.42402 16.2142C9.99277 15.6454 9.99277 14.7267 9.42402 14.1579L3.76569 8.49959L9.42402 2.84126C9.99277 2.27251 9.99277 1.35376 9.42402 0.785009C8.85527 0.216259 7.93652 0.216259 7.36777 0.785009L0.674024 7.47876C0.105274 8.04751 0.105274 8.96626 0.674024 9.53501L7.36777 16.2288C7.92194 16.7829 8.85527 16.7829 9.42402 16.2142Z"
              fill="#fff"
            />
          </svg>
        </Link>
        <h3 className="text-white text-base font-medium">
          Set Alerts for {state?.name}
        </h3>
      </div>
      <div className="mt-5 px-8 py-3 rounded-xl bg-gradient-to-t from-[#030E6B]/30 to-[#172EA7]/30">
        <p className="text-white text-sm font-semibold">
          Blood Pressure Systolic
        </p>
        <div className="mt-2 grid grid-cols-2 gap-8">
          <label className="flex items-center space-x-2">
            <span className="text-xs text-secondary/80">Low</span>
            <input
              type="text"
              name="sys-low"
              inputMode="decimal"
              value={sysLow}
              maxLength={3}
              onChange={(e) => setSysLow(e.target.value)}
              className="w-full px-3 py-0.5 rounded-md outline-none border-2 border-secondary/80 focus:border-secondary bg-white text-primary text-xs leading-none"
            />
          </label>

          <label className="flex items-center space-x-2">
            <span className="text-xs text-secondary/80">High</span>
            <input
              type="text"
              name="sys-high"
              inputMode="decimal"
              value={sysHigh}
              maxLength={3}
              onChange={(e) => setSysHigh(e.target.value)}
              className="w-full px-3 py-0.5 rounded-md outline-none border-2 border-secondary/80 focus:border-secondary bg-white text-primary text-xs leading-none"
            />
          </label>
        </div>
      </div>
      <div className="mt-3 px-8 py-3 rounded-xl bg-gradient-to-t from-[#030E6B]/30 to-[#172EA7]/30">
        <p className="text-white text-sm font-semibold">
          Blood Pressure Diastolic
        </p>
        <div className="mt-2 grid grid-cols-2 gap-8">
          <label className="flex items-center space-x-2">
            <span className="text-xs text-secondary/80">Low</span>
            <input
              type="text"
              name="dia-low"
              inputMode="decimal"
              value={diaLow}
              maxLength={3}
              onChange={(e) => setDiaLow(e.target.value)}
              className="w-full px-3 py-0.5 rounded-md outline-none border-2 border-secondary/80 focus:border-secondary bg-white text-primary text-xs leading-none"
            />
          </label>
          <label className="flex items-center space-x-2">
            <span className="text-xs text-secondary/80">High</span>
            <input
              type="text"
              name="dia-high"
              inputMode="decimal"
              value={diaHigh}
              maxLength={3}
              onChange={(e) => setDiaHigh(e.target.value)}
              className="w-full px-3 py-0.5 rounded-md outline-none border-2 border-secondary/80 focus:border-secondary bg-white text-primary text-xs leading-none"
            />
          </label>
        </div>
      </div>
      <div className="mt-3 px-8 py-3 rounded-xl bg-gradient-to-t from-[#030E6B]/30 to-[#172EA7]/30">
        <p className="text-white text-sm font-semibold">Heart Rate</p>
        <div className="mt-2 grid grid-cols-2 gap-8">
          <label className="flex items-center space-x-2">
            <span className="text-xs text-secondary/80">Low</span>
            <input
              type="text"
              name="hr-low"
              inputMode="decimal"
              value={hrLow}
              maxLength={3}
              onChange={(e) => setHRLow(e.target.value)}
              className="w-full px-3 py-0.5 rounded-md outline-none border-2 border-secondary/80 focus:border-secondary bg-white text-primary text-xs leading-none"
            />
          </label>
          <label className="flex items-center space-x-2">
            <span className="text-xs text-secondary/80">High</span>
            <input
              type="text"
              name="hr-high"
              inputMode="decimal"
              value={hrHigh}
              maxLength={3}
              onChange={(e) => setHRHigh(e.target.value)}
              className="w-full px-3 py-0.5 rounded-md outline-none border-2 border-secondary/80 focus:border-secondary bg-white text-primary text-xs leading-none"
            />
          </label>
        </div>
      </div>
      <div className="mt-3 px-8 py-3 rounded-xl bg-gradient-to-t from-[#030E6B]/30 to-[#172EA7]/30">
        <p className="text-white text-sm font-semibold">Respiration Rate</p>
        <div className="mt-2 grid grid-cols-2 gap-8">
          <label className="flex items-center space-x-2">
            <span className="text-xs text-secondary/80">Low</span>
            <input
              type="text"
              name="rr-low"
              inputMode="decimal"
              value={rrLow}
              maxLength={3}
              onChange={(e) => setRRLow(e.target.value)}
              className="w-full px-3 py-0.5 rounded-md outline-none border-2 border-secondary/80 focus:border-secondary bg-white text-primary text-xs leading-none"
            />
          </label>
          <label className="flex items-center space-x-2">
            <span className="text-xs text-secondary/80">High</span>
            <input
              type="text"
              name="rr-high"
              inputMode="decimal"
              value={rrHigh}
              maxLength={3}
              onChange={(e) => setRRHigh(e.target.value)}
              className="w-full px-3 py-0.5 rounded-md outline-none border-2 border-secondary/80 focus:border-secondary bg-white text-primary text-xs leading-none"
            />
          </label>
        </div>
      </div>
      <div className="mt-3 px-8 py-3 rounded-xl bg-gradient-to-t from-[#030E6B]/30 to-[#172EA7]/30">
        <p className="text-white text-sm font-semibold">HbA1c</p>
        <div className="mt-2 grid grid-cols-2 gap-8">
          <label className="flex items-center space-x-2">
            <span className="text-xs text-secondary/80">Low</span>
            <input
              type="text"
              name="hba1c-low"
              inputMode="decimal"
              value={hba1cLow}
              maxLength={3}
              onChange={(e) => setHbA1cLow(e.target.value)}
              className="w-full px-3 py-0.5 rounded-md outline-none border-2 border-secondary/80 focus:border-secondary bg-white text-primary text-xs leading-none"
            />
          </label>
          <label className="flex items-center space-x-2">
            <span className="text-xs text-secondary/80">High</span>
            <input
              type="text"
              name="hba1c-high"
              inputMode="decimal"
              value={hba1cHigh}
              maxLength={3}
              onChange={(e) => setHbA1cHigh(e.target.value)}
              className="w-full px-3 py-0.5 rounded-md outline-none border-2 border-secondary/80 focus:border-secondary bg-white text-primary text-xs leading-none"
            />
          </label>
        </div>
      </div>
      <div className="mt-3 px-8 py-3 rounded-xl bg-gradient-to-t from-[#030E6B]/30 to-[#172EA7]/30">
        <Listbox
          as="div"
          className="relative"
          value={stressHigh}
          onChange={setStressHigh}
        >
          <Listbox.Label className="text-xs">Stress</Listbox.Label>
          <Listbox.Button className="w-full flex items-center justify-between space-x-4 mt-2 px-3 py-1.5 rounded-lg outline-none border-2 focus:border-secondary disabled:border-[#BABABA] bg-white disabled:bg-[#BABABA] text-primary text-xs text-left group">
            <span>
              {StressValues.find((s) => s.value === stressHigh)?.name ?? ""}
            </span>
            <Chevron className="shrink-0 h-3.5 w-3.5 text-secondary group-disabled:hidden" />
          </Listbox.Button>
          <Transition
            as={Fragment}
            enter="transition duration-200 ease-out"
            enterFrom="transform scale-95 opacity-0"
            enterTo="transform scale-100 opacity-100"
            leave="transition duration-300 ease-out"
            leaveFrom="transform scale-100 opacity-100"
            leaveTo="transform scale-95 opacity-0"
          >
            <Listbox.Options className="absolute bottom-9 w-full py-1 shadow-lg rounded-md bg-white text-primary text-xs">
              {StressValues.map((stress, idx) => (
                <Listbox.Option
                  key={`stress-${idx}`}
                  value={stress.value}
                  className="px-4 py-2 active:bg-secondary active:text-white"
                >
                  {stress.name}
                </Listbox.Option>
              ))}
            </Listbox.Options>
          </Transition>
        </Listbox>
      </div>
      {error.length > 0 && (
        <p className="mt-4 text-center text-sm font-normal text-error">
          {error}
        </p>
      )}
      {hasSaved && (
        <p className="mt-4 text-center text-sm font-normal text-light-green-500">
          ✅ Alert Updated Successfully
        </p>
      )}
      <button
        className="flex items-center justify-center space-x-2 mt-6 w-full rounded-full outline-none px-5 py-2.5 bg-gradient disabled:bg-secondary text-white"
        type="button"
        onClick={updateAlert}
      >
        <span>Update</span>&nbsp;
        {btnLoading && <Loader className="shrink-0 h-4 w-4 mt-1" />}
      </button>
    </div>
  );
};

export default AlertSetting;
