import { Fragment, useMemo, useState } from "react";
import { Link, Navigate, useLocation, useNavigate } from "react-router-dom";
import { Dialog, Transition } from "@headlessui/react";
import Slider from "react-slick";
import { useAuth } from "../utils/auth";
import { sdkWrapperURL } from "../utils/api-url-list";
import FaceScan from "../assets/images/FaceScan.webp";
import FingerScan from "../assets/images/FingerScan.webp";
import InstructionFaceScan1 from "../assets/images/instructions/face1.webp";
import InstructionFaceScan2 from "../assets/images/instructions/face2.webp";
import InstructionFaceScan3 from "../assets/images/instructions/face3.webp";
import InstructionFaceFingerScan from "../assets/images/instructions/face-finger.webp";
import InstructionFingerScan1 from "../assets/images/instructions/finger1.webp";
import InstructionFingerScan2 from "../assets/images/instructions/finger2.webp";
import { ReactComponent as Loader } from "../assets/icons/Loader.svg";

const ScanInstructions = () => {
  const {
    getToken,
    userData: { user_id, profile_id },
  } = useAuth();
  const navigate = useNavigate();
  const location = useLocation();
  const scanType = location.state?.scanType;
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState("");

  const startScan = async () => {
    try {
      window?.ReactNativeWebView?.postMessage?.("prompt-camera-permission");
      setLoading(true);
      const token = await getToken();
      const scanTokenResp = await fetch(
        sdkWrapperURL("/users/sdk/test_api_private"),
        {
          method: "POST",
          headers: { "Content-Type": "application/json", Authorization: token },
          body: JSON.stringify({
            action: "create_scan_UL",
            employee_id: user_id,
            profile_id: profile_id,
          }),
        }
      );
      const scanTokenRespJSON = await scanTokenResp.json();
      if (scanTokenRespJSON?.statusCode?.toString().startsWith("2")) {
        console.log(scanTokenRespJSON);
        navigate(`/${scanType}-scan`, {
          state: {
            scanToken: scanTokenRespJSON.scan_token,
            scanTokenExpiry: new Date(
              scanTokenRespJSON.token_expiry?.replace(" ", "T")
            ).getTime(),
          },
        });
      } else
        throw new Error(
          scanTokenRespJSON?.message ?? "Error in Fetching the Scan Token"
        );
    } catch (err) {
      console.error(err);
      setError(err.message);
    } finally {
      setLoading(false);
    }
  };

  const instructions = useMemo(
    () =>
      ({
        face: [
          {
            img: InstructionFaceScan1,
            desc: "The ambient light should be bright, consistent, and white.",
          },
          {
            img: InstructionFaceScan2,
            desc: "Remove any face-obstructing items, such as your mask, eyewear, or clothing.",
          },
          {
            img: InstructionFaceScan3,
            desc: "Keep your face and phone steady to avoid movements during the scan.",
          },
          {
            img: InstructionFaceFingerScan,
            desc: "If prompted, allow the camera permission.",
          },
        ],
        finger: [
          {
            img: InstructionFingerScan1,
            desc: "Place your finger on the rear primary camera and the flashlight.",
          },
          {
            img: InstructionFingerScan2,
            desc: "If the flash is not turned on then place some light behind the phone.",
          },
          {
            img: InstructionFaceFingerScan,
            desc: "If prompted, allow the camera permission.",
          },
        ],
      }[scanType] ?? []),
    [scanType]
  );
  const settings = {
    dots: true,
    lazyLoad: true,
    infinite: true,
    speed: 850,
    slidesToShow: 1,
    slidesToScroll: 1,
    initialSlide: 0,
    autoplay: true,
    Arrow: false,
    autoplaySpeed: 2500,
    pauseOnDotsHover: false,
  };

  return scanType === "face" || scanType === "finger" ? (
    <section className="px-8 pt-2 pb-24">
      <div className="flex items-center justify-between space-x-4">
        <h2 className="text-2xl font-semibold text-white">
          How to do a<br />
          <span className="text-secondary capitalize">{scanType} Scan</span>?
        </h2>
        <img
          className="shrink-0 h-28 rounded-full border-4 border-dotted border-white"
          src={scanType === "face" ? FaceScan : FingerScan}
          alt="Instruction"
        />
      </div>

      <Slider {...settings} className="mt-8 instruction-slide">
        {instructions.map((ins, idx) => (
          <div key={`scan-instruction-${idx}`} className="slider-item">
            <div className="mx-0.5">
              <img
                className="h-48 w-full object-fill rounded-2xl border-2 border-secondary"
                src={ins.img}
                alt={`Instruction Banner ${idx + 1}`}
              />
              <p className="mt-8 text-center leading-snug text-white">
                {ins.desc}
              </p>
            </div>
          </div>
        ))}
      </Slider>

      <button
        className="mt-6 w-full flex items-center justify-center space-x-4 rounded-full outline-none px-5 py-2.5 bg-gradient disabled:bg-none disabled:bg-darkgray text-white"
        type="button"
        onClick={startScan}
        disabled={loading}
      >
        <span>Understood, Let's Start</span>
        {loading && <Loader className="flex-shrink-0 h-4 w-4" />}
      </button>
      <Link
        to={-1}
        className="block max-w-max mx-auto mt-4 text-sm font-medium text-white"
      >
        Back
      </Link>

      <Transition show={error.length > 0} as={Fragment}>
        <Dialog as={Fragment} onClose={() => {}}>
          <Transition.Child
            className="fixed top-0 bottom-0 left-0 right-0 bg-black/70 flex items-center justify-center"
            enter="duration-300"
            enterFrom="opacity-0"
            enterTo="opacity-100"
            leave="duration-300 delay-150"
            leaveFrom="opacity-100"
            leaveTo="opacity-0"
          >
            <Transition.Child
              as={Fragment}
              enter="delay-150 duration-200"
              enterFrom="opacity-0 scale-50"
              enterTo="opacity-100 scale-100"
              leave="duration-100"
              leaveFrom="opacity-50 scale-50"
              leaveTo="opacity-0 scale-0"
            >
              <Dialog.Panel className="w-4/5 bg-white rounded-2xl p-6 flex flex-col text-center">
                <Dialog.Description className="text-black font-normal text-sm">
                  {error}
                </Dialog.Description>
                <Link
                  className="mt-6 mx-auto px-4 py-2 rounded-full bg-gradient text-white text-xs font-medium"
                  to={-2}
                >
                  Back to Dashboard
                </Link>
              </Dialog.Panel>
            </Transition.Child>
          </Transition.Child>
        </Dialog>
      </Transition>

      <div className="mt-6 p-4 rounded-xl bg-gradient-transparent text-white space-y-1.5">
        <h3 className="text-xs font-medium">Disclaimer</h3>
        <p className="text-xxs text-left">
          For Investigational Use Only. CarePlix is not a substitute for the
          clinical judgment of a healthcare professional. CarePlix is intended
          to improve your awareness of general wellness. CarePlix does not
          diagnose, treat, mitigate or prevent any disease, symptom, disorder or
          abnormal physical state. Consult with a healthcare professional or
          emergency services if you believe you may have a medical issue.
        </p>
      </div>
    </section>
  ) : (
    <Navigate to="/select-option" replace />
  );
};

export default ScanInstructions;
