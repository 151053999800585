import { Link, useLocation } from "react-router-dom";
import ReactMarkdown from "react-markdown";
import remarkBreaks from "remark-breaks";
import { ReactComponent as BackArrow } from "../assets/icons/BackArrow.svg";

const DYKDetails = () => {
  const { state } = useLocation();
  return (
    <div className="w-full px-6 pt-2 pb-24">
      <div className="flex items-center">
        <Link className="shrink-0 p-1 pr-2.5" to={-1}>
          <BackArrow className="h-2.5 w-2.5" />
        </Link>
        <h3 className="text-sm font-medium">{state?.name}</h3>
      </div>
      <div className="my-6 rounded-lg overflow-hidden">
        <img
          src={state?.content?.content_url}
          alt="Illustration"
          className="w-full"
        />
      </div>
      {/* <h3 className="mb-2 text-secondary text-sm font-semibold">
        {state?.content?.heading}
      </h3> */}
      <ReactMarkdown
        className="prose-sm prose-neutral text-xs text-white/80"
        children={state?.content?.description}
        remarkPlugins={[remarkBreaks]}
      />
    </div>
  );
};

export default DYKDetails;
