import { ReactComponent as StressLow } from "../assets/icons/StressLow.svg";
import { ReactComponent as StressNormal } from "../assets/icons/StressNormal.svg";
import { ReactComponent as StressElevated } from "../assets/icons/StressElevated.svg";
import { ReactComponent as StressHigh } from "../assets/icons/StressHigh.svg";
import { ReactComponent as StressVeryHigh } from "../assets/icons/StressVeryHigh.svg";

export const constants = {
  "Heart Rate": {
    unit: "BPM",
    info: "Heart rate is a measure of the number of times the heart beats per minute (bpm). It is an important indicator of the body's cardiovascular health and can provide insight into various physiological and medical conditions.",
    ranges: {
      resting: [
        {
          point: 30,
          label: "Low",
          color: "#FAC02B",
          desc: "A low resting heart rate range is 30 to 59 bpm.",
        },
        {
          point: 60,
          label: "Normal",
          color: "#64BD40",
          desc: "A Normal resting heart rate range is 60 to 100 bpm.",
        },
        {
          point: 101,
          label: "High",
          color: "#DD3923",
          desc: "A high resting heart rate range is 100 to 139 bpm.",
        },
        {
          point: 220,
          label: "High",
          color: "#DD3923",
          desc: "A high resting heart rate range is 100 to 139 bpm.",
        },
      ],
      standing: [
        {
          point: 30,
          label: "Low",
          color: "#FAC02B",
          desc: "A low standing heart rate range is 30 to 59 bpm.",
        },
        {
          point: 60,
          label: "Normal",
          color: "#64BD40",
          desc: "Normal standing heart rate range is 60 to 100 bpm.",
        },
        {
          point: 101,
          label: "High",
          color: "#DD3923",
          desc: "A high-standing heart rate is 100 to 139 bpm.",
        },
        {
          point: 220,
          label: "High",
          color: "#DD3923",
          desc: "A high-standing heart rate is 100 to 139 bpm.",
        },
      ],
      walking: [
        {
          point: 30,
          label: "Low",
          color: "#FAC02B",
          desc: "A low walking heart rate range is 70 to 99 bpm.",
        },
        {
          point: 91,
          label: "Normal",
          color: "#64BD40",
          desc: "Normal walking heart rate range is 100 to 120 bpm.",
        },
        {
          point: 121,
          label: "High",
          color: "#DD3923",
          desc: "A high walking heart rate range is 121 to 149 bpm.",
        },
        {
          point: 220,
          label: "High",
          color: "#DD3923",
          desc: "A high walking heart rate range is 121 to 149 bpm.",
        },
      ],
      exercising: [
        {
          point: 30,
          label: "Low",
          color: "#FAC02B",
          desc: "A Low exercising heart rate range is 100 to 119 bpm.",
        },
        {
          point: 120,
          label: "Normal",
          color: "#64BD40",
          desc: "A Normal exercising heart rate range is 120 to 150 bpm.",
        },
        {
          point: 151,
          label: "High",
          color: "#DD3923",
          desc: "A High exercising heart rate range is 150 to 189 bpm.",
        },
        {
          point: 220,
          label: "High",
          color: "#DD3923",
          desc: "A High exercising heart rate range is 150 to 189 bpm.",
        },
      ],
    },
  },
  "Respiration Rate": {
    unit: "breaths/min",
    info: "Respiration rate is the number of breaths a person takes in a minute. It is a measure of the rate of breathing, which is an indicator of the oxygen and carbon dioxide exchange that is taking place in the body. Your respiratory rate is also known as your breathing rate.",
    ranges: [
      {
        point: 6,
        label: "Low",
        color: "#FAC02B",
        desc: "A Low respiration rate range is below 12 bpm.",
      },
      {
        point: 12,
        label: "Normal",
        color: "#64BD40",
        desc: "A Normal respiration heart rate range is 12 to 20 bpm.",
      },
      {
        point: 21,
        label: "High",
        color: "#DD3923",
        desc: "A High respiration heart rate range is 21 to 30 bpm.",
      },
      {
        point: 30,
        label: "High",
        color: "#DD3923",
        desc: "A High respiration heart rate range is 21 to 30 bpm.",
      },
    ],
  },
  "Oxygen Saturation": {
    unit: "%",
    info: "Oxygen saturation, also known as SpO₂ is an important measurement used to assess the amount of oxygen being carried by the blood and delivered to the body's tissues.",
    ranges: [
      {
        point: 80,
        label: "Low",
        color: "#DD3923",
        desc: "Oxygen saturation below 90% is very concerning and indicates an emergency.\n\nLow oxygen saturation range is 86 to 90%",
      },
      {
        point: 91,
        label: "Concerning",
        color: "#FAC02B",
        desc: "Oxygen concentrations between 91% and 94% may indicate a medical problem. You should contact your healthcare provider.",
      },
      {
        point: 95,
        label: "Normal",
        color: "#64BD40",
        desc: "Measures how much oxygen the red blood cells are carrying from the lungs to the rest of the body. This is the number of breaths you take per minute.\n\nNormal oxygen saturation range is 95 to 100%",
      },
      {
        point: 100,
        label: "Normal",
        color: "#64BD40",
        desc: "Measures how much oxygen the red blood cells are carrying from the lungs to the rest of the body. This is the number of breaths you take per minute.\n\nNormal oxygen saturation range is 95 to 100%",
      },
    ],
  },
  "Blood Pressure": {
    unit: "mmHg",
    info: "Blood pressure is the pressure of circulating blood against the walls of blood vessels. The pressure of blood is exerted on the walls of the arteries, which carry blood from the heart to other parts of the body.",
    ranges: [
      [
        {
          point: 50,
          label: "Low",
          color: "#FAC02B",
          desc: "Low systolic pressure is less than 100 mmHg.",
        },
        {
          point: 100,
          label: "Normal",
          color: "#64BD40",
          desc: "Normal systolic pressure is from 101 to 120 mmHg.",
        },
        {
          point: 120,
          label: "Above Normal",
          color: "#F18233",
          desc: "Above Normal systolic pressure is from 121 to 139 mmHg.",
        },
        {
          point: 139,
          label: "High",
          color: "#DD3923",
          desc: "High blood pressure is often related to unhealthy lifestyle habits.\nHigh systolic pressure is above 140 mmHg.",
        },
        {
          point: 220,
          label: "High",
          color: "#DD3923",
          desc: "High blood pressure is often related to unhealthy lifestyle habits.\nHigh systolic pressure is above 140 mmHg.",
        },
      ],
      [
        { point: 30, label: "Low", color: "#FAC02B" },
        { point: 70, label: "Normal", color: "#64BD40" },
        { point: 80, label: "Above Normal", color: "#F18233" },
        { point: 90, label: "High", color: "#DD3923" },
        { point: 120, label: "High", color: "#DD3923" },
      ],
    ],
  },
  SDNN: {
    unit: "ms",
    info: "Heart rate variability (HRV) is a measure of the variation in time between successive heartbeats. It is the fluctuation in the time intervals between adjacent heartbeats.\n\nSDNN (Standard Deviation of NN Intervals) is a measure of heart rate variability (HRV). It's a commonly used index that provides an estimate of the average variation in time between successive heartbeats (also known as NN intervals or R-R intervals).",
    ranges: [
      {
        point: 0,
        label: "Low",
        color: "#FAC02B",
        desc: "A low SDNN range is from 1 to 29 ms.",
      },
      {
        point: 30,
        label: "Normal",
        color: "#64BD40",
        desc: "A normal SDNN range is from 30 to 96 ms.",
      },
      {
        point: 97,
        label: "High",
        color: "#1A5701",
        desc: "A High SDNN range is above 97ms.",
      },
      {
        point: 150,
        label: "High",
        color: "#1A5701",
        desc: "A High SDNN range is above 97ms.",
      },
    ],
  },
  RMSSD: {
    unit: "ms",
    info: "Heart rate variability (HRV) is a measure of the variation in time between successive heartbeats. It is the fluctuation in the time intervals between adjacent heartbeats.\n\nRMSSD (Root Mean Square of Successive Differences) is another measure of heart rate variability (HRV). RMSSD provides an estimate of the variability in time between successive heartbeats (also known as NN intervals or R-R intervals).",
    ranges: [
      {
        point: 0,
        label: "Low",
        color: "#FAC02B",
        desc: "A low RMSSD range is from 1 to 19 ms.",
      },
      {
        point: 20,
        label: "Normal",
        color: "#64BD40",
        desc: "A normal RMSSD range is from 20 to 89 ms.",
      },
      {
        point: 90,
        label: "High",
        color: "#1A5701",
        desc: "A high RMSSD range is above 90ms.",
      },
      {
        point: 150,
        label: "High",
        color: "#1A5701",
        desc: "A high RMSSD range is above 90ms.",
      },
    ],
  },
  PNN50: {
    unit: "%",
    info: "Heart rate variability (HRV) is a measure of the variation in time between successive heartbeats. It is the fluctuation in the time intervals between adjacent heartbeats.\n\nPNN50 is a measure of the number of short-term fluctuations in the heart rate and is considered to reflect changes in HRV primarily driven by the sympathetic branch of the autonomic nervous system, which prepares the body for stress and physical activity. HRV is the physiological phenomenon of variation, it is measured by the variation in the beat-to-beat interval.",
    ranges: [
      {
        point: 0,
        label: "Low",
        color: "#FAC02B",
        desc: "A low PNN50 range is from 1 to 4%",
      },
      {
        point: 5,
        label: "Normal",
        color: "#64BD40",
        desc: "A normal PNN50 range is from 5 to 40%",
      },
      {
        point: 41,
        label: "High",
        color: "#1A5701",
        desc: "A high PNN50 range is above 41%",
      },
      {
        point: 90,
        label: "High",
        color: "#1A5701",
        desc: "A high PNN50 range is above 41%",
      },
    ],
  },
  "Cardiac Output": {
    unit: "L/min",
    info: "Cardiac output is the amount of blood that the heart pumps out per minute. It is a crucial measure of heart function and is considered an important indicator of overall cardiovascular health.",
    ranges: [
      {
        point: 1,
        label: "Low",
        color: "#FAC02B",
        desc: "A low cardiac output range is from 2 to 3 L/min",
      },
      {
        point: 4,
        label: "Normal",
        color: "#64BD40",
        desc: "A normal cardiac output range is from 4 to 6 L/min",
      },
      {
        point: 8,
        label: "High",
        color: "#DD3923",
        desc: "A high cardiac output range is from 8 L/min",
      },
      {
        point: 10,
        label: "High",
        color: "#DD3923",
        desc: "A high cardiac output range is from 8 L/min",
      },
    ],
  },
  "Mean Arterial Pressure": {
    unit: "mmHg",
    info: "Mean arterial pressure (MAP) is a measure of the average blood pressure in the arteries over one cardiac cycle, which is important for maintaining the proper flow of blood and oxygen to the body's tissues.",
    ranges: [
      {
        point: 1,
        label: "Low",
        color: "#FAC02B",
        desc: "A low MAP range is less than 60 mmHg.",
      },
      {
        point: 70,
        label: "Normal",
        color: "#64BD40",
        desc: "A normal MAP range is less than 70 to 100 mmHg.",
      },
      {
        point: 101,
        label: "High",
        color: "#DD3923",
        desc: "A high MAP range is above 100 mmHg.",
      },
      {
        point: 120,
        label: "High",
        color: "#DD3923",
        desc: "A high MAP range is above 100 mmHg.",
      },
    ],
  },
  "Heart Utilized": {
    unit: "%",
    info: "A healthy heart contracts (empties blood) and relaxes (refills blood). With each heartbeat, the heart pumps blood from the left and right ventricles.",
    ranges: [
      {
        point: 20,
        label: "Low",
        color: "#FAC02B",
        desc: "A low heart utilization is less than 40%",
      },
      {
        point: 41,
        label: "Normal",
        color: "#64BD40",
        desc: "A normal heart utilization is from 41 to 70%",
      },
      {
        point: 71,
        label: "High",
        color: "#DD3923",
        desc: "A high heart utilization is above 75%",
      },
      {
        point: 100,
        label: "High",
        color: "#DD3923",
        desc: "A high heart utilization is above 75%",
      },
    ],
  },
  "Heart Rate Max": {
    unit: "BPM",
    description:
      "Your heart rate max is the number of beats per minute your heart can reach during exercise. It is possible to exceed the upper limit of your zone without any ill effects, as long as you do not have coronary artery disease or are at risk for a heart attack.",
  },
  "Heart Rate Reserve": {
    unit: "BPM",
    description:
      "Heart rate reserve is the difference between your maximum (peak) heart rate and your resting heart rate.",
  },
  "Target HR Range": {
    unit: "BPM",
    description:
      "Your target heart rate is a range of numbers that reflect how fast your heart should be beating when you exercise.",
  },
  "VO₂ Max": {
    unit: "mL/kg/min",
    description:
      "VO₂ max refers to how much oxygen your body can absorb and use during exercise.",
  },
  HbA1c: {
    unit: "%",
    info: "When sugar enters the bloodstream, it binds to hemoglobin, this process is called glycation. The HBA1C measures the percentage of glycated hemoglobin levels in the blood for the last two to three months.",
    ranges: [
      {
        point: 3.5,
        label: "Optimal",
        color: "#64BD40",
        desc: "An optimal HBA1C is below 5.7%",
      },
      {
        point: 5.7,
        label: "Elevated",
        color: "#F18233",
        desc: "An elevated HBA1C range is between 5.7% - 6.4%",
      },
      {
        point: 6.4,
        label: "High",
        color: "#DD3923",
        desc: "A high HBA1C range is above 6.5%",
      },
      {
        point: 10,
        label: "High",
        color: "#DD3923",
        desc: "A high HBA1C range is above 6.5%",
      },
    ],
  },
  "Average Blood Glucose": {
    unit: "mg/dl",
    info: "Average blood glucose refers to the average concentration of glucose (sugar) in the bloodstream over a certain period of time. It is measured to assess an individual's overall blood sugar control and determine the presence or need for monitoring diabetes.",
    ranges: [
      {
        point: 65,
        label: "Optimal",
        color: "#64BD40",
        desc: "The optimal average blood glucose is below 117 mg/dl",
      },
      {
        point: 117,
        label: "Elevated",
        color: "#F18233",
        desc: "An elevated average blood glucose range is between 117 - 137 mg/dl",
      },
      {
        point: 138,
        label: "High",
        color: "#DD3923",
        desc: "A high average blood glucose range is above 138 mg/dl",
      },
      {
        point: 450,
        label: "High",
        color: "#DD3923",
        desc: "A high average blood glucose range is above 138 mg/dl",
      },
    ],
  },
  BMI: {
    unit: "kg/m²",
    info: "Body Mass Index (BMI) is a measurement based on your weight and height. It is more of an indicator than a direct measurement of your total body fat.",
    ranges: [
      {
        point: 0,
        label: "Low",
        color: "#FAC02B",
        desc: "A low BMI is from 0 to 18.4 kg/m²",
      },
      {
        point: 18.4,
        label: "Normal",
        color: "#64BD40",
        desc: "A normal BMI is from 18.5 to 24.9 kg/m²",
      },
      {
        point: 24.9,
        label: "High",
        color: "#F18233",
        desc: "A high BMI is from 25 to 29.9 kg/m²",
      },
      {
        point: 29.9,
        label: "Obese",
        color: "#DD3923",
        desc: "A very high BMI is above 30 kg/m²",
      },
      {
        point: 40,
        label: "Obese",
        color: "#DD3923",
        desc: "A very high BMI is above 30 kg/m²",
      },
    ],
  },
  "Blood Volume": {
    unit: "ml",
    description:
      "Blood volume refers to the total amount of fluid circulating within the arteries, capillaries, veins, venules, and chambers of the heart at any time.",
  },
  "Total Body Water": {
    unit: "liters",
    description:
      "Total Body Water is the total amount of water in the human body. This includes fluids both inside and outside of cells.",
  },
  "Body Water %": {
    unit: "%",
    description:
      "Most of the human body is water, with an average of roughly 60%. The amount of water in the body changes slightly with age, sex, and hydration levels.",
  },
  "Total Body Fat": {
    unit: "%",
    description:
      "Body fat percentage is an indicator of your body composition and indicates the amount of fat you have in your body",
  },
  "Body Weight": {
    unit: "kg",
    description:
      "Body weight refers to the total mass of a person's body, which is composed of various tissues, such as bones, muscles, organs, and body fat.",
  },
  "Calorie from Carb": {
    unit: "%",
    description:
      "Calories from carbohydrates describe the amount of energy that is derived from the consumption of carbohydrates in a person's diet. Carbohydrates are a macronutrient that are an important source of energy for the body, along with proteins and fats.",
  },
  "Calorie from Fat": {
    unit: "%",
    description:
      "A calorie from fat is a unit of measurement of the energy obtained from consuming fats. It is a type of calorie that is derived from the macronutrient fat.",
  },
  "Stress Level": {
    unit: "Stress Index (SI)",
    ranges: [
      {
        point: 0,
        label: "Low",
        color: "#1A5701",
        icon: <StressLow height={40} width={40} />,
      },
      {
        point: 0.5,
        label: "Normal",
        color: "#64BD40",
        icon: <StressNormal height={40} width={40} />,
      },
      {
        point: 1.5,
        label: "Elevated",
        color: "#FAC02B",
        icon: <StressElevated height={40} width={40} />,
      },
      {
        point: 5,
        label: "High",
        color: "#DD3923",
        icon: <StressHigh height={40} width={40} />,
      },
      {
        point: 9,
        label: "Very High",
        color: "#BF0715",
        icon: <StressVeryHigh height={40} width={40} />,
      },
      {
        point: 10,
        label: "Very High",
        color: "#BF0715",
        icon: <StressVeryHigh height={40} width={40} />,
      },
    ],
    description:
      "The body's reaction to a challenge or demand. There are 5 levels of stress.",
  },
  "Wellbeing Score": {
    ranges: [
      {
        point: 0,
        label: "Poor",
        color: "#DD3923",
        info: "Your body vitals are in the poor range. We suggest you consult with a healthcare provider right away to get a diagnosis and treatment plan.",
      },
      {
        point: 25,
        label: "Moderate",
        color: "#FAC02B",
        info: "Your body vitals are in the moderate range, which means there is some room for improvement. You may need to make some lifestyle changes or consult with a healthcare provider to address any issues. But don't worry, with the right actions, you can boost your wellbeing and feel better.",
      },
      {
        point: 50,
        label: "Good",
        color: "#64BD40",
        info: "Your body vitals are good, which means you're doing a lot of things right. You may have a few areas where you could improve, but overall you're in good shape. Keep monitoring your vitals and making healthy choices to maintain this level of wellbeing.",
      },
      {
        point: 75,
        label: "Excellent",
        color: "#3ca312",
        info: "Congratulations, your body vitals are excellent! Your heart is beating strong, your blood pressure is steady, your oxygen level is optimal, and your breathing is easy. Keep up the good work and enjoy feeling your best.",
      },
      {
        point: 100,
        label: "Excellent",
        color: "#3ca312",
        info: "Congratulations, your body vitals are excellent! Your heart is beating strong, your blood pressure is steady, your oxygen level is optimal, and your breathing is easy. Keep up the good work and enjoy feeling your best.",
      },
    ],
  },
};
