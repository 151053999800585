import { useEffect, useMemo, useRef, useState } from "react";
import { Link, useLocation } from "react-router-dom";
import {
  Bar,
  BarChart,
  Cell,
  ResponsiveContainer,
  CartesianGrid,
  Tooltip,
  XAxis,
  YAxis,
} from "recharts";
import LoadingScreen from "../components/LoadingScreen";
import ProgressCircle from "../components/ProgressCircle";
import ChartIcon from "../assets/icons/insert_chart.svg";
import Downarrow from "../assets/icons/down_arrow.svg";
import { useAuth } from "../utils/auth";
import { sdkWrapperURL } from "../utils/api-url-list";
import { constants } from "../utils/ranges";

const CustomTooltip = ({ active, payload, label }) => {
  if (active && payload && payload.length) {
    return (
      <div className="custom-tooltip bg-white rounded relative h-[40px] border border-secondary px-3 flex items-center justify-center">
        <p className="label text-[11px]">{`${new Date(label).toLocaleDateString(
          undefined,
          { day: "numeric", month: "2-digit" }
        )}:  ${Math.round(payload[0].value ?? 0)}`}</p>
      </div>
    );
  }

  return null;
};

const HeartHealthScore = () => {
  const [active, setActive] = useState(false);
  const content = useRef(null);
  const [height, setHeight] = useState("0px");
  const [loading, setLoading] = useState(true);
  const [analytics, setAnalytics] = useState([]);

  function toggleAccordion() {
    setActive(!active);
    setHeight(active ? "0px" : `${content.current.scrollHeight}px`);
  }

  const { getToken } = useAuth();
  const {
    state: { scoreData },
  } = useLocation();

  useEffect(() => {
    setLoading(true);
    (async () => {
      try {
        const token = await getToken();
        const today = new Date();
        const sixWeeks = new Date(
          today.getTime() - 6 * 7 * 24 * 60 * 60 * 1000
        );

        const scoreResp = await fetch(
          sdkWrapperURL("/score/graph-scores-analytics"),
          {
            method: "POST",
            headers: {
              "Content-Type": "application/json",
              Authorization: token,
            },
            body: JSON.stringify({
              action: "average",
              average_interval: 1,
              date_range: "weekly",
              score_name: scoreData.scoreName,
              start_date: sixWeeks.toISOString().split("T")[0],
              end_date: today.toISOString().split("T")[0],
            }),
          }
        );
        const scoreRespJSON = await scoreResp.json();
        if (scoreRespJSON?.statusCode?.toString().startsWith("2")) {
          setAnalytics(
            scoreRespJSON?.[scoreData.scoreName]?.map?.((entry) => ({
              timestamp: new Date(entry.start_date ?? 0).getTime(),
              value: entry.value,
            }))
          );
        }
      } catch (err) {
        console.error(err);
      } finally {
        setLoading(false);
      }
    })();
  }, [getToken, scoreData.scoreName]);

  const selectedScoreRange = useMemo(
    () =>
      constants["Wellbeing Score"].ranges.reduce((p, c) =>
        scoreData.score > c.point ? c : p
      ),
    [scoreData.score]
  );

  return (
    <div className="px-6 pb-24">
      <div className="flex items-center mb-7">
        <Link to={-1} className="mr-3">
          <svg
            width="6"
            height="10"
            viewBox="0 0 10 17"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M9.42402 16.2142C9.99277 15.6454 9.99277 14.7267 9.42402 14.1579L3.76569 8.49959L9.42402 2.84126C9.99277 2.27251 9.99277 1.35376 9.42402 0.785009C8.85527 0.216259 7.93652 0.216259 7.36777 0.785009L0.674024 7.47876C0.105274 8.04751 0.105274 8.96626 0.674024 9.53501L7.36777 16.2288C7.92194 16.7829 8.85527 16.7829 9.42402 16.2142Z"
              fill="#fff"
            />
          </svg>
        </Link>
        <h3 className="text-white text-[16px] font-semibold">Heart Health</h3>
      </div>
      {loading ? (
        <LoadingScreen style={{ minHeight: "60vh" }} />
      ) : (
        <div className="flex flex-col items-center">
          <ProgressCircle
            value={scoreData.score}
            color={selectedScoreRange.color}
          />
          <h3 className="text-[14px] text-[#812EFD] font-medium mt-2">
            Heart Score
          </h3>
          <h4
            className="text-[16px] font-medium"
            style={{ color: selectedScoreRange.color }}
          >
            {selectedScoreRange.label}
          </h4>
          <p className="text-[11px] text-[#fff] mb-2">
            Last update&nbsp;
            {new Date(scoreData.date).toLocaleDateString(undefined, {
              day: "2-digit",
              month: "short",
              year: "numeric",
            })}
          </p>
          <div className="accordion__section w-full">
            <button
              className={`accordion ${active ? "active" : ""} block mx-auto`}
              type="button"
              onClick={toggleAccordion}
            >
              <p className="accordion__title text-[10px] text-white font-medium flex items-center justify-center ">
                <img src={ChartIcon} alt="chart icon" className="mr-1 h-7" />{" "}
                View Analytics
              </p>
            </button>
            <div
              ref={content}
              style={{ maxHeight: `${height}` }}
              className="accordion__content"
            >
              <ResponsiveContainer width="100%" height={170}>
                <BarChart
                  data={analytics}
                  margin={{ top: 16, bottom: 0, left: -32, right: 0 }}
                >
                  <CartesianGrid horizontal vertical={false} />
                  <XAxis
                    type="category"
                    dataKey="timestamp"
                    interval={0}
                    tickLine={false}
                    tickFormatter={(v) =>
                      new Date(v).toLocaleDateString(undefined, {
                        day: "numeric",
                        month: "2-digit",
                      })
                    }
                    axisLine={{ stroke: "rgba(25,40,82, 0.5)" }}
                    fontSize={10}
                    fontWeight={500}
                    color="#192852"
                    opacity={0.75}
                  />
                  <YAxis
                    type="number"
                    dataKey="value"
                    domain={[0, 100]}
                    tickLine={false}
                    axisLine={false}
                    fontSize={10}
                    fontWeight={500}
                    color="#192852"
                    opacity={0.75}
                  />
                  <Tooltip
                    cursor={{ fill: "none" }}
                    content={<CustomTooltip />}
                  />
                  <Bar
                    dataKey="value"
                    fill="#A977F9"
                    barSize={8}
                    radius={[10, 10, 10, 10]}
                  >
                    {analytics.map(({ value }, index) => (
                      <Cell
                        key={`color-${index}`}
                        fill={
                          constants["Wellbeing Score"].ranges?.reduce?.(
                            (p, c) => (value > c.point ? c : p)
                          )?.color ?? "#000"
                        }
                      />
                    ))}
                  </Bar>
                </BarChart>
              </ResponsiveContainer>
              <div className="flex items-center justify-center">
                <span style={{}} onClick={toggleAccordion}>
                  <img src={Downarrow} alt="Down arrow" />
                </span>
              </div>
            </div>
          </div>
          <p className="mt-4 text-[11px] text-white text-justify bg-[#031434] rounded-3xl p-4">
            The Heart Score is a measure of your heart health that takes into
            account various factors to assess how well your heart is doing right
            now. It looks at different health markers to give you an idea of the
            current condition of your heart.
          </p>
          <div className="flex items-center justify-center mt-4 mb-2">
            <Link
              to="/score-history"
              state={{
                scoreID: scoreData.scoreID,
                scoreName: scoreData.scoreName,
              }}
              className="bg-gradient-to-r from-[#063BC4] to-[#680FAD] rounded-[40px] px-8 py-2 text-[11px] text-white hover:bg-secondary active:bg-secondary hover:text-white active:text-white focus:outline-none"
            >
              View History
            </Link>
          </div>
        </div>
      )}
    </div>
  );
};

export default HeartHealthScore;
