import { Fragment, useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { Dialog, RadioGroup, Transition } from "@headlessui/react";

import LoadingScreen from "../../components/LoadingScreen";
import Graph from "../../components/Graph";

import { constants } from "../../utils/ranges";
import { useAuth } from "../../utils/auth";
import { sdkWrapperURL } from "../../utils/api-url-list";

import { ReactComponent as BackArrow } from "../../assets/icons/BackArrow.svg";
import { ReactComponent as HeartRate } from "../../assets/icons/HeartRate.svg";
import { ReactComponent as BloodPressure } from "../../assets/icons/BloodPressure.svg";
import { ReactComponent as RespirationRate } from "../../assets/icons/RespirationRate.svg";
// import { ReactComponent as OxygenSaturation } from "../../assets/icons/OxygenSaturation.svg";

const BodyVitals = () => {
  const {
    getToken,
    userData: { user_id, profile_id },
    subscriptionPlanDetails: { packageName },
  } = useAuth();

  const [isSuccessModalOpen, setSuccessModalOpen] = useState(false);
  const [loading, setLoading] = useState(true);
  const [graphType, setGraphType] = useState("weekly");
  const [allGraphData, setGraphData] = useState([]);

  const [modalState, setModalState] = useState({
    visible: false,
    title: "",
    desc: "",
  });
  const closeModal = () =>
    setModalState({ visible: false, title: "", desc: "" });

  useEffect(() => {
    const controller = new AbortController();
    setLoading(true);
    setGraphData([]);
    (async () => {
      try {
        const timezone = Intl.DateTimeFormat().resolvedOptions().timeZone;
        const token = await getToken();
        const dataResp = await fetch(
          sdkWrapperURL("/users/sdk/graph_vitals_history_private"),
          {
            method: "POST",
            headers: {
              "Content-Type": "application/json",
              Authorization: token,
            },
            body: JSON.stringify({
              employee_id: user_id,
              profile_id: profile_id,
              date_range: graphType,
              timezone,
            }),
          }
        );
        const dataRespJSON = await dataResp.json();
        if (dataRespJSON?.statusCode?.toString().startsWith("2"))
          setGraphData(dataRespJSON.response.response_graph.reverse());
        else throw new Error(dataRespJSON.message);
      } catch (err) {
        console.error(err);
      } finally {
        setLoading(false);
      }
    })();
    return () => {
      controller.abort();
    };
  }, [getToken, user_id, profile_id, graphType]);

  const subscribe = async () => {
    try {
      const token = await getToken();
      const resp = await fetch(sdkWrapperURL("/users/add-saved-products"), {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          Authorization: token,
        },
        body: JSON.stringify({
          profile_id,
          product_id: "a5a5e1b5-fe4a-4224-b1bb-a37a9c4ca2d6",
          interest_filtration: "ACCEPT",
          application_info: {},
          module_type: "content",
        }),
      });
      const resp_json = await resp.json();
      if (resp_json.statusCode?.toString().startsWith("2"))
        setSuccessModalOpen(true);
      else throw new Error(resp_json.message);
    } catch (err) {
      console.error(err);
    }
  };

  return (
    <section className="px-6 py-2 pb-24">
      <div className="flex items-center text-white">
        <Link className="flex-shrink-0 p-1 pr-2.5" to={-1}>
          <BackArrow className="h-2.5 w-2.5" />
        </Link>
        <h3 className="text-sm font-medium">Body Vitals</h3>
      </div>

      <div className="my-6 space-y-6">
        <RadioGroup
          value={graphType}
          onChange={setGraphType}
          disabled={loading}
          className="flex items-center justify-between px-8 space-x-2"
        >
          {["yearly", "monthly", "weekly", "daily"].map((type) => (
            <RadioGroup.Option
              key={`graph-type-${type}`}
              value={type}
              className={({ checked }) =>
                `text-xs ${
                  checked ? "text-[#812EFD]" : "text-white/70"
                } capitalize cursor-pointer font-semibold`
              }
            >
              {type}
            </RadioGroup.Option>
          ))}
        </RadioGroup>
        {loading ? (
          <LoadingScreen style={{ minHeight: "50vh" }} />
        ) : (
          <>
            <Graph
              icon={<HeartRate className="h-5 w-5" />}
              label="Heart Rate"
              chartType={graphType}
              chartData={allGraphData.map((item) => ({
                label: item.start_date,
                value:
                  typeof item.heart_rate === "number"
                    ? parseInt(item.heart_rate.toFixed(0), 10)
                    : 0,
                color: constants["Heart Rate"].ranges.resting.reduce(
                  (p, c) => (item.heart_rate >= c.point ? c.color : p),
                  "#0000"
                ),
              }))}
              legend={constants["Heart Rate"].ranges.resting
                .slice(0, -1)
                .map(({ label, color }) => ({ label, color }))}
              unit={constants["Heart Rate"].unit}
              defaultExpanded
              viewInfo={() =>
                setModalState({
                  visible: true,
                  title: "Heart Rate",
                  desc: constants["Heart Rate"].info,
                })
              }
              historyDetail={{
                title: "Heart Rate",
                ranges: constants["Heart Rate"].ranges,
                keys: ["vitals", "heart_rate"],
                unit: constants["Heart Rate"].unit,
              }}
            />
            <Graph
              icon={<RespirationRate className="h-5 w-5" />}
              label="Respiration Rate"
              chartType={graphType}
              chartData={allGraphData.map((item) => ({
                label: item.start_date,
                value:
                  typeof item.resp_rate === "number"
                    ? parseInt(item.resp_rate.toFixed(0), 10)
                    : 0,
                color: constants["Respiration Rate"].ranges.reduce(
                  (p, c) => (item.resp_rate >= c.point ? c.color : p),
                  "#0000"
                ),
              }))}
              legend={constants["Respiration Rate"].ranges
                .slice(0, -1)
                .map(({ label, color }) => ({ label, color }))}
              unit={constants["Respiration Rate"].unit}
              defaultExpanded
              viewInfo={() =>
                setModalState({
                  visible: true,
                  title: "Respiration Rate",
                  desc: constants["Respiration Rate"].info,
                })
              }
              historyDetail={{
                title: "Respiration Rate",
                ranges: constants["Respiration Rate"].ranges,
                keys: ["vitals", "resp_rate"],
                unit: constants["Respiration Rate"].unit,
              }}
            />
            {packageName === "FREEMIUM" || packageName === "LITE" ? (
              <div className="flex flex-col items-center p-6 rounded-2xl bg-gradient-to-b from-[#063BC4] to-[#680FAD]/90 text-white text-center">
                <h4 className="font-semibold">Available Only for Members</h4>
                <p className="mt-5 text-sm font-light">
                  Join our community to unlock this feature & other exclusive
                  wellness offerings. Become a member today and take your health
                  journey to the next level!
                </p>
                <button
                  className="mt-6 px-6 py-3 rounded-full outline-none bg-white text-secondary text-sm font-medium"
                  type="button"
                  onClick={subscribe}
                >
                  Request Membership
                </button>
                <Transition show={isSuccessModalOpen} as={Fragment}>
                  <Dialog
                    as={Fragment}
                    onClose={() => setSuccessModalOpen(false)}
                  >
                    <Transition.Child
                      className="fixed top-0 bottom-0 left-0 right-0 bg-black/70 flex items-center justify-center"
                      enter="duration-300"
                      enterFrom="opacity-0"
                      enterTo="opacity-100"
                      leave="duration-300 delay-150"
                      leaveFrom="opacity-100"
                      leaveTo="opacity-0"
                    >
                      <Transition.Child
                        as={Fragment}
                        enter="delay-150 duration-200"
                        enterFrom="opacity-0 scale-50"
                        enterTo="opacity-100 scale-100"
                        leave="duration-100"
                        leaveFrom="opacity-50 scale-50"
                        leaveTo="opacity-0 scale-0"
                      >
                        <Dialog.Panel className="w-4/5 bg-white rounded-2xl p-6 flex flex-col items-center text-center">
                          <Dialog.Title className="text-secondary font-semibold">
                            Request Submitted Successfully!
                          </Dialog.Title>
                          <Dialog.Description className="mt-5 text-darkgray text-sm font-light">
                            Your membership request has been submitted
                            successfully. Our team will get back to you shortly.
                            Welcome to the path of better health and wellness!
                          </Dialog.Description>
                          <button
                            className="mt-6 px-6 py-3 rounded-full outline-none bg-gradient text-white text-sm font-medium"
                            type="button"
                            onClick={() => setSuccessModalOpen(false)}
                          >
                            Ok
                          </button>
                        </Dialog.Panel>
                      </Transition.Child>
                    </Transition.Child>
                  </Dialog>
                </Transition>
              </div>
            ) : (
              <Graph
                icon={<BloodPressure className="h-5 w-5" />}
                label="Blood Pressure"
                chartType={graphType}
                chartData={
                  allGraphData.length > 0
                    ? [
                        allGraphData.map((item) => ({
                          label: item.start_date,
                          value:
                            typeof item.bp_sys === "number"
                              ? parseInt(item.bp_sys.toFixed(0), 10)
                              : 0,
                          color: constants["Blood Pressure"].ranges[0].reduce(
                            (p, c) => (item.bp_sys > c.point ? c.color : p),
                            "#0000"
                          ),
                        })),
                        allGraphData.map((item) => ({
                          label: item.start_date,
                          value:
                            typeof item.bp_dia === "number"
                              ? parseInt(item.bp_dia.toFixed(0), 10)
                              : 0,
                          color: constants["Blood Pressure"].ranges[1].reduce(
                            (p, c) => (item.bp_dia > c.point ? c.color : p),
                            "#0000"
                          ),
                        })),
                      ]
                    : []
                }
                legend={constants["Blood Pressure"].ranges[0]
                  .slice(0, -1)
                  .map(({ label, color }) => ({ label, color }))}
                unit={constants["Blood Pressure"].unit}
                defaultExpanded
                viewInfo={() =>
                  setModalState({
                    visible: true,
                    title: "Blood Pressure",
                    desc: constants["Blood Pressure"].info,
                  })
                }
                historyDetail={{
                  title: "Blood Pressure",
                  ranges: constants["Blood Pressure"].ranges[0],
                  keys: ["vitals", "bp_sys", "vitals", "bp_dia"],
                  unit: constants["Blood Pressure"].unit,
                }}
              />
            )}

            {/* <Graph
              icon={<OxygenSaturation className="h-5 w-5" />}
              label="Oxygen Saturation"
              chartType={graphType}
              chartData={allGraphData.map((item) => ({
                label: item.start_date,
                value:
                  typeof item.oxy_sat_prcnt === "number"
                    ? parseInt(item.oxy_sat_prcnt.toFixed(0), 10)
                    : 0,
                color: constants["Oxygen Saturation"].ranges.reduce(
                  (p, c) => (item.oxy_sat_prcnt >= c.point ? c.color : p),
                  "#0000"
                ),
              }))}
              legend={constants["Oxygen Saturation"].ranges
                .slice(0, -1)
                .map(({ label, color }) => ({ label, color }))}
              unit={constants["Oxygen Saturation"].unit}
              defaultExpanded
              viewInfo={() =>
                setModalState({
                  visible: true,
                  title: "Oxygen Saturation",
                  desc: constants["Oxygen Saturation"].info,
                })
              }
              historyDetail={{
                title: "Oxygen Saturation",
                ranges: constants["Oxygen Saturation"].ranges,
                keys: ["vitals", "oxy_sat_prcnt"],
                unit: constants["Oxygen Saturation"].unit,
              }}
            /> */}

            <Transition show={modalState.visible} as={Fragment}>
              <Dialog as={Fragment} onClose={closeModal}>
                <Transition.Child
                  className="fixed top-0 bottom-0 left-0 right-0 bg-black/70 flex items-center justify-center"
                  enter="duration-300"
                  enterFrom="opacity-0"
                  enterTo="opacity-100"
                  leave="duration-300 delay-150"
                  leaveFrom="opacity-100"
                  leaveTo="opacity-0"
                >
                  <Transition.Child
                    as={Fragment}
                    enter="delay-150 duration-200"
                    enterFrom="opacity-0 scale-50"
                    enterTo="opacity-100 scale-100"
                    leave="duration-100"
                    leaveFrom="opacity-50 scale-50"
                    leaveTo="opacity-0 scale-0"
                  >
                    <Dialog.Panel className="w-4/5 bg-white rounded-2xl p-6 flex flex-col items-center text-center">
                      <Dialog.Title className="text-primary text-xs font-medium">
                        {modalState.title}
                      </Dialog.Title>
                      <Dialog.Description className="mt-2 text-primary/70 text-xxs">
                        {modalState.desc}
                      </Dialog.Description>
                      <button
                        className="mt-4 px-4 py-2 rounded-full bg-gradient-to-r from-[#063BC4] to-[#680FAD] text-white text-xs font-medium"
                        type="button"
                        onClick={closeModal}
                      >
                        Close
                      </button>
                    </Dialog.Panel>
                  </Transition.Child>
                </Transition.Child>
              </Dialog>
            </Transition>
          </>
        )}
      </div>
    </section>
  );
};

export default BodyVitals;
