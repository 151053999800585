import { Fragment, useState } from "react";
import { Link, useLocation } from "react-router-dom";
import { Dialog, Transition } from "@headlessui/react";
import HeartHealth from "../assets/icons/HeartHealth.svg";
import BodyVitals from "../assets/icons/BodyVitals.svg";
import PhysioFitness from "../assets/icons/PhysioFitness.svg";
// import StressIndex from "../assets/icons/StressIndex.svg";
import HealthScore from "../assets/icons/health-score.svg";

const Card = ({ color = "#fff8", img, title = "", subtitle = "" }) => (
  <div className="flex items-center bg-gradient-to-b from-[#5517A7]/60 to-[#02102B] rounded-[10px] px-2 py-3 mb-3 health-box border border-[#05183d]">
    <img src={img} alt="health icon" width="50" />
    <div className="ml-3">
      <h4 className="text-[14px] font-semibold text-white">{title}</h4>
      <p className="text-[11px] text-white/[0.8] whitespace-pre-line">
        {subtitle}
      </p>
    </div>
  </div>
);

const MyHealth = () => {
  const {
    state: { lastScanResult, allScore },
  } = useLocation();
  const [isModalOpen, setModalOpen] = useState(false);
  return (
    <div className="w-full pb-28">
      <div className="flex items-center mb-10 ml-6">
        <Link to={-1} className="mr-3">
          <svg
            width="6"
            height="10"
            viewBox="0 0 10 17"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M9.42402 16.2142C9.99277 15.6454 9.99277 14.7267 9.42402 14.1579L3.76569 8.49959L9.42402 2.84126C9.99277 2.27251 9.99277 1.35376 9.42402 0.785009C8.85527 0.216259 7.93652 0.216259 7.36777 0.785009L0.674024 7.47876C0.105274 8.04751 0.105274 8.96626 0.674024 9.53501L7.36777 16.2288C7.92194 16.7829 8.85527 16.7829 9.42402 16.2142Z"
              fill="#fff"
            />
          </svg>
        </Link>
        <h3 className="text-white text-[14px] font-semibold">My Health</h3>
      </div>
      <div className="px-6">
        <div
          className="w-full flex flex-wrap flex-col justify-between"
          data-aos="fade-left"
          data-aos-duration="1000"
        >
          <Link
            className={allScore?.length > 0 ? undefined : "grayscale"}
            to="/vitals-score"
            state={{
              scores: {
                vitalsScore: allScore.find((score) => score.scoreID === "C001"),
                diabetesScore: allScore.find(
                  (score) => score.scoreID === "C002"
                ),
                heartScore: allScore.find((score) => score.scoreID === "C003"),
                dhaScore: allScore.find((score) => score.scoreID === "C005"),
              },
            }}
            onClick={(e) => {
              if (!(allScore?.length > 0)) {
                e.preventDefault();
                setModalOpen(true);
              }
            }}
          >
            <Card
              img={HealthScore}
              title="Health Score"
              subtitle="Overall health is represented by a numerical score"
            />
            <Transition show={isModalOpen} as={Fragment}>
              <Dialog as={Fragment} onClose={() => {}}>
                <Transition.Child
                  className="fixed top-0 bottom-0 left-0 right-0 bg-black/70 flex items-center justify-center"
                  enter="duration-300"
                  enterFrom="opacity-0"
                  enterTo="opacity-100"
                  leave="duration-300 delay-150"
                  leaveFrom="opacity-100"
                  leaveTo="opacity-0"
                >
                  <Transition.Child
                    as={Fragment}
                    enter="delay-150 duration-200"
                    enterFrom="opacity-0 scale-50"
                    enterTo="opacity-100 scale-100"
                    leave="duration-100"
                    leaveFrom="opacity-50 scale-50"
                    leaveTo="opacity-0 scale-0"
                  >
                    <Dialog.Panel className="w-4/5 bg-white rounded-2xl p-6 flex flex-col text-center">
                      <Dialog.Description className="text-black font-normal text-sm">
                        You don't have any scores generated yet. Please do a
                        scan to generate scores.
                      </Dialog.Description>
                      <div className="mt-6 w-full flex justify-evenly">
                        <button
                          className="w-24 px-4 py-2 rounded-full bg-white text-[#FA6363] border border-[#FA6363] text-xs font-medium"
                          type="button"
                          onClick={() => {
                            setModalOpen(false);
                          }}
                        >
                          Close
                        </button>
                        <Link
                          className="w-24 px-4 py-2 rounded-full bg-gradient text-white text-xs font-medium"
                          to="/start-scan"
                        >
                          Scan Now
                        </Link>
                      </div>
                    </Dialog.Panel>
                  </Transition.Child>
                </Transition.Child>
              </Dialog>
            </Transition>
          </Link>
          {[
            {
              icon: HeartHealth,
              text: "Heart Health",
              subtitle: "Love your heart, live a healthy life.",
              to: "/history/heart-health",
              data: {
                created_at: lastScanResult.created_at,
                sdnn: lastScanResult.heart_scores?.sdnn ?? "--",
                cardiac_out: lastScanResult.cardiovascular?.cardiac_out ?? "--",
                map: lastScanResult.cardiovascular?.map ?? "--",
                vo2max: lastScanResult.physiological_scores?.vo2max ?? "--",
              },
            },
            {
              icon: BodyVitals,
              text: "Body Vitals",
              subtitle: "Monitor. Maintain. Track. Your body's vital signs.",
              to: "/history/body-vitals",
              data: {
                created_at: lastScanResult.created_at,
                heart_rate: lastScanResult.vitals?.heart_rate ?? "--",
                bp: `${lastScanResult.vitals?.bp_sys ?? "--"}/${
                  lastScanResult.vitals?.bp_dia ?? "--"
                }`,
                resp_rate: lastScanResult.vitals?.resp_rate ?? "--",
                // oxy_sat_prcnt: lastScanResult.vitals?.oxy_sat_prcnt ?? "--",
              },
            },
            {
              icon: PhysioFitness,
              text: "Physio Fitness",
              subtitle: "Restore. Strengthen. Monitor. Keep a track.",
              to: "/history/physio-fitness",
              data: {
                bmi: lastScanResult.physiological_scores?.bmi ?? 0,
                tbw: lastScanResult.physiological_scores?.tbw ?? "--",
                tbwp: lastScanResult.physiological_scores?.tbwp ?? "--",
                bloodvolume:
                  lastScanResult.physiological_scores?.bloodvolume ?? "--",
                bodyfat: lastScanResult.physiological_scores?.bodyfat ?? "--",
                cal_fat: lastScanResult.physiological_scores?.cal_fat ?? "--",
                cal_carb: lastScanResult.physiological_scores?.cal_carb ?? "--",
              },
            },
            // {
            //   icon: StressIndex,
            //   text: "Stress Index",
            //   subtitle: "Measure. Manage. Find balance. Stress less.",
            //   to: "/history/stress-index",
            //   data: {
            //     stress_index: lastScanResult.heart_scores?.stress_index ?? 0,
            //     rmssd: lastScanResult.heart_scores?.rmssd ?? "--",
            //     heart_rate: lastScanResult.vitals?.heart_rate ?? "--",
            //     bp_sys: lastScanResult.vitals?.bp_sys ?? "--",
            //     bp_dia: lastScanResult.vitals?.bp_dia ?? "--",
            //   },
            // },
          ].map((activity, index) => (
            <Link
              key={`activity-${index}`}
              className={`flex w-[100%] flex-col space-y-1.5 ${
                !(lastScanResult?.created_at?.length > 0)
                  ? "cursor-not-allowed"
                  : ""
              }`}
              to={
                !(lastScanResult?.created_at?.length > 0)
                  ? undefined
                  : activity.to
              }
              state={
                !(lastScanResult?.created_at?.length > 0)
                  ? undefined
                  : activity.data
              }
            >
              <Card
                img={activity.icon}
                title={activity.text}
                subtitle={activity.subtitle}
              />
            </Link>
          ))}
        </div>
      </div>
    </div>
  );
};

export default MyHealth;
