import { Link } from "react-router-dom";
import Carousel from "react-elastic-carousel";
import WellnessImg from "../assets/images/wellness-program.png";
import WellnessCard from "../components/WellnessCard";
import MindBody from "../assets/images/program1.png";
import NutritionImg from "../assets/images/program2.png";
import HealthyHabits from "../assets/images/program3.png";
import MentalWellbing from "../assets/images/mental-wellbing.png";

const WellnessProgram = () => {
  const breakPoints = [
    { width: 1, itemsToShow: 3 },
    { width: 550, itemsToShow: 3, itemsToScroll: 1, pagination: false },
    { width: 850, itemsToShow: 3 },
    { width: 1150, itemsToShow: 4, itemsToScroll: 2 },
    { width: 1450, itemsToShow: 5 },
    { width: 1750, itemsToShow: 6 },
  ];

  return (
    <div className="w-full pb-24">
      <div className="px-6">
        <div className="flex items-center mb-5">
          <Link to="/" className="mr-3">
            <svg
              width="6"
              height="10"
              viewBox="0 0 10 17"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M9.42402 16.2142C9.99277 15.6454 9.99277 14.7267 9.42402 14.1579L3.76569 8.49959L9.42402 2.84126C9.99277 2.27251 9.99277 1.35376 9.42402 0.785009C8.85527 0.216259 7.93652 0.216259 7.36777 0.785009L0.674024 7.47876C0.105274 8.04751 0.105274 8.96626 0.674024 9.53501L7.36777 16.2288C7.92194 16.7829 8.85527 16.7829 9.42402 16.2142Z"
                fill="#192852"
              />
            </svg>
          </Link>
          <h3 className="text-primary text-[16px] font-semibold">
            Recommended Programs
          </h3>
        </div>
      </div>
      <img
        src={WellnessImg}
        alt=""
        data-aos="fade-up"
        data-aos-duration="1000"
        data-aos-delay="50"
      />
      <div className="px-6 mb-3 mt-5">
        <h3
          className="text-black text-[18px] font-semibold mb-2"
          data-aos="fade-up"
          data-aos-duration="1000"
          data-aos-delay="100"
        >
          Nourish Your Life
        </h3>
        <p
          className="text-[#000]/[.7] text-[11px] leading-[16px] text-justify"
          data-aos="fade-up"
          data-aos-duration="1000"
          data-aos-delay="200"
        >
          Nourish Your Life Wellness Program is designed to help individuals
          achieve optimal health and wellness through a combination of
          nutrition, exercise, and lifestyle modifications. This program will
          include a variety of audio, video, and text-based content that will
          provide education, motivation, and support to individuals as they
          embark on their wellness journey.
        </p>
        <div
          className="flex items-center justify-center"
          data-aos="fade-up"
          data-aos-duration="1000"
          data-aos-delay="250"
        >
          <Link
            to="/Join-program"
            className="flex items-center justify-center space-x-2 mt-6 px-4 py-2 rounded-full bg-secondary disabled:bg-darkgray text-white text-[12px]"
          >
            Join the Program
          </Link>
        </div>
      </div>
      <div
        className="wellness-col mb-6 mt-5"
        data-aos="fade-right"
        data-aos-duration="1000"
        data-aos-delay="300"
      >
        <h2 className="text-primary mb-5 mt-10 text-sm font-medium flex justify-between items-center pl-6">
          More Recommended Programs
        </h2>
        <div className="scroll-col">
          <div className="fle-scroll">
            <Carousel
              breakPoints={breakPoints}
              showArrows={false}
              pagination={false}
            >
              <Link to="/">
                <WellnessCard
                  img={MindBody}
                  tittle="MindBody Fuel"
                  subtittle="A comprehensive wellness program featuring nutrition ...."
                  color="rgba(113, 197, 197, 0.8)"
                />
              </Link>
              <Link to="/">
                <WellnessCard
                  img={NutritionImg}
                  tittle="Nutrition In-take"
                  subtittle="Find out if your nutrition in-take is sufficient."
                  color="rgba(118, 186, 47, 0.8)"
                />
              </Link>
              <Link to="/">
                <WellnessCard
                  img={HealthyHabits}
                  tittle="Radiant Health"
                  subtittle="A program aimed at achieving optimal health and wellness ....."
                  color="rgba(31, 40, 55, 0.8)"
                />
              </Link>
              <Link to="/">
                <WellnessCard
                  img={MentalWellbing}
                  tittle="Know Your Health"
                  subtittle="Find your health score in less than 60 secs."
                  color="rgba(214, 71, 102, 0.8)"
                />
              </Link>
            </Carousel>
          </div>
        </div>
      </div>
    </div>
  );
};

export default WellnessProgram;
