const WellnessCard = ({
  color = "#fff8",
  img,
  tittle = "MindBody Fuel",
  subtittle = "A comprehensive wellness program featuring nutrition ....",
}) => {
  return (
    <div className="wellness-card">
      <img src={img} alt="Mental Wellbeing" className=" rounded-[10px]" />
      <div className="overlay-bg" style={{ backgroundColor: color }}>
        <h4>{tittle}</h4>
        <p>{subtittle}</p>
      </div>
    </div>
  );
};

export default WellnessCard;
