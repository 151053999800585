import { useRef, useState } from "react";
import { Link, useLocation } from "react-router-dom";
import { ReactComponent as AudioIcon } from "../assets/icons/Music.svg";
import { ReactComponent as PlayIcon } from "../assets/icons/MusicPlay.svg";
import { ReactComponent as PauseIcon } from "../assets/icons/MusicPause.svg";
import AudioPlayer from "react-h5-audio-player";
import "react-h5-audio-player/lib/styles.css";

const BedtimeStory = () => {
  const {
    state: { name, data },
  } = useLocation();
  const playerRef = useRef();
  const [audioState, setAudioState] = useState({ playing: false, src: "" });
  return (
    <div className="w-full mb-36">
      <div
        className="px-6"
        data-aos="fade-right"
        data-aos-duration="1000"
        data-aos-delay="50"
      >
        <div className="flex items-center mb-5">
          <Link to={-1} className="mr-3">
            <svg
              width="6"
              height="10"
              viewBox="0 0 10 17"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M9.42402 16.2142C9.99277 15.6454 9.99277 14.7267 9.42402 14.1579L3.76569 8.49959L9.42402 2.84126C9.99277 2.27251 9.99277 1.35376 9.42402 0.785009C8.85527 0.216259 7.93652 0.216259 7.36777 0.785009L0.674024 7.47876C0.105274 8.04751 0.105274 8.96626 0.674024 9.53501L7.36777 16.2288C7.92194 16.7829 8.85527 16.7829 9.42402 16.2142Z"
                fill="#fff"
              />
            </svg>
          </Link>
          <h3 className="text-white text-[14px] font-semibold">{name}</h3>
        </div>
      </div>
      <div
        className="px-6 space-y-4"
        data-aos="fade-up"
        data-aos-duration="1000"
        data-aos-delay="200"
      >
        {data.map((item) => (
          <button
            key={`music-${item.content_rank}`}
            type="button"
            onClick={() =>
              setAudioState((s) => {
                const newData =
                  s.src === item.tab_content_music
                    ? { ...s, playing: !s.playing }
                    : { playing: true, src: item.tab_content_music };
                if (newData.playing) {
                  if (s.src?.length > 0)
                    playerRef.current?.audio?.current?.play?.();
                } else playerRef.current?.audio?.current?.pause?.();
                return newData;
              })
            }
            className="relative rounded-xl overflow-hidden"
          >
            <div className="relative">
              <img
                className="w-full"
                src={item.tab_content_poster}
                alt={item.tab_content}
              />
              <AudioIcon className="absolute top-3 right-3 w-6 text-white" />
              {audioState.src === item.tab_content_music &&
              audioState.playing ? (
                <PauseIcon className="absolute top-1/2 left-1/2 -translate-x-1/2 -translate-y-1/2 w-10 p-1 rounded-full text-white bg-[#5517A7]/60" />
              ) : (
                <PlayIcon className="absolute top-1/2 left-1/2 -translate-x-1/2 -translate-y-1/2 w-10 p-1 rounded-full text-white bg-[#5517A7]/60" />
              )}
            </div>
            <p className="px-4 py-2 text-xs text-left text-white bg-[#9300ff]">
              {item.tab_content}
            </p>
          </button>
        ))}
      </div>
      <AudioPlayer
        className="fixed left-0 right-0 bottom-[4.96rem] z-50"
        style={{
          display: audioState.src?.length > 0 ? "block" : "none",
        }}
        layout="horizontal"
        customControlsSection={["MAIN_CONTROLS", "VOLUME_CONTROLS"]}
        ref={playerRef}
        src={audioState.src}
        muted={false}
        loop={false}
        autoPlay
        autoPlayAfterSrcChange
        onLoadedData={() => {
          playerRef.current.audio.current.play();
        }}
        onPlay={() => {
          setAudioState((s) => ({ ...s, playing: true }));
        }}
        onPause={() => {
          setAudioState((s) => ({ ...s, playing: false }));
        }}
        onEnded={() => {
          playerRef.current.audio.current.pause();
          playerRef.current.audio.current.currentTime = 0;
          playerRef.current.audio.current.src = "";
          setAudioState({ playing: false, src: "" });
        }}
      />
    </div>
  );
};

export default BedtimeStory;
