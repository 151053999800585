import { Link } from "react-router-dom";
import EducationImg from "../assets/images/education/education7.png";

const EducationDetails = () => (
  <div className="w-full mb-28">
    {/* back button */}
    <div className="px-6">
      <div
        className="flex items-center mb-5"
        data-aos="fade-right"
        data-aos-duration="1000"
        data-aos-delay="50"
      >
        <Link to="/Education" className="mr-3">
          <svg
            width="7"
            height="12"
            viewBox="0 0 10 17"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M9.42402 16.2142C9.99277 15.6454 9.99277 14.7267 9.42402 14.1579L3.76569 8.49959L9.42402 2.84126C9.99277 2.27251 9.99277 1.35376 9.42402 0.785009C8.85527 0.216259 7.93652 0.216259 7.36777 0.785009L0.674024 7.47876C0.105274 8.04751 0.105274 8.96626 0.674024 9.53501L7.36777 16.2288C7.92194 16.7829 8.85527 16.7829 9.42402 16.2142Z"
              fill="#3298F1"
            />
          </svg>
        </Link>
        <h3 className="text-secondary text-[16px] font-semibold">Education</h3>
      </div>
    </div>
    {/* back button end */}
    <div data-aos="fade-up" data-aos-duration="1000" data-aos-delay="100">
      <img src={EducationImg} alt="" />
    </div>

    <div className="px-6">
      <h3
        className="text-black text-[18px] font-semibold mb-3 mt-5"
        data-aos="fade-up"
        data-aos-duration="1000"
        data-aos-delay="150"
      >
        Stress Management
      </h3>
      <p
        className="text-[#000]/[.7] text-[11px] leading-[16px]"
        data-aos="fade-up"
        data-aos-duration="1000"
        data-aos-delay="200"
      >
        Stress management refers to the strategies and techniques that
        individuals can use to cope with the negative effects of stress on
        physical and mental health. Here are some strategies for stress
        management:
      </p>
      <ol className="list-decimal text-[#000]/[.7] text-[11px] leading-[16px] pl-3 mt-3">
        <li
          className="mb-1"
          data-aos="fade-up"
          data-aos-duration="1000"
          data-aos-delay="250"
        >
          Identify sources of stress: The first step in managing stress is to
          identify the sources of stress in your life. These can be related to
          work, relationships, financial issues, or other personal challenges.
        </li>
        <li
          className="mb-1"
          data-aos="fade-up"
          data-aos-duration="1000"
          data-aos-delay="300"
        >
          Practice relaxation techniques: Relaxation techniques such as deep
          breathing, meditation, yoga, and progressive muscle relaxation can
          help to reduce stress and promote relaxation.
        </li>
        <li
          className="mb-1"
          data-aos="fade-up"
          data-aos-duration="1000"
          data-aos-delay="350"
        >
          Exercise regularly: Regular physical activity has been shown to reduce
          stress and improve mood. Engage in at least 30 minutes of
          moderate-intensity physical activity on most days of the week.
        </li>
        <li
          className="mb-1"
          data-aos="fade-up"
          data-aos-duration="1000"
          data-aos-delay="400"
        >
          Practice good sleep hygiene: Getting adequate sleep is important for
          managing stress. Establish a regular sleep schedule, avoid caffeine
          and alcohol before bedtime, and create a relaxing sleep environment.
        </li>
        <li
          className="mb-1"
          data-aos="fade-up"
          data-aos-duration="1000"
          data-aos-delay="450"
        >
          Prioritize self-care: Taking care of yourself is an important part of
          managing stress. Engage in activities that bring you pleasure, such as
          hobbies or spending time with friends and family.
        </li>
        <li
          className="mb-1"
          data-aos="fade-up"
          data-aos-duration="1000"
          data-aos-delay="500"
        >
          Seek social support: Social support from friends, family, or a
          therapist can help to reduce stress and improve mental health.
        </li>
        <li
          className="mb-1"
          data-aos="fade-up"
          data-aos-duration="1000"
          data-aos-delay="550"
        >
          Manage time effectively: Effective time management can help to reduce
          stress by reducing the sense of overwhelm and allowing you to focus on
          what's important.
        </li>
        <li
          className="mb-1"
          data-aos="fade-up"
          data-aos-duration="1000"
          data-aos-delay="600"
        >
          Avoid unhealthy coping strategies: Unhealthy coping strategies such as
          substance abuse, overeating, or social withdrawal can actually make
          stress worse. Avoid these strategies and instead focus on healthy
          coping mechanisms.
        </li>
      </ol>
      <p
        className="text-[#000]/[.7] text-[11px] leading-[16px] mb-4"
        data-aos="fade-up"
        data-aos-duration="1000"
        data-aos-delay="50"
      >
        Overall, stress management is an important aspect of maintaining good
        physical and mental health. By identifying sources of stress and
        practicing healthy coping strategies, individuals can reduce the
        negative effects of stress on their health and well-being.
      </p>
    </div>
  </div>
);

export default EducationDetails;
