import { Fragment, useState } from "react";
import { Link, Navigate, useLocation, useNavigate } from "react-router-dom";
import { Dialog, Disclosure, Tab, Transition } from "@headlessui/react";
import Slider from "react-slick";
import { useAuth } from "../utils/auth";
import { sdkWrapperURL } from "../utils/api-url-list";
import { constants } from "../utils/ranges";
import { ReactComponent as Chevron } from "../assets/icons/Chevron.svg";
import { ReactComponent as Info } from "../assets/icons/Info.svg";
import { ReactComponent as Caret } from "../assets/icons/Caret.svg";
import { ReactComponent as HbA1cOptimal } from "../assets/icons/HbA1cOptimal.svg";
import { ReactComponent as HbA1cNonOptimal } from "../assets/icons/HbA1cNonOptimal.svg";
// import Scratchcard from "../assets/images/cupon-icon.png";
// import ConfettiPop from "../assets/images/Confetti-Pop.gif";
import HeartHealthScore from "../assets/images/scores/HeartHealthScore.png";
import VitalityMetricScore from "../assets/images/scores/VitalityMetricScore.png";
import MentalWellbeingScore from "../assets/images/scores/MentalWellbeingScore.png";
import DigitalHealthAssessmentScore from "../assets/images/scores/DigitalHealthAssessmentScore.png";
import DiabetesControlScore from "../assets/images/scores/DiabetesControlScore.png";

const Range = ({
  ranges = [
    { point: 0, label: "Start", color: "#ccc" },
    { point: 100, label: "End", color: "#000" },
  ],
  selectedIndex,
  className = "",
}) => {
  selectedIndex -= ranges.length - 1 === selectedIndex;
  return (
    <div className={`relative grid grid-flow-col auto-cols-fr ${className}`}>
      {ranges.slice(0, -1).map((rangeItem, index) => (
        <div key={`range-${index}`} className="flex flex-col justify-end">
          {index === selectedIndex && (
            <Caret
              className="self-center shrink-0 w-2 h-1"
              style={{ color: ranges[selectedIndex].color }}
            />
          )}
          <h6
            className="opacity-60 text-center text-xxs font-medium uppercase truncate"
            style={
              index === selectedIndex
                ? { color: ranges[selectedIndex].color }
                : {}
            }
          >
            {rangeItem.label}
          </h6>
          <div
            className="my-1 h-1 bg-white"
            style={
              index === selectedIndex
                ? { backgroundColor: ranges[selectedIndex].color }
                : {}
            }
          />
          <p className="opacity-70 text-xxs">{rangeItem.point}</p>
        </div>
      ))}
      <p className="absolute bottom-0 right-0 opacity-70 text-xxs">
        {ranges[ranges.length - 1].point}
      </p>
    </div>
  );
};

const ListItemContainer = ({
  disabled = false,
  label = "",
  defaultExpanded = false,
  title,
  desc,
  className = "",
  ...props
}) => {
  return (
    <Disclosure
      defaultOpen={defaultExpanded}
      as="div"
      className="px-6 py-4 rounded-2xl border border-white bg-primary text-white shadow-box"
    >
      <Disclosure.Button as="div" disabled={disabled}>
        {({ open }) => [
          <div
            key="list-item-container-head"
            className="flex items-start justify-between space-x-1"
          >
            <h2 className="text-xs font-medium">{label}</h2>
            <Chevron
              className={`h-3 w-3 opacity-70 transition-transform duration-300 ${
                open ? "rotate-180" : "rotate-0"
              }`}
            />
          </div>,
          <Fragment key="list-item-container-body">{props.children}</Fragment>,
        ]}
      </Disclosure.Button>
      <Disclosure.Panel>
        {typeof title === "string" && (
          <h5 className="mt-2.5 text-secondary text-xs font-medium">{title}</h5>
        )}
        {typeof desc === "string" && (
          <p className="mt-1.5 text-xxs font-light leading-tight whitespace-pre-line">
            {desc}
          </p>
        )}
      </Disclosure.Panel>
    </Disclosure>
  );
};

const ScanResult = () => {
  const {
    getToken,
    userData: {
      profile_id,
      // flyy_id
    },
    subscriptionPlanDetails: { packageName },
  } = useAuth();
  const location = useLocation();
  const result = location.state?.result;
  const scores = location.state?.scores;
  const navigate = useNavigate();

  const [loading, setLoading] = useState(false);

  const [isSuccessModalOpen, setSuccessModalOpen] = useState(false);
  const subscribe = async () => {
    try {
      const token = await getToken();
      const resp = await fetch(sdkWrapperURL("/users/add-saved-products"), {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          Authorization: token,
        },
        body: JSON.stringify({
          profile_id,
          product_id: "a5a5e1b5-fe4a-4224-b1bb-a37a9c4ca2d6",
          interest_filtration: "ACCEPT",
          application_info: {},
          module_type: "content",
        }),
      });
      const resp_json = await resp.json();
      if (resp_json.statusCode?.toString().startsWith("2"))
        setSuccessModalOpen(true);
      else throw new Error(resp_json.message);
    } catch (err) {
      console.error(err);
    }
  };

  // const confidenceScore = useMemo(() => {
  //   const value = result?.metadata?.scan_accuracy ?? 0;
  //   const label = constants["Wellbeing Score"].ranges.reduce((p, c) =>
  //     value > c.point ? c : p
  //   ).label;
  //   return `${label} - ${value}%`;
  // }, [result?.metadata?.scan_accuracy]);

  const getScores = async () => {
    try {
      setLoading(true);
      const token = await getToken();
      const scoreResp = await fetch(sdkWrapperURL("/score/get-scores"), {
        method: "POST",
        headers: { "Content-Type": "application/json", Authorization: token },
        body: JSON.stringify({
          profile_id,
          scan_id: result?.scan_id,
          scores_display_count: 1,
          scores_display_count_offset: 0,
          table_config_rows_per_page: 20,
          table_config_page_no: 1,
        }),
      });
      const scoreRespJSON = await scoreResp.json();
      if (scoreRespJSON?.statusCode?.toString().startsWith("2")) {
        const scoresList = scoreRespJSON.response.map((score) => ({
          code: score.score_code,
          value: score.user_score_details?.[0]?.user_earned_score ?? "--",
          timestamp: score.user_score_details?.[0]?.score_generation_time,
        }));
        if (scoresList.every((score) => !(score.timestamp?.length > 0)))
          throw new Error("Scores not Generated yet.");
        else
          navigate(".", {
            state: { result, scores: scoresList },
            replace: true,
          });
      } else throw new Error(scoreRespJSON.message);
    } catch (e) {
      console.error(e);
    } finally {
      setLoading(false);
    }
  };

  const [modalState, setModalState] = useState({
    visible: false,
    title: "",
    desc: "",
  });

  const closeModal = () =>
    setModalState({ visible: false, title: "", desc: "" });

  // const [isRewardsModalOpen, setRewardsModalOpen] = useState(
  //   () => Date.now() < (location.state?.popup_time ?? 0)
  // );

  // const viewRewards = () => {
  //   if (typeof window?.ReactNativeWebView?.postMessage === "function")
  //     window.ReactNativeWebView.postMessage(
  //       JSON.stringify({ event: "view-rewards", data: { userID: flyy_id } })
  //     );
  //   else {
  //     window.flyySDK?.openRewardsScreen({ fullScreen: true });
  //   }
  //   setRewardsModalOpen(false);
  // };

  const settings = {
    dots: true,
    lazyLoad: true,
    infinite: true,
    speed: 850,
    slidesToShow: 1,
    slidesToScroll: 1,
    initialSlide: 0,
    autoplay: true,
    Arrow: false,
    autoplaySpeed: 2500,
    pauseOnDotsHover: false,
  };

  return result?.scan_id?.length > 0 ? (
    <section className="pt-2 pb-24 overflow-hidden">
      {typeof scores === "undefined" ? (
        <div className="mx-6 mb-4 p-5 rounded-xl border border-white">
          <p className="text-center font-medium">
            We weren't able to
            <br />
            fetch your scores.
          </p>
          <button
            className="block mt-6 mx-auto px-4 py-2 rounded-full bg-gradient disabled:bg-none disabled:bg-secondary text-white text-sm font-medium"
            type="button"
            onClick={getScores}
            disabled={loading}
          >
            Retry
          </button>
        </div>
      ) : (
        <Slider {...settings} className="instruction-slide">
          {[
            {
              title: "Digital Health\nAssessment Score",
              desc: 'Digital Health Risk Assessment Score is a numerical measurement of an individual\'s overall state of well-being, taking into account various aspects of physical, mental, and emotional health. A "good" wellbeing score typically refers to a score that indicates a high level of well-being, with low levels of stress, anxiety, and negative emotions, and high levels of physical and mental energy, resilience, and satisfaction with life.',
              value: scores?.find?.((s) => s.code === "C005")?.value ?? "--",
              logo: DigitalHealthAssessmentScore,
            },
            {
              title: "Heart Health\nScore",
              desc: "The Cardiac Score provides a comprehensive assessment of an individual's cardiac health status, helps to monitor cardiac function, track progress over time, and tailor interventions or treatment plans accordingly. A higher Cardiac Score typically indicates better cardiac health and function, while a lower score may suggest the need for further evaluation or intervention to improve cardiovascular well-being.",
              value: scores?.find?.((s) => s.code === "C003")?.value ?? "--",
              logo: HeartHealthScore,
            },
            {
              title: "Vitality Metric\nScore",
              desc: "The Physiological Score, also known as the Physio Fitness Score, is a quantitative measure that evaluates an individual's overall physiological fitness and well-being. It assesses various aspects of physical health and performance, providing insights into an individual's functional capacity, endurance, and overall health status.",
              value: scores?.find?.((s) => s.code === "C001")?.value ?? "--",
              logo: VitalityMetricScore,
            },
            {
              title: "Mental Wellbeing\nScore",
              desc: "The Mental Wellbeing Score, derived from an individual's stress level, mood insights, sleep quality provides a quantitative measure of an individual's overall mental health. It encompasses various dimensions of mental wellness, including emotional resilience, coping mechanisms, and overall psychological functioning.",
              value: scores?.find?.((s) => s.code === "C004")?.value ?? "--",
              logo: MentalWellbeingScore,
            },
            {
              title: "Diabetes Control\nScore",
              desc: "The Diabetes Control Score (DCS) is a numerical value used to assess how well an individual is managing their condition and maintaining optimal blood glucose levels. It provides insight into the effectiveness of diabetes management strategies.",
              value: scores?.find?.((s) => s.code === "C002")?.value ?? "--",
              logo: DiabetesControlScore,
            },
          ].map((score, index) =>
            index > 0 &&
            (packageName === "FREEMIUM" || packageName === "LITE") ? (
              <div key={`score-${index}-paywalled`} className="px-12">
                <h2 className="mb-6 text-xl font-semibold whitespace-pre-line">
                  {score.title}
                </h2>
                <div className="flex flex-col items-center p-6 rounded-2xl bg-gradient-to-b from-[#063BC4] to-[#680FAD]/90 text-white text-center">
                  <h4 className="font-semibold">Available Only for Members</h4>
                  <p className="mt-5 text-sm font-light">
                    Join our community to unlock this feature & other exclusive
                    wellness offerings. Become a member today and take your
                    health journey to the next level!
                  </p>
                  <button
                    className="mt-6 px-6 py-3 rounded-full outline-none bg-white text-secondary text-sm font-medium"
                    type="button"
                    onClick={subscribe}
                  >
                    Request Membership
                  </button>
                </div>
              </div>
            ) : (
              <div key={`score-${index}`} className="px-12">
                <h2 className="mb-6 text-xl font-semibold whitespace-pre-line">
                  {score.title}
                </h2>
                <div className="flex items-center">
                  <div className="relative h-32 w-32">
                    <div className="absolute inset-[12%] bg-gradient rounded-full -rotate-90 opacity-50" />
                    <div className="absolute inset-0 bg-gradient rounded-full -rotate-90 opacity-25" />
                    <div className="absolute inset-[24%] bg-gradient rounded-full -rotate-90 opacity-100" />
                    <img
                      className="absolute top-1/2 left-1/2 -translate-x-1/2 -translate-y-1/2 h-10 w-10"
                      src={score.logo}
                      alt={score.title}
                    />
                  </div>
                  <p className="text-secondary text-6xl font-bold ml-7">
                    {score.value}
                  </p>
                </div>
                <p className="mt-5 text-[11px] leading-snug text-justify opacity-70">
                  {score.desc}
                </p>
              </div>
            )
          )}
        </Slider>
      )}

      {/* <Link
        to=""
        className="block max-w-max mx-auto my-6 p-4 rounded-full bg-gradient text-white text-sm font-medium"
      >
        Detailed Report &darr;
      </Link>

      <div className="my-5 mx-6 p-6 rounded-xl border border-white">
        <h5 className="text-secondary text-xl font-bold">Scan Quality</h5>
        <p className="mt-3 font-light">
          This measurement's quality is&nbsp;
          <span className="text-secondary font-semibold">
            {confidenceScore}
          </span>
          &nbsp;(confidence level).
          <br />
          <br />
          You're either moving your face or the ambient light was not
          sufficient.
        </p>
      </div> */}

      <h2 className="mt-6 mb-4 mx-6 text-secondary text-xl font-semibold">
        Detailed Report
      </h2>
      <Tab.Group>
        <Tab.List className="flex items-start justify-start overflow-x-auto tab-link relative px-5">
          {["Key Body Vitals", "Heart Health", "Physiological"].map(
            (label, index) => (
              <Tab
                key={`tab-header-${index}`}
                className={({ selected }) =>
                  `m-1 px-4 py-2 rounded-full border border-white ${
                    selected
                      ? "bg-secondary text-white"
                      : "bg-white text-secondary"
                  } text-xxs font-medium whitespace-nowrap outline-none`
                }
              >
                {label}
              </Tab>
            )
          )}
          <Tab
            key="tab-header-4"
            className={({ selected }) =>
              `relative m-1 px-4 py-2 rounded-full border border-white ${
                selected ? "bg-secondary text-white" : "bg-white text-secondary"
              } text-xxs font-medium whitespace-nowrap outline-none`
            }
          >
            Blood Biomarkers
            <span className="absolute right-3 top-0 -translate-y-1/2 px-1 py-0.5 rounded-full bg-error text-white text-[0.5625rem] leading-none font-semibold">
              BETA
            </span>
          </Tab>
          {(result?.metadata?.heart_scores?.stress_index ?? "--") !== "--" && (
            <Tab
              key="tab-header-5"
              className={({ selected }) =>
                `m-1 px-4 py-2 rounded-full border border-white ${
                  selected
                    ? "bg-secondary text-white"
                    : "bg-white text-secondary"
                } text-xxs font-medium whitespace-nowrap outline-none`
              }
            >
              Stress & ANS
            </Tab>
          )}
        </Tab.List>
        <Tab.Panels className="px-6 my-6 space-y-6">
          <Tab.Panel className="space-y-6">
            {[
              { label: "Heart Rate", value: result?.vitals.heart_rate },
              { label: "Respiration Rate", value: result?.vitals.resp_rate },
              // {
              //   label: "Oxygen Saturation",
              //   value: result?.vitals.oxy_sat_prcnt,
              // },
            ].map((item, index) => {
              const ranges =
                item.label === "Heart Rate"
                  ? constants[item.label].ranges[result?.posture ?? "resting"]
                  : constants[item.label].ranges;
              const selectedRangeIndex = ranges.reduce(
                (p, c, ci) => (item.value >= c.point ? ci : p),
                0
              );
              return (
                <ListItemContainer
                  key={`section-item-${index}`}
                  label={item.label}
                  title={`Your ${item.label} is ${ranges[selectedRangeIndex].label}`}
                  desc={
                    constants[item.label].info +
                    "\n\n" +
                    ranges[selectedRangeIndex].desc
                  }
                >
                  <div className="py-3 flex items-center justify-between space-x-2">
                    <div className="shrink-0 basis-1/4 text-center font-semibold">
                      <h4 className="text-secondary text-xl">{item.value}</h4>
                      <h5 className="opacity-70 text-xxs">
                        {constants[item.label].unit}
                      </h5>
                    </div>
                    <Range
                      className="grow"
                      ranges={ranges}
                      selectedIndex={selectedRangeIndex}
                    />
                  </div>
                </ListItemContainer>
              );
            })}
            {(() => {
              if (packageName === "FREEMIUM" || packageName === "LITE")
                return (
                  <div className="flex flex-col items-center p-6 rounded-2xl border border-white bg-gradient-to-b from-[#063BC4] to-[#680FAD]/90 text-white text-center">
                    <h4 className="font-semibold">
                      Available Only for Members
                    </h4>
                    <p className="mt-5 text-sm font-light">
                      Join our community to unlock this feature & other
                      exclusive wellness offerings. Become a member today and
                      take your health journey to the next level!
                    </p>
                    <button
                      className="mt-6 px-6 py-3 rounded-full outline-none bg-white text-secondary text-sm font-medium"
                      type="button"
                      onClick={subscribe}
                    >
                      Request Membership
                    </button>
                  </div>
                );
              else {
                const sys = result?.vitals.bp_sys;
                const dia = result?.vitals.bp_dia;
                const selectedSYSRangeIndex =
                  sys !== "--"
                    ? constants["Blood Pressure"].ranges[0].reduce(
                        (p, c, ci) => (sys > c.point ? ci : p),
                        0
                      )
                    : undefined;
                const selectedDIARangeIndex =
                  dia !== "--"
                    ? constants["Blood Pressure"].ranges[1].reduce(
                        (p, c, ci) => (dia > c.point ? ci : p),
                        0
                      )
                    : undefined;
                return (
                  <ListItemContainer
                    key="section-item-3"
                    label="Blood Pressure"
                    title={`Your Blood Pressure is ${constants["Blood Pressure"].ranges[0][selectedSYSRangeIndex]?.label}`}
                    desc={
                      constants["Blood Pressure"].info +
                      "\n\n" +
                      constants["Blood Pressure"].ranges[0][
                        selectedSYSRangeIndex
                      ]?.desc
                    }
                    disabled={
                      selectedSYSRangeIndex === undefined ||
                      selectedDIARangeIndex === undefined
                    }
                  >
                    <div className="py-3 flex items-center justify-between space-x-2">
                      <div className="shrink-0 basis-1/4 text-center">
                        <div className="font-semibold">
                          <h4 className="inline text-secondary text-xl">
                            {sys}
                          </h4>
                          &nbsp;
                          <h5 className="inline opacity-70 text-xxs">
                            {constants["Blood Pressure"].unit}
                          </h5>
                        </div>
                        <p className="text-ternary text-xxs font-medium">
                          Systolic
                        </p>
                      </div>
                      <Range
                        className="grow"
                        ranges={constants["Blood Pressure"].ranges[0]}
                        selectedIndex={selectedSYSRangeIndex}
                      />
                    </div>
                    <div className="py-3 flex items-center justify-between space-x-2">
                      <div className="shrink-0 basis-1/4 text-center">
                        <div className="font-semibold">
                          <h4 className="inline text-secondary text-xl">
                            {dia}
                          </h4>
                          &nbsp;
                          <h5 className="inline opacity-70 text-xxs">
                            {constants["Blood Pressure"].unit}
                          </h5>
                        </div>
                        <p className="text-ternary text-xxs font-medium">
                          Diastolic
                        </p>
                      </div>
                      <Range
                        className="grow"
                        ranges={constants["Blood Pressure"].ranges[1]}
                        selectedIndex={selectedDIARangeIndex}
                      />
                    </div>
                  </ListItemContainer>
                );
              }
            })()}
          </Tab.Panel>
          <Tab.Panel className="space-y-6">
            {[
              {
                label: "SDNN",
                value:
                  result?.metadata.heart_scores.sdnn !== "--"
                    ? Math.round(result?.metadata.heart_scores.sdnn)
                    : "--",
              },
              {
                label: "RMSSD",
                value:
                  result?.metadata.heart_scores.rmssd !== "--"
                    ? Math.round(result?.metadata.heart_scores.rmssd)
                    : "--",
              },
              {
                label: "PNN50",
                value: result?.metadata.heart_scores.pNN50_per,
              },
              ...(packageName === "FREEMIUM" || packageName === "LITE"
                ? []
                : [
                    {
                      label: "Cardiac Output",
                      value: result?.metadata.cardiovascular.cardiac_out,
                    },
                    {
                      label: "Mean Arterial Pressure",
                      value: result?.metadata.cardiovascular.map,
                    },
                  ]),
              {
                label: "Heart Utilized",
                value: result?.metadata.heart_scores.heart_utilized,
              },
            ].map((item, index) => {
              const selectedRangeIndex =
                item.value !== "--"
                  ? constants[item.label].ranges.reduce(
                      (p, c, ci) => (item.value >= c.point ? ci : p),
                      0
                    )
                  : undefined;
              return (
                <ListItemContainer
                  key={`section-item-${index}`}
                  label={item.label}
                  title={`Your ${item.label} is ${
                    constants[item.label].ranges[selectedRangeIndex]?.label
                  }`}
                  desc={
                    constants[item.label].info +
                    "\n\n" +
                    constants[item.label].ranges[selectedRangeIndex]?.desc
                  }
                  disabled={selectedRangeIndex === undefined}
                >
                  <div className="py-3 flex items-center justify-between space-x-2">
                    <div className="shrink-0 basis-1/4 text-center font-semibold">
                      <h4 className="text-secondary text-xl">{item.value}</h4>
                      <h5 className="opacity-70 text-xxs">
                        {constants[item.label].unit}
                      </h5>
                    </div>
                    <Range
                      className="grow"
                      ranges={constants[item.label].ranges}
                      selectedIndex={selectedRangeIndex}
                    />
                  </div>
                </ListItemContainer>
              );
            })}
            <div className="grid grid-cols-2 gap-3">
              {[
                {
                  label: "Heart Rate Max",
                  value: result?.metadata.heart_scores.HRMax,
                  gradient: "from-[#2055F3] to-transparent",
                },
                {
                  label: "Heart Rate Reserve",
                  value: result?.metadata.heart_scores.HRR,
                  gradient: "from-[#2055F3] to-transparent",
                },
                {
                  label: "Target HR Range",
                  value: result?.metadata.heart_scores.THRR,
                  gradient: "from-[#5517A7]/60 to-transparent",
                },
                {
                  label: "VO₂ Max",
                  value: result?.metadata.physiological_scores.vo2max,
                  gradient: "from-[#5517A7]/60 to-transparent",
                },
              ].map((item, index) => (
                <button
                  type="button"
                  key={`section-smallitem-${index}`}
                  className={`px-3 py-4 rounded-2xl bg-gradient-to-b ${item.gradient} flex flex-col items-stretch justify-between text-left`}
                  onClick={() =>
                    setModalState({
                      visible: true,
                      title: item.label,
                      desc: constants[item.label].description,
                    })
                  }
                >
                  <div className="flex items-start justify-between space-x-1">
                    <h2 className="text-xs font-medium">{item.label}</h2>
                    <Info className="my-0.5 h-3 w-3 opacity-70" />
                  </div>
                  <div className="mt-3 break-all font-semibold">
                    <h4 className="inline text-secondary text-xl">
                      {item.value}
                    </h4>
                    &nbsp;
                    <h5 className="inline opacity-70 text-xxs">
                      {constants[item.label].unit}
                    </h5>
                  </div>
                </button>
              ))}
            </div>
            {(packageName === "FREEMIUM" || packageName === "LITE") && (
              <div className="flex flex-col items-center p-6 rounded-2xl border border-white bg-gradient-to-b from-[#063BC4] to-[#680FAD]/90 text-white text-center">
                <h4 className="font-semibold">Available Only for Members</h4>
                <p className="mt-5 text-sm font-light">
                  Join our community to unlock this feature & other exclusive
                  wellness offerings. Become a member today and take your health
                  journey to the next level!
                </p>
                <button
                  className="mt-6 px-6 py-3 rounded-full outline-none bg-white text-secondary text-sm font-medium"
                  type="button"
                  onClick={subscribe}
                >
                  Request Membership
                </button>
              </div>
            )}
          </Tab.Panel>
          <Tab.Panel className="space-y-6">
            {(() => {
              const bmi = result?.metadata.physiological_scores.bmi;
              const selectedBMIRangeIndex = constants.BMI.ranges.reduce(
                (p, c, ci) => (bmi > c.point ? ci : p),
                0
              );
              return (
                <ListItemContainer
                  label="BMI"
                  title={`Your BMI is ${constants.BMI.ranges[selectedBMIRangeIndex].label}`}
                  desc={
                    constants.BMI.info +
                    "\n\n" +
                    constants.BMI.ranges[selectedBMIRangeIndex].desc
                  }
                >
                  <div className="py-3 flex items-center justify-between space-x-2">
                    <div className="shrink-0 basis-1/4 text-center font-semibold">
                      <h4 className="text-secondary text-xl">{bmi}</h4>
                      <h5 className="opacity-70 text-xxs">
                        {constants.BMI.unit}
                      </h5>
                    </div>
                    <Range
                      className="grow"
                      ranges={constants.BMI.ranges}
                      selectedIndex={selectedBMIRangeIndex}
                    />
                  </div>
                </ListItemContainer>
              );
            })()}
            <div>
              <div className="grid grid-cols-2 gap-3">
                {[
                  {
                    label: "Blood Volume",
                    value: result?.metadata.physiological_scores.bloodvolume,
                    gradient: "from-[#2055F3] to-transparent",
                  },
                  {
                    label: "Total Body Water",
                    value: result?.metadata.physiological_scores.tbw,
                    gradient: "from-[#2055F3] to-transparent",
                  },
                  ...(result?.metadata.physiological_scores.cal_carb !== "--" &&
                  result?.metadata.physiological_scores.cal_fat !== "--"
                    ? [
                        {
                          label: "Calorie from Carb",
                          value:
                            !(
                              result?.metadata.physiological_scores.cal_carb >=
                              0
                            ) ||
                            !(
                              result?.metadata.physiological_scores.cal_carb <=
                              100
                            )
                              ? "--"
                              : result.metadata.physiological_scores.cal_carb,
                          gradient: "from-[#543C9A] to-transparent",
                        },
                        {
                          label: "Calorie from Fat",
                          value:
                            !(
                              result?.metadata.physiological_scores.cal_fat >= 0
                            ) ||
                            !(
                              result?.metadata.physiological_scores.cal_fat <=
                              100
                            )
                              ? "--"
                              : result.metadata.physiological_scores.cal_fat,
                          gradient: "from-[#543C9A] to-transparent",
                        },
                      ]
                    : []),
                ].map((item, index) => (
                  <button
                    type="button"
                    key={`section-smallitem-${index}`}
                    className={`px-3 py-4 rounded-2xl bg-gradient-to-b ${item.gradient} flex flex-col items-stretch justify-between text-left`}
                    onClick={() =>
                      setModalState({
                        visible: true,
                        title: item.label,
                        desc: constants[item.label].description,
                      })
                    }
                  >
                    <div className="flex items-start justify-between space-x-1">
                      <h2 className="text-xs font-medium">{item.label}</h2>
                      <Info className="my-0.5 h-3 w-3 opacity-70" />
                    </div>
                    <div className="mt-3 break-all font-semibold">
                      <h4 className="inline text-secondary text-xl">
                        {item.value}
                      </h4>
                      &nbsp;
                      <h5 className="inline opacity-70 text-xxs">
                        {constants[item.label].unit}
                      </h5>
                    </div>
                  </button>
                ))}
              </div>
              <div className="mt-3 grid grid-cols-3 gap-3">
                {[
                  {
                    label: "Body Water %",
                    value: result?.metadata.physiological_scores.tbwp,
                  },
                  {
                    label: "Total Body Fat",
                    value: result?.metadata.physiological_scores.bodyfat,
                  },
                  {
                    label: "Body Weight",
                    value: result?.metadata.physiological_scores.weight,
                  },
                ].map((item, index) => (
                  <button
                    type="button"
                    key={`section-smallitem-${index}`}
                    className="px-3 py-4 rounded-2xl bg-gradient-to-b from-[#5517A7]/60 to-transparent flex flex-col items-stretch justify-between text-left"
                    onClick={() =>
                      setModalState({
                        visible: true,
                        title: item.label,
                        desc: constants[item.label].description,
                      })
                    }
                  >
                    <div className="flex items-start justify-between space-x-1">
                      <h2 className="text-xs font-medium">{item.label}</h2>
                      <Info className="my-0.5 h-3 w-3 opacity-70" />
                    </div>
                    <div className="mt-3 break-all font-semibold">
                      <h4 className="inline text-secondary text-xl">
                        {item.value}
                      </h4>
                      &nbsp;
                      <h5 className="inline opacity-70 text-xxs">
                        {constants[item.label].unit}
                      </h5>
                    </div>
                  </button>
                ))}
              </div>
            </div>
          </Tab.Panel>
          <Tab.Panel className="space-y-6">
            {(() => {
              if (packageName === "FREEMIUM" || packageName === "LITE")
                return (
                  <div className="flex flex-col items-center p-6 rounded-2xl border border-white bg-gradient-to-b from-[#063BC4] to-[#680FAD]/90 text-white text-center">
                    <h4 className="font-semibold">
                      Available Only for Members
                    </h4>
                    <p className="mt-5 text-sm font-light">
                      Join our community to unlock this feature & other
                      exclusive wellness offerings. Become a member today and
                      take your health journey to the next level!
                    </p>
                    <button
                      className="mt-6 px-6 py-3 rounded-full outline-none bg-white text-secondary text-sm font-medium"
                      type="button"
                      onClick={subscribe}
                    >
                      Request Membership
                    </button>
                  </div>
                );
              else if (
                typeof result?.metadata?.glucose_info?.hba1c !== "number"
              )
                return null;
              else {
                const hba1c = result?.metadata.glucose_info.hba1c;
                return (
                  <div className="px-6 py-4 rounded-2xl border border-white bg-primary text-white shadow-box">
                    <div className="flex items-center justify-between space-x-4 text-[#C924A4]">
                      {hba1c < 5.7 ? (
                        <HbA1cOptimal height={36} width={36} />
                      ) : (
                        <HbA1cNonOptimal height={36} width={36} />
                      )}
                      <div className="grow">
                        <h2 className="text-xs font-medium text-white">
                          Predicted HbA1c Level
                        </h2>
                        <h4 className="mt-1 text-xxs font-medium">
                          {hba1c < 5.7 ? "Optimal" : "Above Optimal"}
                        </h4>
                      </div>
                      <div className="shrink-0 text-right font-semibold">
                        <h4 className="text-xl">
                          {hba1c < 5.7 ? "<5.7" : "≥5.7"}
                        </h4>
                        <h5 className="opacity-70 text-xxs">
                          {constants["HbA1c"].unit}
                        </h5>
                      </div>
                    </div>
                    <div className="relative my-1 py-4 mx-8 grid grid-flow-col auto-cols-fr">
                      <div
                        style={{ color: constants["HbA1c"].ranges[0].color }}
                      >
                        <h6 className="opacity-60 text-left text-xxs font-medium uppercase truncate">
                          {constants["HbA1c"].ranges[0].label}
                        </h6>
                        <div className="my-1 h-1 bg-current rounded-l-full" />
                      </div>
                      <div
                        style={{ color: constants["HbA1c"].ranges[1].color }}
                      >
                        <h6 className="opacity-60 text-center text-xxs font-medium uppercase truncate">
                          {constants["HbA1c"].ranges[1].label}
                        </h6>
                        <div className="my-1 h-1 bg-current rounded-none" />
                        <p className="ml-1 !text-white/70 text-xs">
                          {constants["HbA1c"].ranges[1].point}
                        </p>
                      </div>
                      <div
                        style={{ color: constants["HbA1c"].ranges[2].color }}
                      >
                        <h6 className="opacity-60 text-right text-xxs font-medium uppercase truncate">
                          {constants["HbA1c"].ranges[2].label}
                        </h6>
                        <div className="my-1 h-1 bg-current rounded-r-full" />
                        <p className="-ml-2 !text-white/70 text-xs">
                          {constants["HbA1c"].ranges[2].point}
                        </p>
                      </div>
                      <div className="absolute left-1/3 top-0 bottom-0 border-l-2 border-dashed border-white/60" />
                      <svg
                        className={`absolute left-1/3 top-0.5 h-2.5 ${
                          hba1c < 5.7
                            ? "-translate-x-[250%] rotate-180"
                            : "translate-x-[150%]"
                        }`}
                        viewBox="0 0 6 11"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <path
                          d="M0.5 0.5L0.5 10.5L5.5 5.42754L0.5 0.5Z"
                          fill="#C924A4"
                        />
                      </svg>
                    </div>
                    <p className="text-xxs text-white/60">
                      We have estimated your HbA1c level to be around&nbsp;
                      <span className="text-white">{hba1c}%</span>.<br />
                      Please note that this is an approximate value and may
                      vary.
                    </p>
                  </div>
                );
              }
            })()}
          </Tab.Panel>
          {(result?.metadata?.heart_scores?.stress_index ?? "--") !== "--" && (
            <Tab.Panel className="space-y-6">
              {(() => {
                const stressLevel = result?.metadata.heart_scores.stress_index;
                const selectedRangeIndex = constants[
                  "Stress Level"
                ].ranges.reduce(
                  (p, c, ci) => (stressLevel > c.point ? ci : p),
                  0
                );
                const selectedRange =
                  constants["Stress Level"].ranges[selectedRangeIndex];
                let detailTitle = "";
                let detailDescription = "";
                if ((result?.metadata?.heart_scores?.rmssd ?? "--") !== "--") {
                  if (result.metadata.heart_scores.rmssd < 65) {
                    if (
                      result.vitals.heart_rate > 100 ||
                      result.vitals.bp_sys > 130 ||
                      result.vitals.bp_dia > 90
                    ) {
                      detailTitle = "More Sympathetic Interactions";
                      detailDescription =
                        "Although HRV manifests as a function of your heart rate, it actually originates from your nervous system. Your autonomic nervous system, which controls the involuntary aspects of your physiology, has two branches, parasympathetic (deactivating) and sympathetic (activating).\n\nThe sympathetic nervous system (often called “fight or flight”) reflects responses to things like stress and exercise, and increases your heart rate and blood pressure.";
                    } else {
                      detailTitle = "More Parasympathetic Interactions";
                      detailDescription =
                        "Although HRV manifests as a function of your heart rate, it actually originates from your nervous system. Your autonomic nervous system, which controls the involuntary aspects of your physiology, has two branches, parasympathetic (deactivating) and sympathetic (activating)\n\nThe parasympathetic nervous system (often referred to as “rest and digest”) handles inputs from internal organs, like digestion or your fingernails and hair growing.";
                    }
                  } else {
                    detailTitle = "Balanced ANS Interactions";
                    detailDescription =
                      "When you have high heart rate variability, it means that your body is responsive to both sets of inputs (parasympathetic and sympathetic). This is a sign that your nervous system is balanced, and that your body is very capable of adapting to its environment and performing at its best.";
                  }
                } else {
                  detailDescription =
                    "Not enought data to process. This happens when the signal strength of the scan is poor due to improper light or too much movements.";
                }
                return (
                  <>
                    <div className="px-6 py-4 rounded-2xl border border-white bg-primary text-white shadow-box">
                      <div className="flex items-center justify-between space-x-4">
                        {selectedRange.icon}
                        <div className="grow">
                          <h2 className="text-xs font-medium">Stress Level</h2>
                          <h4
                            className="mt-1 text-xxs font-medium uppercase"
                            style={{ color: selectedRange.color }}
                          >
                            {selectedRange.label}
                          </h4>
                        </div>
                        <div className="shrink-0 text-right font-semibold">
                          <h4 className="text-secondary text-xl">
                            {stressLevel}
                          </h4>
                          <h5 className="opacity-70 text-xxs">
                            {constants["Stress Level"].unit}
                          </h5>
                        </div>
                      </div>
                      <div className="mt-4">
                        <h5 className="text-secondary text-xs font-medium">
                          Your Stress Level is {selectedRange.label}
                        </h5>
                        <p className="mt-1.5 text-xxs font-light leading-tight whitespace-pre-line">
                          {constants["Stress Level"].description}
                        </p>
                      </div>
                    </div>
                    <div className="p-6 rounded-xl bg-ternary/20 space-y-1.5">
                      {detailTitle.length > 0 && (
                        <h3 className="text-xs font-medium">{detailTitle}</h3>
                      )}
                      <p className="text-xxs font-light leading-tight whitespace-pre-line">
                        {detailDescription}
                      </p>
                      <h5 className="text-xs text-center font-medium">
                        Autonomic Nervous Systems (ANS)
                      </h5>
                    </div>
                  </>
                );
              })()}
            </Tab.Panel>
          )}
          <div className="p-6 rounded-2xl flex flex-col items-center text-center bg-gradient-transparent text-white">
            <h4 className="text-xs font-medium">Disclaimer</h4>
            <p className="mt-2 text-xxs text-left">
              For Investigational Use Only. CarePlix is not a substitute for the
              clinical judgment of a healthcare professional. CarePlix is
              intended to improve your awareness of general wellness. CarePlix
              does not diagnose, treat, mitigate or prevent any disease,
              symptom, disorder or abnormal physical state. Consult with a
              healthcare professional or emergency services if you believe you
              may have a medical issue.
            </p>
            <Link
              className="mt-4 px-4 py-2 rounded-full bg-white text-primary text-xs font-medium"
              to="/"
              replace
            >
              Dashboard
            </Link>
          </div>
        </Tab.Panels>
      </Tab.Group>

      <Transition appear show={modalState.visible} as={Fragment}>
        <Dialog as="div" className="relative z-10" onClose={closeModal}>
          <Transition.Child
            as={Fragment}
            enter="ease-out duration-300"
            enterFrom="opacity-0"
            enterTo="opacity-100"
            leave="ease-in duration-200"
            leaveFrom="opacity-100"
            leaveTo="opacity-0"
          >
            <div className="fixed inset-0 bg-black/70" />
          </Transition.Child>

          <div className="fixed inset-0 overflow-y-auto">
            <div className="flex min-h-full items-center justify-center p-4 text-center">
              <Transition.Child
                as={Fragment}
                enter="ease-out duration-300"
                enterFrom="opacity-0 scale-95"
                enterTo="opacity-100 scale-100"
                leave="ease-in duration-200"
                leaveFrom="opacity-100 scale-100"
                leaveTo="opacity-0 scale-95"
              >
                <Dialog.Panel className="w-[90%] max-w-md transform overflow-hidden rounded-2xl bg-white px-4 py-8 text-center align-middle shadow-xl transition-all">
                  <h3 className="text-primary text-sm font-semibold">
                    {modalState.title}
                  </h3>
                  <p className="mt-4 text-primary/70 text-xs leading-tight">
                    {modalState.desc}
                  </p>
                  <button
                    className="mt-6 px-8 py-2 rounded-full outline-none bg-gradient text-white text-sm"
                    onClick={closeModal}
                  >
                    Close
                  </button>
                </Dialog.Panel>
              </Transition.Child>
            </div>
          </div>
        </Dialog>
      </Transition>

      {/* <Transition appear show={isRewardsModalOpen} as={Fragment}>
        <Dialog as={Fragment} onClose={() => setRewardsModalOpen(false)}>
          <Transition.Child
            className="fixed inset-0 bg-black/70 flex items-center justify-center"
            enter="duration-300"
            enterFrom="opacity-0"
            enterTo="opacity-100"
            leave="duration-300 delay-150"
            leaveFrom="opacity-100"
            leaveTo="opacity-0"
          >
            <Transition.Child
              as={Fragment}
              enter="delay-150 duration-200"
              enterFrom="opacity-0 scale-50"
              enterTo="opacity-100 scale-100"
              leave="duration-100"
              leaveFrom="opacity-50 scale-50"
              leaveTo="opacity-0 scale-0"
            >
              <Dialog.Panel className="relative w-[68%] bg-white rounded-2xl p-6 flex flex-col items-center text-center">
                <button
                  className="absolute top-2.5 right-2.5 h-7 w-7 text-primary z-40 outline-none"
                  type="button"
                  onClick={() => setRewardsModalOpen(false)}
                >
                  <span className="sr-only">Close</span>
                  <svg viewBox="0 0 30 30" fill="none">
                    <path
                      d="M15 2.5C21.875 2.5 27.5 8.125 27.5 15C27.5 21.875 21.875 27.5 15 27.5C8.125 27.5 2.5 21.875 2.5 15C2.5 8.125 8.125 2.5 15 2.5Z"
                      stroke="currentColor"
                      strokeOpacity="0.7"
                      strokeLinecap="round"
                      strokeLinejoin="round"
                    />
                    <path
                      d="M11.4624 11.4622L18.5374 18.5371"
                      stroke="currentColor"
                      strokeOpacity="0.7"
                      strokeLinecap="round"
                      strokeLinejoin="round"
                    />
                    <path
                      d="M18.5374 11.4622L11.4624 18.5371"
                      stroke="currentColor"
                      strokeOpacity="0.7"
                      strokeLinecap="round"
                      strokeLinejoin="round"
                    />
                  </svg>
                </button>
                <Dialog.Title className="text-primary text-lg font-semibold mb-4 leading-[20px] scratch-title">
                  <span className="text-secondary">Congrats,</span>&nbsp;You won
                  <br />a Scratch Card
                </Dialog.Title>
                <img src={Scratchcard} alt="Scratch card" />
                <img className="absolute" src={ConfettiPop} alt="Confetti" />
                <Dialog.Description className="mt-3 text-primary text-xs leading-normal">
                  Received for successfully scanning your body vitals
                </Dialog.Description>
                <button
                  className="mt-4 px-4 py-2 rounded-full bg-gradient text-white text-xs font-medium"
                  type="button"
                  onClick={viewRewards}
                >
                  Scratch Now
                </button>
              </Dialog.Panel>
            </Transition.Child>
          </Transition.Child>
        </Dialog>
      </Transition> */}

      <Transition show={isSuccessModalOpen} as={Fragment}>
        <Dialog as={Fragment} onClose={() => setSuccessModalOpen(false)}>
          <Transition.Child
            className="fixed top-0 bottom-0 left-0 right-0 bg-black/70 flex items-center justify-center"
            enter="duration-300"
            enterFrom="opacity-0"
            enterTo="opacity-100"
            leave="duration-300 delay-150"
            leaveFrom="opacity-100"
            leaveTo="opacity-0"
          >
            <Transition.Child
              as={Fragment}
              enter="delay-150 duration-200"
              enterFrom="opacity-0 scale-50"
              enterTo="opacity-100 scale-100"
              leave="duration-100"
              leaveFrom="opacity-50 scale-50"
              leaveTo="opacity-0 scale-0"
            >
              <Dialog.Panel className="w-4/5 bg-white rounded-2xl p-6 flex flex-col items-center text-center">
                <Dialog.Title className="text-secondary font-semibold">
                  Request Submitted Successfully!
                </Dialog.Title>
                <Dialog.Description className="mt-5 text-darkgray text-sm font-light">
                  Your membership request has been submitted successfully. Our
                  team will get back to you shortly. Welcome to the path of
                  better health and wellness!
                </Dialog.Description>
                <button
                  className="mt-6 px-6 py-3 rounded-full outline-none bg-gradient text-white text-sm font-medium"
                  type="button"
                  onClick={() => setSuccessModalOpen(false)}
                >
                  Ok
                </button>
              </Dialog.Panel>
            </Transition.Child>
          </Transition.Child>
        </Dialog>
      </Transition>
    </section>
  ) : (
    <Navigate to="/" replace />
  );
};

export default ScanResult;
