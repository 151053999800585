import { useState, useEffect } from "react";
import { Link, useLocation, useNavigate } from "react-router-dom";
import LoadingScreen from "../components/LoadingScreen";
import { ReactComponent as Loader } from "../assets/icons/Loader.svg";
import { ReactComponent as Close } from "../assets/icons/Close.svg";
import { ReactComponent as Success } from "../assets/icons/success.svg";
import { sdkWrapperURL } from "../utils/api-url-list";
import { useAuth } from "../utils/auth";

const Goal = () => {
  const {
    userData: { profile_id },
    getToken,
  } = useAuth();
  const navigate = useNavigate();
  const location = useLocation();
  const [loading, setLoading] = useState(false);
  const [btnLoading, setBtnLoading] = useState(false);
  const [selectedGoalList, setSelectedGoalList] = useState(
    location.state?.savedGoals || []
  );
  const [goalsList, setgoalsList] = useState([]);
  const [alertActive, setAlertActive] = useState(false);
  const [hasSaved, setSaved] = useState(false);
  const [error, setError] = useState("");
  useEffect(() => {
    setLoading(true);
    (async () => {
      try {
        const token = await getToken();
        const goalsListResp = await fetch(sdkWrapperURL("users/list/goals"), {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
            Authorization: token,
          },
          body: JSON.stringify({
            profile_id: profile_id,
            product_type_id: 23,
            table_config_rows_per_page: 20,
            table_config_page_no: 1,
            saved: 0,
          }),
        });
        const goalsRespJSON = await goalsListResp.json();
        if (goalsRespJSON?.statusCode?.toString().startsWith("2")) {
          setgoalsList(goalsRespJSON.response);
        } else {
          throw Error(goalsRespJSON.message ?? "Error in fetching Alerts.");
        }
      } catch (err) {
        console.error(err);
      } finally {
        setLoading(false);
      }
    })();
  }, [getToken, profile_id]);

  const handleGoalSelection = (index, item) => {
    if (item && item.product_id) {
      const productId = item.product_id;
      if (selectedGoalList.includes(productId)) {
        setSelectedGoalList((gl) => gl.filter((id) => id !== productId));
      } else {
        setSelectedGoalList((gl) => [...gl, productId]);
      }
    }
  };
  const updateGoalsList = async () => {
    try {
      setError("");
      setBtnLoading(true);
      const token = await getToken();
      const alertResp = await fetch(sdkWrapperURL("/users/save-products"), {
        method: "POST",
        headers: { "Content-Type": "application/json", Authorization: token },
        body: JSON.stringify({
          product_type_id: "23",
          action: "multi",
          profile_id: profile_id,
          interest_filtration: "ACCEPT",
          application_info: {
            choices: selectedGoalList,
          },
        }),
      });
      const RespJSON = await alertResp.json();
      if (RespJSON?.statusCode?.toString().startsWith("2")) {
        setSaved(true);
        setTimeout(() => setSaved(false), 2500);
        setTimeout(() => navigate(-1), 3000);
      } else throw new Error(RespJSON.message);
    } catch (e) {
      setError(e.message);
      console.error(e);
    } finally {
      setBtnLoading(false);
    }
  };

  const updateGoals = async () => {
    setAlertActive(false);
    if (selectedGoalList.length < 1 && location.state?.savedGoals < 1) {
      setAlertActive(true);
    } else {
      await updateGoalsList();
    }
  };
  return (
    <div className="w-full pb-24 px-6">
      <div className="flex items-center mb-5">
        <Link to={-1} className="mr-3">
          <svg
            width="6"
            height="10"
            viewBox="0 0 10 17"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M9.42402 16.2142C9.99277 15.6454 9.99277 14.7267 9.42402 14.1579L3.76569 8.49959L9.42402 2.84126C9.99277 2.27251 9.99277 1.35376 9.42402 0.785009C8.85527 0.216259 7.93652 0.216259 7.36777 0.785009L0.674024 7.47876C0.105274 8.04751 0.105274 8.96626 0.674024 9.53501L7.36777 16.2288C7.92194 16.7829 8.85527 16.7829 9.42402 16.2142Z"
              fill="#fff"
            />
          </svg>
        </Link>
        <h3 className="text-white text-base font-semibold">My Goals</h3>
      </div>
      {loading ? (
        <LoadingScreen style={{ minHeight: "60vh" }} />
      ) : (
        <div className="mt-6">
          <h4 className=" text-lg text-[#663CC7] font-medium mb-5">
            Select Your Goals
          </h4>
          <ul className="flex flex-wrap justify-between">
            {goalsList.map((item, i) => (
              <li className="multi-checkbox w-[48%] mb-4 relative">
                <input
                  className="contents"
                  type="checkbox"
                  id={i.toString()}
                  value={i.toString()}
                  onChange={() => handleGoalSelection(i, item)}
                  defaultChecked={selectedGoalList.includes(item.product_id)}
                />
                <label for={i.toString()} className="relative">
                  <p className="absolute z-10 left-4 bottom-4 text-xs font-bold">
                    {item.structure_val.name}
                  </p>
                  <img
                    alt=""
                    src={item.structure_val.logo ?? ""}
                    className="w-full"
                  />
                </label>
              </li>
            ))}
          </ul>
          {alertActive && (
            <div className="flex  border p-2 bg-[#FED0D0] rounded">
              <p className="flex text-center text-sm font-normal text-[#7C272A] w-[70%] justify-evenly">
                <span className="text-center">Please select your goals</span>
              </p>
              <Close
                className="ml-auto mr-1 w-4 z-50 text-[#000] rounded-2xl justify-center text-center"
                onClick={() => setSaved(false)}
              />
            </div>
          )}
          {error.length > 0 && (
            <p className="mt-4 text-center text-sm font-normal text-error">
              {error}
            </p>
          )}
          {hasSaved && (
            <div className="flex  border p-2 bg-[#D9FFED] rounded">
              <p className="flex text-center text-sm font-normal text-[#12C56F] w-[70%] justify-evenly">
                <Success />{" "}
                <span className="text-center">
                  {location.state?.savedGoals.length > 0
                    ? "Edit Goals Successfully"
                    : "Goals Added Successfully"}
                </span>
              </p>
              <Close
                className="ml-auto mr-1 w-4 z-50 text-[#000] rounded-2xl justify-center text-center"
                onClick={() => setSaved(false)}
              />
            </div>
          )}
          <button
            type="button"
            className="bg-gradient w-full h-10 text-sm font-medium text-white rounded-full flex items-center justify-center mt-4"
            onClick={updateGoals}
          >
            Update Goals
            {btnLoading && <Loader className="shrink-0 h-4 w-4" />}
          </button>
        </div>
      )}
    </div>
  );
};

export default Goal;
