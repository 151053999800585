import { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { Tab, Tabs, TabList, TabPanel } from "react-tabs";
import "react-tabs/style/react-tabs.css";
import Slider from "react-slick";
import {
  Accordion,
  AccordionHeader,
  AccordionBody,
} from "@material-tailwind/react";
import MealIcon from "../assets/icons/meal-icon.svg";
import { PieChart, Pie, Legend, Cell, ResponsiveContainer } from "recharts";
import { sdkWrapperURL } from "../utils/api-url-list";
import { useAuth } from "../utils/auth";
import LoadingScreen from "../components/LoadingScreen";

const COLORS = ["#5782ff", "#ff8b6f", "#ffb23e"];
const RADIAN = Math.PI / 180;
const renderCustomizedLabel = ({
  cx,
  cy,
  midAngle,
  innerRadius,
  outerRadius,
  percent,
  index,
}) => {
  const radius = innerRadius + (outerRadius - innerRadius) * 0.5;
  const x = cx + radius * Math.cos(-midAngle * RADIAN);
  const y = cy + radius * Math.sin(-midAngle * RADIAN);

  return (
    <text
      x={x}
      y={y}
      fill="white"
      textAnchor={x > cx ? "start" : "end"}
      dominantBaseline="central"
    >
      {`${(percent * 100).toFixed(0)}%`}
    </text>
  );
};
function Icon({ isOpen = false }) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      fill="none"
      viewBox="0 0 24 24"
      strokeWidth={2}
      stroke="#fff"
      className={`${isOpen ? "rotate-180 " : ""}h-5 w-5 transition-transform`}
    >
      <path
        strokeLinecap="round"
        strokeLinejoin="round"
        d="M19.5 8.25l-7.5 7.5-7.5-7.5"
      />
    </svg>
  );
}
const getDate = (dateType = "today") => {
  const formatDate = (date = new Date()) => {
    const year = date.getFullYear();
    const month = (date.getMonth() + 1).toString().padStart(2, "0");
    const day = date.getDate().toString().padStart(2, "0");
    return `${year}-${month}-${day}`;
  };
  const today = new Date();
  switch (dateType) {
    case "yesterday":
      const yesterday = new Date();
      yesterday.setDate(today.getDate() - 1);
      return formatDate(yesterday);
    default:
    case "today":
      return formatDate(today);
    case "tomorrow":
      const tomorrow = new Date();
      tomorrow.setDate(today.getDate() + 1);
      return formatDate(tomorrow);
  }
};

const NutrationHistory = () => {
  const {
    getToken,
    userData: { profile_id },
  } = useAuth();
  const [loading, setLoading] = useState(false);
  const [activeItem, setActiveItem] = useState(1);
  const [open, setOpen] = useState(-1);
  const [selectedTabIndex, setSelectedTabIndex] = useState(0);
  const [mealDetails, setMealDetails] = useState({});
  const handleTabSelect = (index) => {
    const mealType = ["breakfast", "lunch", "dinner"][index];
    setOpen((mealDetails[mealType]?.info?.length ?? 0) - 1);
    setSelectedTabIndex(index);
  };
  useEffect(() => {
    (async () => {
      setLoading(true);
      setMealDetails({});
      setOpen(-1);
      try {
        const day = ["yesterday", "today", "tomorrow"][activeItem];
        const token = await getToken();
        const resp = await fetch(
          sdkWrapperURL("/nutrition/get-nutrition-history"),
          {
            method: "POST",
            headers: {
              "Content-Type": "application/json",
              Authorization: token,
            },
            body: JSON.stringify({
              profile_id,
              nutrition_type: "meal",
              start_date: getDate(day),
              end_date: getDate(day),
              table_config_rows_per_page: 3,
              table_config_page_no: 1,
            }),
          }
        );
        const respJSON = await resp.json();
        if (respJSON?.statusCode?.toString().startsWith("2")) {
          const respMealDetails = Object.fromEntries(
            respJSON?.response?.map((item) => [
              item.meal_type,
              {
                id: item.meal_id,
                query: item.meal_query,
                info: item.meal_info,
              },
            ])
          );
          setMealDetails(respMealDetails);
          const mealType = ["breakfast", "lunch", "dinner"][selectedTabIndex];
          setOpen((respMealDetails[mealType]?.info?.length ?? 0) - 1);
        } else throw new Error(respJSON?.message ?? "Error in Fetching List");
      } catch (err) {
        console.error(err);
      } finally {
        setLoading(false);
      }
    })();
  }, [getToken, profile_id, activeItem, selectedTabIndex]);
  const settings = {
    dots: false,
    lazyLoad: true,
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    initialSlide: 1,
    autoplay: false,
    Arrow: true,
    autoplaySpeed: 2500,
    pauseOnDotsHover: false,
    afterChange: (currentSlide) => {
      setActiveItem(currentSlide);
    },
  };

  return (
    <div className="px-6 w-full pb-24">
      <div className="flex items-center mb-4">
        <Link to={-1} className="mr-3">
          <svg
            width="6"
            height="10"
            viewBox="0 0 10 17"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M9.42402 16.2142C9.99277 15.6454 9.99277 14.7267 9.42402 14.1579L3.76569 8.49959L9.42402 2.84126C9.99277 2.27251 9.99277 1.35376 9.42402 0.785009C8.85527 0.216259 7.93652 0.216259 7.36777 0.785009L0.674024 7.47876C0.105274 8.04751 0.105274 8.96626 0.674024 9.53501L7.36777 16.2288C7.92194 16.7829 8.85527 16.7829 9.42402 16.2142Z"
              fill="#fff"
            />
          </svg>
        </Link>
        <h3 className="text-white text-base font-semibold">Meals History</h3>
      </div>
      <div className="relative">
        <Slider {...settings} className="day-slide">
          <div className="slider-item">Yesterday</div>
          <div className="slider-item">Today</div>
          <div className="slider-item">Tomorrow</div>
        </Slider>
      </div>
      <div className="mt-5">
        <Tabs selectedIndex={selectedTabIndex} onSelect={handleTabSelect}>
          <TabList className="meal-tab bg-[#02102b] h-10 justify-between flex items-center mb-4">
            {["breakfast", "lunch", "dinner"].map((item) => (
              <Tab
                key={item}
                className="border-2 border-[#1C31BF] rounded-lg bg-transparent flex items-center justify-center outline-none text-sm capitalize h-9 w-[30%]"
              >
                {item}
              </Tab>
            ))}
          </TabList>

          {["breakfast", "lunch", "dinner"].map((item) => (
            <TabPanel key={item}>
              {loading ? (
                <LoadingScreen style={{ minHeight: "60vh" }} />
              ) : mealDetails[item]?.info?.length > 0 ? (
                <div className="bg-gradient-to-t from-[#5E14B0]/5 to-[#5717B1] px-6 py-6 rounded-[10px] border-2 border-[#3C158E] mb-8">
                  <h4 className="text-sm text-white">Nutrition Results</h4>
                  {mealDetails[item]?.info?.map((item, index) => {
                    const chartData = [
                      { name: "Fats", value: item.fat_total_g },
                      { name: "Protiens", value: item.protein_g },
                      {
                        name: "Carbohydrates",
                        value: item.carbohydrates_total_g,
                      },
                    ];
                    return (
                      <Accordion
                        key={`accordion-${index}`}
                        open={open === index}
                        icon={<Icon isOpen={open === index} />}
                        className="text-sm text-white accordin-tab"
                      >
                        <AccordionHeader
                          onClick={() =>
                            setOpen((op) => (op === index ? -1 : index))
                          }
                          className="text-sm text-white font-semibold capitalize"
                        >
                          {item.name}
                        </AccordionHeader>
                        <AccordionBody>
                          <div className="w-50 h-56 -mt-3 mb-5">
                            <ResponsiveContainer width="100%" height="100%">
                              <PieChart width={400} height={400}>
                                <Pie
                                  data={chartData}
                                  cx="50%"
                                  cy="50%"
                                  labelLine={false}
                                  label={renderCustomizedLabel}
                                  outerRadius={80}
                                  dataKey="value"
                                >
                                  {chartData.map((entry, index) => (
                                    <Cell
                                      style={{
                                        outline: "none",
                                      }}
                                      key={`cell-${index}`}
                                      fill={COLORS[index % COLORS.length]}
                                      stroke="#3a087f"
                                      strokeWidth={3}
                                    />
                                  ))}
                                </Pie>
                                <Legend />
                              </PieChart>
                            </ResponsiveContainer>
                          </div>
                          <p className="flex items-center justify-between text-xs text-white mb-1">
                            Approx. Serving Size&nbsp;
                            <span>{item.serving_size_g?.toFixed(2) ?? 0}g</span>
                          </p>
                          <p className="flex items-center justify-between text-xs text-white mb-1">
                            Calories&nbsp;
                            <span>{item.calories?.toFixed(2) ?? 0}cal</span>
                          </p>
                          <p className="flex items-center justify-between text-xs text-white mb-1">
                            Total Fat&nbsp;
                            <span>{item.fat_total_g?.toFixed(2) ?? 0}g</span>
                          </p>
                          <p className="flex items-center justify-between text-xs text-white mb-1">
                            Saturated Fat&nbsp;
                            <span>
                              {item.fat_saturated_g?.toFixed(2) ?? 0}g
                            </span>
                          </p>
                          <p className="flex items-center justify-between text-xs text-white mb-1">
                            Cholesterol&nbsp;
                            <span>
                              {item.cholesterol_mg?.toFixed(2) ?? 0}mg
                            </span>
                          </p>
                          <p className="flex items-center justify-between text-xs text-white mb-1">
                            Sodium&nbsp;
                            <span>{item.sodium_mg?.toFixed(2) ?? 0}mg</span>
                          </p>
                          <p className="flex items-center justify-between text-xs text-white mb-1">
                            Carbohydrates&nbsp;
                            <span>
                              {item.carbohydrates_total_g?.toFixed(2) ?? 0}g
                            </span>
                          </p>
                          <p className="flex items-center justify-between text-xs text-white mb-1">
                            Fiber&nbsp;
                            <span>{item.fiber_g?.toFixed(2) ?? 0}g</span>
                          </p>
                          <p className="flex items-center justify-between text-xs text-white mb-1">
                            Sugar&nbsp;
                            <span>{item.sugar_g?.toFixed(2) ?? 0}g</span>
                          </p>
                          <p className="flex items-center justify-between text-xs text-white mb-1">
                            Protein&nbsp;
                            <span>{item.protein_g?.toFixed(2) ?? 0}g</span>
                          </p>
                        </AccordionBody>
                      </Accordion>
                    );
                  })}
                  <Link
                    to="/nutrition-results"
                    state={{
                      logMealType: item,
                      logMealDay: ["yesterday", "today", "tomorrow"][
                        activeItem
                      ],
                      query: mealDetails[item]?.query ?? "",
                    }}
                    replace
                    className="block w-40 mx-auto mt-6 px-4 py-2 rounded-full bg-[#0A39C3] text-white text-sm text-center"
                  >
                    Update Details
                  </Link>
                </div>
              ) : (
                <div className="flex flex-col items-center justify-center h-[50vh]">
                  <img src={MealIcon} alt="" />
                  <p className="text-sm text-white/70 mt-5">
                    You have not tracked your meal.
                  </p>
                  <Link
                    to="/nutrition-results"
                    state={{
                      logMealType: item,
                      logMealDay: ["yesterday", "today", "tomorrow"][
                        activeItem
                      ],
                    }}
                    replace
                    className="block w-full mx-auto mt-20 px-4 py-2 rounded-full bg-[#0A39C3] text-white text-sm text-center"
                  >
                    Log Meal
                  </Link>
                </div>
              )}
            </TabPanel>
          ))}
        </Tabs>
      </div>
    </div>
  );
};

export default NutrationHistory;
