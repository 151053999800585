import { Link } from "react-router-dom";
import ErrorPage from "../assets/images/404.svg";

const NotFound = () => {
  return (
    <div className="flex flex-col items-center justify-center h-screen">
      <img src={ErrorPage} alt="" className=" w-60" />
      <p className="text-sm text-white my-4">Page not found</p>
      <Link
        to="/"
        className="flex justify-center items-center h-8 bg-[#0A39C3] rounded-3xl text-xs text-white px-6 mt-3"
      >
        Back to Dashboard
      </Link>
    </div>
  );
};

export default NotFound;
