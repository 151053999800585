import { useState, useEffect } from "react";
import { Link, useLocation, useNavigate } from "react-router-dom";
import { Tab } from "@headlessui/react";
import ReactMarkdown from "react-markdown";
import remarkBreaks from "remark-breaks";
import { Player } from "video-react";
import { useAuth } from "../utils/auth";
import { sdkWrapperURL } from "../utils/api-url-list";
import LoadingScreen from "../components/LoadingScreen";
import { ReactComponent as BackArrow } from "../assets/icons/BackArrow.svg";

const ContentTabs = () => {
  const navigate = useNavigate();
  const { state } = useLocation();
  const {
    getToken,
    userData: { profile_id },
  } = useAuth();
  const [products, setProducts] = useState([]);

  useEffect(() => {
    (async () => {
      try {
        const token = await getToken();
        const resp = await fetch(sdkWrapperURL("/users/list/products"), {
          method: "POST",
          headers: { "Content-Type": "application/json", Authorization: token },
          body: JSON.stringify({
            module_type: "content",
            profile_id,
            product_type_id: state?.listID,
            table_config_page_no: 1,
            table_config_rows_per_page: 100,
          }),
        });
        const respJSON = await resp.json();
        if (respJSON?.statusCode?.toString().startsWith("2")) {
          setProducts(
            respJSON?.response
              ?.map?.((p) => ({
                product_id: p.product_id ?? "",
                ...(p.structure_val ?? {}),
              }))
              ?.sort(
                (a, b) =>
                  a.tab_content.content_rank - b.tab_content.content_rank
              ) ?? []
          );
        } else
          throw new Error(respJSON?.message ?? "Error in Fetching Products");
      } catch (err) {
        console.error(err);
        navigate(-1);
      }
    })();
  }, [navigate, getToken, profile_id, state?.listID]);

  return products?.length > 0 ? (
    <div className="pt-2 pb-24">
      <div className="flex items-center mb-5 px-6 text-white">
        <Link className="shrink-0 p-1 pr-2.5" to={-1}>
          <BackArrow className="h-2.5 w-2.5" />
        </Link>
        <h3 className="text-sm font-medium">{state?.name}</h3>
      </div>

      <Tab.Group>
        <Tab.List className="px-6 flex items-stretch space-x-1.5 overflow-x-auto lab-tab">
          {products.map((p) => (
            <Tab
              key={`tab-header-${p.product_id}`}
              className={({ selected }) =>
                `shrink-0 w-2/5 flex flex-col space-y-1.5 p-1.5 outline-none rounded-xl border border-secondary/70 ${
                  selected ? "bg-gradient-transparent" : "bg-transparent"
                } text-white text-left`
              }
            >
              <h4 className="text-[11px] leading-none font-medium">
                {p.tab_heading.heading}
              </h4>
              <p className="text-[8px] leading-tight font-light tracking-tight line-clamp-2">
                {p.tab_heading.description}
              </p>
            </Tab>
          ))}
        </Tab.List>
        <Tab.Panels>
          {products?.map((p) => (
            <Tab.Panel key={`tab-panel-${p.product_id}`} className="px-6 mt-7">
              {p.tab_content.content_url?.length > 0 && (
                <div className="mb-6 w-full rounded-lg overflow-hidden">
                  <Player poster={p.tab_content.content_thumbnail}>
                    <source
                      className="rounded-lg"
                      src={p.tab_content.content_url}
                      controls
                      controlsList="nodownload noplaybackrate"
                      playsInline
                      disablePictureInPicture
                      disableRemotePlayback
                    />
                  </Player>
                </div>
              )}
              <ReactMarkdown
                className="prose-sm prose-neutral text-xs text-white/80"
                children={p.tab_content.description}
                remarkPlugins={[remarkBreaks]}
              />
            </Tab.Panel>
          ))}
        </Tab.Panels>
      </Tab.Group>
    </div>
  ) : (
    <LoadingScreen style={{ minHeight: "60vh" }} />
  );
};

export default ContentTabs;
