import { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import Carousel from "react-elastic-carousel";
import LoadingScreen from "../components/LoadingScreen";

import { useAuth } from "../utils/auth";
import { sdkWrapperURL } from "../utils/api-url-list";

const Card = ({ img, title = "", subtitle = "" }) => {
  return (
    <div className="wellness-card">
      <img src={img} alt="Mental Wellbeing" className=" rounded-[10px]" />
      <div className="flex flex-col justify-center w-full absolute left-0 bottom-0 rounded-[10px] bg-gradient-to-t from-[#44148B]/90 to-[#02102B]/90 p-2 opacity-90">
        <h4 className="text-xxs text-white font-medium">{title}</h4>
        <p className="text-[7px] text-white">{subtitle}</p>
      </div>
    </div>
  );
};

const BetterSleep = () => {
  const {
    getToken,
    userData: { profile_id },
  } = useAuth();
  const [loading, setLoading] = useState(true);
  const [musicCards, setMusicCards] = useState([]);
  const [banners, setBanners] = useState([]);
  useEffect(() => {
    (async () => {
      setLoading(true);
      try {
        const token = await getToken();
        const resp = await fetch(sdkWrapperURL("/users/list/products"), {
          method: "POST",
          headers: { "Content-Type": "application/json", Authorization: token },
          body: JSON.stringify({
            module_type: "content",
            profile_id,
            product_type_id: 35,
            table_config_rows_per_page: 100,
            table_config_page_no: 1,
          }),
        });
        const respJSON = await resp.json();
        if (respJSON?.statusCode?.toString().startsWith("2")) {
          setBanners(
            respJSON?.response?.map?.((p) => ({
              product_id: p.product_id ?? "",
              ...(p.structure_val ?? {}),
            })) ?? []
          );
        } else
          throw new Error(respJSON?.message ?? "Error in Fetching List Data");

        const respp = await fetch(sdkWrapperURL("/users/list/products"), {
          method: "POST",
          headers: { "Content-Type": "application/json", Authorization: token },
          body: JSON.stringify({
            module_type: "content",
            profile_id,
            product_type_id: [36, 37, 38, 39],
            table_config_rows_per_page: 100,
            table_config_page_no: 1,
          }),
        });
        const respJSONN = await respp.json();
        if (respJSONN?.statusCode?.toString().startsWith("2")) {
          const products =
            respJSONN?.response?.map?.((p) => ({
              product_id: p.product_id ?? "",
              ...(p.structure_val ?? {}),
            })) ?? [];
          setMusicCards([
            {
              href: "/relaxing-music",
              ...products.find((p) => p.title === "Relaxing Music"),
            },
            {
              href: "/bedtime-story",
              ...products.find((p) => p.title === "Bedtime Story"),
            },
            {
              href: "/bedtime-melody",
              ...products.find((p) => p.title === "Bedtime Melody"),
            },
            {
              href: "/bedtime-stretching",
              ...products.find((p) => p.title === "Bedtime Stretching"),
            },
          ]);
        } else
          throw new Error(respJSON?.message ?? "Error in Fetching List Data");
      } catch (err) {
        console.error(err);
      } finally {
        setLoading(false);
      }
    })();
  }, [getToken, profile_id]);
  const breakPoints = [
    { width: 1, itemsToShow: 1.7 },
    { width: 550, itemsToShow: 1.7, itemsToScroll: 1, pagination: false },
    { width: 850, itemsToShow: 1.7 },
    { width: 1150, itemsToShow: 4, itemsToScroll: 1 },
    { width: 1450, itemsToShow: 5 },
    { width: 1750, itemsToShow: 6 },
  ];
  return (
    <div className="pb-24">
      <div className="px-6">
        <div className="flex items-center mb-5">
          <Link to={-1} className="mr-3">
            <svg
              width="6"
              height="10"
              viewBox="0 0 10 17"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M9.42402 16.2142C9.99277 15.6454 9.99277 14.7267 9.42402 14.1579L3.76569 8.49959L9.42402 2.84126C9.99277 2.27251 9.99277 1.35376 9.42402 0.785009C8.85527 0.216259 7.93652 0.216259 7.36777 0.785009L0.674024 7.47876C0.105274 8.04751 0.105274 8.96626 0.674024 9.53501L7.36777 16.2288C7.92194 16.7829 8.85527 16.7829 9.42402 16.2142Z"
                fill="#fff"
              />
            </svg>
          </Link>
          <h3 className="text-white text-[14px] font-semibold">Better Sleep</h3>
        </div>
      </div>
      {loading ? (
        <LoadingScreen style={{ minHeight: "60vh" }} />
      ) : (
        <>
          <div className="w-full">
            <Carousel
              data-aos="fade-right"
              data-aos-duration="1000"
              breakPoints={breakPoints}
              showArrows={false}
              pagination={false}
            >
              {banners?.map((el, index) => (
                <Link
                  key={`top-card-${index}`}
                  className="mr-2"
                  to="/content-details"
                  state={{
                    name: el.title,
                    data: el.contents?.sort?.(
                      (a, b) => (a.content_rank ?? 0) - (b.content_rank ?? 0)
                    ),
                  }}
                >
                  <Card
                    img={el.thumbnail}
                    title={el.title}
                    subtitle={el.description}
                  />
                </Link>
              ))}
            </Carousel>
          </div>
          <div className="px-6 mt-6">
            <div className="grid grid-cols-2 gap-4">
              {musicCards?.map((el, index) => (
                <Link
                  key={`bottom-card-${index}`}
                  to={el.href}
                  state={{
                    name: el.title,
                    data: el.contents?.sort?.(
                      (a, b) => (a.content_rank ?? 0) - (b.content_rank ?? 0)
                    ),
                  }}
                >
                  <div
                    className="p-4 h-36 rounded-2xl flex flex-col items-start justify-between bg-gradient-to-t from-[#02102B]/55 to-[#5517A7]/55"
                    // style={{ backgroundColor: `${el.color}80` }}
                  >
                    <div
                      className="w-12 h-12 rounded-full flex items-center justify-center bg-gradient-to-t from-transparent to-[#6F1CE9]"
                      // style={{ backgroundColor: el.color }}
                    >
                      <img src={el.thumbnail} className="w-9" alt={el.title} />
                    </div>
                    <div>
                      <p className="text-white/80 text-xs">
                        {el.contents?.length} Music
                      </p>
                      <h4 className="text-[#9147FF] text-[12px] font-medium">
                        {el.title}
                      </h4>
                    </div>
                  </div>
                </Link>
              ))}
            </div>
          </div>
        </>
      )}
    </div>
  );
};

export default BetterSleep;
