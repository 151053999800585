import { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import { Tab, Tabs, TabList, TabPanel } from "react-tabs";
import PackageCard from "../components/PackageCard";
import { useAuth } from "../utils/auth";
import { sdkWrapperURL } from "../utils/api-url-list";
import LoadingScreen from "../components/LoadingScreen";

const PackageList = () => {
  const {
    getToken,
    userData: { profile_id },
  } = useAuth();
  const [loading, setLoading] = useState(true);
  const [tabIndex, setTabIndex] = useState(0);
  const [wellnesspackgesList, setWellnesspackgesList] = useState();
  const [dietpackgesList, setDietpackgesList] = useState();
  const [gympackgesList, setGympackgesList] = useState();

  useEffect(() => {
    const controller = new AbortController();
    setLoading(true);
    (async () => {
      try {
        const token = await getToken();
        const wellnessResp = await fetch(
          sdkWrapperURL("/users/list/products"),
          {
            method: "POST",
            headers: {
              "Content-Type": "application/json",
              Authorization: token,
            },
            body: JSON.stringify({
              profile_id,
              product_type_id: 4,
              table_config_rows_per_page: 100,
              table_config_page_no: 1,
            }),
            signal: controller.signal,
          }
        );
        const wellnessRespJSON = await wellnessResp.json();

        if (wellnessRespJSON?.statusCode?.toString().startsWith("2")) {
          const fitness = wellnessRespJSON?.response?.filter(
            (val) => val?.structure_val?.type === "Workout + Meal Plan"
          );
          const diet = wellnessRespJSON?.response?.filter(
            (val) => val?.structure_val?.type === "Diet Plan"
          );
          const gym = wellnessRespJSON?.response?.filter(
            (val) => val?.structure_val?.type === "Gym Package"
          );
          setWellnesspackgesList(fitness);
          setDietpackgesList(diet);
          setGympackgesList(gym);
        } else
          throw new Error(
            wellnessRespJSON?.message ?? "Error in Fetching Wellness list"
          );
      } catch (err) {
        console.error(err);
      } finally {
        setLoading(false);
      }
    })();
    return () => {
      controller.abort();
    };
  }, [getToken, profile_id]);

  return loading ? (
    <LoadingScreen style={{ minHeight: "60vh" }} />
  ) : (
    <div className="pb-32 w-full">
      <div
        className="px-6"
        data-aos="fade-right"
        data-aos-duration="1000"
        data-aos-delay="50"
      >
        <div className="flex items-center mb-5">
          <Link to={-1} className="mr-3">
            <svg
              width="6"
              height="10"
              viewBox="0 0 10 17"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M9.42402 16.2142C9.99277 15.6454 9.99277 14.7267 9.42402 14.1579L3.76569 8.49959L9.42402 2.84126C9.99277 2.27251 9.99277 1.35376 9.42402 0.785009C8.85527 0.216259 7.93652 0.216259 7.36777 0.785009L0.674024 7.47876C0.105274 8.04751 0.105274 8.96626 0.674024 9.53501L7.36777 16.2288C7.92194 16.7829 8.85527 16.7829 9.42402 16.2142Z"
                fill="#192852"
              />
            </svg>
          </Link>
          <h3 className="text-primary text-[14px] font-semibold">
            Package List
          </h3>
        </div>
      </div>
      <Tabs
        selectedIndex={tabIndex}
        onSelect={(tabIndex) => setTabIndex(tabIndex)}
        className="wellbing-tab"
      >
        <TabList
          data-aos="fade-right"
          data-aos-duration="1000"
          data-aos-delay="100"
        >
          <Tab>
            <h4>Workout + Meal Plan</h4>
            <p>
              Focus your attention on a specific object, thought, or sensation.
            </p>
          </Tab>
          <Tab>
            <h4>Diet Packages</h4>
            <p>
              Reduces stress & anxiety, blood pressure, & improve mental health
            </p>
          </Tab>
          <Tab>
            <h4>Gym packages</h4>
            <p>
              Be fully present & aware of thoughts, feelings, & surroundings
            </p>
          </Tab>
        </TabList>
        <TabPanel>
          <div
            className="px-6 mt-7"
            data-aos="fade-up"
            data-aos-duration="1000"
            data-aos-delay="150"
          >
            {wellnesspackgesList?.map((value, i) => (
              <PackageCard
                key={i}
                img={value?.structure_val?.large_icon}
                tittle={value?.product_name}
                subtittle={value?.structure_val?.subtext}
                packageData={value}
              />
            ))}
          </div>
        </TabPanel>

        <TabPanel>
          <div
            className="px-6 mt-5"
            data-aos="fade-up"
            data-aos-duration="1000"
            data-aos-delay="150"
          >
            {dietpackgesList?.map((value, i) => (
              <PackageCard
                key={i}
                img={value?.structure_val?.large_icon}
                tittle={value?.product_name}
                subtittle={value?.structure_val?.subtext}
                packageData={value}
              />
            ))}
          </div>
        </TabPanel>
        <TabPanel>
          <div
            className="px-6 mt-5"
            data-aos="fade-up"
            data-aos-duration="1000"
            data-aos-delay="150"
          >
            {gympackgesList?.map((value, i) => (
              <PackageCard
                key={i}
                img={value?.structure_val?.large_icon}
                tittle={value?.product_name}
                subtittle={value?.structure_val?.subtext}
                packageData={value}
              />
            ))}
          </div>
        </TabPanel>
      </Tabs>
    </div>
  );
};

export default PackageList;
