import { Fragment, useState } from "react";
import { Link, useLocation } from "react-router-dom";
import { Dialog, Transition } from "@headlessui/react";
import { useAuth } from "../utils/auth";
import { sdkWrapperURL } from "../utils/api-url-list";
import MoodIcon from "../assets/icons/mood-icon.svg";
import MentalIcon from "../assets/icons/mental-wel-bing.svg";
import StressIndex from "../assets/icons/stress-icon.svg";

const MyMind = () => {
  const {
    state: { stressIndexData, mentalData, surveyID },
  } = useLocation();
  const {
    getToken,
    userData: { profile_id },
    subscriptionPlanDetails: { packageName },
  } = useAuth();
  const [isModalOpen, setModalOpen] = useState(false);
  const [isFreemiumModalOpen, setFreemiumModalOpen] = useState(false);
  const [isSuccessModalOpen, setSuccessModalOpen] = useState(false);

  const subscribe = async () => {
    try {
      const token = await getToken();
      const resp = await fetch(sdkWrapperURL("/users/add-saved-products"), {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          Authorization: token,
        },
        body: JSON.stringify({
          profile_id,
          product_id: "a5a5e1b5-fe4a-4224-b1bb-a37a9c4ca2d6",
          interest_filtration: "ACCEPT",
          application_info: {},
          module_type: "content",
        }),
      });
      const resp_json = await resp.json();
      if (resp_json.statusCode?.toString().startsWith("2")) {
        setSuccessModalOpen(true);
        setFreemiumModalOpen(false);
      } else throw new Error(resp_json.message);
    } catch (err) {
      console.error(err);
    }
  };

  return (
    <div className="px-6 pb-24">
      <div className="flex items-center mb-5">
        <Link to={-1} className="mr-3">
          <svg
            width="6"
            height="10"
            viewBox="0 0 10 17"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M9.42402 16.2142C9.99277 15.6454 9.99277 14.7267 9.42402 14.1579L3.76569 8.49959L9.42402 2.84126C9.99277 2.27251 9.99277 1.35376 9.42402 0.785009C8.85527 0.216259 7.93652 0.216259 7.36777 0.785009L0.674024 7.47876C0.105274 8.04751 0.105274 8.96626 0.674024 9.53501L7.36777 16.2288C7.92194 16.7829 8.85527 16.7829 9.42402 16.2142Z"
              fill="#fff"
            />
          </svg>
        </Link>
        <h3 className="text-white text-[14px] font-semibold">My Mind</h3>
      </div>
      <div className="w-full">
        <div className="w-full relative">
          <Link
            to="/score-details"
            state={{ scoreData: mentalData }}
            className={`bg-gradient-to-t from-transparent to-[#5413A2]/80 rounded-[12px] px-4 py-4 min-h-[100px] flex ${
              packageName === "FREEMIUM" ||
              packageName === "LITE" ||
              !mentalData?.score
                ? "grayscale"
                : ""
            }`}
            onClick={(e) => {
              if (packageName === "FREEMIUM" || packageName === "LITE") {
                e.preventDefault();
                setFreemiumModalOpen(true);
              } else if (!mentalData?.score) {
                e.preventDefault();
                setModalOpen(true);
              }
            }}
          >
            <div className="flex flex-col w-[65%]">
              <h4 className=" text-sm text-white font-semibold mb-2">
                Mental Wellbeing Score
              </h4>
              <p className="text-[11px] text-white/[0.8]">
                Nurturing your mind for a flourishing life and inner peace
              </p>
            </div>

            <img
              src={MentalIcon}
              width="120"
              alt=""
              className=" absolute right-4 bottom-0"
            />
          </Link>
          <Transition show={isModalOpen} as={Fragment}>
            <Dialog as={Fragment} onClose={() => {}}>
              <Transition.Child
                className="fixed top-0 bottom-0 left-0 right-0 bg-black/70 flex items-center justify-center"
                enter="duration-300"
                enterFrom="opacity-0"
                enterTo="opacity-100"
                leave="duration-300 delay-150"
                leaveFrom="opacity-100"
                leaveTo="opacity-0"
              >
                <Transition.Child
                  as={Fragment}
                  enter="delay-150 duration-200"
                  enterFrom="opacity-0 scale-50"
                  enterTo="opacity-100 scale-100"
                  leave="duration-100"
                  leaveFrom="opacity-50 scale-50"
                  leaveTo="opacity-0 scale-0"
                >
                  <Dialog.Panel className="w-4/5 bg-white rounded-2xl p-6 flex flex-col text-center">
                    <Dialog.Description className="text-black font-normal text-sm">
                      This score is not generated yet.
                      <br />
                      Please do a scan to generate scores.
                    </Dialog.Description>
                    <div className="mt-6 w-full flex justify-evenly">
                      <button
                        className="w-24 px-4 py-2 rounded-full bg-white text-[#FA6363] border border-[#FA6363] text-xs font-medium"
                        type="button"
                        onClick={() => {
                          setModalOpen(false);
                        }}
                      >
                        Close
                      </button>
                      <Link
                        className="w-24 px-4 py-2 rounded-full bg-gradient text-white text-xs font-medium"
                        to="/start-scan"
                      >
                        Scan Now
                      </Link>
                    </div>
                  </Dialog.Panel>
                </Transition.Child>
              </Transition.Child>
            </Dialog>
          </Transition>
          <Transition show={isFreemiumModalOpen} as={Fragment}>
            <Dialog as={Fragment} onClose={() => setFreemiumModalOpen(false)}>
              <Transition.Child
                className="fixed top-0 bottom-0 left-0 right-0 bg-black/70 flex items-center justify-center"
                enter="duration-300"
                enterFrom="opacity-0"
                enterTo="opacity-100"
                leave="duration-300 delay-150"
                leaveFrom="opacity-100"
                leaveTo="opacity-0"
              >
                <Transition.Child
                  as={Fragment}
                  enter="delay-150 duration-200"
                  enterFrom="opacity-0 scale-50"
                  enterTo="opacity-100 scale-100"
                  leave="duration-100"
                  leaveFrom="opacity-50 scale-50"
                  leaveTo="opacity-0 scale-0"
                >
                  <Dialog.Panel className="w-4/5 bg-white rounded-2xl p-6 flex flex-col items-center text-center">
                    <Dialog.Title className="text-secondary font-semibold">
                      Available Only for Members
                    </Dialog.Title>
                    <Dialog.Description className="mt-5 text-darkgray text-sm font-light">
                      Join our community to unlock this feature & other
                      exclusive wellness offerings. Become a member today and
                      take your health journey to the next level!
                    </Dialog.Description>
                    <button
                      className="mt-6 px-6 py-3 rounded-full outline-none bg-gradient text-white text-sm font-medium"
                      type="button"
                      onClick={subscribe}
                    >
                      Request Membership
                    </button>
                  </Dialog.Panel>
                </Transition.Child>
              </Transition.Child>
            </Dialog>
          </Transition>
          <Transition show={isSuccessModalOpen} as={Fragment}>
            <Dialog as={Fragment} onClose={() => setSuccessModalOpen(false)}>
              <Transition.Child
                className="fixed top-0 bottom-0 left-0 right-0 bg-black/70 flex items-center justify-center"
                enter="duration-300"
                enterFrom="opacity-0"
                enterTo="opacity-100"
                leave="duration-300 delay-150"
                leaveFrom="opacity-100"
                leaveTo="opacity-0"
              >
                <Transition.Child
                  as={Fragment}
                  enter="delay-150 duration-200"
                  enterFrom="opacity-0 scale-50"
                  enterTo="opacity-100 scale-100"
                  leave="duration-100"
                  leaveFrom="opacity-50 scale-50"
                  leaveTo="opacity-0 scale-0"
                >
                  <Dialog.Panel className="w-4/5 bg-white rounded-2xl p-6 flex flex-col items-center text-center">
                    <Dialog.Title className="text-secondary font-semibold">
                      Request Submitted Successfully!
                    </Dialog.Title>
                    <Dialog.Description className="mt-5 text-darkgray text-sm font-light">
                      Your membership request has been submitted successfully.
                      Our team will get back to you shortly. Welcome to the path
                      of better health and wellness!
                    </Dialog.Description>
                    <button
                      className="mt-6 px-6 py-3 rounded-full outline-none bg-gradient text-white text-sm font-medium"
                      type="button"
                      onClick={() => setSuccessModalOpen(false)}
                    >
                      Ok
                    </button>
                  </Dialog.Panel>
                </Transition.Child>
              </Transition.Child>
            </Dialog>
          </Transition>
        </div>
        <div className="w-full relative mt-4">
          <Link
            to="/history/stress-index"
            state={stressIndexData}
            className="bg-gradient-to-t from-transparent to-[#5413A2]/80 rounded-[12px] px-4 py-4 min-h-[100px] flex"
          >
            <div className="flex flex-col w-[60%]">
              <h4 className=" text-sm text-white font-semibold mb-2">
                Stress Index
              </h4>
              <p className="text-[11px] text-white/[0.8]">
                Measure. Manage. Find balance. Stress less.
              </p>
            </div>

            <img
              src={StressIndex}
              width="70"
              alt=""
              className=" absolute right-4 bottom-0"
            />
          </Link>
        </div>
        <div className="w-full relative mt-4">
          <Link
            state={{ surveyID }}
            to="/mood-insight"
            className="bg-gradient-to-t from-transparent to-[#5413A2]/80 rounded-[12px] px-4 py-4 min-h-[100px] flex"
          >
            <div className="flex flex-col w-[65%]">
              <h4 className=" text-sm text-white font-semibold mb-2">
                Mood Insight
              </h4>
              <p className="text-[11px] text-white/[0.8]">
                Daily reflections tracking emotions for a healthier state of
                mind.
              </p>
            </div>

            <img
              src={MoodIcon}
              width="65"
              alt=""
              className="absolute right-4 top-[0px]"
            />
          </Link>
        </div>
      </div>
    </div>
  );
};

export default MyMind;
