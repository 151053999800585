import { Fragment, useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { Dialog, RadioGroup, Transition } from "@headlessui/react";

import LoadingScreen from "../../components/LoadingScreen";
import Graph from "../../components/Graph";

import { constants } from "../../utils/ranges";
import { useAuth } from "../../utils/auth";
import { sdkWrapperURL } from "../../utils/api-url-list";

import { ReactComponent as BackArrow } from "../../assets/icons/BackArrow.svg";
import { ReactComponent as HRV } from "../../assets/icons/HRV.svg";
import { ReactComponent as CardiacOutput } from "../../assets/icons/CardiacOutput.svg";
import { ReactComponent as MeanArterialPressure } from "../../assets/icons/MeanArterialPressure.svg";
import { ReactComponent as VO2Max } from "../../assets/icons/VO2Max.svg";
import { ReactComponent as HBA1C } from "../../assets/icons/hba1c.svg";

const HeartHealth = () => {
  const {
    getToken,
    userData: { user_id, profile_id },
    subscriptionPlanDetails: { packageName },
  } = useAuth();

  const [isSuccessModalOpen, setSuccessModalOpen] = useState(false);
  const [loading, setLoading] = useState(true);
  const [graphType, setGraphType] = useState("weekly");
  const [allGraphData, setGraphData] = useState([]);

  const [modalState, setModalState] = useState({
    visible: false,
    title: "",
    desc: "",
  });
  const closeModal = () =>
    setModalState({ visible: false, title: "", desc: "" });

  useEffect(() => {
    const controller = new AbortController();
    setLoading(true);
    setGraphData([]);
    (async () => {
      try {
        const timezone = Intl.DateTimeFormat().resolvedOptions().timeZone;
        const token = await getToken();
        const dataResp = await fetch(
          sdkWrapperURL("/users/sdk/graph_vitals_history_private"),
          {
            method: "POST",
            headers: {
              "Content-Type": "application/json",
              Authorization: token,
            },
            body: JSON.stringify({
              employee_id: user_id,
              profile_id: profile_id,
              date_range: graphType,
              timezone,
            }),
          }
        );
        const dataRespJSON = await dataResp.json();
        if (dataRespJSON?.statusCode?.toString().startsWith("2"))
          setGraphData(dataRespJSON.response.response_graph.reverse());
        else throw new Error(dataRespJSON.message);
      } catch (err) {
        console.error(err);
      } finally {
        setLoading(false);
      }
    })();
    return () => {
      controller.abort();
    };
  }, [getToken, user_id, profile_id, graphType]);

  const subscribe = async () => {
    try {
      const token = await getToken();
      const resp = await fetch(sdkWrapperURL("/users/add-saved-products"), {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          Authorization: token,
        },
        body: JSON.stringify({
          profile_id,
          product_id: "a5a5e1b5-fe4a-4224-b1bb-a37a9c4ca2d6",
          interest_filtration: "ACCEPT",
          application_info: {},
          module_type: "content",
        }),
      });
      const resp_json = await resp.json();
      if (resp_json.statusCode?.toString().startsWith("2"))
        setSuccessModalOpen(true);
      else throw new Error(resp_json.message);
    } catch (err) {
      console.error(err);
    }
  };

  return (
    <section className="px-6 py-2 mb-24">
      <div className="flex items-center text-white">
        <Link className="flex-shrink-0 p-1 pr-2.5" to={-1}>
          <BackArrow className="h-2.5 w-2.5" />
        </Link>
        <h3 className="text-sm font-medium">Heart Health</h3>
      </div>

      <div className="my-6 space-y-6">
        <RadioGroup
          value={graphType}
          onChange={setGraphType}
          disabled={loading}
          className="flex items-center justify-between px-8 space-x-2"
        >
          {["yearly", "monthly", "weekly", "daily"].map((type) => (
            <RadioGroup.Option
              key={`graph-type-${type}`}
              value={type}
              className={({ checked }) =>
                `text-xs ${
                  checked ? "text-[#812EFD]" : "text-white/70"
                } capitalize cursor-pointer font-semibold`
              }
            >
              {type}
            </RadioGroup.Option>
          ))}
        </RadioGroup>
        {loading ? (
          <LoadingScreen style={{ minHeight: "50vh" }} />
        ) : (
          <>
            <Graph
              icon={<HRV className="h-5 w-5" />}
              label="HRV (SDNN)"
              chartType={graphType}
              chartData={allGraphData.map((item) => ({
                label: item.start_date,
                value:
                  typeof item.sdnn === "number"
                    ? parseInt(item.sdnn.toFixed(0), 10)
                    : 0,
                color: constants["SDNN"].ranges.reduce(
                  (p, c) => (item.sdnn >= c.point ? c.color : p),
                  "#0000"
                ),
              }))}
              legend={constants["SDNN"].ranges
                .slice(0, -1)
                .map(({ label, color }) => ({ label, color }))}
              unit={constants["SDNN"].unit}
              defaultExpanded
              viewInfo={() =>
                setModalState({
                  visible: true,
                  title: "SDNN",
                  desc: constants["SDNN"].info,
                })
              }
              historyDetail={{
                title: "HRV (SDNN)",
                ranges: constants.SDNN.ranges,
                keys: ["heart_scores", "sdnn"],
                unit: constants.SDNN.unit,
              }}
            />

            <Graph
              icon={<HRV className="h-5 w-5" />}
              label="HRV (RMSSD)"
              chartType={graphType}
              chartData={allGraphData.map((item) => ({
                label: item.start_date,
                value:
                  typeof item.rmssd === "number"
                    ? parseInt(item.rmssd.toFixed(0), 10)
                    : 0,
                color: constants["RMSSD"].ranges.reduce(
                  (p, c) => (item.rmssd >= c.point ? c.color : p),
                  "#0000"
                ),
              }))}
              legend={constants["RMSSD"].ranges
                .slice(0, -1)
                .map(({ label, color }) => ({ label, color }))}
              unit={constants["RMSSD"].unit}
              defaultExpanded
              viewInfo={() =>
                setModalState({
                  visible: true,
                  title: "RMSSD",
                  desc: constants["RMSSD"].info,
                })
              }
              historyDetail={{
                title: "HRV (RMSSD)",
                ranges: constants.RMSSD.ranges,
                keys: ["heart_scores", "rmssd"],
                unit: constants.RMSSD.unit,
              }}
            />

            <Graph
              icon={<HRV className="h-5 w-5" />}
              label="HRV (PNN50)"
              chartType={graphType}
              chartData={allGraphData.map((item) => ({
                label: item.start_date,
                value:
                  typeof item.pnn50_per === "number"
                    ? parseFloat(item.pnn50_per.toFixed(2))
                    : 0,
                color: constants["PNN50"].ranges.reduce(
                  (p, c) => (item.pnn50_per >= c.point ? c.color : p),
                  "#0000"
                ),
              }))}
              legend={constants["PNN50"].ranges
                .slice(0, -1)
                .map(({ label, color }) => ({ label, color }))}
              unit={constants["PNN50"].unit}
              defaultExpanded
              viewInfo={() =>
                setModalState({
                  visible: true,
                  title: "PNN50",
                  desc: constants["PNN50"].info,
                })
              }
              historyDetail={{
                title: "HRV (PNN50)",
                ranges: constants.PNN50.ranges,
                keys: ["heart_scores", "pNN50_per"],
                unit: constants.PNN50.unit,
              }}
            />

            {!(packageName === "FREEMIUM" || packageName === "LITE") && (
              <>
                <Graph
                  icon={<CardiacOutput className="h-5 w-5" />}
                  label="Cardiac Output"
                  chartType={graphType}
                  chartData={allGraphData.map((item) => ({
                    label: item.start_date,
                    value:
                      typeof item.cardiac_out === "number"
                        ? parseFloat(item.cardiac_out.toFixed(2))
                        : 0,
                    color: constants["Cardiac Output"].ranges.reduce(
                      (p, c) => (item.cardiac_out >= c.point ? c.color : p),
                      "#0000"
                    ),
                  }))}
                  legend={constants["Cardiac Output"].ranges
                    .slice(0, -1)
                    .map(({ label, color }) => ({ label, color }))}
                  unit={constants["Cardiac Output"].unit}
                  defaultExpanded
                  viewInfo={() =>
                    setModalState({
                      visible: true,
                      title: "Cardiac Output",
                      desc: constants["Cardiac Output"].info,
                    })
                  }
                  historyDetail={{
                    title: "Cardiac Output",
                    ranges: constants["Cardiac Output"].ranges,
                    keys: ["cardiovascular", "cardiac_out"],
                    unit: constants["Cardiac Output"].unit,
                  }}
                />
                <Graph
                  icon={<MeanArterialPressure className="h-5 w-5" />}
                  label="Mean Arterial Pressure"
                  chartType={graphType}
                  chartData={allGraphData.map((item) => ({
                    label: item.start_date,
                    value:
                      typeof item.map === "number"
                        ? parseFloat(item.map.toFixed(2))
                        : 0,
                    color: constants["Mean Arterial Pressure"].ranges.reduce(
                      (p, c) => (item.map >= c.point ? c.color : p),
                      "#0000"
                    ),
                  }))}
                  legend={constants["Mean Arterial Pressure"].ranges
                    .slice(0, -1)
                    .map(({ label, color }) => ({ label, color }))}
                  unit={constants["Mean Arterial Pressure"].unit}
                  defaultExpanded
                  viewInfo={() =>
                    setModalState({
                      visible: true,
                      title: "Mean Arterial Pressure",
                      desc: constants["Mean Arterial Pressure"].info,
                    })
                  }
                  historyDetail={{
                    title: "Mean Arterial Pressure",
                    ranges: constants["Mean Arterial Pressure"].ranges,
                    keys: ["cardiovascular", "map"],
                    unit: constants["Mean Arterial Pressure"].unit,
                  }}
                />
              </>
            )}

            <Graph
              icon={<VO2Max className="h-5 w-5" />}
              label="VO₂ Max"
              chartType={graphType}
              chartData={allGraphData.map((item) => ({
                label: item.start_date,
                value:
                  typeof item.vo2max === "number"
                    ? parseFloat(item.vo2max.toFixed(2))
                    : 0,
                color: "#C924A4",
              }))}
              unit={constants["VO₂ Max"].unit}
              defaultExpanded
              viewInfo={() =>
                setModalState({
                  visible: true,
                  title: "VO₂ Max",
                  desc: constants["VO₂ Max"].description,
                })
              }
              historyDetail={{
                title: "VO₂ Max",
                keys: ["physiological_scores", "vo2max"],
                unit: constants["VO₂ Max"].unit,
              }}
            />

            {packageName === "FREEMIUM" || packageName === "LITE" ? (
              <div className="flex flex-col items-center p-6 rounded-2xl bg-gradient-to-b from-[#063BC4] to-[#680FAD]/90 text-white text-center">
                <h4 className="font-semibold">Available Only for Members</h4>
                <p className="mt-5 text-sm font-light">
                  Join our community to unlock this feature & other exclusive
                  wellness offerings. Become a member today and take your health
                  journey to the next level!
                </p>
                <button
                  className="mt-6 px-6 py-3 rounded-full outline-none bg-white text-secondary text-sm font-medium"
                  type="button"
                  onClick={subscribe}
                >
                  Request Membership
                </button>
                <Transition show={isSuccessModalOpen} as={Fragment}>
                  <Dialog
                    as={Fragment}
                    onClose={() => setSuccessModalOpen(false)}
                  >
                    <Transition.Child
                      className="fixed top-0 bottom-0 left-0 right-0 bg-black/70 flex items-center justify-center"
                      enter="duration-300"
                      enterFrom="opacity-0"
                      enterTo="opacity-100"
                      leave="duration-300 delay-150"
                      leaveFrom="opacity-100"
                      leaveTo="opacity-0"
                    >
                      <Transition.Child
                        as={Fragment}
                        enter="delay-150 duration-200"
                        enterFrom="opacity-0 scale-50"
                        enterTo="opacity-100 scale-100"
                        leave="duration-100"
                        leaveFrom="opacity-50 scale-50"
                        leaveTo="opacity-0 scale-0"
                      >
                        <Dialog.Panel className="w-4/5 bg-white rounded-2xl p-6 flex flex-col items-center text-center">
                          <Dialog.Title className="text-secondary font-semibold">
                            Request Submitted Successfully!
                          </Dialog.Title>
                          <Dialog.Description className="mt-5 text-darkgray text-sm font-light">
                            Your membership request has been submitted
                            successfully. Our team will get back to you shortly.
                            Welcome to the path of better health and wellness!
                          </Dialog.Description>
                          <button
                            className="mt-6 px-6 py-3 rounded-full outline-none bg-gradient text-white text-sm font-medium"
                            type="button"
                            onClick={() => setSuccessModalOpen(false)}
                          >
                            Ok
                          </button>
                        </Dialog.Panel>
                      </Transition.Child>
                    </Transition.Child>
                  </Dialog>
                </Transition>
              </div>
            ) : (
              <Graph
                icon={<HBA1C className="h-5 w-5" />}
                label="HbA1c"
                chartType={graphType}
                chartData={allGraphData.map((item) => ({
                  label: item.start_date,
                  value:
                    typeof item.hba1c === "number"
                      ? parseFloat(item.hba1c.toFixed(1))
                      : 0,
                  color: constants["HbA1c"].ranges.reduce(
                    (p, c) => (item.hba1c >= c.point ? c.color : p),
                    "#0000"
                  ),
                }))}
                legend={constants["HbA1c"].ranges
                  .slice(0, -1)
                  .map(({ label, color }) => ({ label, color }))}
                unit={constants["HbA1c"].unit}
                defaultExpanded
                viewInfo={() =>
                  setModalState({
                    visible: true,
                    title: "HbA1c",
                    desc: constants["HbA1c"].info,
                  })
                }
                historyDetail={{
                  title: "HbA1c",
                  ranges: constants["HbA1c"].ranges,
                  keys: ["glucose_info", "hba1c"],
                  unit: constants["HbA1c"].unit,
                }}
              />
            )}

            <Transition show={modalState.visible} as={Fragment}>
              <Dialog as={Fragment} onClose={closeModal}>
                <Transition.Child
                  className="fixed top-0 bottom-0 left-0 right-0 bg-black/70 flex items-center justify-center"
                  enter="duration-300"
                  enterFrom="opacity-0"
                  enterTo="opacity-100"
                  leave="duration-300 delay-150"
                  leaveFrom="opacity-100"
                  leaveTo="opacity-0"
                >
                  <Transition.Child
                    as={Fragment}
                    enter="delay-150 duration-200"
                    enterFrom="opacity-0 scale-50"
                    enterTo="opacity-100 scale-100"
                    leave="duration-100"
                    leaveFrom="opacity-50 scale-50"
                    leaveTo="opacity-0 scale-0"
                  >
                    <Dialog.Panel className="w-4/5 bg-white rounded-2xl p-6 flex flex-col items-center text-center">
                      <Dialog.Title className="text-primary text-xs font-medium">
                        {modalState.title}
                      </Dialog.Title>
                      <Dialog.Description className="mt-2 text-primary/70 text-xxs">
                        {modalState.desc}
                      </Dialog.Description>
                      <button
                        className="mt-4 px-4 py-2 rounded-full bg-gradient-to-r from-[#063BC4] to-[#680FAD] text-white text-xs font-medium"
                        type="button"
                        onClick={closeModal}
                      >
                        Close
                      </button>
                    </Dialog.Panel>
                  </Transition.Child>
                </Transition.Child>
              </Dialog>
            </Transition>
          </>
        )}
      </div>
    </section>
  );
};

export default HeartHealth;
