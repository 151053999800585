import { Fragment, useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import { Dialog, Transition } from "@headlessui/react";
import { useAuth } from "../utils/auth";
import { sdkWrapperURL } from "../utils/api-url-list";

const Vouchers = () => {
  const navigate = useNavigate();
  const {
    userData: { profile_id },
    subscriptionPlanDetails: { packageName },
    getToken,
  } = useAuth();
  const [isSuccessModalOpen, setSuccessModalOpen] = useState(false);

  const subscribe = async () => {
    try {
      const token = await getToken();
      const resp = await fetch(sdkWrapperURL("/users/add-saved-products"), {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          Authorization: token,
        },
        body: JSON.stringify({
          profile_id,
          product_id: "a5a5e1b5-fe4a-4224-b1bb-a37a9c4ca2d6",
          interest_filtration: "ACCEPT",
          application_info: {},
          module_type: "content",
        }),
      });
      const resp_json = await resp.json();
      if (resp_json.statusCode?.toString().startsWith("2"))
        setSuccessModalOpen(true);
      else throw new Error(resp_json.message);
    } catch (err) {
      console.error(err);
    }
  };

  const closeModal = () => {
    setSuccessModalOpen(false);
    navigate(-1);
  };

  return (
    <div className="px-6 mb-28">
      <div
        className="flex items-center mb-5"
        data-aos="fade-right"
        data-aos-duration="1000"
        data-aos-delay="50"
      >
        <Link to={-1} className="mr-3">
          <svg
            width="6"
            height="10"
            viewBox="0 0 10 17"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M9.42402 16.2142C9.99277 15.6454 9.99277 14.7267 9.42402 14.1579L3.76569 8.49959L9.42402 2.84126C9.99277 2.27251 9.99277 1.35376 9.42402 0.785009C8.85527 0.216259 7.93652 0.216259 7.36777 0.785009L0.674024 7.47876C0.105274 8.04751 0.105274 8.96626 0.674024 9.53501L7.36777 16.2288C7.92194 16.7829 8.85527 16.7829 9.42402 16.2142Z"
              fill="#fff"
            />
          </svg>
        </Link>
        <h3 className="text-white text-sm font-semibold">Vouchers</h3>
      </div>
      {packageName === "FREEMIUM" ? (
        <div className="flex flex-col items-center p-6 rounded-2xl bg-gradient-to-b from-[#063BC4] to-[#680FAD]/90 text-white text-center">
          <h4 className="font-semibold">Available Only for Members</h4>
          <p className="mt-5 text-sm font-light">
            Join our community to unlock this feature & other exclusive wellness
            offerings. Become a member today and take your health journey to the
            next level!
          </p>
          <button
            className="mt-6 px-6 py-3 rounded-full outline-none bg-white text-secondary text-sm font-medium"
            type="button"
            onClick={subscribe}
          >
            Request Membership
          </button>
          <Transition show={isSuccessModalOpen} as={Fragment}>
            <Dialog as={Fragment} onClose={closeModal}>
              <Transition.Child
                className="fixed top-0 bottom-0 left-0 right-0 bg-black/70 flex items-center justify-center"
                enter="duration-300"
                enterFrom="opacity-0"
                enterTo="opacity-100"
                leave="duration-300 delay-150"
                leaveFrom="opacity-100"
                leaveTo="opacity-0"
              >
                <Transition.Child
                  as={Fragment}
                  enter="delay-150 duration-200"
                  enterFrom="opacity-0 scale-50"
                  enterTo="opacity-100 scale-100"
                  leave="duration-100"
                  leaveFrom="opacity-50 scale-50"
                  leaveTo="opacity-0 scale-0"
                >
                  <Dialog.Panel className="w-4/5 bg-white rounded-2xl p-6 flex flex-col items-center text-center">
                    <Dialog.Title className="text-secondary font-semibold">
                      Request Submitted Successfully!
                    </Dialog.Title>
                    <Dialog.Description className="mt-5 text-darkgray text-sm font-light">
                      Your membership request has been submitted successfully.
                      Our team will get back to you shortly. Welcome to the path
                      of better health and wellness!
                    </Dialog.Description>
                    <button
                      className="mt-6 px-6 py-3 rounded-full outline-none bg-gradient text-white text-sm font-medium"
                      type="button"
                      onClick={closeModal}
                    >
                      Ok
                    </button>
                  </Dialog.Panel>
                </Transition.Child>
              </Transition.Child>
            </Dialog>
          </Transition>
        </div>
      ) : (
        <p className="my-12 text-sm text-center">No Available Voucher</p>
      )}
    </div>
  );
};

export default Vouchers;
