import { useMemo, useState } from "react";
import Mood0 from "../assets/mood-trak/very-sad.svg";
import Mood1 from "../assets/mood-trak/sad.svg";
import Mood2 from "../assets/mood-trak/normal.svg";
import Mood3 from "../assets/mood-trak/good.svg";
import Mood4 from "../assets/mood-trak/excited.svg";
import {
  Accordion,
  AccordionHeader,
  AccordionBody,
} from "@material-tailwind/react";
import { Link, useLocation } from "react-router-dom";

const MoodIcon = {
  Awful: Mood0,
  Sad: Mood1,
  Normal: Mood2,
  Happy: Mood3,
  Excited: Mood4,
};

const MoodListItem = ({ timestamp = new Date(), answers = [] }) => {
  const [open, setOpen] = useState(false);
  const [mood, restAnswers] = useMemo(() => {
    const mood = answers?.[0] ?? "";
    const restAnswers = answers?.slice?.(1) ?? [];
    return [mood, restAnswers];
  }, [answers]);
  return (
    <Accordion
      open={open}
      icon={
        <svg
          xmlns="http://www.w3.org/2000/svg"
          className={`h-5 w-5 transition-transform ${open ? "rotate-180" : ""}`}
          fill="none"
          viewBox="0 0 24 24"
          stroke="currentColor"
          strokeWidth={2}
        >
          <path
            strokeLinecap="round"
            strokeLinejoin="round"
            d="M19 9l-7 7-7-7"
          />
        </svg>
      }
      className="bg-[#fff] rounded-[12px] px-4 overflow-hidden relative box-shadow mb-4"
    >
      <AccordionHeader
        onClick={() => setOpen((s) => !s)}
        className="border-0 h-[80px]"
      >
        <div className="grow flex items-center space-x-3">
          <img className="w-12 shrink-0" src={MoodIcon[mood]} alt={mood} />
          <div className="grow flex flex-col">
            <p className="text-[11px] leading-normal font-medium text-secondary">
              {timestamp.toLocaleDateString(undefined, { dateStyle: "full" })}
            </p>
            <h4 className="text-base font-medium text-primary">{mood}</h4>
          </div>
        </div>
      </AccordionHeader>
      <AccordionBody className="pt-0">
        <ul className="list-none flex flex-wrap">
          {restAnswers.map((ans, index) => (
            <li
              key={index}
              className="mr-3 mb-2 text-[12px] text-primary font-normal border rounded-[15px] px-3 py-1 border-[#dae6fc]"
            >
              {ans}
            </li>
          ))}
        </ul>
      </AccordionBody>
    </Accordion>
  );
};

const MoodHistory = () => {
  const {
    state: { answerList },
  } = useLocation();
  const listData = useMemo(() => {
    const list = {};
    answerList?.forEach?.((q) => {
      const options = q.question_options ?? {};
      const answers = q.answer_details ?? [];
      answers.forEach((ans) => {
        const date = new Date(ans.answered_on ?? 0).toISOString().split("T")[0];
        const data = list[date] ?? [];
        ans.answer?.forEach?.((a) => data.push(options[a]));
        list[date] = data;
      });
    });
    return Object.entries(list)
      .map(([date, answers]) => ({ date: new Date(date), answers }))
      .sort((a, b) => b.date - a.date);
  }, [answerList]);

  return (
    <div
      className="mb-28 w-full px-6"
      data-aos="fade-up"
      data-aos-duration="1000"
      data-aos-delay="5"
    >
      <div
        className="flex items-center mb-5"
        data-aos="fade-right"
        data-aos-duration="1000"
        data-aos-delay="50"
      >
        <Link to={-1} className="mr-3">
          <svg
            width="6"
            height="10"
            viewBox="0 0 10 17"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M9.42402 16.2142C9.99277 15.6454 9.99277 14.7267 9.42402 14.1579L3.76569 8.49959L9.42402 2.84126C9.99277 2.27251 9.99277 1.35376 9.42402 0.785009C8.85527 0.216259 7.93652 0.216259 7.36777 0.785009L0.674024 7.47876C0.105274 8.04751 0.105274 8.96626 0.674024 9.53501L7.36777 16.2288C7.92194 16.7829 8.85527 16.7829 9.42402 16.2142Z"
              fill="#192852"
            />
          </svg>
        </Link>
        <h3 className="text-primary text-[14px] font-semibold">Mood History</h3>
      </div>

      {listData.map((listItem, index) => (
        <MoodListItem
          key={index}
          timestamp={listItem.date}
          answers={listItem.answers}
        />
      ))}
    </div>
  );
};

export default MoodHistory;
