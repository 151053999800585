import {
  Fragment,
  // useEffect,
  useMemo,
  useState,
} from "react";
import { Link } from "react-router-dom";
import { Dialog, Transition } from "@headlessui/react";
import { useAuth } from "../utils/auth";
import { sdkWrapperURL } from "../utils/api-url-list";
import { logAnalyticsEvent } from "../utils/analytics";
import CareCoin from "../assets/images/care-coin.png";
import { ReactComponent as BackArrow } from "../assets/icons/BackArrow.svg";
import { ReactComponent as Logout } from "../assets/icons/logout-icon.svg";
import { ReactComponent as Refresh } from "../assets/icons/refresh-icon.svg";
import { ReactComponent as Loader } from "../assets/icons/Loader.svg";

const MyProfile = () => {
  const {
    getToken,
    userData,
    subscriptionPlanDetails: { packageName, careCoins },
    logout,
  } = useAuth();
  const age = useMemo(() => {
    const ageDifMs = Date.now() - new Date(userData.dob).getTime();
    const ageDate = new Date(ageDifMs);
    return Math.abs(ageDate.getUTCFullYear() - 1970);
  }, [userData.dob]);
  // const [balance, setBalance] = useState(-1);

  const [modalOpen, setModalOpen] = useState("");
  const [isModalBtnDisabled, setModalBtnDisabled] = useState(false);
  const deleteProfile = async () => {
    setModalBtnDisabled(true);
    try {
      const token = await getToken();
      const deleteResp = await fetch(
        sdkWrapperURL("/users/profile/delete-data"),
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
            Authorization: token,
          },
          body: JSON.stringify({
            employee_id: userData.user_id,
            profile_id: userData.profile_id,
          }),
        }
      );
      const deleteRespJSON = await deleteResp.json();
      if (deleteRespJSON?.statusCode?.toString().startsWith("2")) {
        if (userData.relation?.toUpperCase?.() === "SELF")
          logAnalyticsEvent("acount_deleted");
        setModalOpen("");
        logout();
      } else throw new Error(deleteRespJSON?.message ?? "Error in Saving");
    } catch (err) {
      console.error(err);
    } finally {
      setModalBtnDisabled(false);
    }
  };

  const modalContent = {
    logout: {
      heading: "Logout",
      content: "Are you sure you want to Logout?",
      action: logout,
    },
    delete: {
      heading: "Delete Account",
      content:
        "This will Delete your Account and Erase all the Data stored at CarePlix\nAre you sure you want to proceed?",
      action: deleteProfile,
    },
  };

  // useEffect(() => {
  //   (async () => {
  //     try {
  //       const token = await getToken();
  //       const walletCoins = await fetch(sdkWrapperURL("/flyy/get-balance"), {
  //         method: "POST",
  //         headers: { "content-type": "application/json", Authorization: token },
  //         body: JSON.stringify({ profile_id: userData.profile_id }),
  //       });
  //       const res = await walletCoins.json();
  //       if (res?.statusCode?.toString().startsWith("2")) setBalance(res.coins);
  //       else
  //         throw new Error(res?.message ?? "Error in fetching wallet balance.");
  //     } catch (err) {
  //       console.error(err);
  //     }
  //   })();
  // }, [getToken, userData.profile_id]);

  return (
    <div className="px-6 pb-24 pt-8">
      <div className="mb-5 flex items-center justify-between text-white">
        <Link className="shrink-0 p-1 pr-2.5" to={-1}>
          <BackArrow className="h-3 w-3" />
        </Link>
        <h3 className="grow text-lg font-semibold">My Profile</h3>
        <button
          className="shrink-0"
          type="button"
          onClick={() => setModalOpen("logout")}
        >
          <Logout className="h-5 w-5" />
          <span className="sr-only">Logout</span>
        </button>
      </div>

      <h4 className="text-lg font-semibold text-secondary capitalize">
        {userData.fname} {userData.lname}
      </h4>
      <p className="mb-2 text-xs text-white capitalize">
        {age} Years, {userData.gender}
      </p>
      <Link
        to="/select-user"
        className="px-2 py-1 inline-flex items-center justify-center space-x-1 rounded-full bg-gradient text-white text-xxs"
      >
        <Refresh className="shrink-0 h-3 w-3" />
        &nbsp;Switch User
      </Link>

      {careCoins >= 0 && (
        <div className="mt-7 p-4 flex justify-between items-center rounded-2xl bg-gradient-transparent">
          <div>
            <p className="text-sm text-white">Care Coins</p>
            <h2 className="text-xl text-[#FFD06C] font-bold">{careCoins}</h2>
          </div>
          <img src={CareCoin} alt="Care Coins" />
        </div>
      )}

      <div className="mt-4 flex flex-col">
        <h3 className="text-sm text-[#6A10D3] font-semibold mb-3">Profile</h3>
        <Link
          to="/my-account"
          className="mb-3 px-4 py-3 rounded-xl bg-gradient-to-l from-[#331879]/30 to-[#5517A7]/30 text-white text-xs font-light"
        >
          Update Profile
        </Link>
        {/* <Link
          to=""
          className="bg-gradient-to-l from-[#331879]/30 to-[#5517A7]/30 h-10 pl-4 rounded-[10px] flex items-center text-xs font-light text-white mb-3"
        >
          Update Members
        </Link> */}
        {/* <Link
          to="/insurance-policy"
          className="mb-3 px-4 py-3 rounded-xl bg-gradient-to-t from-transparent to-[#943C86] text-white text-xs font-light"
        >
          View Insurance Policy
        </Link> */}

        {/* <h3 className="text-sm text-[#6A10D3] font-semibold mb-3">
          Subscription
        </h3>
        <Link
          to="/our-pricing"
          className="mb-3 px-4 py-3 rounded-xl bg-gradient-to-l from-[#331879]/30 to-[#5517A7]/30 text-white text-xs font-light"
        >
          My Subscription
        </Link> */}

        <h3 className="text-sm text-[#6A10D3] font-semibold mb-3">
          My Settings
        </h3>
        <div className="mb-3 px-4 py-3 flex items-center justify-between rounded-xl bg-gradient-to-l from-[#331879]/30 to-[#5517A7]/30 text-white text-xs font-light">
          <p>Membership</p>
          <p className="capitalize font-medium text-[#F159CF]">
            {packageName.toLowerCase?.()}
          </p>
        </div>
        {/* <Link
          to="/configure-alerts"
          className="bg-gradient-to-l from-[#331879]/30 to-[#5517A7]/30 h-10 pl-4 rounded-[10px] flex items-center text-xs font-light text-white mb-3"
        >
          Configure Alerts & Notifications
        </Link> */}
        {/* <Link
          to=""
          className="bg-gradient-to-l from-[#331879]/30 to-[#5517A7]/30 h-10 pl-4 rounded-[10px] flex items-center text-xs font-light text-white mb-3"
        >
          Change Theme
        </Link> */}
        <Link
          to="/support"
          className="mb-3 px-4 py-3 rounded-xl bg-gradient-to-l from-[#331879]/30 to-[#5517A7]/30 text-white text-xs font-light"
        >
          Contact Support
        </Link>
        {/* <Link
          to=""
          className="bg-gradient-to-l from-[#331879]/30 to-[#5517A7]/30 h-10 pl-4 rounded-[10px] flex items-center text-xs font-light text-white mb-3"
        >
          Referral Code
        </Link> */}
        {/* <Link
          to=""
          className="bg-gradient-to-l from-[#331879]/30 to-[#5517A7]/30 h-10 pl-4 rounded-[10px] flex items-center text-xs font-light text-white mb-3"
        >
          Change Language
        </Link> */}
        <button
          type="button"
          onClick={() => setModalOpen("delete")}
          className="px-4 py-3 rounded-xl bg-gradient-to-t from-transparent to-[#DF0606]/30 text-white text-xs font-light"
        >
          Delete Account
        </button>
      </div>

      <Transition show={modalOpen?.length > 0} as={Fragment}>
        <Dialog as={Fragment} onClose={() => {}}>
          <Transition.Child
            className="fixed top-0 bottom-0 left-0 right-0 bg-black/70 flex items-center justify-center"
            enter="duration-300"
            enterFrom="opacity-0"
            enterTo="opacity-100"
            leave="duration-300 delay-150"
            leaveFrom="opacity-100"
            leaveTo="opacity-0"
          >
            <Transition.Child
              as={Fragment}
              enter="delay-150 duration-200"
              enterFrom="opacity-0 scale-50"
              enterTo="opacity-100 scale-100"
              leave="duration-100"
              leaveFrom="opacity-50 scale-50"
              leaveTo="opacity-0 scale-0"
            >
              <Dialog.Panel className="w-4/5 bg-white rounded-2xl p-6 flex flex-col text-center">
                <Dialog.Title className="text-primary font-semibold">
                  {modalContent[modalOpen]?.heading}
                </Dialog.Title>

                <Dialog.Description className="mt-1.5 text-primary/70 text-sm">
                  {modalContent[modalOpen]?.content}
                </Dialog.Description>

                <div className="mt-4 flex justify-around">
                  <button
                    className="w-28 px-4 py-2 rounded-full bg-error/80 text-white text-xs font-medium"
                    type="button"
                    onClick={() => setModalOpen("")}
                    disabled={isModalBtnDisabled}
                  >
                    Cancel
                  </button>
                  <button
                    className="w-28 px-4 py-2 flex items-center justify-center space-x-2.5 rounded-full bg-gradient text-white"
                    type="button"
                    onClick={modalContent[modalOpen]?.action}
                    disabled={isModalBtnDisabled}
                  >
                    <span className="text-xs font-medium">Proceed</span>
                    {isModalBtnDisabled && (
                      <Loader className="shrink-0 h-4 w-4" />
                    )}
                  </button>
                </div>
              </Dialog.Panel>
            </Transition.Child>
          </Transition.Child>
        </Dialog>
      </Transition>
    </div>
  );
};

export default MyProfile;
